import React from 'react';
import { NoEquipmentPlaceholder } from './NoEquipmentPlaceholder';
import { EquipmentItem } from './EquipmentItem';
import { EQUIPMENT_ACTIONS } from '../ManageEquipment.constants';

export const TestSerialEquipmentList: React.FC<any> = props => {
  const { equipmentStatus, equipments, equipmentSearch, onSelectedEquipment } = props;

  const handleCreateEquipment = (serial: string) => {
    onSelectedEquipment &&
      onSelectedEquipment(
        {
          serial: serial.toUpperCase(),
        },
        EQUIPMENT_ACTIONS.CREATE
      );
  };

  return (
    <React.Fragment>
      {equipments && !!equipments.length ? (
        equipments
          .slice(0, 20)
          .map((equipment: any) => (
            <EquipmentItem
              key={equipment.serial}
              equipment={equipment}
              equipmentStatus={equipmentStatus}
              onSelectedEquipment={onSelectedEquipment}
            />
          ))
      ) : (
        <NoEquipmentPlaceholder
          equipmentStatus={equipmentStatus}
          equipmentSearch={equipmentSearch}
          onCreateEquipment={handleCreateEquipment}
        />
      )}
    </React.Fragment>
  );
};

export default TestSerialEquipmentList;
