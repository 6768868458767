import styled from 'styled-components';

export const PickerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;

  //.datetime-placeholder {
  //  --placeholder-color: var(--ion-color-dark);
  //  color: var(--ion-color-dark);
  //  text-align: center;
  //}

  ion-datetime {
    flex: 1 1 auto;
    padding: 0 15px;
    text-align: center;
    --placeholder-color: var(--ion-color-dark);
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }
`;
