import backendConstants from './backendConstants';
export const routes = {
  root: '/',
  home: '/home',
  stats: '/stats',
  login: '/login',
  resetPassword: '/reset-password',
  report: {
    main: '/report',
    byClient: '/report?byTab=CLIENT',
    bySale: '/report?byTab=SALE',
    byDelivery: '/report?byTab=DELIVERY',
    byEquipment: '/report?byTab=EQUIPMENT',
  },
  notification: '/notification',
  settings: {
    main: '/settings/theme',
    theme: '/settings/theme',
    restore: '/settings/restore',
    changePassword: '/settings/change-password',
  },
  manageUser: {
    main: '/manage-user', // User list, select one user in list
    add: '/manage-user?userTab=ADD',
    list: '/manage-user?userTab=LIST',
    userClient: '/manage-user?userTab=USER_CLIENT',
  },
  manageTank: {
    main: '/manage-tank',
    empty: '/manage-tank?tankStatus=EMPTY',
    loading: '/manage-tank?tankStatus=LOADING',
    readyToUse: '/manage-tank?tankStatus=READY_TO_USE',
    atClient: '/manage-tank?tankStatus=AT_CLIENT',
    testSerial: '/manage-tank?tankStatus=TEST_SERIAL',
  },
  manageEquipment: {
    main: '/manage-equipment',
    test: '/manage-equipment?equipmentStatus=TEST_SERIAL',
    readyToUse: '/manage-equipment?equipmentStatus=READY_TO_USE',
    soldRentedLent: '/manage-equipment?equipmentStatus=SOLD_RENTED_LENT',
    warranty: '/manage-equipment?equipmentStatus=WARRANTY',
    maintain: '/maintain-equipment',
    disabled: '/manage-equipment?equipmentStatus=DISABLED',
  },
  manageClient: {
    main: '/manage-client',
    add: '/manage-client?userClientTab=ADD',
    list: '/manage-client?userClientTab=LIST',
    demo: '/manage-client?userClientTab=DEMO',
    report: '/manage-client?userClientTab=REPORT',
  },
  manageInvoice: {
    main: '/manage-invoice',
    day: '/manage-invoice?invoiceTab=DAY',
    week: '/manage-invoice?invoiceTab=WEEK',
    month: '/manage-invoice?invoiceTab=MONTH',
    lastMonth: '/manage-invoice?invoiceTab=LAST_MONTH',
    add: '/manage-invoice?invoiceTab=ADD',
  },
  storeTransfer: {
    main: '/store-transfer',
    transferTank: '/store-transfer?transferTab=TANK',
    transferEquipment: '/store-transfer?transferTab=EQUIPMENT',
  },
};

export const homeRoutes = {
  [backendConstants.USER_TYPES.ADMIN]: routes.manageInvoice.month,
  [backendConstants.USER_TYPES.STORE]: routes.manageInvoice.month,
  [backendConstants.USER_TYPES.REVIEWER]: routes.manageInvoice.month,
  [backendConstants.USER_TYPES.CLIENT]: routes.home,

  [backendConstants.USER_TYPES.SALE]: routes.manageInvoice.main,
  [backendConstants.USER_TYPES.DELIVERY]: routes.manageInvoice.main,

  [backendConstants.USER_TYPES.TECHNICAL]: routes.manageEquipment.maintain,
};

export default routes;
