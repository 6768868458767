import styled from 'styled-components';

export const LoginFormWrapper: any = styled.div`
  padding-top: 50px;

  .app-logo-container {
    position: relative;
  }

  .app-test-title {
    position: absolute;
    right: 80px;
    font-weight: 800;
  }

  .app-test-link {
    display: block;
    padding: 20px 0;
    font-size: 12px;
    text-align: center;
  }
`;
