import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonItemGroup,
  IonItemDivider,
  IonBadge,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonRefresherContent,
  IonRefresher,
  IonButton,
  IonSearchbar,
  IonFab, IonInfiniteScrollContent, IonInfiniteScroll,
} from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';
import * as generalUtils from 'utils/generalUtils';
import { arrowDown, arrowUp } from 'ionicons/icons';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as confirmActions from 'store/confirm';
import * as tankActions from 'store/tank';
import * as equipmentActions from 'store/equipment';
import * as userClientActions from 'store/userClient';
import * as userSaleActions from 'store/userSale';
import * as invoiceActions from 'store/invoice';
import * as clientActions from 'store/client';
import { Wrapper, SubHeader } from 'commons/commons.styles';
import backendConstants from 'backendConstants';
import _ from 'lodash';
import qs from 'querystring';
import { RefresherEventDetail } from '@ionic/core';
import languages from 'languages';
import routes from 'routes';
import ShowContent from 'commons/ShowContent/ShowContent';
import ClientItem from './Components/ClientItem';
import GroupedClientReport from './Components/GroupedClientReport';
import ClientChangeModal from './Modals/ClientChangeModal';
import { ClientWrapper } from './ManageClient.styles';
import { AddEditClientForm } from './Forms/AddEditClientForm';
import { getStore, isCenterStore } from 'utils/sessionUtils';

export const CLIENT_TAB_KEYS = {
  ADD: 'ADD',
  LIST: 'LIST',
  DEMO: 'DEMO',
  REPORT: 'REPORT',
};

export const CLIENT_REPORT_OPTIONS = {
  SECTOR: 'SECTOR',
  PROVINCE: 'PROVINCE',
};

export const NormalClientList: React.FC<any> = props => {
  const { clients, handleSelectedClient, handleAddUserToClient } = props;
  const [clientScrollCount, setClientScrollCount] = useState(10);
  const handleClientCountIncrement = (e: any) => {
    setClientScrollCount(clientScrollCount + 10);
    e && e.target && e.target.complete();
  };
  return (
    <ClientWrapper>
      {clients.slice(0, clientScrollCount).map((client: any) => (
        <ClientItem
          key={client._id}
          client={client}
          onSelectClient={handleSelectedClient}
          onAddUserToClient={handleAddUserToClient}
        />
      ))}
      <IonInfiniteScroll
        threshold={`200px`}
        onIonInfinite={handleClientCountIncrement}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </ClientWrapper>
  )
}

export const GroupedClientList: React.FC<any> = props => {
  const { clients, handleExpandGroup, groupExpanded, handleSelectedUserClient } = props;
  const [clientScrollCount, setClientScrollCount] = useState(10);
  const handleClientCountIncrement = (e: any) => {
    setClientScrollCount(clientScrollCount + 10);
    e && e.target && e.target.complete();
  };
  return <React.Fragment>
    {
      clients.slice(0, clientScrollCount).map((client:any) => (
        <IonItemGroup key={client._id}>
          <IonItemDivider
            onClick={
              client.status === backendConstants.CLIENT_STATUSES.ACTIVE
                ? handleExpandGroup(client._id)
                : undefined
            }
          >
            <IonLabel>
              <IonText
                color={client.status === backendConstants.CLIENT_STATUSES.ACTIVE ? undefined : `danger`}
              >
                {client.name}
              </IonText>
            </IonLabel>
            {client.userClients && (
              <IonBadge color={`medium`} slot={`end`}>
                {client.userClients.length}
              </IonBadge>
            )}
            <IonIcon icon={(groupExpanded as any)[client._id] ? arrowUp : arrowDown} slot={`end`} />
          </IonItemDivider>
          {(groupExpanded as any)[client._id] &&
          client.status === backendConstants.CLIENT_STATUSES.ACTIVE &&
          client.userClients.map((userClient: any) => (
            <IonItem
              key={userClient._id}
              button={true}
              disabled={userClient.status === backendConstants.USER_STATUSES.DISABLED}
              onClick={handleSelectedUserClient(userClient, client)}
            >
              <IonLabel>
                <p>
                  <IonText color={`primary`}>{userClient.name}</IonText>
                </p>
                {userClient.status === backendConstants.USER_STATUSES.DISABLED && (
                  <p>
                    <IonText color={`danger`}>Không hoạt động</IonText>
                  </p>
                )}
                <p>
                  <IonText>Số máy sử dụng: {userClient.equipments.length} máy</IonText>
                </p>
                <p>
                  <IonText>Sổ vỏ nợ: {userClient.tanks.length} vỏ</IonText>
                </p>
              </IonLabel>
            </IonItem>
          ))}
        </IonItemGroup>
      ))
    }
    <IonInfiniteScroll
      threshold={`200px`}
      onIonInfinite={handleClientCountIncrement}
    >
      <IonInfiniteScrollContent />
    </IonInfiniteScroll>
  </React.Fragment>;
}

export const ManageClient: React.FC<any> = props => {
  const {
    tankReducer,
    clientReducer,
    userClientReducer,
    userSaleReducer,
    equipmentReducer,

    setSubmitting,
    setToast,
    setConfirm,

    createClient,
    updateClient,
    readClients,

    createInvoice,

    createUserClient,
    readUserClients,
    resetUserClients,

    checkUserSales,
    resetUserSales,

    checkTanks,
    updateTanks,
    resetTanks,

    selfReducer,

    checkEquipments,
    resetEquipments,

    history,
    location,
  } = props;

  const queryParams = qs.parse(location.search.replace('?', ''));

  const [refreshing, setRefreshing] = useState(false);
  const [userClientTab, setUserClientTab] = useState<string>((queryParams.userClientTab as string) || CLIENT_TAB_KEYS.LIST);
  const [clientSearch, setClientSearch] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedUserClient, setSelectedUserClient] = useState<any>(null);
  const [groupExpanded, setGroupExpanded] = useState({});
  const [formProps, setFormProps] = useState<any>(null);
  const [reportOption, setReportOption] = useState(CLIENT_REPORT_OPTIONS.SECTOR);

  useEffect(() => {
    if (queryParams.userClientTab) {
      setUserClientTab(queryParams.userClientTab as any);
    }
  }, [queryParams.userClientTab]);

  const clients = clientReducer && clientReducer.responseRead && clientReducer.responseRead.data;

  const userClients = userClientReducer && userClientReducer.responseRead && userClientReducer.responseRead.data;

  const userSales = userSaleReducer && userSaleReducer.responseCheck && userSaleReducer.responseCheck.data;

  const availableTanks = tankReducer && tankReducer.responseCheck && tankReducer.responseCheck.data;

  const availableEquipments = equipmentReducer && equipmentReducer.responseCheck && equipmentReducer.responseCheck.data;

  const self = selfReducer && selfReducer.response;

  const showContent = useMemo(() => {
    return location.pathname === routes.manageClient.main;
  }, [location.pathname]);

  const clientsByUserClients = useMemo(() => {
    if (!showContent) return [];
    if (!userClients || !clients) return [];
    const groups = _.groupBy(userClients, userClient => userClient.clientId._id);

    if (Object.keys(groups).length <= 1 && Object.keys(groups)[0] === 'undefined') return [];

    const groupClients = clients.map((client: any) => {
      return groups[client._id]
        ? {
            ...groups[client._id][0].clientId,
            userClients: groups[client._id],
          }
        : {
            ...client,
            userClients: [],
            tanks: [],
            equipments: [],
          };
    });
    return (
      groupClients
        .map((group: any) => {
          const totalTank = group.userClients.reduce(
            (total: number, userClient: any) => total + ((userClient.tanks && userClient.tanks.length) || 0),
            0
          );
          return {
            ...group,
            totalTank,
            tanks: (group as any).userClients.reduce(
              (clientTanks: any, userClient: any) => [...clientTanks, ...userClient.tanks],
              []
            ),
            equipments: (group as any).userClients.reduce(
              (clientEquipments: any, userClient: any) => [...clientEquipments, ...userClient.equipments],
              []
            ),
          };
        })
        // IF DEMO TAB - ONLY CURRENT STORE RECORD HERE
        .filter((client: any) => userClientTab !== CLIENT_TAB_KEYS.DEMO || client.store === getStore())
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClients, clients, showContent]);

  const clientsByClientSearch = useMemo(() => {
    if (!clientsByUserClients) return [];
    return [...clientsByUserClients]
      .sort((clientA, clientB) => clientA.status.localeCompare(clientB.status) || clientA.name.localeCompare(clientB.name))
      .filter(client => client.name.toLowerCase().includes(clientSearch.toLowerCase()));
  }, [clientsByUserClients, clientSearch]);

  const clientsForReport = useMemo(() => {
    return clientsByClientSearch.filter(
      (client: any) => client && client.province && client.status === backendConstants.CLIENT_STATUSES.ACTIVE
    );
  }, [clientsByClientSearch]);

  const clientsByProvince = useMemo(() => {
    return _.groupBy(clientsForReport, (client: any) => client.province);
  }, [clientsForReport]);

  const clientsBySector = useMemo(() => {
    return _.groupBy(clientsForReport, (client: any) => client.sector);
  }, [clientsForReport]);

  const clientByClientSearchDateSorted = useMemo(() => {
    return [...clientsByClientSearch].sort((clientA, clientB) => clientB.createdAt.localeCompare(clientA.createdAt));
  }, [clientsByClientSearch]);

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const resetGroupExpanded = () => setGroupExpanded({});

  const resetClientSearch = () => setClientSearch('');
  const resetSelectedClient = () => setSelectedClient(null);
  const resetSelectedUserClient = () => setSelectedUserClient(null);
  const resetFormProps = () => setFormProps(null);
  const resetReportOption = () => setReportOption(CLIENT_REPORT_OPTIONS.SECTOR);

  const resetCurrentClientTab = () => {
    resetUserClients();
    resetUserSales();

    resetFormProps();
    resetGroupExpanded();
    resetClientSearch();
    resetSelectedClient();
    resetSelectedUserClient();
    resetReportOption();

    // Reset Content Scroll
    resetContentScrollTop();
  };

  const handleExpandGroup = (groupStatus: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupStatus]: !(groupExpanded as any)[groupStatus],
    });

  const handleSearchChange = (event: any) => {
    if (event && event.target) {
      setClientSearch(event.target.value || '');
    }
  };

  const handleUserClientTabChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setUserClientTab(event.detail.value);
      history.push(`${routes.manageClient.main}?userClientTab=${event.detail.value}`);
    }
  };

  const handleClientReportOptionChange = (event: any) => {
    if (event && event.target && event.target.value) {
      setReportOption(event.target.value);
    }
  };

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    await handleReadClient();
    await handleReadUserClient();
    await handleReadUserSale();
    setRefereshingDebounced(event);
  };

  const handleReadClient = () => {
    return readClients({
      page: 1,
      pageSize: 99999,
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        // status: backendConstants.CLIENT_STATUSES.ACTIVE
      },
      populate: [
        {
          path: 'parentId',
        },
      ],
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadUserClient = () => {
    return readUserClients({
      page: 1,
      pageSize: 99999,
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        // status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
      populate: [
        {
          path: 'clientId',
          populate: {
            path: 'parentId',
          },
        },
      ],
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadUserSale = () => {
    return checkUserSales({
      page: 1,
      pageSize: 99999,
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        userType: 'SALE',
      },
      populate: [
        {
          path: 'clientId',
          populate: {
            path: 'parentId',
          },
        },
      ],
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadTank = () => {
    if (selectedUserClient && selectedUserClient.reportExcluded === backendConstants.USER_CLIENT_REPORT_EXCLUDED.YES) {
      return checkTanks({
        page: 1,
        pageSize: 99999,
        filter: {
          store: getStore(),
          status: backendConstants.TANK_STATUSES.READY_TO_USE,
        },
        sort: {
          updatedAt: 1,
        },
        populate: [
          {
            path: 'clientUserId',
            select: '_id name',
          },
        ],
      });
    }

    // when select user client for demo export tanks
    if (selectedUserClient && selectedUserClient.equipmentTankTypes && selectedUserClient.equipmentTankTypes.length) {
      return checkTanks({
        page: 1,
        pageSize: 99999,
        filter: {
          store: getStore(),
          type: {
            $in: selectedUserClient.equipmentTankTypes,
          },
          status: backendConstants.TANK_STATUSES.READY_TO_USE,
        },
        sort: {
          updatedAt: 1,
        },
        populate: [
          {
            path: 'clientUserId',
            select: '_id name',
          },
        ],
      });
    }

    if (selectedUserClient) {
      return checkTanks({
        page: 1,
        pageSize: 1,
        filter: {
          returnNothing: 1,
        },
      });
    }

    // when select client and show modal to view client info
    if (selectedClient && !selectedClient.willCreateUser && selectedClient.tanks && selectedClient.tanks.length) {
      return checkTanks({
        page: 1,
        pageSize: 99999,
        filter: {
          store: isCenterStore() ? undefined : getStore(),
          _id: {
            $in: selectedClient.tanks,
          },
        },
        sort: {
          updatedAt: 1,
        },
        populate: [
          {
            path: 'clientUserId',
            select: '_id name',
          },
        ],
      });
    }

    return resetTanks();
  };

  const handleReadEquipment = () => {
    if (selectedClient && !selectedClient.willCreateUser && selectedClient.equipments && selectedClient.equipments.length) {
      return checkEquipments({
        page: 1,
        pageSize: 99999,
        filter: {
          store: isCenterStore() ? undefined : getStore(),
          _id: {
            $in: selectedClient.equipments,
          },
          status: {
            $in: [
              backendConstants.EQUIPMENT_STATUSES.SOLD,
              backendConstants.EQUIPMENT_STATUSES.RENTED,
              backendConstants.EQUIPMENT_STATUSES.LENT,
            ],
          },
        },
        sort: {
          updatedAt: -1,
        },
      });
    } else {
      return resetEquipments();
    }
  };

  const handleSelectedClient = (client: any) => {
    if (client) {
      setSelectedClient(client);
    }
  };

  const handleAddUserToClient = (client: any) => {
    if (client) {
      setSelectedClient({
        ...client,
        willCreateUser: true,
      });
    }
  };

  const handleSelectedUserClient = (userClient: any, client: any) => () => {
    if (userClient) {
      setSelectedUserClient(userClient);
      setSelectedClient(client);
    }
  };

  useEffect(() => {
    return setGroupExpanded({});
  }, [clientsByUserClients]);

  useEffect(() => {
    resetCurrentClientTab();
    if (showContent) {
      handleReadClient();
      handleReadUserClient();
      handleReadUserSale();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClientTab, showContent]);

  useEffect(() => {
    handleReadEquipment();
    handleReadTank();
    return () => {
      resetEquipments();
      resetTanks();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const handleFormUpdate = generalUtils.handleFormPropsUpdate(formProps, setFormProps);

  const handleConfirmAlert = _.throttle(() => setConfirm('Xác nhận thêm khách hàng mới', handleConfirmAdd), 3000, { leading: true, trailing: false });

  const resetSubmittingDebounced = _.debounce((isSuccessful, successMessage, failedMessage) => {
    if (isSuccessful) {
      setToast(successMessage, `primary`);
    } else {
      setToast(failedMessage, `danger`);
    }
    resetCurrentClientTab();
    handleReadClient();
    handleReadUserClient();
    handleReadUserSale();
    setSubmitting(false);
  }, 500);

  const handleConfirmAdd = async () => {
    const messages = [`Thêm khách hàng thành công`, `Thêm khách hàng không thành công`];
    setSubmitting(true);
    try {
      await createClient({
        store: getStore(),
        ...formProps.values,
      });
      return resetSubmittingDebounced(true, messages[0], messages[1]);
    } catch (err) {
      return resetSubmittingDebounced(false, messages[0], messages[1]);
    }
  };

  const handleConfirmEdit = async (selectedClient: any, extraData: any) => {
    // Xuat DEMO
    if (extraData.selectedUserClient && extraData.selectedTanks) {
      const messages = [`Xuất demo thành công`, `Xuất demo không thành công`];
      setSubmitting(true);
      try {
        const availableTanksResponse = await checkTanks({
          filter: {
            store: getStore(),
            _id: {
              $in: extraData.selectedTanks.map((tank: any) => tank._id),
            },
            status: backendConstants.TANK_STATUSES.READY_TO_USE,
          },
        });
        const availableTanks = availableTanksResponse && availableTanksResponse.data && availableTanksResponse.data.data;
        console.log(availableTanks.length, extraData.selectedTanks.length);
        if (availableTanks && availableTanks.length === extraData.selectedTanks.length) {
          const newInvoiceResponse = await createInvoice({
            store: getStore(),
            type: backendConstants.INVOICE_TYPES.DEMO,
            status: backendConstants.INVOICE_STATUSES.APPROVED,
            ownerUser: self.user._id,
            clientUserId: extraData.selectedUserClient._id,
            tanks: extraData.selectedTanks.map((tank: any) => tank._id),
            note: `Tạo bởi ${self.user.name}`,
          });
          const newInvoice = newInvoiceResponse && newInvoiceResponse.data && newInvoiceResponse.data.data;

          if (newInvoice) {
            await updateTanks({
              store: getStore(),
              serial: extraData.selectedTanks.map((tank: any) => tank.serial),
              status: backendConstants.TANK_STATUSES.LOCKED,
              clientUserId: extraData.selectedUserClient._id,
              invoiceId: newInvoice._id,
            });
            return resetSubmittingDebounced(true, messages[0], messages[1]);
          }
        }

        return resetSubmittingDebounced(false, messages[0], messages[1]);
      } catch (err) {
        return resetSubmittingDebounced(false, messages[0], messages[1]);
      }
    }

    // Tao KH
    if (extraData.formProps) {
      const messages = [`Thông tin KH cập nhật thành công`, `Thông tin KH cập nhật không thành công`];

      if (!extraData.formProps.values || _.isEmpty(extraData.formProps.values)) {
        return resetSubmittingDebounced(false, messages[0], messages[1]);
      }
      setSubmitting(true);
      try {
        await updateClient({
          store: getStore(),
          _id: selectedClient._id,
          ...extraData.formProps.values,
        });
        return resetSubmittingDebounced(true, messages[0], messages[1]);
      } catch (err) {
        return resetSubmittingDebounced(false, messages[0], messages[1]);
      }
    }

    // Tao TK KH
    if (extraData.formUserProps) {
      const messages = [`Tài khoản KH được tạo thành công`, `Tài khoản KH được tạo không thành công`];
      if (!extraData.formUserProps.values || _.isEmpty(extraData.formUserProps.values)) {
        return resetSubmittingDebounced(false, messages[0], messages[1]);
      }
      setSubmitting(true);
      try {
        await createUserClient({
          store: getStore(),
          ...extraData.formUserProps.values,
          clientId: selectedClient._id,
        });
        return resetSubmittingDebounced(true, messages[0], messages[1]);
      } catch (err) {
        return resetSubmittingDebounced(false, messages[0], messages[1]);
      }
    }

    return resetSubmittingDebounced(false, ``, `Cập nhật không thành công`);
  };

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.manageClient.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleUserClientTabChange} scrollable={true}>
              {Object.keys(CLIENT_TAB_KEYS).map((tabKey: any) => (
                <IonSegmentButton key={tabKey} value={tabKey} checked={userClientTab === tabKey}>
                  {(language.manageClient.ui.tabs as any)[tabKey]}
                </IonSegmentButton>
              ))}
            </IonSegment>
            {[CLIENT_TAB_KEYS.LIST, CLIENT_TAB_KEYS.DEMO, CLIENT_TAB_KEYS.REPORT].includes(userClientTab) && (
              <IonSearchbar
                placeholder={`Tìm tên khách hàng ...`}
                value={clientSearch}
                onIonChange={handleSearchChange}
                debounce={250}
              />
            )}
            {userClientTab === CLIENT_TAB_KEYS.REPORT && (
              <IonSegment onIonChange={handleClientReportOptionChange}>
                {Object.keys(CLIENT_REPORT_OPTIONS).map((option: any) => (
                  <IonSegmentButton key={option} value={option} checked={reportOption === option}>
                    {(languages.manageClient.ui.reportOptions as any)[option]}
                  </IonSegmentButton>
                ))}
              </IonSegment>
            )}
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>

            <ClientChangeModal
              isOpen={!!selectedClient}
              onClose={resetSelectedClient}
              onConfirm={handleConfirmEdit}
              availableTanks={availableTanks}
              availableEquipments={availableEquipments}
              selectedClient={selectedClient}
              selectedUserClient={selectedUserClient}
              userSales={userSales}
              setConfirm={setConfirm}
            />

            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {userClientReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    {userClientTab === CLIENT_TAB_KEYS.ADD && (
                      <AddEditClientForm userSales={userSales} onFormUpdate={handleFormUpdate} />
                    )}
                    {userClientTab === CLIENT_TAB_KEYS.LIST && (
                      <IonList className={`ion-margin-vertical`}>
                        {clientsByUserClients && (
                          <React.Fragment>
                            <IonListHeader>Kết quả: {clientsByClientSearch.length} khách hàng</IonListHeader>
                            <NormalClientList
                              clients={clientsByClientSearch}
                              handleSelectedClient={handleSelectedClient}
                              handleAddUserToClient={handleAddUserToClient}
                            />
                          </React.Fragment>
                        )}
                      </IonList>
                    )}
                    {userClientTab === CLIENT_TAB_KEYS.DEMO && (
                      <IonList>
                        <IonListHeader>Kết quả: {clientByClientSearchDateSorted.length} khách hàng</IonListHeader>
                        {clientByClientSearchDateSorted && (
                          <GroupedClientList
                            clients={clientByClientSearchDateSorted}
                            handleExpandGroup={handleExpandGroup}
                            groupExpanded={groupExpanded}
                            handleSelectedUserClient={handleSelectedUserClient}
                          />
                        )}
                      </IonList>
                    )}
                    {userClientTab === CLIENT_TAB_KEYS.REPORT && (
                      <React.Fragment>
                        <IonList className={`ion-margin-vertical`}>
                          <IonListHeader>
                            Kết quả: {clientsForReport && clientsForReport.length}
                            {' khách hàng'}
                          </IonListHeader>
                        </IonList>
                        {reportOption === CLIENT_REPORT_OPTIONS.SECTOR && (
                          <GroupedClientReport
                            groupedClients={clientsBySector}
                            onSelectClient={handleSelectedClient}
                            onAddUserToClient={handleAddUserToClient}
                          />
                        )}
                        {reportOption === CLIENT_REPORT_OPTIONS.PROVINCE && (
                          <GroupedClientReport
                            groupedClients={clientsByProvince}
                            onSelectClient={handleSelectedClient}
                            onAddUserToClient={handleAddUserToClient}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </IonGrid>
            {userClientTab === CLIENT_TAB_KEYS.ADD && (
              <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
                <IonButton
                  color={`primary`}
                  onClick={handleConfirmAlert}
                  disabled={!formProps || !formProps.dirty || !formProps.isValid}
                >
                  <IonLabel>{`Thêm khách hàng`}</IonLabel>
                </IonButton>
              </IonFab>
            )}
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  tankReducer: state.tankReducer,
  clientReducer: state.clientReducer,
  userClientReducer: state.userClientReducer,
  userSaleReducer: state.userSaleReducer,
  equipmentReducer: state.equipmentReducer,
  selfReducer: state.selfReducer,
});

const mapDispatchToProps = {
  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,
  setConfirm: confirmActions.setConfirm,

  createClient: clientActions.createClient,
  updateClient: clientActions.updateClient,
  readClients: clientActions.readClients,

  createUserClient: userClientActions.createUserClient,
  readUserClients: userClientActions.readUserClients,
  resetUserClients: userClientActions.resetUserClients,

  createInvoice: invoiceActions.createInvoice,

  checkTanks: tankActions.checkTanks,
  updateTanks: tankActions.updateTanks,
  resetTanks: tankActions.resetTanks,

  checkUserSales: userSaleActions.checkUserSales,
  resetUserSales: userSaleActions.resetUserSales,

  checkEquipments: equipmentActions.checkEquipments,
  resetEquipments: equipmentActions.resetEquipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageClient);
