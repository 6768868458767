import { API_PATH } from 'backendConstants';
import { generateCrudActionTypes, generateCrudReducer, generateThunkActions } from 'utils/storeUtils';

export const ENDPOINT = `${API_PATH}/password`;
export const CHANGE_PASSWORD_CRUD_ACTIONS = generateCrudActionTypes(`CHANGE_PASSWORD_CRUD`);

export const {
  check: checkChangePassword,
  update: updateChangePassword,
  resetState: resetChangePassword,
} = generateThunkActions(CHANGE_PASSWORD_CRUD_ACTIONS, ENDPOINT);

export const changePasswordReducer = generateCrudReducer(CHANGE_PASSWORD_CRUD_ACTIONS);
