import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonSpinner,
} from '@ionic/react';
import { SpaceDivider, FormWrapper } from 'commons/commons.styles';
import { LoginFormWrapper } from './Login.styles';
import { Formik, Form, Field, FormikProps, FormikValues } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import * as submittingActions from 'store/submitting';
import * as selfActions from 'store/self';
import routes, { homeRoutes } from 'routes';
import { setupSocket } from 'utils/notificationSocketUtils';
import * as notificationActions from 'store/notification';
import { getDeviceNativeToken } from 'utils/notificationNativeUtils';

const logo = require('assets/logo.png');

const loginSchema = yup.object().shape({
  usernameOrEmail: yup.string().required(`Yêu cầu nhập tên tài khoản hoặc email`),
  password: yup.string().required(`Yêu cầu nhập mật khẩu`),
});

const loginInitialValues = {};

const Login: React.FC<any> = (props: any) => {
  const {
    history,
    submittingReducer,
    setSubmitting,
    login,
    checkAuth,
    isLoggedIn,
    user,
    firstLocation,
    setSocket,
    socket,
    processNotification,
  } = props;

  const userType = user && user.userType;

  const resetSubmittingDebounced = _.debounce(() => {
    setSubmitting(false);
  }, 250);

  const handleSubmit = async (values: any, actions: any) => {
    setSubmitting(true);
    actions.setValues({});
    try {
      await login(values.usernameOrEmail, values.password);
    } catch (err) {
      // const message = err && err.response && err.response.data && err.response.data.error;
      actions.setErrors({
        password: `Tên đăng nhập/mật khẩu không hợp lệ, yêu cầu thử lại`,
      });
    }
    resetSubmittingDebounced();
  };

  useEffect(() => {
    checkAuth();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectDebounced = _.debounce(() => {
    if (
      firstLocation &&
      firstLocation.pathname !== routes.root &&
      firstLocation.pathname !== routes.login &&
      firstLocation.pathname !== routes.notification
    ) {
      history.push(`${firstLocation.pathname}${firstLocation.search}`);
    } else {
      history.push(homeRoutes[userType] || routes.home);
    }
  }, 1000);

  useEffect(() => {
    if (isLoggedIn) {
      getDeviceNativeToken();
      if (!socket) {
        setupSocket(setSocket, processNotification);
      }
      redirectDebounced();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, firstLocation]);

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <LoginFormWrapper>
          <IonGrid>
            <IonRow className={`ion-align-items-center`}>
              <IonCol>
                <div className={`ion-text-center app-logo-container`}>
                  {process.env.REACT_APP_BACKEND === 'TEST' && (
                    <IonText className={`app-test-title`} color={`danger`}>
                      TEST
                    </IonText>
                  )}
                  <img src={logo} style={{ width: 200 }} alt={'logo'} />
                </div>
                <FormWrapper>
                  <Formik initialValues={loginInitialValues} validationSchema={loginSchema} onSubmit={handleSubmit}>
                    {(formikProps: FormikProps<FormikValues>) =>
                      !submittingReducer && !isLoggedIn ? (
                        <Form className={`ion-text-center`}>
                          <Field type={`submit`} className={`ion-hide`} />
                          <Field name={`usernameOrEmail`}>
                            {(fieldProps: any) => {
                              return (
                                <React.Fragment>
                                  <IonItem>
                                    <IonLabel position={`floating`}>
                                      Nhập tài khoản / email <IonText color={`danger`}>*</IonText>
                                    </IonLabel>
                                    <IonInput
                                      type={`text`}
                                      {...fieldProps.field}
                                      onIonChange={fieldProps.field.onChange}
                                      onIonBlur={fieldProps.field.onBlur}
                                    />
                                  </IonItem>
                                  {formikProps.submitCount > 0 && fieldProps.meta.error && (
                                    <IonItem lines={`none`}>
                                      <IonText color={`danger`}>{fieldProps.meta.error}</IonText>
                                    </IonItem>
                                  )}
                                </React.Fragment>
                              );
                            }}
                          </Field>
                          <Field name={`password`}>
                            {(fieldProps: any) => (
                              <React.Fragment>
                                <IonItem>
                                  <IonLabel position={`floating`}>
                                    Nhập mật khẩu <IonText color={`danger`}>*</IonText>
                                  </IonLabel>
                                  <IonInput
                                    type={`password`}
                                    {...fieldProps.field}
                                    onIonChange={fieldProps.field.onChange}
                                    onIonBlur={fieldProps.field.onBlur}
                                  />
                                </IonItem>
                                <IonItem lines={`none`}>
                                  {formikProps.submitCount > 0 && fieldProps.meta.error && (
                                    <IonText color={`danger`}>{fieldProps.meta.error}</IonText>
                                  )}
                                </IonItem>
                              </React.Fragment>
                            )}
                          </Field>
                          <SpaceDivider height={20} />
                          <IonButton expand={`block`} type={`submit`}>
                            Đăng nhập
                          </IonButton>
                          <IonButton
                            expand={`block`}
                            color={`secondary`}
                            disabled
                            onClick={e => {
                              e.preventDefault();
                              history.push('/');
                            }}
                          >
                            Quên mật khẩu
                          </IonButton>
                        </Form>
                      ) : (
                        <IonGrid>
                          <IonRow className={`ion-align-items-center`}>
                            <IonCol className={`ion-padding-vertical ion-text-center`}>
                              <IonSpinner className={'big-spinner'} />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      )
                    }
                  </Formik>
                </FormWrapper>
              </IonCol>
            </IonRow>
          </IonGrid>
        </LoginFormWrapper>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  submittingReducer: state.submittingReducer,
});

const mapDispatchTopProps = {
  setSubmitting: submittingActions.setSubmitting,
  login: selfActions.login,
  checkAuth: selfActions.checkAuth,
  setSocket: selfActions.setSocket,
  processNotification: notificationActions.processNotification,
};

export default connect(mapStateToProps, mapDispatchTopProps)(Login);
