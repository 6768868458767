import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonBadge,
  IonText,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { TitleWrapper } from '../commons.styles';
import routes, { homeRoutes } from 'routes';
import { navigateByHistory } from 'utils/routeUtils';
import { notifications, refreshCircle } from 'ionicons/icons';
import language from 'languages';
import vnConstants from 'vnConstants';
import backendConstants from '../../backendConstants';
import { generateUsePrevious } from '../../utils/generalUtils';

interface HeaderProps extends RouteComponentProps {
  title?: string;
  noStore?: boolean;
  hasBack?: boolean;
  hasMenu?: boolean;
  noNotification?: boolean;
  notificationCount?: number;
  selfReducer: any;
  notificationReducer: any;
}

const usePathname = generateUsePrevious();

const Header: React.FC<HeaderProps> = ({
  title,
  noStore,
  hasBack,
  hasMenu,
  children,
  history,
  location,
  selfReducer,
  notificationReducer,
}) => {
  const store =
    selfReducer && selfReducer.response && selfReducer.response.user && (vnConstants as any)[selfReducer.response.user.store];
  const userType = selfReducer && selfReducer.response && selfReducer.response.user && selfReducer.response.userType;
  const unreadNotification = notificationReducer.filter(
    (notification: any) => notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD
  );

  const [lastPathname] = usePathname(history && history.location && history.location.pathname);

  const handleBack = () => {
    if (history) {
      history.replace(lastPathname ? String(lastPathname) : (homeRoutes[userType] || routes.home));
    }
  };

  const handleNavigation = (e: SyntheticEvent) => {
    if (location && location.pathname !== routes.notification) {
      navigateByHistory(history, routes.notification)(e);
    }
  };

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot={`secondary`}>
          {hasMenu && <IonMenuButton />}
          {hasBack && <IonButton onClick={handleBack}><IonIcon icon={arrowBack}/>{language.back}</IonButton>}
          {!noStore && store && (
            <TitleWrapper>
              <p className={`header-store-name`}>
                <IonText color={`primary`}>{store.name}</IonText>
              </p>
            </TitleWrapper>
          )}
        </IonButtons>
        <IonTitle>{title}</IonTitle>
        <IonButtons slot={`primary`}>
          <IonButton onClick={() => window.location.reload()}>
            <IonIcon icon={refreshCircle} />
          </IonButton>
          <IonButton onClick={handleNavigation}>
            <IonIcon icon={notifications} />
            {unreadNotification.length > 0 && <IonBadge color={`danger`}>{unreadNotification.length}</IonBadge>}
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {children}
    </IonHeader>
  );
};

Header.defaultProps = {
  title: '',
  hasBack: false,
  hasMenu: false,
  noStore: false,
};

const mapStateToProps = (state: any) => ({
  selfReducer: state.selfReducer,
  notificationReducer: state.notificationReducer,
});

export default withRouter(connect(mapStateToProps)(Header));
