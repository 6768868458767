import backendConstants from 'backendConstants';

export const EQUIPMENT_TEST_SERIAL = 'TEST_SERIAL';
export const EQUIPMENT_MIN_CHAR = 5;
export const EQUIPMENT_ACTIONS = {
  CREATE: 'CREATE',
  EDIT_SOLD_RENTED_LENT: 'EDIT_SOLD_RENTED_LENT',
  CHECKING_FIXING: 'CHECKING_FIXING',
  CHECKED_READY_TO_USE: 'CHECKED_READY_TO_USE',
  FIXED_READY_TO_USE: 'FIXED_READY_TO_USE',
  DISABLED: 'DISABLED',
  RECOVERED: 'RECOVERED',
  SOLD_RENTED_LENT: 'SOLD_RENTED_LENT',
  MAINTAIN: 'MAINTAIN',
};

export const EQUIPMENT_NAMES = {
  [backendConstants.EQUIPMENT_TYPES.HOSPITAL]: 'Máy PlasmaMED - BV',
  [backendConstants.EQUIPMENT_TYPES.CLINIC_BIG]: 'Máy PlasmaMED - TM',
  [backendConstants.EQUIPMENT_TYPES.CLINIC_MEDIUM]: 'Máy PlasmaMED - SD',
  [backendConstants.EQUIPMENT_TYPES.PLASMA_CARE]: 'Máy Plasma Care',
};
