import io from 'socket.io-client';
import { API_PATH } from 'frontendConstants';
import { getToken } from 'utils/sessionUtils';
import backendConstants from '../backendConstants';

export const setupSocket = (setSocket: any, processNotification: any) => {
  console.log('Connect to socket at ', window.location.origin, `${API_PATH}/socket`);
  const socket = io.connect(window.location.origin, {
    path: `${API_PATH}/socket`,
    query: {
      token: getToken(),
    },
  });

  if (socket) {
    console.log('A socket connection has been established!');
    setSocket(socket);
  }

  socket.on(backendConstants.SOCKET_EVENTS.NOTIFICATION, processNotification);
};
