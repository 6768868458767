import * as yup from 'yup';
import * as generalUtils from 'utils/generalUtils';
import backendConstants from 'backendConstants';

export const addUserSchema = yup.object().shape({
  username: yup
    .string()
    .required(`Yêu cầu nhập tên tài khoản`)
    .test(`isNoSpecialChar`, `Tên đăng nhập không chứa kí tự đặc biệt`, username => {
      return !(username || '').match(/[^A-Za-z0-9.]/);
    })
    .test(`isUserValid`, `Tài khoản đã tồn tại`, generalUtils.checkUserValid),
  password: yup.string().required(`Yêu cầu nhập mật khẩu`),
  email: yup
    .string()
    .email(`Yêu nhập email hợp lệ`)
    .required(`Yêu cầu nhập email`)
    .test(`isEmailValid`, `Email đã tồn tại`, generalUtils.checkEmailValid),
  name: yup.string().required(`Yêu cầu nhập tên khách hàng`),
  contactNumber: yup.string().required(`Yêu cầu nhập số điện thoại`),
  userType: yup
    .string()
    .required(`Yêu cầu nhập loại tài khoản`)
    .oneOf(
      [backendConstants.USER_TYPES.SALE, backendConstants.USER_TYPES.DELIVERY, backendConstants.USER_TYPES.TECHNICAL],
      `Loại tài khoản không hợp lệ`
    ),
  status: yup
    .string()
    .oneOf(Object.keys(backendConstants.USER_STATUSES))
    .required(`Yêu cầu chọn trạng thái tài khoản`),
  note: yup.string(),
});

export const editUserSchema = yup.object().shape({
  password: yup.string(),
  email: yup
    .string()
    .email(`Yêu nhập email hợp lệ`)
    .required(`Yêu cầu nhập email`)
    .test(`isEmailValid`, `Email đã tồn tại`, generalUtils.checkEmailValid),
  name: yup.string().required(`Yêu cầu nhập tên khách hàng`),
  contactNumber: yup.string().required(`Yêu cầu nhập số điện thoại`),
  userType: yup
    .string()
    .required(`Yêu cầu nhập loại tài khoản`)
    .oneOf(
      [backendConstants.USER_TYPES.SALE, backendConstants.USER_TYPES.DELIVERY, backendConstants.USER_TYPES.TECHNICAL],
      `Loại tài khoản không hợp lệ`
    ),
  status: yup
    .string()
    .oneOf(Object.keys(backendConstants.USER_STATUSES))
    .required(`Yêu cầu chọn trạng thái tài khoản`),
  note: yup.string(),
});
