import React, { useMemo } from 'react';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { FormWrapper } from 'commons/commons.styles';
import { NormalField } from 'commons/Form/NormalField';
import { OptionField } from 'commons/Form/OptionField';
import { CheckField } from 'commons/Form/CheckField';
import { addUserClientSchema, editUserClientSchema } from './AddEditUserClientForm.schema';
import backendConstants from 'backendConstants';
import languages from 'languages';

export const USER_STATUS_OPTIONS = [
  {
    key: backendConstants.USER_STATUSES.ACTIVE,
    label: 'Hoạt động',
  },
  {
    key: backendConstants.USER_STATUSES.DISABLED,
    label: 'Không hoạt động',
  },
];

export const AddEditUserClientForm: React.FC<any> = props => {
  const { selectedClient, selectedUserClient, submittingReducer, onConfirm, onFormUpdate } = props;

  const addEditUserInitialValues = useMemo(() => {
    return selectedUserClient
      ? {
          username: selectedUserClient.username,
          email: selectedUserClient.email,
          contactName: selectedUserClient.contactName,
          contactNumber: selectedUserClient.contactNumber,
          name: selectedUserClient.name,
          note: selectedUserClient.note,
          clientId: selectedUserClient.clientId._id || selectedUserClient.clientId,
          userType: backendConstants.USER_TYPES.CLIENT,
          status: selectedUserClient.status,
          reportExcluded: selectedUserClient.reportExcluded,
        }
      : {
          clientId: selectedClient._id,
          userType: backendConstants.USER_TYPES.CLIENT,
        };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserClient]);

  const handleSubmit = (values: any) => {
    onConfirm && onConfirm(values);
  };

  const canActiveDisable =
    !selectedUserClient ||
    (selectedUserClient &&
      selectedUserClient._id &&
      selectedUserClient.tanks &&
      selectedUserClient.tanks.length === 0 &&
      selectedUserClient.equipments &&
      selectedUserClient.equipments.length === 0);

  return (
    selectedClient && (
      <FormWrapper>
        <Formik
          initialValues={addEditUserInitialValues}
          validationSchema={selectedUserClient ? editUserClientSchema : addUserClientSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {(formikProps: FormikProps<FormikValues>) => {
            onFormUpdate && onFormUpdate(formikProps);
            return !submittingReducer ? (
              <Form className={`ion-text-center`} autoComplete={`off`}>
                <Field type={`submit`} className={`ion-hide`} />
                <NormalField
                  fieldName={`username`}
                  title={`Tên đăng nhập/Username`}
                  type={`text`}
                  showErrorWhenAvailable={true}
                  debounce={300}
                  disabled={!!selectedUserClient}
                />
                <NormalField fieldName={`password`} title={`Mật khẩu`} type={`password`} />
                <NormalField
                  fieldName={`email`}
                  title={`Địa chỉ email`}
                  type={`text`}
                  showErrorWhenAvailable={true}
                  debounce={300}
                />
                <OptionField
                  fieldName={`userType`}
                  title={`Loại tài khoản`}
                  type={`text`}
                  options={[
                    {
                      key: backendConstants.USER_TYPES.CLIENT,
                      label: languages.manageUser.ui.userTypes[backendConstants.USER_TYPES.CLIENT],
                    },
                  ]}
                />
                <OptionField
                  fieldName={`status`}
                  title={`Trạng thái`}
                  type={`text`}
                  options={USER_STATUS_OPTIONS}
                  disabled={!canActiveDisable}
                />
                {!canActiveDisable && (
                  <p>
                    <IonText color={`danger`}>Khách hàng còn nợ máy/vỏ</IonText>
                  </p>
                )}
                <NormalField fieldName={`name`} title={`Tên tài khoản/Tên khoa`} type={`text`} />
                <NormalField fieldName={`contactName`} title={`Tên người tiếp nhận`} type={`text`} />
                <NormalField fieldName={`contactNumber`} title={`Số điện thoại`} type={`text`} />
                <CheckField fieldName={`reportExcluded`} title={`Xác nhận kho tạm`} color={`primary`} />
              </Form>
            ) : (
              <IonGrid>
                <IonRow className={`ion-align-items-center`}>
                  <IonCol className={`ion-padding-vertical ion-text-center`}>
                    <IonSpinner className={`big-spinner`} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            );
          }}
        </Formik>
      </FormWrapper>
    )
  );
};

export default AddEditUserClientForm;
