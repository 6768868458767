import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage, IonButton, IonCard, IonCardContent, IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';

import * as confirmActions from 'store/confirm';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as changePasswordActions from 'store/changePassword';

import _ from 'lodash';
import routes from 'routes';

const ChangePassword: React.FC<any> = props => {
  const {
    changePasswordReducer,
    updateChangePassword,
    resetChangePassword,

    setConfirm,
    setSubmitting,
    setToast,

    location,
  } = props;

  const isThisScreen = location && location.pathname === routes.settings.changePassword;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const updatePasswordState = changePasswordReducer && changePasswordReducer.responseUpdate;

  useEffect(() => {
    handleReset();
    resetChangePassword();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isThisScreen]);

  const handleReset = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const handleSetPassword = (setAction: any) => (e: any) => {
    setAction && e && e.target && setAction(e.target.value);
  };

  const handleConfirmAlert = _.throttle(() => setConfirm('Xác nhận thay đổi mật khẩu', handleChangePassword), 1000, { leading: true, trailing: false });

  const handleRestoreResult = (isSuccessful: boolean) => {
    setSubmitting(false);
    if (isSuccessful) {
      setToast('Thay đổi mật khẩu thành công', 'primary');
      localStorage.clear();
      window.location.reload();
    } else {
      setToast('Thay đổi mật khẩu không thành công', 'danger');
    }
    handleReset();
  };

  const handleChangePassword = () => {
    setSubmitting(true);
    updateChangePassword({
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      .then(() => {
        handleRestoreResult(true);
      })
      .catch(() => {
        handleRestoreResult(false);
      });
  };

  const canChangePassword = useMemo(
    () => oldPassword && newPassword && confirmNewPassword && newPassword === confirmNewPassword && oldPassword !== newPassword,
    [oldPassword, newPassword, confirmNewPassword]
  );

  return (
    <IonPage>
      <Header title={language.settings.changePassword} hasMenu={true} />
      <IonContent className={`ion-padding`}>
        <IonCard className={`ion-text-center ion-padding-vertical`}>
          <IonCardContent>
            <IonItem className={`ion-padding-bottom`}>
              <IonLabel position={`floating`}>Nhập mật khẩu cũ</IonLabel>
              <IonInput type={`password`} value={oldPassword} onIonChange={handleSetPassword(setOldPassword)} />
            </IonItem>
            <IonItem className={`ion-padding-bottom`}>
              <IonLabel position={`floating`}>Nhập mật khẩu mới</IonLabel>
              <IonInput type={`password`} value={newPassword} onIonChange={handleSetPassword(setNewPassword)} />
            </IonItem>
            <IonItem className={`ion-padding-bottom`}>
              <IonLabel position={`floating`}>Nhắc lại mật khẩu mới</IonLabel>
              <IonInput type={`password`} value={confirmNewPassword} onIonChange={handleSetPassword(setConfirmNewPassword)} />
            </IonItem>
            <IonButton slot={`start`} color={`danger`} onClick={handleConfirmAlert} disabled={!canChangePassword}>
              Đổi mật khẩu
            </IonButton>
            <p className={`ion-padding-top`}>
              Lưu ý: Chức năng chỉ dành cho các tài khoản thường, tài khoản quản lí cần thay đổi trực tiếp từ nhà phát triển
            </p>
            {updatePasswordState && (
              <p className={`ion-padding-vertical`}>
                {updatePasswordState.message && <IonText color={`success`}>Mật khẩu đã được thay đổi thành công.</IonText>}
              </p>
            )}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  changePasswordReducer: state.changePasswordReducer,
});
const mapDispatchToProps = {
  updateChangePassword: changePasswordActions.updateChangePassword,
  resetChangePassword: changePasswordActions.resetChangePassword,

  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,
  setConfirm: confirmActions.setConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
