import styled from 'styled-components';

export const RootGroupWrapper = styled.div`
  & > ion-item-group > ion-item-divider > ion-label {
    font-weight: 600;
  }
`;

export const FirstGroupWrapper = styled.div``;

export const SecondGroupWrapper = styled.div`
  &:first-child {
    margin-top: 15px;
  }
  padding-left: 15px;
`;
