import React from 'react';
import _ from 'lodash';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFooter,
  IonHeader,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { TitleWrapper } from 'commons/commons.styles';
import { ModalFooterWrapper, ModalWrapper } from 'commons/commons.styles';
import languages from 'languages';
import vnConstants from 'vnConstants';
import TransferTankItem from '../Components/TransferTankItem';
import TransferEquipmentItem from '../Components/TransferEquipmentItem';
import { TRANSFER_TABS } from '../StoreTransfer';

export const StoreTransferChangeModal: React.FC<any> = props => {
  const {
    isOpen,
    tanksBySelectedTanks,
    equipmentsBySelectedEquipments,
    transferTab,
    fromStore,
    toStore,
    onClose,
    onConfirm,
    onSelectTank,
    onSelectEquipment,
  } = props;

  const count =
    (tanksBySelectedTanks && tanksBySelectedTanks.length) ||
    (equipmentsBySelectedEquipments && equipmentsBySelectedEquipments.length);

  const handleConfirm = _.throttle(() => {
    onConfirm && onConfirm();
  }, 3000, { leading: true, trailing: false });

  const handleCloseModal = (noReload: boolean) => () => {
    onClose && onClose(noReload);
  };

  return isOpen ? (
    <IonModal isOpen={true} backdropDismiss={false} showBackdrop={true}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={`start`}>
            <IonButton onClick={handleCloseModal(true)}>Trở lại</IonButton>
          </IonButtons>
          <TitleWrapper>
            <IonTitle>
              {(vnConstants as any)[fromStore].name} - {(vnConstants as any)[toStore].name}
              <p>
                <IonText>
                  {(languages.storeTransfer.ui.tabs as any)[transferTab]}
                </IonText>
              </p>
            </IonTitle>
          </TitleWrapper>
        </IonToolbar>
      </IonHeader>
      <IonContent className={`ion-padding`}>
        <ModalWrapper>
          {transferTab === TRANSFER_TABS.TANK && (
            <IonList className={`ion-margin-vertical`}>
              <IonListHeader>
                Kết quả: {tanksBySelectedTanks && tanksBySelectedTanks.length}
                {' vỏ/bình'}
              </IonListHeader>
              <TransferTankItem tanks={tanksBySelectedTanks} onSelectTank={onSelectTank} isInModal={true} />
            </IonList>
          )}
          {transferTab === TRANSFER_TABS.EQUIPMENT && (
            <IonList className={`ion-margin-vertical`}>
              <IonListHeader>
                Kết quả: {equipmentsBySelectedEquipments && equipmentsBySelectedEquipments.length}
                {' máy'}
              </IonListHeader>
              <TransferEquipmentItem
                equipments={equipmentsBySelectedEquipments}
                onSelectEquipment={onSelectEquipment}
                isInModal={true}
              />
            </IonList>
          )}
        </ModalWrapper>
      </IonContent>
      <IonFooter>
        <ModalFooterWrapper>
          <IonFab vertical={`top`} horizontal={`end`} slot={`fixed`}>
            <IonButton onClick={handleConfirm} disabled={!count}>
              <IonLabel>Xác nhận chuyển kho</IonLabel>
              <IonBadge color={`light`}>{count}</IonBadge>
            </IonButton>
          </IonFab>
        </ModalFooterWrapper>
      </IonFooter>
    </IonModal>
  ) : null;
};

export default StoreTransferChangeModal;
