import styled from 'styled-components';

export const MonthWrapper = styled.div`
  text-transform: capitalize;
`;

export const InvoicesWrapper = styled.div`
  ion-list-header {
    margin-top: 5px;
  }
  ion-card-content {
    padding-top: 0;
    padding-bottom: 12px;
    ion-item-divider {
      margin: 10px 0;
      border-radius: 4px;
      ion-badge {
        margin-right: 5px;
      }
    }
  }
`;
