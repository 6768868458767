import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';
import _ from 'lodash';
import { API_PATH, STORES } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/log/overview`;
export const OVERVIEW_CRUD_ACTIONS = generateCrudActionTypes(`OVERVIEW_CRUD`);

export const { check: checkOverview, resetState: resetOverview, generateRequest } = generateThunkActions(
  OVERVIEW_CRUD_ACTIONS,
  ENDPOINT
);

export const storeRequests = _.mapValues(STORES, (store: string) =>
  generateRequest(`OVERVIEW__${store}`, ENDPOINT, { data: { store } })
);

export const overviewReducer = generateCrudReducer(OVERVIEW_CRUD_ACTIONS);
