import styled from 'styled-components';

export const MenuListHeaderWrapper = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover {
    background-color: var(--ion-color-light-shade);
  }
`;

export const MenuListHeader = styled.div`
  ion-icon {
    padding-left: 5px;
    vertical-align: top;
    padding-top: 2px;
    display: inline-block;
  }
`;

export const MenuItemWrapper: any = styled.div`
  .app-menu-sub-label {
    font-size: 12px;
  }
`;

export const MenuVersionText = styled.div`
  font-size: 12px;
  span {
    color: var(--ion-color-primary);
  }
`;
