import React from 'react';
import _ from 'lodash';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import { RowItemTitle, RowItemMainP, RowItemSubP } from '../Report.styles';
import { getStore } from '../../../utils/sessionUtils';
import vnConstants from '../../../vnConstants';

export const ReportSaleItem: React.FC<any> = props => {
  const { userSale } = props;

  const getClientsByEquipments = (equipments: any) => {
    if (!equipments) return [];
    return _.groupBy(equipments, e => e.clientId);
  };

  const renderClients = (clients: any) => {
    return Object.keys(clients).map((clientId: any) => (
      <RowItemSubP key={clientId}>
        {clients[clientId][0].clientName}: <IonText color={`primary`}>{clients[clientId].length} máy</IonText>
      </RowItemSubP>
    ));
  };

  // const willShow =
  //   userSale ||
  //   userSale.tankCount > 0 ||
  //   Object.keys(userSale.toClientEquipments).length > 0 ||
  //   Object.keys(userSale.toStoreEquipments).length > 0;

  console.log(userSale);

  return userSale ? (
    <div>
      <IonItem lines={`none`}>
        <IonLabel>
          <RowItemTitle className={`ion-padding-bottom`}>
            <IonText color={`primary`}>{userSale.name}</IonText>
          </RowItemTitle>
          <RowItemMainP>
            Kho:{' '}
            <IonText color={userSale.store === getStore() ? `primary` : `danger`}>
              {(vnConstants as any)[userSale.store].name}
            </IonText>
          </RowItemMainP>
          <RowItemMainP>
            Bán: <IonText color={`primary`}>{userSale.tankCount || 0} bình</IonText>
            {' - '}
            Demo: <IonText color={`danger`}>{userSale.tankDemoCount || 0} bình</IonText>
          </RowItemMainP>
          {userSale.toClientEquipments && userSale.toClientEquipments.SOLD && !!userSale.toClientEquipments.SOLD.length && (
            <React.Fragment>
              <RowItemMainP>
                Máy bán: <IonText color={`primary`}>{userSale.toClientEquipments.SOLD.length} máy</IonText>
              </RowItemMainP>
              {renderClients(getClientsByEquipments(userSale.toClientEquipments.SOLD))}
            </React.Fragment>
          )}
          {userSale.toClientEquipments && userSale.toClientEquipments.LENT && !!userSale.toClientEquipments.LENT.length && (
            <React.Fragment>
              <RowItemMainP>
                Máy cho mượn: <IonText color={`primary`}>{userSale.toClientEquipments.LENT.length} máy</IonText>
              </RowItemMainP>
              {renderClients(getClientsByEquipments(userSale.toClientEquipments.LENT))}
            </React.Fragment>
          )}
          {userSale.toClientEquipments && userSale.toClientEquipments.RENTED && !!userSale.toClientEquipments.RENTED.length && (
            <React.Fragment>
              <RowItemMainP>
                Máy cho thuê: <IonText color={`primary`}>{userSale.toClientEquipments.RENTED.length} máy</IonText>
              </RowItemMainP>
              {renderClients(getClientsByEquipments(userSale.toClientEquipments.RENTED))}
            </React.Fragment>
          )}
          {userSale.toStoreEquipments && userSale.toStoreEquipments.CHECKING && !!userSale.toStoreEquipments.CHECKING.length && (
            <React.Fragment>
              <RowItemMainP>
                Máy thu hồi: <IonText color={`primary`}>{userSale.toStoreEquipments.CHECKING.length} máy</IonText>
              </RowItemMainP>
              {renderClients(getClientsByEquipments(userSale.toStoreEquipments.CHECKING))}
            </React.Fragment>
          )}
          {userSale.toStoreEquipments && userSale.toStoreEquipments.FIXING && !!userSale.toStoreEquipments.FIXING.length && (
            <React.Fragment>
              <RowItemMainP>
                Máy sửa: <IonText color={`primary`}>{userSale.toStoreEquipments.FIXING.length} máy</IonText>
              </RowItemMainP>
              {renderClients(getClientsByEquipments(userSale.toStoreEquipments.FIXING))}
            </React.Fragment>
          )}
        </IonLabel>
      </IonItem>
    </div>
  ) : null;
};

export default ReportSaleItem;
