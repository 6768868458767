import styled from 'styled-components';

export const Wrapper: any = styled.div`
  h5 {
    margin-top: 5px;
  }

  ion-content {
  }

  ion-list {
    margin-top: 0;
  }

  ion-searchbar {
    padding: 5px 0;
  }

  ion-item {
    .ion-padding-right {
      padding-right: 50px;
    }
    .ion-fixed-end {
      font-size: 10px;
      position: absolute;
      right: 10px;
      bottom: 15px;
    }
  }

  ion-item-group {
    margin-bottom: 15px;
  }

  ion-item-divider {
    ion-badge {
      margin: 0 5px;
    }
  }

  ion-fab {
    ion-badge {
      margin-left: 10px;
    }
  }

  ion-list-header {
    ion-button {
      font-size: 10px;
    }
  }
  ion-toast {
    &::shadow {
      border: 10px solid red;
    }
  }
`;

export const SpaceDivider: any = styled.div`
  height: ${(props: any) => props.height || 0}px;
`;

export const LineDivider: any = styled.div`
  margin: ${(props: any) => props.margin || 0}px 0;
  height: 1px;
  background-color: var(--ion-color-medium);
  opacity: 0.25;
`;

export const SubHeader: any = styled.div`
  background-color: transparent;
  padding: 5px 15px;
  &:empty {
    padding: 0 15px;
  }
`;

export const TitleWrapper: any = styled.div`
  p {
    margin: 0;
    font-size: 10px;

    &.header-store-name {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--ion-color-primary);
      & ion-text.md {
        color: white;
      }
    }
  }
`;

export const SearchBarModalWrapper: any = styled.div`
  ion-searchbar {
    padding: 0;
    height: 40px;
  }
`;

export const PageSpinner: any = styled.div`
  ion-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
`;

export const PlaceHolderWrapper: any = styled.div`
  ion-badge {
    margin-left: 5px;
    font-size: 14px;
    letter-spacing: 1px;
  }
`;

export const ModalWrapper: any = styled.div`
  ion-item-group {
    margin-bottom: 15px;
  }
  ion-item-divider {
    ion-badge {
      margin: 0 5px;
    }
  }
`;

export const ModalFooterWrapper: any = styled.div`
  ion-fab {
    animation: opacityAnimation;
    animation-duration: 0.3s;
    top: -100px;
    ion-badge {
      margin-left: 10px;
    }
  }
`;

export const FormWrapper: any = styled.div`
  padding-bottom: 100px;
  ion-spinner.big-spinner {
    margin: 30px auto;
    transform: scale(2);
  }
`;
