import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonRefresherContent,
  IonRefresher,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonList,
  IonListHeader,
} from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';

import _ from 'lodash';
import { RefresherEventDetail } from '@ionic/core';
import routes from 'routes';
import * as tankActions from 'store/tank';
import * as equipmentActions from 'store/equipment';
import * as generalUtils from 'utils/generalUtils';
import { ListWrapper } from '../Report/Report.styles';
import { getStore, isCenterStore } from 'utils/sessionUtils';
import { RowItemMainP } from '../Report/Report.styles';
import { StatsItemWrapper } from './Stats.styles';
import vnConstants from 'vnConstants';

const LABEL_REFS = {
  ...language.manageEquipment.ui.types,
  ...language.manageTank.ui.types,
};

const getSum1Level = (obj: any) => _.sumBy(Object.values(obj), (value: any) => (Array.isArray(value) ? value.length : 0));

const byStore = (arr: any) => _.groupBy(arr, (item: any) => item.store);
const byTypeByStore = (arr: any) =>
  _.mapValues(
    _.groupBy(arr, (item: any) => item.type),
    (groupByType: any) => _.groupBy(groupByType, (item: any) => item.store)
  );

const StatsItem: React.FC<any> = props => {
  const { items, unit, disabledItemCount } = props;

  const itemsByStore = useMemo(() => {
    return byStore(items);
  }, [items]);

  const itemsByTypeByStore = useMemo(() => {
    return byTypeByStore(items);
  }, [items]);

  return items ? (
    <StatsItemWrapper>
      <IonList className={`ion-margin-vertical`}>
        <div>
          <IonListHeader>
            <IonText color={`primary`}>Thống kê {unit && unit.toUpperCase()}</IonText>
          </IonListHeader>
          <IonItem>
            <IonLabel>
              {isCenterStore() && (
                <RowItemMainP className={`ion-padding-bottom`}>
                  Toàn quốc: <IonText color={`primary`}>{items.length}</IonText> {disabledItemCount ? unit + ' đang hoạt động': unit}
                  {disabledItemCount && (
                    <span> <IonText color={`danger`}>{disabledItemCount}</IonText> {unit} đã hủy</span>
                  )}
                </RowItemMainP>
              )}
              <RowItemMainP>
                {Object.keys(itemsByStore).sort((a: any, b: any) => a.localeCompare(b)).map((store: any) => (
                  <span key={store}>
                    {(vnConstants as any)[store].name}: <IonText color={`primary`}>{itemsByStore[store].length}</IonText>
                  </span>
                ))}
              </RowItemMainP>
            </IonLabel>
          </IonItem>
        </div>
        {Object.keys(itemsByTypeByStore).map((type: any) => (
          <div key={type} className={`ion-padding-start ion-padding-top`}>
            <IonListHeader>
              <span>{unit} {(LABEL_REFS as any)[type]}: <IonText color={`primary`}>{getSum1Level(itemsByTypeByStore[type])}</IonText> {unit}</span>
            </IonListHeader>
            <IonItem>
              <IonLabel>
                <RowItemMainP>
                  {Object.keys(itemsByTypeByStore[type]).sort((a: any, b: any) => a.localeCompare(b)).map((store: any) => (
                    <span key={store}>
                      {(vnConstants as any)[store].name}:{' '}
                      <IonText color={`danger`}>{itemsByTypeByStore[type][store].length}</IonText>
                    </span>
                  ))}
                </RowItemMainP>
              </IonLabel>
            </IonItem>
          </div>
        ))}
      </IonList>
    </StatsItemWrapper>
  ) : null;
};

const Stats: React.FC<any> = props => {
  const {
    tankReducer,
    equipmentReducer,

    checkTanks,
    checkEquipments,

    resetTanks,
    resetEquipments,

    location,
  } = props;

  const tanks = tankReducer && tankReducer.responseCheck && tankReducer.responseCheck.data;
  const equipments = equipmentReducer && equipmentReducer.responseCheck && equipmentReducer.responseCheck.data;
  const activeEquipments = _.filter(equipments, s => s.status !== "DISABLED")
  const isFetching = tankReducer.isFetching || equipmentReducer.isFetching;

  const [refreshing, setRefreshing] = useState(false);

  const handleReadTank = () =>
    checkTanks({
      filter: {
        store: isCenterStore() ? undefined : getStore(),
      },
    });

  const handleReadEquipments = () =>
    checkEquipments({
      filter: {
        store: isCenterStore() ? undefined : getStore(),
      },
    });

  useEffect(() => {
    handleReset();
    handleReadTank();
    handleReadEquipments();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === routes.stats]);

  const handleReset = () => {
    resetTanks();
    resetEquipments();
    resetContentScrollTop();
  };

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    handleReset();
    handleReadTank();
    handleReadEquipments();
    setRefreshing(true);
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  return (
    <IonPage>
      <Header title={language.stats} hasMenu={true} />
      <IonContent className={`ion-padding`}>
        <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {isFetching ? (
          <IonGrid className={`ion-padding`}>
            <IonRow className={`ion-padding ion-justify-content-center`}>
              <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <ListWrapper>
            <StatsItem items={tanks} unit={`bình`} />
            <StatsItem items={activeEquipments} unit={`máy`} disabledItemCount={equipments?.length - activeEquipments?.length}  />
          </ListWrapper>
        )}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  tankReducer: state.tankReducer,
  equipmentReducer: state.equipmentReducer,
});
const mapDispatchToProps = {
  checkTanks: tankActions.checkTanks,
  checkEquipments: equipmentActions.checkEquipments,
  resetTanks: tankActions.resetTanks,
  resetEquipments: equipmentActions.resetEquipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
