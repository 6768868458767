import React from 'react';
import PropTypes from 'prop-types';
import { IonInput, IonItem, IonLabel, IonText, IonRadioGroup, IonRadio, IonList, IonListHeader, IonIcon } from '@ionic/react';
import { OptionWrapper } from './styles';
import { Field } from 'formik';
import { checkmark } from 'ionicons/icons';

export const OptionField: React.FC<any> = props => {
  const { fieldName, type, title, options, disabled } = props;
  const handleSelectedOption = (formikProps: any, fieldName: any) => (event: any) => {
    if (formikProps && formikProps.setFieldValue && fieldName && event && event.target) {
      formikProps.setFieldValue(fieldName, event.target.value);
    }
  };
  return (
    <Field name={fieldName}>
      {(fieldProps: any) => {
        return (
          <OptionWrapper>
            <IonList>
              <IonListHeader>
                <IonLabel>
                  {title} <IonText color={`danger`}>*</IonText>
                  {((fieldProps.form.dirty && fieldProps.field.value) || fieldProps.meta.touched) && !fieldProps.meta.error && (
                    <IonIcon color={`primary`} icon={checkmark} slot={`end`} />
                  )}
                </IonLabel>
              </IonListHeader>
              <IonInput
                className={`ion-hide`}
                type={type}
                {...fieldProps.field}
                onIonChange={fieldProps.field.onChange}
                onIonBlur={fieldProps.field.onBlur}
                disabled={disabled}
              />
              <IonRadioGroup onIonChange={handleSelectedOption(fieldProps.form, fieldName)}>
                {options &&
                  options.map((option: any) => (
                    <IonItem key={option.key}>
                      <IonLabel>
                        {option.label}
                        {option.subLabel && <p>{option.subLabel}</p>}
                      </IonLabel>
                      <IonRadio
                        mode={`md`}
                        slot={`start`}
                        value={option.key}
                        checked={fieldProps.field.value === option.key}
                        disabled={disabled}
                      />
                    </IonItem>
                  ))}
              </IonRadioGroup>
            </IonList>
            {((fieldProps.form.dirty && fieldProps.field.value) || fieldProps.meta.touched) && fieldProps.meta.error && (
              <IonItem lines={`none`}>
                <IonText color={`danger`}>{fieldProps.meta.error}</IonText>
              </IonItem>
            )}
          </OptionWrapper>
        );
      }}
    </Field>
  );
};

OptionField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default OptionField;
