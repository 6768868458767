import { API_PATH } from 'backendConstants';
import { generateCrudActionTypes, generateCrudReducer, generateThunkActions } from 'utils/storeUtils';

export const ENDPOINT = `${API_PATH}/restore-backup`;
export const RESTORE_CRUD_ACTIONS = generateCrudActionTypes(`RESTORE_CRUD`);

export const { check: checkRestore, resetState: resetRestore, generateRequest } = generateThunkActions(
  RESTORE_CRUD_ACTIONS,
  ENDPOINT
);

export const hotRestore = generateRequest(`RESTORE`, `/restore/hot`, { method: 'get' });
export const hotBackup = generateRequest(`BACKUP`, `/backup/hot`, { method: 'get' });

export const restoreReducer = generateCrudReducer(RESTORE_CRUD_ACTIONS);
