import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/invoice`;
export const INVOICE_CRUD_ACTIONS = generateCrudActionTypes(`INVOICE_CRUD`);

export const {
  check: checkInvoices,
  read: readInvoices,
  create: createInvoice,
  update: updateInvoice,
  updateBatch: updateInvoices,
  resetState: resetInvoices,
} = generateThunkActions(INVOICE_CRUD_ACTIONS, ENDPOINT);

export const invoiceReducer = generateCrudReducer(INVOICE_CRUD_ACTIONS);
