import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import {
  IonBadge,
  IonIcon,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
} from '@ionic/react';
import languages from 'languages';
import { arrowDown, arrowUp } from 'ionicons/icons';
import ClientItem from './ClientItem';
import vnConstants from 'vnConstants';

export const LABELS = {
  ...languages.manageClient.ui.sectors,
  ..._.mapValues(vnConstants, (province: any) => province.name),
};

export const GroupedClientReport: React.FC<any> = props => {
  const { groupedClients, onSelectClient, onAddUserToClient } = props;
  const handleSelectedClient = (client: any) => () => {
    onSelectClient && onSelectClient(client);
  };
  const handleAddUserToClient = (client: any) => () => {
    onAddUserToClient && onAddUserToClient(client);
  };

  const groups = useMemo(() => {
    return groupedClients
      ? Object.keys(groupedClients)
          .sort((keyA: any, keyB: any) => (groupedClients[keyB] || []).length - (groupedClients[keyA] || []).length)
          .map((groupKey: any) => {
            return {
              name: groupKey,
              label: (LABELS as any)[groupKey],
              clients: groupedClients[groupKey],
            };
          })
      : [];
  }, [groupedClients]);

  const [groupExpanded, setGroupExpanded] = useState({});
  const handleExpandGroup = (groupId: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupId]: !(groupExpanded as any)[groupId],
    });

  return groups && groups.length > 0 ? (
    <IonList>
      {groups.map((group: any) => (
        <IonItemGroup key={group.name}>
          <IonItemDivider onClick={handleExpandGroup(group.name)}>
            <IonLabel>{group.label}</IonLabel>
            <IonBadge color={`primary`} slot={`end`}>
              {group.clients && group.clients.length}
            </IonBadge>
            <IonIcon icon={(groupExpanded as any)[group.name] ? arrowUp : arrowDown} slot={`end`} />
          </IonItemDivider>
          {(groupExpanded as any)[group.name] &&
            group.clients &&
            group.clients.map((client: any) => (
              <ClientItem
                key={client._id}
                client={client}
                onSelectClient={handleSelectedClient(client)}
                onAddUserToClient={handleAddUserToClient(client)}
              />
            ))}
        </IonItemGroup>
      ))}
    </IonList>
  ) : null;
};

export default GroupedClientReport;
