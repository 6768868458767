import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonToggle } from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';
import { setupStatusBar, setupNavigationBar, getDefaultMode } from 'utils/uiUtils';
import { isPlatform } from '@ionic/react';
import { localStorageSet } from '../../utils/sessionUtils';

const Settings: React.FC = () => {
  const [darkMode, setDarkMode] = useState<any>(localStorage.getItem('pltTheme') || 'light');
  const [mode, setMode] = useState<any>(localStorage.getItem('pltMode') || getDefaultMode());

  function handleDarkMode() {
    const nextDarkMode = darkMode === 'dark' ? 'light' : 'dark';
    setDarkMode(nextDarkMode);
  }

  function handleModeMD() {
    const nextMode = mode === 'md' ? undefined : 'md';
    setMode(nextMode);
    if (nextMode) {
      localStorageSet('pltMode', nextMode as any)
    } else {
      localStorageSet('pltMode', '')
    }
    window.location.reload();
  }

  function handleModeIOS() {
    const nextMode = mode === 'ios' ? undefined : 'ios';
    setMode(nextMode);
    if (nextMode) {
      localStorageSet('pltMode', nextMode as any)
    } else {
      localStorageSet('pltMode', '')
    }
    window.location.reload();
  }

  useEffect(() => {
    document.body.setAttribute('class', darkMode);
    localStorageSet('pltTheme', darkMode);
    setupStatusBar();
    setupNavigationBar();
  }, [darkMode]);

  return (
    <IonPage>
      <Header title={language.settings.theme} hasMenu={true} />
      <IonContent className={`ion-padding`}>
        <IonList lines={`none`}>
          <IonItem>
            <IonLabel>Giao diện tối</IonLabel>
            <IonToggle checked={darkMode === 'dark'} onClick={handleDarkMode} />
          </IonItem>
          {isPlatform(`ios`) && (
            <IonItem>
              <IonLabel>Giao diện Material</IonLabel>
              <IonToggle checked={mode === 'md'} onClick={handleModeMD} />
            </IonItem>
          )}
          {isPlatform(`android`) && (
            <IonItem>
              <IonLabel>Giao diện iOS</IonLabel>
              <IonToggle checked={mode === 'ios'} onClick={handleModeIOS} />
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
