import React, { useState } from 'react';
import moment from 'moment-timezone';
import {
  IonCheckbox,
  IonGrid, IonInfiniteScroll, IonInfiniteScrollContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel, IonRow,
  IonText,
} from '@ionic/react';
import languages from 'languages';
import { PlaceHolderWrapper } from 'commons/commons.styles';

export const TransferTankItem: React.FC<any> = props => {
  const { tanks, onSelectTank, selectedTanks, isInModal } = props;
  const [tankScrollCount, setTankScrollCount] = useState(50);
  const handleTankCountIncrement = (e: any) => {
    setTankScrollCount(tankScrollCount + 50);
    e && e.target && e.target.complete();
  };
  return tanks && !!tanks.length ? (
    <React.Fragment>
      {
        tanks.slice(0, tankScrollCount).map((tank: any) => {
          const isDanger = moment().diff(moment(tank.updatedAt), 'days') >= 90;
          return (
            <IonItemSliding key={tank.serial}>
              <IonItem>
                <IonLabel>
                  <IonText color={isDanger ? `danger` : undefined}>{tank.serial}</IonText>
                  <p>
                    <IonText color={isDanger ? `danger` : undefined}>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</IonText>
                  </p>
                  <p>
                    <IonText color={isDanger ? `danger` : undefined}>Trạng thái: {(languages.manageTank.ui.statuses as any)[tank.status]}</IonText>
                  </p>
                  {tank.updatedAt && (
                    <p className={`ion-fixed-end`}>
                      <IonText color={isDanger ? `danger` : undefined}>{moment(tank.updatedAt).format('DD/MM/YYYY HH:mm')}</IonText>
                    </p>
                  )}
                </IonLabel>
                {!isInModal && (
                  <IonCheckbox slot={`start`} onIonChange={onSelectTank(tank.serial)} checked={(selectedTanks as any)[tank.serial]} />
                )}
              </IonItem>
              {isInModal && (
                <IonItemOptions side={`end`}>
                  <IonItemOption color={`danger`} onClick={onSelectTank(tank.serial)}>
                    Loại bỏ
                  </IonItemOption>
                </IonItemOptions>
              )}
            </IonItemSliding>
          );
        })
      }
      <IonInfiniteScroll
        threshold={`300px`}
        onIonInfinite={handleTankCountIncrement}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </React.Fragment>
  ) : (
    <PlaceHolderWrapper>
      <IonGrid>
        <IonRow className={`ion-padding ion-justify-content-center`}>
          <IonItem disabled>Không có vỏ/bình nào</IonItem>
        </IonRow>
      </IonGrid>
    </PlaceHolderWrapper>
  );
};

export default TransferTankItem;
