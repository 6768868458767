import React from 'react';
import PropTypes from 'prop-types';
import { IonInput, IonItem, IonLabel, IonText, IonSpinner, IonIcon } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import { Field } from 'formik';
import { NormalWrapper } from './styles';

export const NormalField: React.FC<any> = props => {
  const { fieldName, type, title, showErrorWhenAvailable, isChecking, debounce, disabled } = props;

  const handleChange = (fieldProps: any) => (event: any) => {
    fieldProps.field.onChange(event);
  };

  const handleBlur = (fieldProps: any) => (event: any) => {
    fieldProps.field.onBlur(event);
  };

  return (
    <Field name={fieldName}>
      {(fieldProps: any) => {
        return (
          <NormalWrapper>
            <IonItem>
              <IonLabel position={`floating`}>
                {title} <IonText color={`danger`}>*</IonText>
              </IonLabel>
              <IonInput
                type={type}
                {...fieldProps.field}
                onIonChange={handleChange(fieldProps)}
                onIonBlur={handleBlur(fieldProps)}
                debounce={debounce}
                disabled={disabled}
              />
              {isChecking && <IonSpinner color={`primary`} name={`crescent`} slot={`end`} />}
              {fieldProps.meta.touched && !fieldProps.meta.error && <IonIcon color={`primary`} icon={checkmark} slot={`end`} />}
            </IonItem>
            {fieldProps.form.dirty && (fieldProps.meta.touched || showErrorWhenAvailable) && fieldProps.meta.error && (
              <IonItem lines={`none`}>
                <IonText color={`danger`}>{fieldProps.meta.error}</IonText>
              </IonItem>
            )}
          </NormalWrapper>
        );
      }}
    </Field>
  );
};

NormalField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default NormalField;
