import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonItemGroup,
  IonItemDivider,
  IonBadge,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonRefresherContent,
  IonRefresher,
  IonButton,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';
import ShowContent from 'commons/ShowContent/ShowContent';
import * as generalUtils from 'utils/generalUtils';
import { arrowDown, arrowUp } from 'ionicons/icons';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as tankActions from 'store/tank';
import * as equipmentActions from 'store/equipment';
import * as userDeliveryActions from 'store/userDelivery';
import * as invoiceActions from 'store/invoice';
import * as allActions from 'store/all';
import { Wrapper, SubHeader, PlaceHolderWrapper } from 'commons/commons.styles';
import backendConstants from 'backendConstants';
import _ from 'lodash';
import qs from 'querystring';
import { RefresherEventDetail } from '@ionic/core';
import languages from 'languages';

import InvoiceStatusChangeModal from './Modals/InvoiceStatusChangeModal';
import routes from 'routes';
import { getStore } from 'utils/sessionUtils';

export const INVOICE_TAB_KEYS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  LAST_MONTH: 'LAST_MONTH',
};

export const getInvoiceTabDate = () => {
  return {
    [INVOICE_TAB_KEYS.DAY]: moment().startOf('day'),
    [INVOICE_TAB_KEYS.WEEK]: moment().startOf('week'),
    [INVOICE_TAB_KEYS.MONTH]: moment().startOf('month'),
    [INVOICE_TAB_KEYS.LAST_MONTH]: moment()
      .subtract(1, 'month')
      .startOf('month'),
  };
};

export const NoInvoicePlaceholder: React.FC<any> = () => {
  return (
    <PlaceHolderWrapper>
      <IonGrid>
        <IonRow className={`ion-padding ion-justify-content-center`}>
          <IonItem disabled>{language.manageInvoice.ui.noInvoice}</IonItem>
        </IonRow>
      </IonGrid>
    </PlaceHolderWrapper>
  );
};

export const GroupedInvoiceList: React.FC<any> = props => {
  const [groupExpanded, setGroupExpanded] = useState({});
  const { groupedInvoices, invoiceTab, onSelectedInvoice, onDisabledInvoice, noInteraction } = props;

  const handleExpandGroup = (groupStatus: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupStatus]: !(groupExpanded as any)[groupStatus],
    });

  const handleSelectedInvoice = (invoice: any) => () => {
    onSelectedInvoice && onSelectedInvoice(invoice);
  };

  const handleDisabledInvoice = (invoice: any) => () => {
    onDisabledInvoice && onDisabledInvoice(invoice);
  };

  useEffect(() => {
    [INVOICE_TAB_KEYS.DAY, INVOICE_TAB_KEYS.MONTH].includes(invoiceTab) &&
      groupedInvoices &&
      groupedInvoices.length &&
      setGroupExpanded({
        [groupedInvoices[0].status]: true,
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedInvoices]);

  if (!groupedInvoices.length) return <NoInvoicePlaceholder />;

  return groupedInvoices.map((group: any) => {
    const invoiceCountInGroup = group.invoices.length;
    return group && group.invoices ? (
      <IonItemGroup key={group.status}>
        <IonItemDivider onClick={handleExpandGroup(group.status)}>
          <IonLabel>{languages.manageInvoice.ui.statuses[group.status]}</IonLabel>
          <IonBadge color={`medium`} slot={`end`}>
            {invoiceCountInGroup}
          </IonBadge>
          <IonIcon icon={(groupExpanded as any)[group.status] ? arrowUp : arrowDown} slot={`end`} />
        </IonItemDivider>
        {(groupExpanded as any)[group.status] &&
          group.invoices.map((invoice: any) => {
            const canAction = [
              backendConstants.INVOICE_STATUSES.REQUEST,
              backendConstants.INVOICE_STATUSES.APPROVED,
              backendConstants.INVOICE_STATUSES.DELIVERING,
            ].includes(invoice.status);

            const noAction = invoice.status === backendConstants.INVOICE_STATUSES.CANCELLED;
            return (
              <IonItemSliding key={invoice._id}>
                <IonItem
                  {...(noAction || noInteraction
                    ? {}
                    : {
                        onClick: handleSelectedInvoice(invoice),
                        button: true,
                      })}
                >
                  <IonLabel className={`ion-text-wrap`}>
                    <p>{generalUtils.getFormedIdx(invoice.invoiceIdx, 'PLT-')}</p>
                    <IonText>
                      {invoice.clientUserId && (
                        <p>
                          <IonText color={`primary`}>{invoice.clientUserId.name}</IonText>
                        </p>
                      )}
                    </IonText>
                    <p>
                      {moment(invoice.updatedAt).format(`DD/MM/YYYY${invoiceTab === INVOICE_TAB_KEYS.DAY ? ' HH:mm' : ''}`)}
                    </p>
                    {invoice.deliveryOption && (
                      <p>Giao vận: {languages.manageInvoice.ui.deliveryOptions[invoice.deliveryOption]}</p>
                    )}
                    {[backendConstants.INVOICE_STATUSES.DELIVERING, backendConstants.INVOICE_STATUSES.DELIVERED].includes(
                      invoice.status
                    ) && (
                      <React.Fragment>
                        <IonText color={`primary`}>Người giao:</IonText>
                        <p>{invoice.deliveryUserId.name}</p>
                      </React.Fragment>
                    )}
                    {invoice.status === backendConstants.INVOICE_STATUSES.DELIVERED && (
                      <React.Fragment>
                        <IonText color={`primary`}>Thông tin giao hàng:</IonText>
                        <p>{invoice.note}</p>
                      </React.Fragment>
                    )}
                    {invoice.type === backendConstants.INVOICE_TYPES.DEMO && (
                      <React.Fragment>
                        <IonText color={`danger`}>Đơn xuất Demo</IonText>
                        <p />
                      </React.Fragment>
                    )}
                    {invoice.status === backendConstants.INVOICE_STATUSES.REQUEST && invoice.note && (
                      <React.Fragment>
                        <IonText color={`primary`}>Ghi chú / Số lượng bình:</IonText>
                        <p>{invoice.note}</p>
                      </React.Fragment>
                    )}
                    {invoice.clientUserId && (
                      <p>
                        LH: <IonText color={`primary`}>{invoice.clientUserId.contactName}</IonText> -{' '}
                        <IonText color={`primary`}>{invoice.clientUserId.contactNumber}</IonText>
                      </p>
                    )}
                    {invoice.ownerUser && invoice.ownerUser.name && (
                      <React.Fragment>
                        <IonText color={`primary`}>Tạo bởi:</IonText>
                        <p>{invoice.ownerUser.name}</p>
                      </React.Fragment>
                    )}
                  </IonLabel>
                  {invoice.tanks && !!invoice.tanks.length && (
                    <IonBadge slot={`end`} color={`primary`}>
                      {invoice.tanks.length} Bình
                    </IonBadge>
                  )}
                </IonItem>
                {canAction && !noInteraction && (
                  <IonItemOptions side={`end`}>
                    <IonItemOption color={`transparent`}>
                      <IonButton onClick={handleDisabledInvoice(invoice)} size={`small`} color={`danger`}>
                        Huỷ đơn
                      </IonButton>
                    </IonItemOption>
                  </IonItemOptions>
                )}
              </IonItemSliding>
            );
          })}
      </IonItemGroup>
    ) : (
      <PlaceHolderWrapper>
        <IonGrid>
          <IonRow className={`ion-padding ion-justify-content-center`}>
            <IonItem disabled>{language.manageInvoice.ui.noInvoice}</IonItem>
          </IonRow>
        </IonGrid>
      </PlaceHolderWrapper>
    );
  });
};

const ManageInvoice: React.FC<any> = props => {
  const {
    invoiceReducer,
    readInvoices,
    updateInvoice,
    resetInvoices,

    userDeliveryReducer,
    checkUserDeliveries,
    resetUserDeliveries,

    tankReducer,
    checkTanks,
    resetTanks,

    equipmentReducer,
    checkEquipments,
    resetEquipments,

    setSubmitting,
    setToast,

    updateAll,

    history,
    location,
  } = props;

  const queryParams = qs.parse(location.search.replace('?', ''));

  const invoices = invoiceReducer && invoiceReducer.responseRead && invoiceReducer.responseRead.data;

  const userDeliveries = userDeliveryReducer && userDeliveryReducer.responseCheck && userDeliveryReducer.responseCheck.data;

  const availableTanks = tankReducer && tankReducer.responseCheck && tankReducer.responseCheck.data;

  const availableEquipments = equipmentReducer && equipmentReducer.responseCheck && equipmentReducer.responseCheck.data;

  const [invoiceTab, setInvoiceTab] = useState<string>((queryParams.invoiceTab as string) || INVOICE_TAB_KEYS.MONTH);

  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);

  useEffect(() => {
    if (queryParams.invoiceTab) {
      setInvoiceTab(queryParams.invoiceTab as any);
    }
  }, [queryParams.invoiceTab]);

  const showContent = useMemo(() => {
    return location.pathname === routes.manageInvoice.main;
  }, [location.pathname]);

  const resetSelectedInvoice = (noReload: any) => {
    setSelectedInvoice(null);
    if (!noReload) {
      resetCurrentInvoiceTab();
      handleReadInvoice();
    }
  };

  const handleSelectedInvoice = (willDisable: boolean) => (invoice: any) => {
    setSelectedInvoice({
      ...invoice,
      willDisable,
    });
  };

  const [refreshing, setRefreshing] = useState(false);

  const resetCurrentInvoiceTab = () => {
    resetInvoices();
    // Reset Content Scroll
    resetContentScrollTop();
  };

  const handleReadInvoice = () => {
    return readInvoices({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        updatedAt: {
          $gte: getInvoiceTabDate()[invoiceTab].toDate(),
          $lte:
            invoiceTab === INVOICE_TAB_KEYS.LAST_MONTH
              ? getInvoiceTabDate()
                  [invoiceTab].endOf('month')
                  .toDate()
              : undefined,
        },
      },
      populate: [
        {
          path: 'ownerUser',
          select: '_id username name',
        },
        {
          path: 'clientUserId',
          select: '_id name clientId contactName contactNumber',
          populate: {
            path: 'clientId',
            select: '_id parentId name',
          },
        },
        {
          path: 'tanks',
          select: '_id serial type updatedAt clientUserId',
          populate: {
            path: 'clientUserId',
            select: '_id username name',
          },
        },
        {
          path: 'deliveryUserId',
          select: '_id name',
        },
      ],
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadUserDelivery = () => {
    return checkUserDeliveries({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        userType: backendConstants.USER_TYPES.DELIVERY,
        status: backendConstants.USER_STATUSES.ACTIVE,
      },
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadTank = () => {
    return checkTanks({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        status: backendConstants.TANK_STATUSES.READY_TO_USE,
      },
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadEquipment = () => {
    if (
      selectedInvoice &&
      selectedInvoice.clientUserId &&
      selectedInvoice.status === backendConstants.INVOICE_STATUSES.REQUEST
    ) {
      return checkEquipments({
        page: 1,
        pageSize: 99999,
        filter: {
          store: getStore(),
          clientUserId: selectedInvoice.clientUserId,
        },
        sort: {
          updatedAt: -1,
        },
      });
    } else {
      return resetEquipments();
    }
  };

  const handleInvoiceTabChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setInvoiceTab(event.detail.value);
      history.push(`${routes.manageInvoice.main}?invoiceTab=${event.detail.value}`);
    }
  };

  useEffect(() => {
    if (showContent) {
      resetCurrentInvoiceTab();
      handleReadInvoice();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceTab, showContent]);

  useEffect(() => {
    handleReadEquipment();
    handleReadUserDelivery();
    handleReadTank();
    return () => {
      resetEquipments();
      resetUserDeliveries();
      resetTanks();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoice]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    resetCurrentInvoiceTab();
    await handleReadInvoice();
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const invoiceGroupedByStatus = useMemo(() => {
    if (!invoices || !invoices.length) return [];
    const invoiceGroupByStatusObject = _.omit(
      _.groupBy(invoices, invoice => invoice.status),
      [backendConstants.INVOICE_STATUSES.TRASH]
    );
    return [
      backendConstants.INVOICE_STATUSES.REQUEST,
      backendConstants.INVOICE_STATUSES.APPROVED,
      backendConstants.INVOICE_STATUSES.DELIVERING,
      backendConstants.INVOICE_STATUSES.DELIVERED,
      backendConstants.INVOICE_STATUSES.CANCELLED,
    ].map((invoiceStatus: string) => ({
      status: invoiceStatus,
      invoices: (invoiceGroupByStatusObject as any)[invoiceStatus] || [],
    }));
  }, [invoices]);

  const closeUpdateModalDebounced = _.debounce(isSuccessful => {
    if (isSuccessful) {
      setToast(`Cập nhật thành công`, `primary`);
    } else {
      setToast(`Cập nhật không thành công`, `danger`);
    }
    resetSelectedInvoice(false);
    setSelectedInvoice(null);
    setSubmitting(false);
  }, 500);

  const handleModalConfirm = _.throttle(async (selectedInvoice: any, extraData: any) => {
    setSubmitting(true);
    try {
      if (selectedInvoice) {
        const status = selectedInvoice.status;

        // DISABLE
        if (selectedInvoice.willDisable) {
          if ([backendConstants.INVOICE_STATUSES.APPROVED, backendConstants.INVOICE_STATUSES.DELIVERING].includes(status)) {
            await updateAll({
              tanks: (selectedInvoice as any).tanks.map((tank: any) => ({
                store: getStore(),
                serial: tank.serial,
                status: backendConstants.TANK_STATUSES.READY_TO_USE,
                clientUserId: null,
                invoiceId: null,
              })),
              invoices: [
                {
                  store: getStore(),
                  _id: selectedInvoice._id,
                  tanks: [],
                  status: backendConstants.INVOICE_STATUSES.CANCELLED,
                },
              ],
            });
          } else {
            await updateInvoice({
              store: getStore(),
              _id: selectedInvoice._id,
              tanks: [],
              status: backendConstants.INVOICE_STATUSES.CANCELLED,
            });
          }
          return closeUpdateModalDebounced(true);
        } else {
          // REQUEST -> APPROVE
          if (
            selectedInvoice.status === backendConstants.INVOICE_STATUSES.REQUEST &&
            extraData.tanksBySelectedTanks &&
            extraData.tanksBySelectedTanks.length
          ) {
            const readyTanksResponse = await checkTanks({
              page: 1,
              pageSize: 99999,
              filter: {
                store: getStore(),
                serial: {
                  $in: extraData.tanksBySelectedTanks.map((tank: any) => tank.serial),
                },
                status: backendConstants.TANK_STATUSES.READY_TO_USE,
              },
            });
            const readyTanks = readyTanksResponse && readyTanksResponse.data && readyTanksResponse.data.data;
            if (readyTanks.length === extraData.tanksBySelectedTanks.length) {
              await updateAll({
                tanks: extraData.tanksBySelectedTanks.map((tank: any) => ({
                  store: getStore(),
                  serial: tank.serial,
                  status: backendConstants.TANK_STATUSES.LOCKED,
                  clientUserId: selectedInvoice.clientUserId._id,
                  invoiceId: selectedInvoice._id,
                })),
                invoices: [
                  {
                    store: getStore(),
                    _id: selectedInvoice._id,
                    tanks: extraData.tanksBySelectedTanks.map((tank: any) => tank._id),
                    status: backendConstants.INVOICE_STATUSES.APPROVED,
                  }
                ],
              });
              return closeUpdateModalDebounced(true);
            }
          }

          // APPROVE -> DELIVERING
          if (
            selectedInvoice.status === backendConstants.INVOICE_STATUSES.APPROVED &&
            extraData.selectedOption &&
            extraData.selectedDeliveryUserId
          ) {
            await updateInvoice({
              store: getStore(),
              _id: selectedInvoice._id,
              deliveryOption: extraData.selectedOption,
              deliveryUserId: extraData.selectedDeliveryUserId,
              status: backendConstants.INVOICE_STATUSES.DELIVERING,
            });
            return closeUpdateModalDebounced(true);
          }

          // DELIVERING -> DELIVERED
          if (
            selectedInvoice.status === backendConstants.INVOICE_STATUSES.DELIVERING &&
            selectedInvoice.tanks &&
            selectedInvoice.tanks.length &&
            extraData.invoiceNote
          ) {
            const deliveringTanksResponse = await checkTanks({
              page: 1,
              pageSize: 99999,
              filter: {
                store: getStore(),
                _id: {
                  $in: selectedInvoice.tanks.map((tank: any) => tank._id),
                },
                status: backendConstants.TANK_STATUSES.LOCKED,
              },
            });

            const deliveringTanks =
              deliveringTanksResponse && deliveringTanksResponse.data && deliveringTanksResponse.data.data;

            if (deliveringTanks.length === selectedInvoice.tanks.length) {
              await updateAll({
                tanks: deliveringTanks.map((tank: any) => ({
                  store: getStore(),
                  serial: tank.serial,
                  status: backendConstants.TANK_STATUSES.AT_CLIENT,
                  clientUserId: selectedInvoice.clientUserId._id,
                  invoiceId: selectedInvoice._id,
                })),
                invoices: [
                  {
                    store: getStore(),
                    _id: selectedInvoice._id,
                    status: backendConstants.INVOICE_STATUSES.DELIVERED,
                    note: extraData.invoiceNote,
                  }
                ]
              });
              return closeUpdateModalDebounced(true);
            }
          }
        }
      }
    } catch (err) {
      return closeUpdateModalDebounced(false);
    }
    return closeUpdateModalDebounced(false);
  }, 3000, { leading: true, trailing: false });

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.manageInvoice.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleInvoiceTabChange} scrollable={true}>
              <IonSegmentButton value={INVOICE_TAB_KEYS.DAY} checked={invoiceTab === INVOICE_TAB_KEYS.DAY}>
                {language.manageInvoice.ui.tabs.DAY}
              </IonSegmentButton>
              <IonSegmentButton value={INVOICE_TAB_KEYS.MONTH} checked={invoiceTab === INVOICE_TAB_KEYS.MONTH}>
                {language.manageInvoice.ui.tabs.MONTH}
              </IonSegmentButton>
              <IonSegmentButton value={INVOICE_TAB_KEYS.LAST_MONTH} checked={invoiceTab === INVOICE_TAB_KEYS.LAST_MONTH}>
                {language.manageInvoice.ui.tabs.LAST_MONTH}
              </IonSegmentButton>
            </IonSegment>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent} timeout={1000}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <InvoiceStatusChangeModal
              title={
                selectedInvoice &&
                (selectedInvoice.willDisable
                  ? languages.manageInvoice.ui.actions.DISABLE
                  : languages.manageInvoice.ui.actions[selectedInvoice.status])
              }
              isOpen={!!selectedInvoice}
              onClose={resetSelectedInvoice}
              onConfirm={handleModalConfirm}
              userDeliveries={userDeliveries}
              availableTanks={availableTanks}
              availableEquipments={availableEquipments}
              selectedInvoice={selectedInvoice}
              confirmLabel={
                selectedInvoice &&
                (selectedInvoice.willDisable
                  ? languages.manageInvoice.ui.modalActions.DISABLE
                  : languages.manageInvoice.ui.modalActions[selectedInvoice.status])
              }
            />
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {invoiceReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    <IonList className={`ion-margin-vertical`}>
                      {invoices && !!invoices.length && (
                        <IonListHeader>Kết quả: {invoiceReducer.responseRead.totalCount} đơn hàng</IonListHeader>
                      )}
                      <GroupedInvoiceList
                        groupedInvoices={invoiceGroupedByStatus}
                        invoiceTab={invoiceTab}
                        onSelectedInvoice={handleSelectedInvoice(false)}
                        onDisabledInvoice={handleSelectedInvoice(true)}
                      />
                    </IonList>
                  </div>
                )}
              </div>
            </IonGrid>
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  invoiceReducer: state.invoiceReducer,
  tankReducer: state.tankReducer,
  userDeliveryReducer: state.userDeliveryReducer,
  equipmentReducer: state.equipmentReducer,
});

const mapDispatchToProps = {
  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,

  readInvoices: invoiceActions.readInvoices,
  resetInvoices: invoiceActions.resetInvoices,
  updateInvoice: invoiceActions.updateInvoice,
  createInvoice: invoiceActions.createInvoice,

  checkUserDeliveries: userDeliveryActions.checkUserDeliveries,
  resetUserDeliveries: userDeliveryActions.resetUserDeliveries,

  checkTanks: tankActions.checkTanks,
  updateTanks: tankActions.updateTanks,
  resetTanks: tankActions.resetTanks,

  updateAll: allActions.updateAll,

  checkEquipments: equipmentActions.checkEquipments,
  resetEquipments: equipmentActions.resetEquipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvoice);
