import backendConstants from '../backendConstants';

import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export const SESSION_KEYS = [
  `pltToken`,
  `pltSession`,
  `pltMode`,
  `pltTheme`
];

export const setupSession = async (cb?: any) => {
  for (let key of SESSION_KEYS) {
    const { value } = await Storage.get({ key });
    if (!!value) {
      localStorage.setItem(key, String(value));
    }
  }
  cb && cb();
};

export const saveSession = async (cb?: any) => {
  for (let key of SESSION_KEYS) {
    const value = localStorage.getItem(key);
    if (!!value) {
      await Storage.set({ key, value });
    }
  }
  cb && cb();
};

export const saveSessionItem = async (key: any) => {
  const value = localStorage.getItem(key) || '';
  await Storage.set({ key, value });
}

export const localStorageSet = (key: any, value: any) => {
  localStorage.setItem(key, value);
  saveSessionItem(key).then(() => console.log('NATIVE SESSION SAVED!!!', key));
}

export const getToken = () => localStorage.getItem(`pltToken`);

export const getSession = () => JSON.parse(localStorage.getItem(`pltSession`) || `{}`);

export const getUser = () => {
  const session = getSession();
  return session && session.user;
};

export const getStore = () => {
  const user = getUser();
  return user && user.store;
};

export const isCenterStore = () => getStore() === backendConstants.CENTER_STORE;

export const isPermittedByUserStore = (permittedStore: string) => {
  return getStore() === permittedStore;
};
