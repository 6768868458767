import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { SubHeader } from 'commons/commons.styles';
import { ModalFooterWrapper, ModalWrapper } from 'commons/commons.styles';
import { arrowDown, arrowUp } from 'ionicons/icons';
import backendConstants from 'backendConstants';
import languages from 'languages';

const TankStatusChangeModal: React.FC<any> = props => {
  const {
    title,
    isOpen,
    isCreate,
    willDisable,
    onClose,
    onConfirm,
    tankStatusTab,
    tanksBySelectedTanks,
    onRemoveTank,
    userClients,
    equipments,
    confirmLabel,
    onCanOpenModal,
  } = props;

  const SEGMENTS = {
    TANK: 'TANK',
    STATUS: 'STATUS',
    CLIENT: 'CLIENT',
  };

  const [segment, setSegment] = useState(SEGMENTS.TANK);
  const [userClientId, setUserClientId] = useState('');
  const [groupExpanded, setGroupExpanded] = useState({});
  const [groupSearch, setGroupSearch] = useState('');
  const [newTankType, setNewTankType] = useState(null);
  const [newTankStatus, setNewTankStatus] = useState(null);
  const [collectTankStatus, setCollectTankStatus] = useState(backendConstants.TANK_STATUSES.EMPTY);

  const resetModal = () => {
    setSegment(SEGMENTS.TANK);
    setUserClientId('');
    setGroupSearch('');
    setCollectTankStatus(backendConstants.TANK_STATUSES.EMPTY);
  };

  const handleRemoveTank = (key: string) => (
    // event: any
  ) => {
    onRemoveTank &&
      onRemoveTank(key)({
        target: {
          checked: false,
        },
      });
  };

  const handleExpandGroup = (groupId: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupId]: !(groupExpanded as any)[groupId],
    });

  const handleCloseModal = (noReload: boolean) => (
    // event?: any
  ) => {
    onClose && onClose(noReload);
    resetModal();
  };

  const handleUserClientRadioChange = (event: any) => {
    event && event.target && setUserClientId(event.target.value);
  };

  const handleCollectStatusRadioChange = (event: any) => {
    event && event.target && setCollectTankStatus(event.target.value);
  };

  const handleNewTankTypeSelect = (event: any) => {
    event && event.target && setNewTankType(event.target.value);
  };

  const handleNewTankStatusSelect = (event: any) => {
    event && event.target && setNewTankStatus(event.target.value);
  };

  const handleConfirmCreate = _.throttle(() => {
    onConfirm && onConfirm({ newTankType, newTankStatus });
    resetModal();
  }, 3000, { leading: true, trailing: false });

  const handleConfirmUpdate = _.throttle(() => {
    onConfirm && onConfirm({ userClientId, collectTankStatus });
    resetModal();
  }, 3000, { leading: true, trailing: false });

  const handleSegmentChange = (event: any) => {
    event && event.detail && setSegment(event.detail.value);
  };

  const handleGroupSearchChange = (event: any) => {
    event && event.target && setGroupSearch(event.target.value);
  };

  const selectedTankTypes = useMemo(() => {
    return Object.keys(_.groupBy(tanksBySelectedTanks, tanks => tanks.type));
  }, [tanksBySelectedTanks]);

  const groupClientUsersByClient = useMemo(() => {
    if (!isOpen) return [];
    if (!userClients || !userClients.length) return [];
    if (!equipments || !equipments.length) return [];

    const userClientGroupedByClientId = _.groupBy(userClients, userClient => userClient.clientId._id);

    if (Object.keys(userClientGroupedByClientId).length <= 1 && Object.keys(userClientGroupedByClientId)[0] === 'undefined')
      return [];

    const equipmentGroupedByClientId = _.groupBy(
      equipments.filter((equipment: any) => equipment.clientUserId),
      equipment => equipment.clientUserId._id
    );

    if (Object.keys(equipmentGroupedByClientId).length <= 1 && Object.keys(equipmentGroupedByClientId)[0] === 'undefined')
      return [];

    const equipmentTankTypesByClientId = _.mapValues(equipmentGroupedByClientId, equipments => {
      return _.uniq(equipments.map(equipment => equipment.tankType));
    });

    const result = Object.values(userClientGroupedByClientId)
      .map(group => {
        return {
          _id: group[0].clientId._id,
          name: group[0].clientId.name,
          // FILTER CLIENTS THAT NOT SUITABLE FOR SELECTED TANK TYPE
          userClients: group.filter(userClient => {
            return (
              (equipmentTankTypesByClientId &&
                equipmentTankTypesByClientId[userClient._id] &&
                selectedTankTypes.every(tankType => equipmentTankTypesByClientId[userClient._id].includes(tankType))) ||
              // EXCEPT for user client is temporary store (reportExcluded = YES)
              (userClient && userClient.reportExcluded === backendConstants.USER_CLIENT_REPORT_EXCLUDED.YES)
            );
          }),
        };
      })
      .filter(group => group.userClients.length)
      .sort((aGroup, bGroup) => aGroup.name.localeCompare(bGroup.name));

    // SET MODAL CAN OPEN IF THERE IS AT LEAST A CLIENT GROUP
    onCanOpenModal && onCanOpenModal(!!result.length);

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClients, equipments, selectedTankTypes, isOpen]);

  const groupClientUsersByClientBySearch = useMemo((): any => {
    return groupClientUsersByClient.filter((group: any) => group.name.toLowerCase().includes(groupSearch.toLowerCase()));
  }, [groupClientUsersByClient, groupSearch]);

  useEffect(() => {
    let initialExpandState = groupClientUsersByClient.reduce((expanded, client) => {
      if (client.userClients && client.userClients.length === 1) {
        return { ...expanded, [client._id]: true };
      }
      return expanded;
    }, {});
    if (!_.isEmpty(initialExpandState)) {
      setGroupExpanded(initialExpandState);
    }
  }, [groupClientUsersByClient]);

  const tankStatus = tanksBySelectedTanks && tanksBySelectedTanks[0] && tanksBySelectedTanks[0].status;

  return (
    isOpen && (
      <IonModal isOpen={true} backdropDismiss={false} showBackdrop={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={`start`}>
              <IonButton onClick={handleCloseModal(true)}>Trở lại</IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
          <SubHeader>
            {userClients && (
              <IonSegment className={`ion-padding-bottom`} scrollable={true} onIonChange={handleSegmentChange}>
                <IonSegmentButton value={SEGMENTS.TANK} checked={segment === SEGMENTS.TANK}>
                  Bình đã chọn
                </IonSegmentButton>
                <IonSegmentButton value={SEGMENTS.CLIENT} checked={segment === SEGMENTS.CLIENT}>
                  Chọn khách hàng
                </IonSegmentButton>
              </IonSegment>
            )}
            {[tankStatusTab, tankStatus].includes(backendConstants.TANK_STATUSES.AT_CLIENT) && (
              <IonSegment className={`ion-padding-bottom`} scrollable={true} onIonChange={handleSegmentChange}>
                <IonSegmentButton value={SEGMENTS.TANK} checked={segment === SEGMENTS.TANK}>
                  Bình đã chọn
                </IonSegmentButton>
                <IonSegmentButton value={SEGMENTS.STATUS} checked={segment === SEGMENTS.STATUS}>
                  Dạng thu hồi
                </IonSegmentButton>
              </IonSegment>
            )}
            {segment === SEGMENTS.CLIENT && (
              <React.Fragment>
                <IonSearchbar
                  placeholder={`Tìm tên khách hàng ...`}
                  value={groupSearch}
                  onIonChange={handleGroupSearchChange}
                  debounce={250}
                />
              </React.Fragment>
            )}
          </SubHeader>
        </IonHeader>
        <IonContent className={`ion-padding`}>
          <ModalWrapper>
            <IonGrid>
              {segment === SEGMENTS.TANK && (
                <React.Fragment>
                  <IonList>
                    <IonListHeader>
                      <IonLabel>
                        Danh sách đã chọn: {tanksBySelectedTanks && tanksBySelectedTanks.length} {userClients ? `bình` : `vỏ`}
                      </IonLabel>
                    </IonListHeader>
                    {tanksBySelectedTanks &&
                      tanksBySelectedTanks.map((tank: any) => (
                        <IonItemSliding key={tank.serial}>
                          <IonItem>
                            <IonLabel>
                              <IonText>{tank.serial}</IonText>
                              {tank.clientUserId && (
                                <p>
                                  <IonText color={`primary`}>{tank.clientUserId.name}</IonText>
                                </p>
                              )}
                            </IonLabel>
                          </IonItem>
                          {tanksBySelectedTanks.length > 1 && (
                            <IonItemOptions side={`end`}>
                              <IonItemOption color={`danger`} onClick={handleRemoveTank(tank.serial)}>
                                Loại bỏ
                              </IonItemOption>
                            </IonItemOptions>
                          )}
                        </IonItemSliding>
                      ))}
                  </IonList>
                  {isCreate && (
                    <React.Fragment>
                      <IonList>
                        <IonListHeader>
                          <IonLabel>Loại vỏ:</IonLabel>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleNewTankTypeSelect}>
                          {Object.keys(backendConstants.TANK_TYPES).map(tankType => (
                            <IonItem key={tankType}>
                              <IonLabel>{(languages.manageTank.ui as any)[tankType]}</IonLabel>
                              <IonRadio mode={`md`} slot={`start`} value={tankType} checked={tankType === newTankType} />
                            </IonItem>
                          ))}
                        </IonRadioGroup>
                      </IonList>
                      <IonList>
                        <IonListHeader>
                          <IonLabel>Trạng thái:</IonLabel>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleNewTankStatusSelect}>
                          {[backendConstants.TANK_STATUSES.EMPTY, backendConstants.TANK_STATUSES.LOADING].map(tankStatus => (
                            <IonItem key={tankStatus}>
                              <IonLabel>{(languages.manageTank.ui.statuses as any)[tankStatus]}</IonLabel>
                              <IonRadio mode={`md`} slot={`start`} value={tankStatus} checked={tankStatus === newTankStatus} />
                            </IonItem>
                          ))}
                        </IonRadioGroup>
                      </IonList>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {segment === SEGMENTS.STATUS && (
                <IonList>
                  <IonRadioGroup onIonChange={handleCollectStatusRadioChange}>
                    {[backendConstants.TANK_STATUSES.EMPTY, backendConstants.TANK_STATUSES.READY_TO_USE].map((status: any) => (
                      <IonItem key={status}>
                        <IonLabel>{languages.manageTank.ui.collectionStatuses[status]}</IonLabel>
                        <IonRadio mode={`md`} slot={`start`} value={status} checked={collectTankStatus === status} />
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                </IonList>
              )}
              {segment === SEGMENTS.CLIENT && (
                <IonList>
                  <IonRadioGroup onIonChange={handleUserClientRadioChange}>
                    {groupClientUsersByClientBySearch.map((group: any) => (
                      <IonItemGroup key={group._id}>
                        <IonItemDivider onClick={handleExpandGroup(group._id)}>
                          <IonLabel>{group.name}</IonLabel>
                          <IonBadge color={`medium`} slot={`end`}>
                            {group.userClients.length}
                          </IonBadge>
                          <IonIcon icon={(groupExpanded as any)[group._id] ? arrowUp : arrowDown} slot={`end`} />
                        </IonItemDivider>
                        {(groupExpanded as any)[group._id] &&
                          group.userClients.map((userClient: any) => (
                            <IonItem key={userClient._id}>
                              <IonLabel>{userClient.name}</IonLabel>
                              <IonRadio
                                mode={`md`}
                                slot={`start`}
                                value={userClient._id}
                                checked={userClientId === userClient._id}
                              />
                            </IonItem>
                          ))}
                      </IonItemGroup>
                    ))}
                  </IonRadioGroup>
                </IonList>
              )}
            </IonGrid>
          </ModalWrapper>
        </IonContent>
        <IonFooter>
          <ModalFooterWrapper>
            <IonFab vertical={`top`} horizontal={`end`} slot={`fixed`}>
              <IonButton
                color={tankStatus === backendConstants.TANK_STATUSES.DISABLED || willDisable ? `danger` : `primary`}
                onClick={isCreate ? handleConfirmCreate : handleConfirmUpdate}
                disabled={
                  (userClients && !userClientId) ||
                  (isCreate && (!newTankType || !newTankStatus)) ||
                  ([tankStatusTab, tankStatus].includes(backendConstants.TANK_STATUSES.AT_CLIENT) && !collectTankStatus)
                }
              >
                <IonLabel>{confirmLabel}</IonLabel>
                <IonBadge color={`light`}>{tanksBySelectedTanks.length}</IonBadge>
              </IonButton>
            </IonFab>
          </ModalFooterWrapper>
        </IonFooter>
      </IonModal>
    )
  );
};

export default TankStatusChangeModal;
