import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { PageSpinner } from 'commons/commons.styles';
import { IonContent, IonSpinner } from '@ionic/react';

const ShowContent: React.FC<any> = props => {
  const { timeout, isShow, color, spinner } = props;
  const [showLoading, setShowLoading] = useState<any>(true);
  const [showContent, setShowContent] = useState<any>(false);

  const handleShowHideContent = _.debounce((isShow: any) => {
    setShowContent(isShow);
    setShowLoading(false);
  }, timeout);

  useEffect(() => {
    setShowLoading(true);
    handleShowHideContent(isShow);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  return showContent && !showLoading ? (
    props.children
  ) : (
    <IonContent>
      <PageSpinner>
        <IonSpinner name={spinner} color={color} />
      </PageSpinner>
    </IonContent>
  );
};

ShowContent.defaultProps = {
  timeout: 500,
};

export default ShowContent;
