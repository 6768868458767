import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  ion-item {
    & > ion-icon {
      width: 18px;
      margin: 12px 12px 12px 0;
    }
    p {
      margin: 5px 0;
      &.ion-top-right {
        font-size: 10px;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
`;
