import styled from 'styled-components';

export const SubHeaderSecondaryWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  ion-select {
    width: 0;
    padding: 0;
  }
  ion-label {
    span > ion-text {
      font-weight: 600;
    }
  }
`;
