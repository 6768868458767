import _ from 'lodash';
import axios from 'axios';
import { API_PATH } from '../backendConstants';
import frontendConstants from '../frontendConstants';
import { defaultMemoize } from 'reselect';

export const getFormedIdx = (idx: number, prefix?: string, preservedPlace: number = 5) =>
  `${prefix}${(
    Array(preservedPlace)
      .fill(0)
      .join('') + idx
  ).substr(-preservedPlace)}`;

export const handleTargetValue = (setState: any) => (e: any) => {
  if (e && e.detail && e.detail.value) {
    return setState(e.detail.value);
  }
  if (e && e.target && e.target.value) {
    return setState(e.target.value);
  }
};

export const handleSelectedList = (setState: any, selectedList: any) => (id: string) => (e: any) => {
  if (e && e.target) {
    setState({
      ...selectedList,
      [id]: e.target.checked,
    });
  }
};

export const handleRemoveListItem = (setState: any, selectedList: any) => (id: string) => () => {
  setState({
    ...selectedList,
    [id]: false,
  });
};

export const itemsBySelectedList = (selectedList: any, refItems: any, uniqueField: string) => {
  const selectedIds = selectedList ? Object.keys(selectedList).filter((key: string) => !!selectedList[key]) : null;
  return selectedIds && refItems ? refItems.filter((item: any) => selectedIds.includes(item[uniqueField])) : [];
};

export const availableItemTypes = (list: any, typeField: string) => {
  return list ? Object.keys(_.groupBy(list, (item: any) => item[typeField])) : [];
};

export const availableItemsByItemType = (list: any, typeList: any, typeField: string) => {
  return list ? list.filter((item: any) => typeList.includes(item[typeField])) : [];
};

export const handleExpandedListWithSoleItem = (groups: any, setGroupExpanded: any, itemsField: string, uniqueField = '_id') => {
  let initialExpandState = groups.reduce((expanded: any, group: any) => {
    if (group[itemsField] && group[itemsField].length === 1) {
      return { ...expanded, [group[uniqueField]]: true };
    }
    return expanded;
  }, {});
  if (!_.isEmpty(initialExpandState)) {
    setGroupExpanded(initialExpandState);
  }
};

export const handleFormPropsUpdate = (formProps: any, setFormProps: any) => (currentFormProps: any) => {
  const chosenFormProps = {
    dirty: currentFormProps.dirty,
    isValid: currentFormProps.isValid,
    values: currentFormProps.values,
  };
  if (!_.isEqual(formProps, chosenFormProps)) {
    setFormProps(chosenFormProps);
  }
};

export const ionContentScrollToTop = (contentDOMRef: any) => () => {
  if (contentDOMRef && contentDOMRef.current && contentDOMRef.current.shadowRoot) {
    const scrollMain = contentDOMRef.current.shadowRoot.querySelector('.inner-scroll');
    if (contentDOMRef.current && contentDOMRef.current.scrollToTop) {
      contentDOMRef.current.scrollToTop(300).then(() => {
        // Do smt
      });
    }
    if (scrollMain && scrollMain.scrollTop) {
      scrollMain.scrollTop = 0;
    }
  }
};

export const memoizeUsername = defaultMemoize(async (username: string) => {
  return await axios.post(`${API_PATH}/username`, {
    username: username.toLowerCase(),
  });
});

export const checkUserValid = async function(this: any, username: string) {
  if (!username) return false;
  try {
    const result = await memoizeUsername(username);
    return result && result.data && !result.data.isExisted;
  } catch (err) {
    return false;
  }
};

export const memoizeEmail = defaultMemoize(async (email: string) => {
  return await axios.post(`${API_PATH}/email`, {
    email: email.toLowerCase(),
  });
});

export const checkEmailValid = async function(this: any, email: string) {
  if (!email) return false;
  if (this.options.originalValue === email) return true;
  try {
    const result = await memoizeEmail(email);
    return result && result.data && !result.data.isExisted;
  } catch (err) {
    return false;
  }
};

export const isPermitted = function(permissions: any, userPermissions: any) {
  if (!permissions || !permissions.length || !userPermissions) {
    return false;
  }
  let permissionsToCheck: any = [];
  if (typeof permissions === 'string') {
    permissionsToCheck = [permissions];
  }
  if (Array.isArray(permissions) && permissions.length) {
    permissionsToCheck = permissions;
  }

  return userPermissions.some((permission: any) => permissionsToCheck.includes(permission));
};

export const isPermittedByUserType = function(userType: any, permissions: any) {
  if (!userType || !frontendConstants.VIEW_PERMISSIONS_GROUPS[userType]) return false;

  return isPermitted(permissions, frontendConstants.VIEW_PERMISSIONS_GROUPS[userType]);
};

export const setupCallbackOnAppState = (activeCallback: any, inactiveCallback: any) => {
  let hidden: string, visibilityChange;
  let currentIsWindowBlur: boolean;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof (document as any)[`msHidden`] !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof (document as any)[`webkitHidden`] !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
  const handleVisibilityChange = (isDocEvent?: any, isWindowBlur?: any) => () => {
    if (isWindowBlur !== null) {
      currentIsWindowBlur = isWindowBlur;
      if (currentIsWindowBlur) {
        inactiveCallback();
      } else {
        activeCallback();
      }
    }

    if (isDocEvent !== null && !currentIsWindowBlur) {
      if ((document as any)[hidden]) {
        inactiveCallback();
      } else {
        activeCallback();
      }
    }
  };
  document.addEventListener(visibilityChange as any, handleVisibilityChange(true, null), false);
  window.addEventListener(`focus`, handleVisibilityChange(null,false), false);
  window.addEventListener(`blur`, handleVisibilityChange(null,true), false);
};

export const generateUsePrevious = (initialValue?: any) => {
  let lastValue = initialValue;
  let currentValue = initialValue;
  return (value: any) => {
    if (currentValue !== value) {
      lastValue = currentValue;
      currentValue = value;
    }
    return [lastValue, currentValue];
  }
};