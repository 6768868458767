import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import qs from 'querystring';
import moment from 'moment-timezone';
import {
  IonContent,
  IonGrid,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonRefresherContent,
  IonRefresher,
  IonRow,
  IonCol,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSearchbar,
} from '@ionic/react';
import * as generalUtils from 'utils/generalUtils';
import backendConstants from 'backendConstants';
import language from 'languages';
import Header from 'commons/Header/Header';
import routes from 'routes';

import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as confirmActions from 'store/confirm';

import * as userClientActions from 'store/userClient';
import * as userTechnicalActions from 'store/userTechnical';
import * as equipmentActions from 'store/equipment';

import { SubHeader, Wrapper } from 'commons/commons.styles';
import ShowContent from 'commons/ShowContent/ShowContent';
import { RefresherEventDetail } from '@ionic/core';
import { NoEquipmentPlaceholder } from './Components/NoEquipmentPlaceholder';
import { EquipmentItem } from './Components/EquipmentItem';
import languages from 'languages';
import { getStore } from 'utils/sessionUtils';

export const EQUIPMENT_MAINTENANCE = {
  THIS_MONTH: 'THIS_MONTH',
  NEXT_MONTH: 'NEXT_MONTH',
};

export const MantainEquipment: React.FC<any> = props => {
  const {
    userClientReducer,
    userTechnicalReducer,
    equipmentReducer,
    selfReducer,

    setConfirm,
    setSubmitting,
    setToast,

    checkUserClients,
    checkUserTechnicals,

    // checkEquipments,
    readEquipments,
    updateEquipment,
    // updateEquipments,
    resetEquipments,

    // createEquipment,

    history,
    location,
  } = props;

  const queryParams = qs.parse(props.location.search.replace('?', ''));
  const showContent = useMemo(() => {
    return location.pathname === routes.manageEquipment.maintain;
  }, [location.pathname]);

  const [refreshing, setRefreshing] = useState(false);
  const [maintainEquipmentTab, setEquipmentStatus] = useState<string>(
    (queryParams.maintainEquipmentTab as string) || EQUIPMENT_MAINTENANCE.THIS_MONTH
  );

  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);
  const [selectedUserClient, setSelectedUserClient] = useState<any>(null);

  const [equipmentSearch, setEquipmentSearch] = useState<any>('');

  const userClients = userClientReducer.responseCheck && userClientReducer.responseCheck.data;

  const userTechnicals = userTechnicalReducer.responseCheck && userTechnicalReducer.responseCheck.data;

  const equipments = equipmentReducer.responseRead && equipmentReducer.responseRead.data;

  const updatedEquipments = equipmentReducer.responseUpdate && equipmentReducer.responseUpdate.data;

  const self = selfReducer.response;

  const equipmentsByTab = useMemo(() => {
    if (self === null) {
      console.log(updatedEquipments,userTechnicals,selectedUserClient);
    }

    return (
      equipments &&
      equipments.filter((equipment: any) => {
        const matchSerial = equipment.serial.toLowerCase().includes(equipmentSearch.toLowerCase());
        const matchClient =
          equipment.clientUserId &&
          equipment.clientUserId.name &&
          equipment.clientUserId.name.toLowerCase().includes(equipmentSearch.toLowerCase());
        return matchSerial || matchClient;
      })
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipments, equipmentSearch, maintainEquipmentTab]);

  const handleEquipmentStatusChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setEquipmentStatus(event.detail.value);
      history.push(`${routes.manageEquipment.maintain}?maintainEquipmentTab=${event.detail.value}`);
    }
  };

  const handleEquipmentSearch = (e: any) => e && e.target && setEquipmentSearch(e.target.value);

  useEffect(() => {
    if (queryParams.maintainEquipmentTab) {
      setEquipmentStatus(queryParams.maintainEquipmentTab as any);
    }
  }, [queryParams.maintainEquipmentTab]);

  const resetSelectedEquipment = () => setSelectedEquipment(null);
  const resetSelectedUserClient = () => setSelectedUserClient(null);
  const resetEquipmentSearch = () => setEquipmentSearch('');

  const resetCurrentEquipmentStatusTab = () => {
    resetEquipments();
    resetSelectedEquipment();
    resetSelectedUserClient();
    resetEquipmentSearch();
    // Reset Content Scroll
    resetContentScrollTop();
  };

  const handleConfirmAlert = _.throttle(
    (selectedEquipment: any) =>
      setConfirm(
        `
        <div>
            Bảo dưỡng máy:
            <h5>${selectedEquipment.serial}</h5>
            Lần tới: ${moment()
              .add(3, 'month')
              .format('DD-MM-YYYY')}
        </div>
        `,
        async () => {
          let submitData: any = {};
          if (selectedEquipment.maintainOption === backendConstants.EQUIPMENT_MAINTAIN_OPTIONS.YES) {
            submitData.maintainedAt = moment()
              .utc()
              .toDate();
          }
          setSubmitting(true);
          try {
            await updateEquipment({
              serial: selectedEquipment.serial,
              maintainedAt: moment()
                .add(backendConstants.EQUIPMENT_MAINTAIN_MONTH, 'month')
                .utc()
                .toDate(),
            });
            setToast(languages.manageEquipment.ui.doneActions.MAINTAIN, 'primary');
          } catch (err) {
            setToast(languages.manageEquipment.ui.failActions.MAINTAIN, 'danger');
          }
          setSubmitting(false);
          resetCurrentEquipmentStatusTab();
          handleReadEquipment();
          handleReadUserClient();
          handleReadUserTechnical();
        }
      ),
    3000, { leading: true, trailing: false }
  );

  useEffect(() => {
    const currentUserClient =
      userClients &&
      userClients.find((userClient: any) => {
        return selectedEquipment && selectedEquipment.clientUserId && selectedEquipment.clientUserId._id === userClient._id;
      });
    if (currentUserClient) {
      setSelectedUserClient(currentUserClient);
    } else {
      resetSelectedUserClient();
    }
  }, [selectedEquipment, userClients]);

  const handleReadUserClient = () => {
    return checkUserClients({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
      populate: {
        path: 'clientId',
        select: '_id name type',
      },
      aggregationOptions: {
        keepObject: true,
      },
    });
  };

  const handleReadUserTechnical = () => {
    return checkUserTechnicals({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        userType: backendConstants.USER_TYPES.TECHNICAL,
      },
      populate: {
        path: 'clientId',
        select: '_id name type',
      },
    });
  };

  const equipmentFilterByTab = useMemo(() => {
    const filter = {
      status: {
        $in: [
          backendConstants.EQUIPMENT_STATUSES.SOLD,
          backendConstants.EQUIPMENT_STATUSES.RENTED,
          backendConstants.EQUIPMENT_STATUSES.LENT,
        ],
      },
      maintainOption: backendConstants.EQUIPMENT_MAINTAIN_OPTIONS.YES,
    };
    if (maintainEquipmentTab === EQUIPMENT_MAINTENANCE.THIS_MONTH) {
      (filter as any).maintainedAt = {
        $lte: moment()
          .subtract(backendConstants.EQUIPMENT_MAINTAIN_MONTH, 'month')
          .utc()
          .toDate(),
      };
    }

    if (maintainEquipmentTab === EQUIPMENT_MAINTENANCE.NEXT_MONTH) {
      (filter as any).maintainedAt = {
        $gte: moment()
          .subtract(backendConstants.EQUIPMENT_MAINTAIN_MONTH, 'month')
          .utc()
          .toDate(),
        $lte: moment()
          .subtract(backendConstants.EQUIPMENT_MAINTAIN_MONTH - 1, 'month')
          .utc()
          .toDate(),
      };
    }
    return filter;
  }, [maintainEquipmentTab]);

  const handleReadEquipment = () => {
    return readEquipments({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        ...equipmentFilterByTab,
      },
      populate: [
        {
          path: 'clientUserId',
          select: '_id name contactName contactNumber clientId',
          populate: 'clientId',
        },
      ],
      sort: {
        maintainedAt: 1,
        updatedAt: 1,
      },
    });
  };

  // FOR NORMAL TAB - SEARCH IMMEDIATELY
  useEffect(() => {
    if (showContent) {
      resetCurrentEquipmentStatusTab();
      handleReadEquipment();
      handleReadUserClient();
      handleReadUserTechnical();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintainEquipmentTab, showContent]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    resetCurrentEquipmentStatusTab();
    await handleReadEquipment();
    await handleReadUserClient();
    await handleReadUserTechnical();
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.manageEquipment.ui.menus.MAINTAIN} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleEquipmentStatusChange} scrollable={true}>
              <IonSegmentButton
                value={EQUIPMENT_MAINTENANCE.THIS_MONTH}
                checked={maintainEquipmentTab === EQUIPMENT_MAINTENANCE.THIS_MONTH}
              >
                {language.manageEquipment.ui.tabs[EQUIPMENT_MAINTENANCE.THIS_MONTH]}
              </IonSegmentButton>
              <IonSegmentButton
                value={EQUIPMENT_MAINTENANCE.NEXT_MONTH}
                checked={maintainEquipmentTab === EQUIPMENT_MAINTENANCE.NEXT_MONTH}
              >
                {language.manageEquipment.ui.tabs[EQUIPMENT_MAINTENANCE.NEXT_MONTH]}
              </IonSegmentButton>
            </IonSegment>
            <div>
              <IonSearchbar
                disabled={false}
                placeholder={`Tìm kiếm theo tên KH hoặc serial ...`}
                value={equipmentSearch}
                onIonChange={handleEquipmentSearch}
                debounce={250}
              />
            </div>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <IonGrid className={`ion-padding-bottom`}>
              <div className={`ion-align-items-center`}>
                {equipmentReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    <IonList className={`ion-margin-vertical`}>
                      {equipments && !!equipments.length && (
                        <IonListHeader>
                          Kết quả: {equipmentsByTab && equipmentsByTab.length}
                          {' máy'}
                        </IonListHeader>
                      )}
                    </IonList>
                    {equipmentsByTab && !!equipmentsByTab.length ? (
                      equipmentsByTab.map((equipment: any) => (
                        <EquipmentItem key={equipment.serial} equipment={equipment} onMaintainEquipment={handleConfirmAlert} />
                      ))
                    ) : (
                      <NoEquipmentPlaceholder />
                    )}
                  </div>
                )}
              </div>
            </IonGrid>
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  equipmentReducer: state.equipmentReducer,
  userClientReducer: state.userClientReducer,
  userTechnicalReducer: state.userTechnicalReducer,
  selfReducer: state.selfReducer,
});

const mapDispatchToProps = {
  setConfirm: confirmActions.setConfirm,
  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,

  checkUserClients: userClientActions.checkUserClients,
  checkUserTechnicals: userTechnicalActions.checkUserTechnicals,

  checkEquipments: equipmentActions.checkEquipments,
  readEquipments: equipmentActions.readEquipments,
  updateEquipment: equipmentActions.updateEquipment,
  updateEquipments: equipmentActions.updateEquipments,
  resetEquipments: equipmentActions.resetEquipments,

  createEquipment: equipmentActions.createEquipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(MantainEquipment);
