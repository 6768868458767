import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/log/client/details`;
export const REPORT_CLIENT_CRUD_ACTIONS = generateCrudActionTypes(`REPORT_CLIENT_CRUD`);

export const { check: checkClientReport, resetState: resetClientReport } = generateThunkActions(
  REPORT_CLIENT_CRUD_ACTIONS,
  ENDPOINT
);

export const reportClientReducer = generateCrudReducer(REPORT_CLIENT_CRUD_ACTIONS);
