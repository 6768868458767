export const TOAST_ACTION = 'TOAST_ACTION';

export const setToast = (hasToast: any, color: any = `primary`) => ({
  type: TOAST_ACTION,
  payload: {
    hasToast,
    color,
  },
});

export const toastReducer = (state: any = false, action: any) => {
  if (action.type === TOAST_ACTION) {
    return action.payload;
  }
  return state;
};
