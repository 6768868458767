import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFab,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
  IonTextarea,
} from '@ionic/react';
import { SubHeader } from 'commons/commons.styles';
import { ModalFooterWrapper, ModalWrapper, SearchBarModalWrapper } from 'commons/commons.styles';
import ShowContent from 'commons/ShowContent/ShowContent';

import backendConstants from 'backendConstants';
import languages from 'languages';

import { NoTankPlaceholder } from 'pages/ManageTank/Components/NoTankPlaceholder';
import { InvoiceNote } from './InvoiceStatusChangeModal.styles';

export const SEGMENTS = {
  TANK: 'TANK',
  SELECTED_TANK: 'SELECTED_TANK',
  OPTION: 'OPTION',
  DELIVERY: 'DELIVERY',
  NOTE: 'NOTE',
};

export const InvoiceStatusChangeModal: React.FC<any> = props => {
  const {
    title,
    isOpen,
    onClose,
    onConfirm,
    selectedInvoice,
    confirmLabel,
    user,
    userDeliveries,
    availableEquipments,
    availableTanks,
  } = props;

  const isUserDelivery = user && user.userType === backendConstants.USER_TYPES.DELIVERY;

  const [segment, setSegment] = useState(SEGMENTS.TANK);
  const [tankSearch, setTankSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedTanks, setSelectedTanks] = useState<any>({});
  const [selectedDeliveryUserId, setSelectedDeliveryUserId] = useState(isUserDelivery && user._id);
  const [invoiceNote, setInvoiceNote] = useState('');

  const resetSegment = () => setSegment(SEGMENTS.TANK);
  const resetSelectedDeliveryUserId = () => setSelectedDeliveryUserId(isUserDelivery && user._id);
  const resetSelectedOption = () => setSelectedOption(null);
  const resetSelectedTanks = () => setSelectedTanks({});
  const resetTankSearch = () => setTankSearch('');
  const resetInvoiceNote = () => setInvoiceNote('');

  const resetModal = () => {
    resetSegment();
    resetSelectedDeliveryUserId();
    resetSelectedOption();
    resetSelectedTanks();
    resetTankSearch();
    resetInvoiceNote();
  };

  const handleCloseModal = (noReload: boolean) => (
    // event?: any
  ) => {
    onClose && onClose(noReload);
    resetModal();
  };

  const handleConfirmUpdate = _.throttle(async () => {
    onConfirm &&
      (await onConfirm(selectedInvoice, {
        tanksBySelectedTanks,
        selectedOption,
        selectedDeliveryUserId,
        invoiceNote,
      }));
    resetModal();
  }, 3000, { leading: true, trailing: false });

  const handleSegmentChange = (event: any) => {
    event && event.detail && setSegment(event.detail.value);
  };

  const handleSearchChange = (event: any) => {
    event && event.target && setTankSearch(event.target.value);
  };

  const handleInvoiceNote = (event: any) => {
    if (event) {
      if (event.detail && event.detail.value) {
        setInvoiceNote(event.detail.value);
      } else {
        if (invoiceNote.length <= 6) {
          setInvoiceNote('');
        }
      }
    }
  };

  const handleSelectedTanks = (tankId: string) => (e: any) => {
    e &&
      setSelectedTanks({
        ...selectedTanks,
        [tankId]: e.target.checked,
      });
  };

  const handleRemoveTank = (tankId: string) => () => {
    setSelectedTanks({
      ...selectedTanks,
      [tankId]: false,
    });
  };

  const tanksBySelectedTanks = useMemo(() => {
    const selectTanksIds = selectedTanks ? Object.keys(selectedTanks).filter((key: string) => !!selectedTanks[key]) : null;
    return selectTanksIds && availableTanks ? availableTanks.filter((tank: any) => selectTanksIds.includes(tank._id)) : [];
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTanks]);

  const handleSelectedOption = (e: any) => {
    e && setSelectedOption(e.target.value);
  };

  const handleSelectedDeliveryUserId = (e: any) => {
    e && setSelectedDeliveryUserId(e.target.value);
  };

  const availableEquipmentTankTypes = useMemo(() => {
    return availableEquipments ? Object.keys(_.groupBy(availableEquipments, (equipment: any) => equipment.tankType)) : [];
  }, [availableEquipments]);

  const availableTanksByTankType = useMemo(() => {
    return availableTanks ? availableTanks.filter((tank: any) => availableEquipmentTankTypes.includes(tank.type)) : [];
  }, [availableTanks, availableEquipmentTankTypes]);

  const availableTanksByTankTypeAfterSearch = useMemo(() => {
    return availableTanksByTankType.filter((tank: any) => tank.serial.toLowerCase().includes(tankSearch.toLowerCase()));
  }, [availableTanksByTankType, tankSearch]);

  const getHasButton = () => {
    if (selectedInvoice) {
      const canAction =
        selectedInvoice &&
        [
          backendConstants.INVOICE_STATUSES.REQUEST,
          backendConstants.INVOICE_STATUSES.APPROVED,
          backendConstants.INVOICE_STATUSES.DELIVERING,
        ].includes(selectedInvoice.status);
      let canShowButton = true;
      if (selectedInvoice.status === backendConstants.INVOICE_STATUSES.REQUEST && segment !== SEGMENTS.SELECTED_TANK) {
        canShowButton = false;
      }
      return (canAction && canShowButton) || selectedInvoice.willDisable;
    }
    return false;
  };
  const getCanSubmit = () => {
    if (selectedInvoice) {
      let canSubmit = true;
      if (selectedInvoice.status === backendConstants.INVOICE_STATUSES.REQUEST) {
        canSubmit = !!tanksBySelectedTanks.length;
      }

      if (selectedInvoice.status === backendConstants.INVOICE_STATUSES.APPROVED) {
        canSubmit = !!selectedOption && !!selectedDeliveryUserId;
      }

      if (selectedInvoice.status === backendConstants.INVOICE_STATUSES.DELIVERING) {
        canSubmit = !!invoiceNote && invoiceNote.length >= 6;
      }

      if (selectedInvoice.willDisable) {
        canSubmit = true;
      }

      return canSubmit;
    }
    return false;
  };

  return (
    isOpen && (
      <IonModal isOpen={true} backdropDismiss={false} showBackdrop={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={`start`}>
              <IonButton onClick={handleCloseModal(true)}>Trở lại</IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
          <SubHeader>
            {selectedInvoice &&
              [
                backendConstants.INVOICE_STATUSES.REQUEST,
                backendConstants.INVOICE_STATUSES.APPROVED,
                backendConstants.INVOICE_STATUSES.DELIVERING,
                backendConstants.INVOICE_STATUSES.DELIVERED,
              ].includes(selectedInvoice.status) &&
              !selectedInvoice.willDisable && (
                <IonSegment className={`ion-padding-bottom`} scrollable={true} onIonChange={handleSegmentChange}>
                  <IonSegmentButton value={SEGMENTS.TANK} checked={segment === SEGMENTS.TANK}>
                    Danh sách bình
                  </IonSegmentButton>
                  {selectedInvoice.status === backendConstants.INVOICE_STATUSES.REQUEST && (
                    <IonSegmentButton value={SEGMENTS.SELECTED_TANK} checked={segment === SEGMENTS.SELECTED_TANK}>
                      Bình đã chọn: {tanksBySelectedTanks.length}
                    </IonSegmentButton>
                  )}
                  {selectedInvoice.status === backendConstants.INVOICE_STATUSES.APPROVED && (
                    <React.Fragment>
                      <IonSegmentButton value={SEGMENTS.OPTION} checked={segment === SEGMENTS.OPTION}>
                        Phương thức giao vận
                      </IonSegmentButton>
                      {!isUserDelivery && (
                        <IonSegmentButton value={SEGMENTS.DELIVERY} checked={segment === SEGMENTS.DELIVERY}>
                          Người giao
                        </IonSegmentButton>
                      )}
                    </React.Fragment>
                  )}
                  {[backendConstants.INVOICE_STATUSES.DELIVERING, backendConstants.INVOICE_STATUSES.DELIVERED].includes(
                    selectedInvoice.status
                  ) && (
                    <IonSegmentButton value={SEGMENTS.NOTE} checked={segment === SEGMENTS.NOTE}>
                      Thông tin giao hàng
                    </IonSegmentButton>
                  )}
                </IonSegment>
              )}
            {selectedInvoice.status === backendConstants.INVOICE_STATUSES.REQUEST &&
              segment === SEGMENTS.TANK &&
              !selectedInvoice.willDisable && (
                <SearchBarModalWrapper>
                  <IonSearchbar
                    placeholder={`Tìm vỏ theo serial...`}
                    value={tankSearch}
                    onIonChange={handleSearchChange}
                    debounce={250}
                  />
                </SearchBarModalWrapper>
              )}
          </SubHeader>
        </IonHeader>
        <ShowContent isShow={true}>
          <IonContent className={`ion-padding`}>
            <ModalWrapper>
              <IonGrid>
                {segment === SEGMENTS.TANK && (
                  <React.Fragment>
                    <IonList>
                      <IonListHeader>
                        <IonLabel>
                          {selectedInvoice &&
                            (selectedInvoice.willDisable ? (
                              <IonText color={`primary`}>{selectedInvoice.clientUserId.name}</IonText>
                            ) : (
                              <IonText>Danh sách bình:</IonText>
                            ))}
                        </IonLabel>
                      </IonListHeader>
                      {selectedInvoice &&
                        selectedInvoice.status === backendConstants.INVOICE_STATUSES.REQUEST &&
                        availableTanksByTankType &&
                        !selectedInvoice.willDisable &&
                        (!!availableTanksByTankTypeAfterSearch.length ? (
                          availableTanksByTankTypeAfterSearch.map((tank: any) => (
                            <IonItem key={tank.serial}>
                              <IonLabel>
                                <IonText>{tank.serial}</IonText>
                                {tank.clientUserId && (
                                  <p>
                                    <IonText color={`primary`}>{tank.clientUserId.name}</IonText>
                                  </p>
                                )}
                                <p>
                                  <IonText>{(languages.manageTank.ui as any)[tank.type]}</IonText>
                                </p>
                              </IonLabel>
                              <IonCheckbox
                                slot={`start`}
                                onIonChange={handleSelectedTanks(tank._id)}
                                checked={(selectedTanks as any)[tank._id]}
                              />
                            </IonItem>
                          ))
                        ) : (
                          <NoTankPlaceholder />
                        ))}
                      {selectedInvoice &&
                        selectedInvoice.status !== backendConstants.INVOICE_STATUSES.REQUEST &&
                        (!!selectedInvoice.tanks.length ? (
                          selectedInvoice.tanks.map((tank: any) => (
                            <IonItem key={tank.serial}>
                              <IonLabel>
                                <IonText>{tank.serial}</IonText>
                                {tank.clientUserId && (
                                  <p>
                                    <IonText color={`primary`}>{tank.clientUserId.name}</IonText>
                                  </p>
                                )}
                                <p>
                                  <IonText>{(languages.manageTank.ui as any)[tank.type]}</IonText>
                                </p>
                              </IonLabel>
                            </IonItem>
                          ))
                        ) : (
                          <NoTankPlaceholder />
                        ))}
                    </IonList>
                  </React.Fragment>
                )}
                {segment === SEGMENTS.SELECTED_TANK &&
                  (!!tanksBySelectedTanks.length ? (
                    <IonList>
                      <IonListHeader>
                        {selectedInvoice && selectedInvoice.clientUserId && (
                          <IonText color={`primary`}>{selectedInvoice.clientUserId.name}</IonText>
                        )}
                      </IonListHeader>
                      {tanksBySelectedTanks.map((tank: any) => (
                        <IonItemSliding key={tank._id}>
                          <IonItem>
                            <IonLabel>
                              <IonText>{tank.serial}</IonText>
                            </IonLabel>
                          </IonItem>
                          {tanksBySelectedTanks.length > 1 && (
                            <IonItemOptions side={`end`}>
                              <IonItemOption color={`danger`} onClick={handleRemoveTank(tank._id)}>
                                Loại bỏ
                              </IonItemOption>
                            </IonItemOptions>
                          )}
                        </IonItemSliding>
                      ))}
                    </IonList>
                  ) : (
                    <NoTankPlaceholder />
                  ))}
                {segment === SEGMENTS.OPTION && (
                  <IonList>
                    <IonRadioGroup onIonChange={handleSelectedOption}>
                      {Object.keys(backendConstants.DELIVERY_OPTIONS).map(option => (
                        <IonItem key={option}>
                          <IonLabel>{(languages.manageInvoice.ui.deliveryOptions as any)[option]}</IonLabel>
                          <IonRadio mode={`md`} slot={`start`} value={option} checked={option === selectedOption} />
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </IonList>
                )}
                {segment === SEGMENTS.DELIVERY && (
                  <IonList>
                    <IonRadioGroup onIonChange={handleSelectedDeliveryUserId}>
                      {userDeliveries &&
                        userDeliveries.map((userDelivery: any) => (
                          <IonItem key={userDelivery._id}>
                            <IonLabel>
                              <IonText>{userDelivery.name}</IonText>
                              <p>{userDelivery.username}</p>
                            </IonLabel>
                            <IonRadio
                              mode={`md`}
                              slot={`start`}
                              value={userDelivery._id}
                              checked={userDelivery._id === selectedDeliveryUserId}
                            />
                          </IonItem>
                        ))}
                    </IonRadioGroup>
                  </IonList>
                )}
                {segment === SEGMENTS.NOTE && (
                  <InvoiceNote>
                    <IonLabel position={`floating`}>
                      {languages.manageInvoice.ui.deliveryOptions[selectedInvoice.deliveryOption]}
                    </IonLabel>
                    {selectedInvoice.status === backendConstants.INVOICE_STATUSES.DELIVERING ? (
                      <IonTextarea
                        rows={10}
                        placeholder={languages.manageInvoice.ui.notePlaceholder[selectedInvoice.deliveryOption]}
                        autoGrow={true}
                        autofocus={true}
                        onIonChange={handleInvoiceNote}
                        onIonBlur={handleInvoiceNote}
                        value={invoiceNote}
                      />
                    ) : (
                      <IonTextarea rows={10} value={selectedInvoice.note} disabled={true} />
                    )}
                  </InvoiceNote>
                )}
              </IonGrid>
            </ModalWrapper>
          </IonContent>
        </ShowContent>
        <IonFooter>
          {getHasButton() && (
            <ModalFooterWrapper>
              <IonFab vertical={`top`} horizontal={`end`} slot={`fixed`}>
                <IonButton
                  color={selectedInvoice.willDisable ? `danger` : `primary`}
                  onClick={handleConfirmUpdate}
                  disabled={!getCanSubmit()}
                >
                  <IonLabel>{confirmLabel}</IonLabel>
                  <IonBadge color={`light`}>
                    {(selectedInvoice && selectedInvoice.tanks.length) || tanksBySelectedTanks.length}
                  </IonBadge>
                </IonButton>
              </IonFab>
            </ModalFooterWrapper>
          )}
        </IonFooter>
      </IonModal>
    )
  );
};

export default InvoiceStatusChangeModal;
