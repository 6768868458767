import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/user`;
export const USER_TECHNICAL_CRUD_ACTIONS = generateCrudActionTypes(`USER_TECHNICAL_CRUD`);

export const {
  check: checkUserTechnicals,
  read: readUserTechnicals,
  create: createUserTechnical,
  update: updateUserTechnical,
  updateBatch: updateUserTechnicals,
  resetState: resetUserTechnicals,
} = generateThunkActions(USER_TECHNICAL_CRUD_ACTIONS, ENDPOINT);

export const userTechnicalReducer = generateCrudReducer(USER_TECHNICAL_CRUD_ACTIONS);
