import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/equipment`;
export const EQUIPMENT_CRUD_ACTIONS = generateCrudActionTypes(`EQUIPMENT_CRUD`);

export const {
  check: checkEquipments,
  read: readEquipments,
  create: createEquipment,
  update: updateEquipment,
  updateBatch: updateEquipments,
  resetState: resetEquipments,
} = generateThunkActions(EQUIPMENT_CRUD_ACTIONS, ENDPOINT);

export const equipmentReducer = generateCrudReducer(EQUIPMENT_CRUD_ACTIONS);
