import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { SubHeader } from 'commons/commons.styles';
import { ModalFooterWrapper, ModalWrapper } from 'commons/commons.styles';
import { arrowDown, arrowUp } from 'ionicons/icons';
import backendConstants from 'backendConstants';
import languages from 'languages';
import { EQUIPMENT_ACTIONS } from '../ManageEquipment.constants';
import EquipmentItem from '../Components/EquipmentItem';

const SEGMENTS = {
  EQUIPMENT: 'EQUIPMENT',
  CLIENT: 'CLIENT',
};

export const EquipmentStatusChangeModal: React.FC<any> = props => {
  const { isOpen, onClose, onConfirm, selectedEquipment,
    selectedUserClient,
    userClients,
    // userTechnicals
  } = props;

  const [segment, setSegment] = useState(SEGMENTS.EQUIPMENT);
  const [userClientId, setUserClientId] = useState('');
  const [groupExpanded, setGroupExpanded] = useState({});
  const [groupSearch, setGroupSearch] = useState('');
  const [optionReason, setOptionReason] = useState<any>('');
  const [optionToUserClient, setOptionToUserClient] = useState<any>(selectedEquipment ? selectedEquipment.status : '');
  const [optionCanMaintain, setOptionCanMaintain] = useState<any>(selectedEquipment ? selectedEquipment.maintainOption : '');
  const [optionWarranty, setOptionWarranty] = useState<any>(selectedEquipment ? selectedEquipment.maintainOption : '');
  const [optionEquipmentType, setOptionEquipmentType] = useState<any>(selectedEquipment ? selectedEquipment.type : '');
  const [optionEquipmentTankType, setOptionEquipmentTankType] = useState<any>(
    selectedEquipment ? selectedEquipment.tankType : ''
  );

  const resetModal = () => {
    setSegment(SEGMENTS.EQUIPMENT);
    setUserClientId('');
    setGroupSearch('');
    setGroupExpanded({});
    setOptionReason('');
    setOptionToUserClient(selectedEquipment ? selectedEquipment.status : '');
    setOptionCanMaintain(selectedEquipment ? selectedEquipment.maintainOption : '');
    setOptionWarranty(selectedEquipment ? selectedEquipment.warrantyOption : '');
    setOptionEquipmentType(selectedEquipment ? selectedEquipment.type : '');
    setOptionEquipmentTankType(selectedEquipment ? selectedEquipment.tankType : '');
  };

  useEffect(() => {
    resetModal();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEquipment]);

  useEffect(() => {
    setOptionWarranty(selectedEquipment ? selectedEquipment.warrantyOption : '');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionToUserClient]);

  const handleExpandGroup = (groupId: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupId]: !(groupExpanded as any)[groupId],
    });

  const handleSegmentChange = (event: any) => {
    event && event.detail && setSegment(event.detail.value);
  };

  const handleGroupSearchChange = (event: any) => {
    event && event.target && setGroupSearch(event.target.value);
  };

  const handleUserClientRadioChange = (event: any) => {
    event && event.target && setUserClientId(event.target.value);
  };

  const handleOptionReason = (event: any) => {
    event && event.target && setOptionReason(event.target.value);
  };

  const handleOptionToUserClient = (event: any) => {
    event && event.target && setOptionToUserClient(event.target.value);
  };

  const handleOptionCanMaintain = (event: any) => {
    event && event.target && setOptionCanMaintain(event.target.value);
  };

  const handleOptionWarranty = (event: any) => {
    event && event.target && setOptionWarranty(event.target.value);
  };

  const handleOptionEquipmentType = (event: any) => {
    event && event.target && setOptionEquipmentType(event.target.value);
  };

  const handleOptionEquipmentTankType = (event: any) => {
    event && event.target && setOptionEquipmentTankType(event.target.value);
  };

  const handleCloseModal = (noReload: boolean) => (
    // event?: any
  ) => {
    onClose && onClose(noReload);
    resetModal();
  };

  const groupClientUsersByClient = useMemo(() => {
    if (!userClients || !userClients.length || !isOpen) return [];

    const userClientGroupedByClientId = _.groupBy(
      // FILTER OUT TEMPORARY STORE USER
      userClients.filter(
        (userClient: any) => userClient && userClient.reportExcluded !== backendConstants.USER_CLIENT_REPORT_EXCLUDED.YES
      ),
      userClient => userClient.clientId._id
    );

    if (Object.keys(userClientGroupedByClientId).length <= 1 && Object.keys(userClientGroupedByClientId)[0] === 'undefined')
      return [];

    return Object.values(userClientGroupedByClientId)
      .map(group => ({
        _id: group[0].clientId._id,
        name: group[0].clientId.name,
        status: group[0].clientId.status,
        userClients: group,
      }))
      .filter(group => group.userClients.length)
      .sort((aGroup, bGroup) => aGroup.name.localeCompare(bGroup.name));
  }, [userClients, isOpen]);

  const groupClientUsersByClientBySearch = useMemo((): any => {
    return groupClientUsersByClient
      ? groupClientUsersByClient.filter(
          (group: any) => group.name && group.name.toLowerCase().includes(groupSearch.toLowerCase())
        )
      : [];
  }, [groupClientUsersByClient, groupSearch]);

  const canSubmit = useMemo(() => {
    if (
      selectedEquipment &&
      selectedEquipment.submitAction === EQUIPMENT_ACTIONS.SOLD_RENTED_LENT &&
      (!userClientId || !optionToUserClient || !optionCanMaintain)
    ) {
      return false;
    }

    if (
      selectedEquipment &&
      selectedEquipment.submitAction === EQUIPMENT_ACTIONS.EDIT_SOLD_RENTED_LENT &&
      (!optionToUserClient || !optionCanMaintain)
    ) {
      return false;
    }

    if (
      selectedEquipment &&
      selectedEquipment.submitAction === EQUIPMENT_ACTIONS.CREATE &&
      (!optionEquipmentType || !optionEquipmentTankType)
    ) {
      return false;
    }

    if (
      selectedEquipment &&
      selectedEquipment.status !== backendConstants.EQUIPMENT_STATUSES.READY_TO_USE &&
      selectedEquipment.submitAction === EQUIPMENT_ACTIONS.CHECKING_FIXING &&
      !optionReason
    ) {
      return false;
    }

    if (optionToUserClient === backendConstants.EQUIPMENT_STATUSES.SOLD && !optionWarranty) {
      return false;
    }

    return !!selectedEquipment;
  }, [
    selectedEquipment,
    userClientId,
    optionToUserClient,
    optionWarranty,
    optionReason,
    optionCanMaintain,
    optionEquipmentType,
    optionEquipmentTankType,
  ]);

  const handleSubmit = () => {
    onConfirm &&
      selectedEquipment &&
      onConfirm({
        ...selectedEquipment,
        submitOptions: {
          userClientId,
          optionToUserClient,
          optionWarranty,
          optionReason,
          optionCanMaintain,
          optionEquipmentType,
          optionEquipmentTankType,
        },
      });
  };

  return (
    isOpen && (
      <IonModal isOpen={true} backdropDismiss={false} showBackdrop={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={`start`}>
              <IonButton onClick={handleCloseModal(true)}>Trở lại</IonButton>
            </IonButtons>
            <IonTitle>{languages.manageEquipment.ui.actions[selectedEquipment.submitAction]}</IonTitle>
          </IonToolbar>
          {selectedEquipment.status === backendConstants.EQUIPMENT_STATUSES.READY_TO_USE &&
            selectedEquipment.submitAction === EQUIPMENT_ACTIONS.SOLD_RENTED_LENT && (
              <SubHeader>
                {userClients && (
                  <IonSegment className={`ion-padding-bottom`} scrollable={true} onIonChange={handleSegmentChange}>
                    <IonSegmentButton value={SEGMENTS.EQUIPMENT} checked={segment === SEGMENTS.EQUIPMENT}>
                      Máy đã chọn
                    </IonSegmentButton>
                    <IonSegmentButton value={SEGMENTS.CLIENT} checked={segment === SEGMENTS.CLIENT}>
                      Chọn khách hàng
                    </IonSegmentButton>
                  </IonSegment>
                )}
                {segment === SEGMENTS.CLIENT && (
                  <IonSearchbar
                    placeholder={`Tìm tên khách hàng ...`}
                    value={groupSearch}
                    onIonChange={handleGroupSearchChange}
                    debounce={250}
                  />
                )}
              </SubHeader>
            )}
        </IonHeader>
        <IonContent className={`ion-padding`}>
          <ModalWrapper>
            <IonGrid>
              {segment === SEGMENTS.EQUIPMENT && (
                <React.Fragment>
                  <EquipmentItem equipment={selectedEquipment} noAction={true} />
                  {[EQUIPMENT_ACTIONS.SOLD_RENTED_LENT, EQUIPMENT_ACTIONS.EDIT_SOLD_RENTED_LENT].includes(
                    selectedEquipment.submitAction
                  ) && (
                    <React.Fragment>
                      <IonList>
                        <IonListHeader>
                          <IonText color={`primary`}>Phương thức xuất máy:</IonText>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleOptionToUserClient}>
                          {[
                            backendConstants.EQUIPMENT_STATUSES.SOLD,
                            backendConstants.EQUIPMENT_STATUSES.RENTED,
                            backendConstants.EQUIPMENT_STATUSES.LENT,
                          ].map(chosenStatus => (
                            <IonItem key={chosenStatus}>
                              <IonLabel>
                                <IonText>{languages.manageEquipment.ui.actions[chosenStatus]}</IonText>
                              </IonLabel>
                              <IonRadio
                                mode={`md`}
                                slot={`start`}
                                value={chosenStatus}
                                checked={optionToUserClient === chosenStatus}
                              />
                            </IonItem>
                          ))}
                        </IonRadioGroup>
                      </IonList>
                      {optionToUserClient === backendConstants.EQUIPMENT_STATUSES.SOLD && (
                        <IonList>
                          <IonListHeader>
                            <IonText color={`primary`}>Phương thức bảo hành:</IonText>
                          </IonListHeader>
                          <IonRadioGroup onIonChange={handleOptionWarranty}>
                            {Object.keys(backendConstants.EQUIPMENT_WARRANTY_OPTIONS).map(chosenOption => (
                              <IonItem key={chosenOption}>
                                <IonLabel>
                                  <IonText>{(languages.manageEquipment.ui.warrantyOption as any)[chosenOption]}</IonText>
                                </IonLabel>
                                <IonRadio
                                  mode={`md`}
                                  slot={`start`}
                                  value={chosenOption}
                                  checked={optionWarranty === chosenOption}
                                />
                              </IonItem>
                            ))}
                          </IonRadioGroup>
                        </IonList>
                      )}
                      <IonList>
                        <IonListHeader>
                          <IonText color={`primary`}>Phương thức bảo dưỡng:</IonText>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleOptionCanMaintain}>
                          {Object.keys(backendConstants.EQUIPMENT_MAINTAIN_OPTIONS).map(chosenOption => (
                            <IonItem key={chosenOption}>
                              <IonLabel>
                                <IonText>{(languages.manageEquipment.ui.maintainOption as any)[chosenOption]}</IonText>
                              </IonLabel>
                              <IonRadio
                                mode={`md`}
                                slot={`start`}
                                value={chosenOption}
                                checked={optionCanMaintain === chosenOption}
                              />
                            </IonItem>
                          ))}
                        </IonRadioGroup>
                      </IonList>
                    </React.Fragment>
                  )}
                  {selectedEquipment.submitAction === EQUIPMENT_ACTIONS.CHECKING_FIXING &&
                    selectedEquipment.status !== backendConstants.EQUIPMENT_STATUSES.READY_TO_USE &&
                    (selectedUserClient &&
                    (selectedUserClient.equipments.filter((equipment: any) => equipment.tankType === selectedEquipment.tankType)
                      .length >= 2 ||
                      selectedUserClient.tanks.every((tank: any) => tank.type !== selectedEquipment.tankType)) ? (
                      <IonList>
                        <IonListHeader>
                          <IonText color={`primary`}>Lí do kiểm tra:</IonText>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleOptionReason}>
                          {[backendConstants.EQUIPMENT_STATUSES.CHECKING, backendConstants.EQUIPMENT_STATUSES.FIXING].map(
                            chosenStatus => (
                              <IonItem key={chosenStatus}>
                                <IonLabel>
                                  <IonText>{(languages.manageEquipment.ui.actions as any)[chosenStatus]}</IonText>
                                </IonLabel>
                                <IonRadio
                                  mode={`md`}
                                  slot={`start`}
                                  value={chosenStatus}
                                  checked={optionReason === chosenStatus}
                                />
                              </IonItem>
                            )
                          )}
                        </IonRadioGroup>
                      </IonList>
                    ) : (
                      <IonList>
                        <IonListHeader>
                          <IonText color={`danger`}>Máy không thể thu hồi:</IonText>
                        </IonListHeader>
                        <IonItem>
                          <IonText color={`danger`}>
                            Khách hàng còn nợ vỏ loại {(languages.manageTank.ui as any)[selectedEquipment.tankType]}
                          </IonText>
                        </IonItem>
                      </IonList>
                    ))}
                  {selectedEquipment.submitAction === EQUIPMENT_ACTIONS.CREATE && (
                    <React.Fragment>
                      <IonList>
                        <IonListHeader>
                          <IonText color={`primary`}>Chọn loại máy:</IonText>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleOptionEquipmentType}>
                          {Object.keys(backendConstants.EQUIPMENT_TYPES).map(chosenType => (
                            <IonItem key={chosenType}>
                              <IonLabel>
                                <IonText>{(languages.manageEquipment.ui.types as any)[chosenType]}</IonText>
                              </IonLabel>
                              <IonRadio
                                mode={`md`}
                                slot={`start`}
                                value={chosenType}
                                checked={optionEquipmentType === chosenType}
                              />
                            </IonItem>
                          ))}
                        </IonRadioGroup>
                      </IonList>
                      <IonList>
                        <IonListHeader>
                          <IonText color={`primary`}>Chọn loại bình sử dụng:</IonText>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleOptionEquipmentTankType}>
                          {Object.keys(backendConstants.TANK_TYPES).map(chosenTankType => (
                            <IonItem key={chosenTankType}>
                              <IonLabel>
                                <IonText>{(languages.manageTank.ui.types as any)[chosenTankType]}</IonText>
                              </IonLabel>
                              <IonRadio
                                mode={`md`}
                                slot={`start`}
                                value={chosenTankType}
                                checked={optionEquipmentTankType === chosenTankType}
                              />
                            </IonItem>
                          ))}
                        </IonRadioGroup>
                      </IonList>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {segment === SEGMENTS.CLIENT && (
                <IonList>
                  <IonRadioGroup onIonChange={handleUserClientRadioChange}>
                    {groupClientUsersByClientBySearch.map(
                      (group: any) =>
                        group.status === backendConstants.CLIENT_STATUSES.ACTIVE && (
                          <IonItemGroup key={group._id}>
                            <IonItemDivider onClick={handleExpandGroup(group._id)}>
                              <IonLabel>{group.name}</IonLabel>
                              <IonBadge color={`medium`} slot={`end`}>
                                {group.userClients.length}
                              </IonBadge>
                              <IonIcon icon={(groupExpanded as any)[group._id] ? arrowUp : arrowDown} slot={`end`} />
                            </IonItemDivider>
                            {(groupExpanded as any)[group._id] &&
                              group.userClients.map((userClient: any) => (
                                <IonItem key={userClient._id}>
                                  <IonLabel>
                                    {userClient.name}
                                    <p>Sở hữu: {userClient.equipments.length || 0} máy</p>
                                    <p>Sử dụng: {userClient.tanks.length || 0} bình</p>
                                  </IonLabel>
                                  <IonRadio
                                    mode={`md`}
                                    slot={`start`}
                                    value={userClient._id}
                                    checked={userClientId === userClient._id}
                                    disabled={userClient.status === backendConstants.USER_STATUSES.DISABLED}
                                  />
                                </IonItem>
                              ))}
                          </IonItemGroup>
                        )
                    )}
                  </IonRadioGroup>
                </IonList>
              )}
            </IonGrid>
          </ModalWrapper>
        </IonContent>
        <IonFooter>
          <ModalFooterWrapper>
            <IonFab vertical={`top`} horizontal={`end`} slot={`fixed`}>
              <IonButton
                color={
                  selectedEquipment && selectedEquipment.submitAction === EQUIPMENT_ACTIONS.DISABLED ? `danger` : `primary`
                }
                onClick={handleSubmit}
                disabled={!canSubmit}
              >
                <IonLabel>{languages.manageEquipment.ui.actions[selectedEquipment.submitAction]}</IonLabel>
              </IonButton>
            </IonFab>
          </ModalFooterWrapper>
        </IonFooter>
      </IonModal>
    )
  );
};

export default EquipmentStatusChangeModal;
