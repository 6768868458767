import { Animation, NavOptions } from '@ionic/core';

export const NavAnimationIOS = (AnimationC: Animation, _: HTMLElement, opts: TransitionOptions): Promise<Animation> => {
  const TRANSLATE_DIRECTION = 'translateX';
  const OFF_BOTTOM = '100px';
  const CENTER = '0px';
  const enteringEl = opts.enteringEl;
  const leavingEl = opts.leavingEl;
  const ionPageElement = getIonPageElement(enteringEl);
  const rootTransition = new AnimationC();

  rootTransition.addElement(ionPageElement).beforeRemoveClass('ion-page-invisible');

  const backDirection = opts.direction === 'back';

  // animate the component itself
  if (backDirection) {
    rootTransition.duration(opts.duration || 200).easing('cubic-bezier(0,.65,.56,1.02)');
  } else {
    rootTransition
      .duration(opts.duration || 200)
      .easing('cubic-bezier(0,.65,.56,1.02)')
      .fromTo(TRANSLATE_DIRECTION, OFF_BOTTOM, CENTER)
      .fromTo('opacity', 0.5, 1);
  }

  // Animate toolbar if it's there
  const enteringToolbarEle = ionPageElement.querySelector('ion-toolbar');
  if (enteringToolbarEle) {
    const enteringToolBar = new AnimationC();
    enteringToolBar.addElement(enteringToolbarEle);
    rootTransition.add(enteringToolBar);
  }

  // setup leaving view
  if (leavingEl) {
    if (backDirection) {
      rootTransition.duration(opts.duration || 200).easing('cubic-bezier(0,.65,.56,1.02)');
      const leavingPage = new AnimationC();
      leavingPage
        .addElement(getIonPageElement(leavingEl))
        .fromTo(TRANSLATE_DIRECTION, CENTER, OFF_BOTTOM)
        .fromTo('opacity', 1, 0);
      rootTransition.add(leavingPage);
    } else {
      leavingEl.style.opacity = '0';
      rootTransition.onFinish(() => {
        leavingEl.style.opacity = '1';
      });
    }
  }

  return Promise.resolve(rootTransition);
};

export interface TransitionOptions extends NavOptions {
  animationCtrl: any;
  progressCallback?: (ani: Animation | undefined) => void;
  window: Window;
  baseEl: any;
  enteringEl: HTMLElement;
  leavingEl: HTMLElement | undefined;
}

function getIonPageElement(element: HTMLElement) {
  if (element.classList.contains('ion-page')) {
    return element;
  }
  const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');
  if (ionPage) {
    return ionPage;
  }
  return element;
}
