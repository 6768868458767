import styled from 'styled-components';

export const NormalWrapper = styled.div``;
export const CheckWrapper = styled.div`
  padding: 15px 0;
`;
export const OptionWrapper = styled.div`
  ion-list {
    margin: 0;
    padding-top: 20px;
  }
`;
export const SelectWrapper = styled.div`
  ion-list {
    margin: 0;
    padding-top: 20px;
  }
`;
