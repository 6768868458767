import languages from 'languages';
import routes from 'routes';
import frontendConstants from 'frontendConstants';
import { home, cog, barcode, nuclear, paper, people, person, settings, gitCompare } from 'ionicons/icons';
import backendConstants from './backendConstants';

export default {
  home: {
    text: languages.home,
    path: routes.home,
    icon: home,
    permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_HOME,
    actions: {
      home: {
        text: languages.home,
        path: routes.home,
      },
      stats: {
        text: languages.stats,
        path: routes.stats,
      }
    },
  },
  homeClient: {
    text: languages.home,
    path: routes.home,
    icon: home,
    permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_HOME_CLIENT,
    actions: {
      home: {
        text: languages.home,
        path: routes.home,
      },
    },
  },
  manageClient: {
    text: languages.manageClient.main,
    path: routes.manageClient.list,
    icon: people,
    permissions: [
      frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT,
      frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT_SALE,
      frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT_TECH,
    ],
    actions: {
      add: {
        text: languages.manageClient.ui.menus.ADD,
        path: routes.manageClient.add,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT,
      },
      list: {
        text: languages.manageClient.ui.menus.LIST,
        path: routes.manageClient.list,
        permissions: [
          frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT,
          frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT_SALE,
          frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT_TECH,
        ],
      },
      demo: {
        text: languages.manageClient.ui.menus.DEMO,
        path: routes.manageClient.demo,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT,
      },
      report: {
        text: languages.manageClient.ui.menus.REPORT,
        path: routes.manageClient.report,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT,
      },
    },
  },
  manageInvoiceSale: {
    text: languages.manageInvoice.main,
    path: routes.manageInvoice.month,
    icon: paper,
    permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_SALE,
    actions: {
      add: {
        text: languages.manageInvoice.ui.menus.ADD,
        path: routes.manageInvoice.add,
      },
      month: {
        text: languages.manageInvoice.ui.menus.MONTH,
        path: routes.manageInvoice.month,
      },
      lastMonth: {
        text: languages.manageInvoice.ui.menus.LAST_MONTH,
        path: routes.manageInvoice.lastMonth,
      },
    },
  },
  manageInvoice: {
    text: languages.manageInvoice.main,
    path: routes.manageInvoice.month,
    icon: paper,
    permissions: [frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE, frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_DELIVERY],
    actions: {
      day: {
        text: languages.manageInvoice.ui.menus.DAY,
        path: routes.manageInvoice.day,
      },
      month: {
        text: languages.manageInvoice.ui.menus.MONTH,
        path: routes.manageInvoice.month,
      },
      lastMonth: {
        text: languages.manageInvoice.ui.menus.LAST_MONTH,
        path: routes.manageInvoice.lastMonth,
        permissions: [
          frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE,
          frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_DELIVERY,
        ],
      },
    },
  },
  manageTank: {
    text: languages.manageTank.main,
    path: routes.manageTank.empty,
    icon: nuclear,
    permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_TANK,
    actions: {
      empty: {
        text: languages.manageTank.ui.menus.EMPTY,
        path: routes.manageTank.empty,
      },
      loading: {
        text: languages.manageTank.ui.menus.LOADING,
        path: routes.manageTank.loading,
      },
      readyToUse: {
        text: languages.manageTank.ui.menus.READY_TO_USE,
        path: routes.manageTank.readyToUse,
      },
      atClient: {
        text: languages.manageTank.ui.menus.AT_CLIENT,
        path: routes.manageTank.atClient,
      },
      testSerial: {
        text: languages.manageTank.ui.menus.TEST_SERIAL,
        path: routes.manageTank.testSerial,
      },
    },
  },
  manageEquipment: {
    text: languages.manageEquipment.main,
    path: routes.manageEquipment.main,
    icon: cog,
    permissions: [
      frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
      frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT_TECHNICAL,
    ],
    actions: {
      test: {
        text: languages.manageEquipment.ui.menus.TEST_SERIAL,
        path: routes.manageEquipment.test,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
      },
      readyToUse: {
        text: languages.manageEquipment.ui.menus.READY_TO_USE,
        path: routes.manageEquipment.readyToUse,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
      },
      soldRentedLent: {
        text: languages.manageEquipment.ui.menus.SOLD_RENTED_LENT,
        path: routes.manageEquipment.soldRentedLent,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
      },
      warranty: {
        text: languages.manageEquipment.ui.menus.WARRANTY,
        path: routes.manageEquipment.warranty,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
      },
      maintain: {
        text: languages.manageEquipment.ui.menus.MAINTAIN,
        path: routes.manageEquipment.maintain,
        permissions: [
          frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
          frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT_TECHNICAL,
        ],
      },
      disabled: {
        text: languages.manageEquipment.ui.menus.DISABLED,
        path: routes.manageEquipment.disabled,
        permissions: [
          frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
          frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT_TECHNICAL,
        ],
      },
    },
  },
  manageUser: {
    text: languages.manageUser.main,
    path: routes.manageUser.list,
    icon: person,
    permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_USER,
    actions: {
      add: {
        text: languages.manageUser.ui.menus.ADD,
        path: routes.manageUser.add,
      },
      list: {
        text: languages.manageUser.ui.menus.LIST,
        path: routes.manageUser.list,
      },
      userClient: {
        text: languages.manageUser.ui.menus.USER_CLIENT,
        path: routes.manageUser.userClient,
      },
    },
  },
  report: {
    text: languages.report.main,
    path: routes.report.main,
    icon: barcode,
    permissions: [
      frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT,
      frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_SALE,
      frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_DELIVERY,
    ],
    actions: {
      byClient: {
        text: languages.report.byClient,
        path: routes.report.byClient,
        permissions: [frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_SALE],
      },
      bySale: {
        text: languages.report.bySale,
        path: routes.report.bySale,
        permissions: [frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_SALE],
      },
      byDelivery: {
        text: languages.report.byDelivery,
        path: routes.report.byDelivery,
        permissions: [frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_DELIVERY],
      },
      byEquipment: {
        text: languages.report.byEquipment,
        path: routes.report.byEquipment,
        permissions: [frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT],
      },
    },
  },
  storeTransfer: {
    text: languages.storeTransfer.main,
    path: routes.storeTransfer.main,
    icon: gitCompare,
    permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_STORE_TRANSFER,
    permittedStore: backendConstants.CENTER_STORE,
    actions: {
      transferTank: {
        text: languages.storeTransfer.transferTank,
        path: routes.storeTransfer.transferTank,
      },
      transferEquipment: {
        text: languages.storeTransfer.transferEquipment,
        path: routes.storeTransfer.transferEquipment,
      },
    },
  },
  settings: {
    text: languages.settings.main,
    path: routes.settings.theme,
    icon: settings,
    actions: {
      theme: {
        text: languages.settings.theme,
        path: routes.settings.theme,
      },
      restore: {
        text: languages.settings.restore,
        path: routes.settings.restore,
        permissions: frontendConstants.VIEW_PERMISSIONS.VIEW_RESTORE_DB,
      },
      changePassword: {
        text: languages.settings.changePassword,
        path: routes.settings.changePassword,
      },
    },
  },
};
