import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as overviewActions from 'store/overview';
import {
  IonContent,
  IonPage,
  IonList,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonBadge,
  IonListHeader,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import Header from 'commons/Header/Header';
import { InvoicesWrapper } from './Home.styles';
import menus from 'menus';
import language from 'languages';
import moment from 'moment-timezone';
import * as invoiceActions from 'store/invoice';
import _ from 'lodash';
import ShowContent from 'commons/ShowContent/ShowContent';
import {
  INVOICE_TAB_KEYS,
  getInvoiceTabDate,
  // GroupedInvoiceList
} from 'pages/ManageInvoice/ManageInvoice';
import routes from 'routes';
// import backendConstants from 'backendConstants';
import { getStore, isCenterStore } from 'utils/sessionUtils';
import { SubHeader } from 'commons/commons.styles';
import vnConstants from 'vnConstants';

export const ALL_STORE = `ALL_STORE`;

export const HomeCard: React.FC<any> = props => {
  const {
    // history, route,
    date, title, data, dataKey
  } = props;
  return (
    <IonCard>
      <IonListHeader>
        {title}
        {': '}
        {date}
      </IonListHeader>
      <IonCardContent>
        <IonList lines={`none`}>
          <IonItem>
            <IonLabel>Đơn hoàn thành</IonLabel>
            <IonBadge color={`primary`} slot={`end`}>
              {data.deliveredInvoices[dataKey]}
            </IonBadge>
          </IonItem>
          <IonItem>
            <IonLabel>Chuyển nạp</IonLabel>
            <IonBadge color={`primary`} slot={`end`}>
              {data.loadingTanks[dataKey]}
            </IonBadge>
          </IonItem>
          <IonItem>
            <IonLabel>Nhập</IonLabel>
            <IonBadge color={`primary`} slot={`end`}>
              {data.loadedTanks[dataKey]}
            </IonBadge>
          </IonItem>
          <IonItem>
            <IonLabel>Thu hồi vỏ</IonLabel>
            <IonBadge color={`tertiary`} slot={`end`}>
              {data.toStoreTanks[dataKey]}
            </IonBadge>
          </IonItem>
          {dataKey === `today` && (
            <React.Fragment>
              <IonItem>
                <IonLabel>Tồn kho</IonLabel>
                <IonBadge color={`secondary`} slot={`end`}>
                  {data.readyToUseTanksCount}
                </IonBadge>
              </IonItem>
              <IonItem className={`ion-padding-start`}>
                <IonLabel>Bình 8 lít</IonLabel>
                <IonBadge color={`secondary`} slot={`end`}>
                  {data.readyToUseTanks8Count}
                </IonBadge>
              </IonItem>
              <IonItem className={`ion-padding-start`}>
                <IonLabel>Bình 4 lít</IonLabel>
                <IonBadge color={`secondary`} slot={`end`}>
                  {data.readyToUseTanks4Count}
                </IonBadge>
              </IonItem>
              <IonItem className={`ion-padding-start`}>
                <IonLabel>Bình 3 lít</IonLabel>
                <IonBadge color={`secondary`} slot={`end`}>
                  {data.readyToUseTanks3Count}
                </IonBadge>
              </IonItem>
            </React.Fragment>
          )}
          <IonItem>
            <IonLabel>Xuất bán</IonLabel>
            <IonBadge color={`primary`} slot={`end`}>
              {data.atClientNormalTanks[dataKey]}
            </IonBadge>
          </IonItem>
          <IonItem>
            <IonLabel>Xuất demo</IonLabel>
            <IonBadge color={`warning`} slot={`end`}>
              {data.atClientDemoTanks[dataKey]}
            </IonBadge>
          </IonItem>
          {dataKey === `today` && (
            <IonItem>
              <IonLabel>Tồn khách</IonLabel>
              <IonBadge color={`danger`} slot={`end`}>
                {data.atClientTanks}
              </IonBadge>
            </IonItem>
          )}
          {dataKey !== `today` && dataKey !== `yesterday` && (
            <React.Fragment>
              <IonItem>
                <IonLabel>Máy bán</IonLabel>
                <IonBadge color={`success`} slot={`end`}>
                  {data.soldEquipments[dataKey]}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Máy cho thuê</IonLabel>
                <IonBadge color={`success`} slot={`end`}>
                  {data.rentedEquipments[dataKey]}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Máy cho mượn</IonLabel>
                <IonBadge color={`success`} slot={`end`}>
                  {data.lentEquipments[dataKey]}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Máy thu hồi</IonLabel>
                <IonBadge color={`danger`} slot={`end`}>
                  {data.toStoreEquipments[dataKey]}
                </IonBadge>
              </IonItem>
            </React.Fragment>
          )}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

const Home: React.FC<any> = props => {
  const {
    history,
    location,
    resetOverview,
    overviewReducer,
    invoiceReducer,

    checkInvoices,
    resetInvoices,
  } = props;

  const [refreshing, setRefreshing] = useState(false);

  const [storeTab, setStoreTab] = useState<any>(getStore());
  const handleStoreTabChange = (e: any) => {
    e && e.target && setStoreTab(e.target.value);
  };

  // const invoices = invoiceReducer && invoiceReducer.responseCheck && invoiceReducer.responseCheck.data;

  const overview = useMemo(() => {
    let currentResponseKey;
    let currentResponseData;
    if (storeTab === ALL_STORE) {
      let mergedOverview = {};
      for (let storeKey of Object.keys(overviewActions.storeRequests)) {
        currentResponseKey = `responseOverview_${storeKey.toLowerCase()}`;
        currentResponseData = overviewReducer && overviewReducer[currentResponseKey] && overviewReducer[currentResponseKey].data;
        mergedOverview = _.mergeWith(mergedOverview, currentResponseData, (objValue: any, srcValue: any) => {
          if (typeof objValue === 'number' && typeof srcValue === 'number') {
            return objValue + srcValue;
          }
        });
      }
      return mergedOverview;
    } else {
      currentResponseKey = `responseOverview_${storeTab.toLowerCase()}`;
      return overviewReducer && overviewReducer[currentResponseKey] && overviewReducer[currentResponseKey].data;
    }
  }, [storeTab, overviewReducer]);

  const isFetching =
    invoiceReducer.isFetching ||
    Object.keys(overviewActions.storeRequests).some(
      (storeKey: any) => overviewReducer[`isFetchingOverview_${storeKey.toLowerCase()}`]
    );

  const showContent = useMemo(() => {
    return location.pathname === routes.home;
  }, [location.pathname]);

  const resetHome = () => {
    resetInvoices();
    resetOverview();
  };

  const checkOverview = async () => {
    let currentCheckAction;
    if (isCenterStore()) {
      await Promise.all([
        ...Object.keys(overviewActions.storeRequests).map((storeKey: any) => {
          currentCheckAction = props[`checkOverview_${storeKey.toLowerCase()}`];
          return currentCheckAction && currentCheckAction({ data: { store: storeKey } });
        }),
      ]);
    } else {
      currentCheckAction = props[`checkOverview_${getStore().toLowerCase()}`];
      currentCheckAction && (await currentCheckAction({ data: { store: getStore() } }));
    }
  };

  useEffect(() => {
    if (showContent) {
      resetHome();
      handleReadInvoice();
      checkOverview();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent]);

  useEffect(() => {
    handleReadInvoice();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeTab]);

  const handleReadInvoice = () => {
    return checkInvoices({
      page: 1,
      pageSize: 99999,
      filter: {
        store: storeTab !== ALL_STORE ? storeTab : undefined,
        updatedAt: {
          $gte: getInvoiceTabDate()
            [INVOICE_TAB_KEYS.DAY].utc()
            .toDate(),
        },
      },
      populate: [
        {
          path: 'clientUserId',
          select: '_id name clientId contactName contactNumber',
          populate: {
            path: 'clientId',
            select: '_id name',
          },
        },
        {
          path: 'tanks',
          select: '_id serial type updatedAt clientUserId',
          populate: {
            path: 'clientUserId',
            select: '_id username name',
          },
        },
        {
          path: 'deliveryUserId',
          select: '_id name',
        },
      ],
      sort: {
        updatedAt: -1,
      },
    });
  };

  // const invoiceGroupedByStatus = useMemo(() => {
  //   if (!invoices || !invoices.length) return [];
  //   const invoiceGroupByStatusObject = _.omit(
  //     _.groupBy(invoices, invoice => invoice.status),
  //     [backendConstants.INVOICE_STATUSES.TRASH]
  //   );
  //   return [
  //     backendConstants.INVOICE_STATUSES.REQUEST,
  //     backendConstants.INVOICE_STATUSES.APPROVED,
  //     backendConstants.INVOICE_STATUSES.DELIVERING,
  //     backendConstants.INVOICE_STATUSES.DELIVERED,
  //     backendConstants.INVOICE_STATUSES.CANCELLED,
  //   ].map((invoiceStatus: string) => ({
  //     status: invoiceStatus,
  //     invoices: (invoiceGroupByStatusObject as any)[invoiceStatus] || [],
  //   }));
  // }, [invoices]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    await checkOverview();
    setRefereshingDebounced(event);
  };

  return (
    <IonPage>
      <Header title={language.home} hasMenu={true}>
        {isCenterStore() && (
          <SubHeader>
            <IonSegment onIonChange={handleStoreTabChange} scrollable={true}>
              <IonSegmentButton key={ALL_STORE} value={ALL_STORE} checked={storeTab === ALL_STORE}>
                Toàn quốc
              </IonSegmentButton>
              {Object.keys(overviewActions.storeRequests).map((storeKey: any) => (
                <IonSegmentButton key={storeKey} value={storeKey} checked={storeTab === storeKey}>
                  {(vnConstants as any)[storeKey].name}
                </IonSegmentButton>
              ))}
            </IonSegment>
          </SubHeader>
        )}
      </Header>
      <ShowContent isShow={showContent}>
        <IonContent>
          <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {isFetching ? (
            <IonGrid className={`ion-padding`}>
              <IonRow className={`ion-padding ion-justify-content-center`}>
                <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            !refreshing &&
            overview && (
              <React.Fragment>
                {/*<InvoicesWrapper className="ion-align-items-center">*/}
                {/*  <IonCard>*/}
                {/*    <IonListHeader>*/}
                {/*      Hôm nay: {invoiceReducer && invoiceReducer.responseCheck && invoiceReducer.responseCheck.totalCount} đơn*/}
                {/*      hàng*/}
                {/*    </IonListHeader>*/}
                {/*    <IonCardContent>*/}
                {/*      <GroupedInvoiceList groupedInvoices={invoiceGroupedByStatus} noInteraction={true} />*/}
                {/*    </IonCardContent>*/}
                {/*  </IonCard>*/}
                {/*</InvoicesWrapper>*/}
                <InvoicesWrapper>
                  <HomeCard
                    title={`Hôm nay`}
                    date={`Ngày ${moment()
                      .locale('vi')
                      .format('DD/MM/YYYY')}`}
                    route={menus.manageTank}
                    {...{ history, location }}
                    data={overview}
                    dataKey={`today`}
                  />
                  <HomeCard
                    title={`Hôm qua`}
                    date={`Ngày ${moment()
                      .subtract(1, 'day')
                      .locale('vi')
                      .format('DD/MM/YYYY')}`}
                    route={menus.manageUser}
                    {...{ history, location }}
                    data={overview}
                    dataKey={`yesterday`}
                  />
                  {/*<HomeCard*/}
                  {/*  title={`Tuần này`}*/}
                  {/*  date={`Tuần ${moment()*/}
                  {/*    .startOf('week')*/}
                  {/*    .locale('vi')*/}
                  {/*    .format('ww/YYYY')}`}*/}
                  {/*  route={menus.manageEquipment}*/}
                  {/*  {...{ history, location }}*/}
                  {/*  data={overview}*/}
                  {/*  dataKey={`thisWeek`}*/}
                  {/*/>*/}
                  <HomeCard
                    title={`Tháng này`}
                    date={moment()
                      .startOf('month')
                      .locale('vi')
                      .format('MMMM/YYYY')}
                    route={menus.manageClient}
                    {...{ history, location }}
                    data={overview}
                    dataKey={`thisMonth`}
                  />
                  <HomeCard
                    title={`Tháng trước`}
                    date={moment()
                      .subtract(1, 'month')
                      .startOf('month')
                      .locale('vi')
                      .format('MMMM/YYYY')}
                    route={menus.report}
                    {...{ history, location }}
                    data={overview}
                    dataKey={`lastMonth`}
                  />
                </InvoicesWrapper>
              </React.Fragment>
            )
          )}
        </IonContent>
      </ShowContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  overviewReducer: state.overviewReducer,
  invoiceReducer: state.invoiceReducer,
});

const mapDispatchToProps = {
  ..._.mapKeys(overviewActions.storeRequests, (value: any, key: any) => `checkOverview_${key.toLowerCase()}`),
  resetOverview: overviewActions.resetOverview,
  checkInvoices: invoiceActions.checkInvoices,
  resetInvoices: invoiceActions.resetInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
