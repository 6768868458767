import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonSplitPane,
  IonPage,
  IonBadge,
  IonLoading,
  IonToast,
  IonAlert,
} from '@ionic/react';
import { home, cog, nuclear, paper, barcode, people } from 'ionicons/icons';

// import routes from './routes';

// All common components if needed
import Menu from './commons/Menu/Menu';

// All Page Components
import Home from './pages/Home/Home';
import Stats from './pages/Stats/Stats';
import HomeClient from './pages/Home/HomeClient';
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ManageUser from './pages/ManageUser/ManageUser';
import ManageTank from './pages/ManageTank/ManageTank';
import ManageEquipment from './pages/ManageEquipment/ManageEquipment';
import MaintainEquipment from './pages/ManageEquipment/MaintainEquipment';
import ManageClient from './pages/ManageClient/ManageClient';
import ManageClientSale from './pages/ManageClient/ManageClientSale';
import ManageClientTech from './pages/ManageClient/ManageClientTech';
import ManageInvoice from './pages/ManageInvoice/ManageInvoice';
import ManageInvoiceSale from './pages/ManageInvoice/ManageInvoiceSale';
import ManageInvoiceDelivery from './pages/ManageInvoice/ManageInvoiceDelivery';
import ManageInvoiceClient from './pages/ManageInvoice/ManageInvoiceClient';
import Report from './pages/Report/Report';
import ReportSale from './pages/Report/ReportSale';
import ReportDelivery from './pages/Report/ReportDelivery';
import ReportClient from './pages/Report/ReportClient';
import Notification from './pages/Notification/Notification';
import Settings from './pages/Settings/Settings';
import Restore from './pages/Restore/Restore';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import StoreTransfer from './pages/StoreTransfer/StoreTransfer';

/* App CSS */
import './App.css';

/* Language */
import language from 'languages';

/* Routes */
import routes from 'routes';
import { getRouteKey, getTabRoute } from 'utils/routeUtils';

/* Authentication */
import * as selfActions from 'store/self';
import * as toastActions from 'store/toast';
import * as confirmActions from 'store/confirm';

/* Styled Components */
import { AppWrapper } from './App.styles';
import backendConstants from './backendConstants';
import frontendConstants from './frontendConstants';

/* Utils */
import { isPermittedByUserType } from 'utils/generalUtils';
import { setupNativeNotification } from 'utils/notificationNativeUtils';

const makeRoute = (userType: any, permissions: any, path: any, component: any) => {
  return isPermittedByUserType(userType, permissions) ? <Route path={path} component={component} /> : <div />;
};

const AppRedirect: React.FC<any> = (props: any) => {
  const { isLoggedIn, logout, history, location, user, unreadNotification, setNativeToken, socket } = props;
  const [firstLocation, setFirstLocation] = useState<any>(null);

  const userType = user && user.userType;

  useEffect(() => {
    setupNativeNotification(setNativeToken, history);
    setFirstLocation(location);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push(routes.login);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <AppWrapper inactived={location.pathname === routes.login}>
      <Route
        path={routes.login}
        render={routerProps => <Login {...routerProps} isLoggedIn={isLoggedIn} user={user} firstLocation={firstLocation} socket={socket} />}
      />
      <Redirect from={`*`} to={routes.login} />
      {isLoggedIn && user ? (
        <IonSplitPane contentId={`main`}>
          <Menu user={user} logout={logout} setFirstLocation={setFirstLocation} />
          <IonPage id={`main`}>
            <IonTabs>
              <IonRouterOutlet>
                {makeRoute(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_HOME, getTabRoute(routes.home), Home)}
                {makeRoute(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_HOME_CLIENT, getTabRoute(routes.home), HomeClient)}
                {makeRoute(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_HOME, getTabRoute(routes.stats), Stats)}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_USER,
                  getTabRoute(routes.manageUser.main),
                  ManageUser
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_TANK,
                  getTabRoute(routes.manageTank.main),
                  ManageTank
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
                  getTabRoute(routes.manageEquipment.main),
                  ManageEquipment
                )}
                {makeRoute(
                  userType,
                  [
                    frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT,
                    frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT_TECHNICAL,
                  ],
                  getTabRoute(routes.manageEquipment.maintain),
                  MaintainEquipment
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT,
                  getTabRoute(routes.manageClient.main),
                  ManageClient
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT_SALE,
                  getTabRoute(routes.manageClient.main),
                  ManageClientSale
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT_TECH,
                  getTabRoute(routes.manageClient.main),
                  ManageClientTech
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE,
                  getTabRoute(routes.manageInvoice.main),
                  ManageInvoice
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_SALE,
                  getTabRoute(routes.manageInvoice.main),
                  ManageInvoiceSale
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_DELIVERY,
                  getTabRoute(routes.manageInvoice.main),
                  ManageInvoiceDelivery
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_CLIENT,
                  getTabRoute(routes.manageInvoice.main),
                  ManageInvoiceClient
                )}
                {makeRoute(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT, getTabRoute(routes.report.main), Report)}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_SALE,
                  getTabRoute(routes.report.main),
                  ReportSale
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_DELIVERY,
                  getTabRoute(routes.report.main),
                  ReportDelivery
                )}
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_CLIENT,
                  getTabRoute(routes.report.main),
                  ReportClient
                )}
                <Route path={getTabRoute(routes.notification)} component={Notification} />

                <Route path={getTabRoute(routes.settings.theme)} component={Settings} />
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_RESTORE_DB,
                  getTabRoute(routes.settings.restore),
                  Restore
                )}
                <Route path={getTabRoute(routes.settings.changePassword)} component={ChangePassword} />
                {makeRoute(
                  userType,
                  frontendConstants.VIEW_PERMISSIONS.VIEW_STORE_TRANSFER,
                  getTabRoute(routes.storeTransfer.main),
                  StoreTransfer
                )}
              </IonRouterOutlet>
              <IonTabBar slot={`bottom`}>
                <IonTabButton
                  tab={getRouteKey(routes.home)}
                  href={routes.home}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_HOME)}
                >
                  <IonIcon icon={home} />
                  <IonLabel>{language.home}</IonLabel>
                  {unreadNotification.length > 0 && <IonBadge color={`danger`}>{unreadNotification.length}</IonBadge>}
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.home)}
                  href={routes.home}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_HOME_CLIENT)}
                >
                  <IonIcon icon={home} />
                  <IonLabel>{language.home}</IonLabel>
                  {unreadNotification.length > 0 && <IonBadge color={`danger`}>{unreadNotification.length}</IonBadge>}
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageTank.main)}
                  href={routes.manageTank.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_TANK)}
                >
                  <IonIcon icon={nuclear} />
                  <IonLabel>{language.tab.manageTank}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageInvoice.main)}
                  href={routes.manageInvoice.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE)}
                >
                  <IonIcon icon={paper} />
                  <IonLabel>{language.tab.manageInvoice}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageInvoice.main)}
                  href={routes.manageInvoice.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_SALE)}
                >
                  <IonIcon icon={paper} />
                  <IonLabel>{language.tab.manageInvoice}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageInvoice.main)}
                  href={routes.manageInvoice.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_DELIVERY)}
                >
                  <IonIcon icon={paper} />
                  <IonLabel>{language.tab.manageInvoice}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageInvoice.main)}
                  href={routes.manageInvoice.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_INVOICE_CLIENT)}
                >
                  <IonIcon icon={paper} />
                  <IonLabel>{language.tab.manageInvoice}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageEquipment.main)}
                  href={routes.manageEquipment.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT)}
                >
                  <IonIcon icon={cog} />
                  <IonLabel>{language.tab.manageEquipment}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageEquipment.maintain)}
                  href={routes.manageEquipment.maintain}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_EQUIPMENT_TECHNICAL)}
                >
                  <IonIcon icon={cog} />
                  <IonLabel>{language.tab.manageEquipment}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.report.main)}
                  href={routes.report.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT)}
                >
                  <IonIcon icon={barcode} />
                  <IonLabel>{language.tab.report}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.report.main)}
                  href={routes.report.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_SALE)}
                >
                  <IonIcon icon={barcode} />
                  <IonLabel>{language.tab.report}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.report.main)}
                  href={routes.report.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_DELIVERY)}
                >
                  <IonIcon icon={barcode} />
                  <IonLabel>{language.tab.report}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.report.main)}
                  href={routes.report.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_REPORT_CLIENT)}
                >
                  <IonIcon icon={barcode} />
                  <IonLabel>{language.tab.report}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageClient.main)}
                  href={routes.manageClient.main}
                  hidden={!isPermittedByUserType(userType, frontendConstants.VIEW_PERMISSIONS.VIEW_CLIENT_TECH)}
                >
                  <IonIcon icon={people} />
                  <IonLabel>{language.manageClient.main}</IonLabel>
                </IonTabButton>
                /* IMPORTANT HAVE TO DECLARE OTHER ROUTE HERE WITH HIDDEN */
                <IonTabButton tab={getRouteKey(routes.manageClient.main)} href={routes.manageClient.main} hidden={true}>
                  <IonLabel>{language.tab.manageUser}</IonLabel>
                </IonTabButton>
                <IonTabButton tab={getRouteKey(routes.manageUser.main)} href={routes.manageUser.main} hidden={true}>
                  <IonLabel>{language.tab.manageClient}</IonLabel>
                </IonTabButton>
                <IonTabButton tab={getRouteKey(routes.settings.theme)} href={routes.settings.theme} hidden={true}>
                  <IonLabel>{language.tab.settings}</IonLabel>
                </IonTabButton>
                <IonTabButton tab={getRouteKey(routes.settings.restore)} href={routes.settings.restore} hidden={true}>
                  <IonLabel>{language.tab.settings}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.settings.changePassword)}
                  href={routes.settings.changePassword}
                  hidden={true}
                >
                  <IonLabel>{language.tab.settings}</IonLabel>
                </IonTabButton>
                <IonTabButton
                  tab={getRouteKey(routes.manageEquipment.maintain)}
                  href={routes.manageEquipment.maintain}
                  hidden={true}
                >
                  <IonLabel>{language.manageEquipment.ui.menus.MAINTAIN}</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </IonPage>
        </IonSplitPane>
      ) : (
        <Route path={routes.resetPassword} component={ResetPassword} />
      )}
    </AppWrapper>
  );
};

const App: React.FC = (props: any) => {
  const {
    selfReducer,
    submittingReducer,
    toastReducer,
    confirmReducer,
    notificationReducer,
    setToast,
    setConfirm,
    logout,
    setNativeToken,
  } = props;
  const isLoggedIn = selfReducer && selfReducer.response && !!selfReducer.response.user;
  const socket = selfReducer && selfReducer.socket;
  const user = isLoggedIn && selfReducer.response.user;
  const userPermissions =
    isLoggedIn &&
    (selfReducer.response.user.userPermission || backendConstants.PERMISSION_GROUPS[selfReducer.response.user.userType]);
  const unreadNotification = notificationReducer.filter(
    (notification: any) => notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD
  );

  return (
    <IonApp>
      <IonReactRouter>
        <Route
          render={routerProps => (
            <AppRedirect
              {...routerProps}
              isLoggedIn={isLoggedIn}
              user={user}
              userPermissions={userPermissions}
              logout={logout}
              unreadNotification={unreadNotification}
              socket={socket}
              setNativeToken={setNativeToken}
            />
          )}
        />
        {submittingReducer && <IonLoading isOpen={true} showBackdrop={true} />}
        {toastReducer.hasToast && (
          <IonToast
            isOpen={true}
            color={toastReducer.color}
            onDidDismiss={() => setToast('')}
            message={toastReducer.hasToast}
            duration={1000}
          />
        )}
        {confirmReducer.hasAlert && (
          <IonAlert
            isOpen={true}
            onDidDismiss={() => setConfirm('')}
            message={confirmReducer.hasAlert}
            buttons={[
              {
                text: `Huỷ bỏ`,
                cssClass: `secondary`,
              },
              {
                text: `Xác nhận`,
                cssClass: `primary`,
                handler: confirmReducer.onConfirm,
              },
            ]}
          />
        )}
      </IonReactRouter>
    </IonApp>
  );
};

const mapStateToProps = (state: any) => ({
  submittingReducer: state.submittingReducer,
  toastReducer: state.toastReducer,
  selfReducer: state.selfReducer,
  confirmReducer: state.confirmReducer,
  notificationReducer: state.notificationReducer,
});

const mapDispatchToProps = {
  setToast: toastActions.setToast,
  setConfirm: confirmActions.setConfirm,
  logout: selfActions.logout,
  setNativeToken: selfActions.setNativeToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
