import React, { useEffect, useMemo, useRef, useState } from 'react';
import qs from 'querystring';
import routes from 'routes';
import backendConstants from 'backendConstants';
import moment from 'moment-timezone';
import _ from 'lodash';
import { RefresherEventDetail } from '@ionic/core';
import * as generalUtils from 'utils/generalUtils';
import { SubHeader, Wrapper } from 'commons/commons.styles';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonListHeader,
  IonPage,
  IonRadioGroup,
  IonRadio,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonLabel,
  IonItem,
  IonInput,
  IonItemGroup,
  IonItemDivider,
  IonBadge,
  IonIcon,
  IonText,
  IonFab,
  IonButton,
  IonFooter,
  IonSearchbar,
} from '@ionic/react';
import Header from 'commons/Header/Header';
import language from 'languages';
import ShowContent from 'commons/ShowContent/ShowContent';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as confirmActions from 'store/confirm';
import * as invoiceActions from 'store/invoice';
import * as userDeliveryActions from 'store/userDelivery';
import * as tankActions from 'store/tank';
import * as equipmentActions from 'store/equipment';
import * as userClientActions from 'store/userClient';
import languages from 'languages';
import { connect } from 'react-redux';
import { getInvoiceTabDate, GroupedInvoiceList } from './ManageInvoice';
import { arrowDown, arrowUp, checkmark } from 'ionicons/icons';
import { getStore } from 'utils/sessionUtils';

export const INVOICE_TAB_KEYS = {
  ADD: 'ADD',
  MONTH: 'MONTH',
  LAST_MONTH: 'LAST_MONTH',
};

const ManageInvoiceSale: React.FC<any> = props => {
  const {
    selfReducer,

    userClientReducer,
    checkUserClients,
    // resetUserClients,

    invoiceReducer,
    readInvoices,
    createInvoice,
    // updateInvoice,
    resetInvoices,

    // userDeliveryReducer,
    // checkUserDeliveries,
    // resetUserDeliveries,

    // tankReducer,
    // checkTanks,
    // updateTanks,
    // resetTanks,

    // equipmentReducer,
    // checkEquipments,
    // resetEquipments,

    setSubmitting,
    setConfirm,
    setToast,

    history,
    location,
  } = props;

  const user = selfReducer && selfReducer.response && selfReducer.response.user;

  const queryParams = qs.parse(location.search.replace('?', ''));

  const userClients = userClientReducer && userClientReducer.responseCheck && userClientReducer.responseCheck.data;

  const invoices = invoiceReducer && invoiceReducer.responseRead && invoiceReducer.responseRead.data;

  const inDayInvoices = useMemo(() => {
    return (invoices || []).filter((invoice: any) =>
      [
        backendConstants.INVOICE_STATUSES.REQUEST,
        backendConstants.INVOICE_STATUSES.APPROVED,
        backendConstants.INVOICE_STATUSES.DELIVERING,
        backendConstants.INVOICE_STATUSES.DELIVERED,
      ].includes(invoice.status) && moment(invoice.updatedAt).isAfter(moment().startOf('day'))
    );
  }, [invoices]);

  const [invoiceTab, setInvoiceTab] = useState<any>((queryParams.invoiceTab as string) || INVOICE_TAB_KEYS.MONTH);

  const [selectedInvoiceType, setSelectedInvoiceType] = useState<any>(backendConstants.INVOICE_TYPES.NORMAL);
  const [requestTankCount, setRequestTankCount] = useState<any>(null);
  const [userClientId, setUserClientId] = useState('');
  const [groupExpanded, setGroupExpanded] = useState({});
  const [clientSearch, setClientSearch] = useState<string>('');

  const resetSelectedInvoiceType = () => setSelectedInvoiceType(backendConstants.INVOICE_TYPES.NORMAL);
  const resetSetRequestTankCount = () => setRequestTankCount(null);
  const resetUserClientId = () => setUserClientId('');
  const resetGroupExpanded = () => setGroupExpanded({});

  useEffect(() => {
    if (queryParams.invoiceTab) {
      setInvoiceTab((queryParams.invoiceTab as any) || INVOICE_TAB_KEYS.MONTH);
    }
  }, [queryParams.invoiceTab]);

  const showContent = useMemo(() => {
    return location.pathname === routes.manageInvoice.main;
  }, [location.pathname]);

  const handleSearchChange = (event: any) => {
    if (event && event.target) {
      setClientSearch(event.target.value || '');
    }
  };

  const handleExpandGroup = (groupId: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupId]: !(groupExpanded as any)[groupId],
    });

  const handleRadioChange = (event: any) => {
    event && event.target && setUserClientId(event.target.value);
  };

  const handleSelectedInvoiceType = (e: any) => {
    e && setSelectedInvoiceType(e.target.value);
  };

  const handleSetRequestTankCount = (e: any) => {
    e && setRequestTankCount(e.target.value);
  };

  const [refreshing, setRefreshing] = useState(false);

  const resetCurrentInvoiceTab = () => {
    resetInvoices();
    resetSelectedInvoiceType();
    resetSetRequestTankCount();
    resetUserClientId();
    resetGroupExpanded();
    // Reset Content Scroll
    resetContentScrollTop();
  };

  const handleReadUserClient = () => {
    return checkUserClients({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
      populate: [
        {
          path: 'clientId',
          populate: {
            path: 'parentId',
          },
        },
      ],
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadInvoice = () => {
    const defaultDate = getInvoiceTabDate()[invoiceTab] || getInvoiceTabDate()[INVOICE_TAB_KEYS.MONTH];
    return (
      defaultDate &&
      readInvoices({
        page: 1,
        pageSize: 99999,
        filter: {
          store: getStore(),
          updatedAt: {
            $gte: defaultDate.toDate(),
          },
        },
        populate: [
          {
            path: 'ownerUser',
            select: '_id username name',
          },
          {
            path: 'clientUserId',
            select: '_id name clientId contactName contactNumber',
            populate: {
              path: 'clientId',
              select: '_id parentId name contactName contactNumber',
              populate: {
                path: 'parentId',
                select: '_id name',
              },
            },
          },
          {
            path: 'tanks',
            select: '_id serial type updatedAt clientUserId',
            populate: {
              path: 'clientUserId',
              select: '_id username name',
            },
          },
          {
            path: 'deliveryUserId',
            select: '_id name',
          },
        ],
        sort: {
          updatedAt: -1,
        },
      })
    );
  };

  const handleInvoiceTabChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setInvoiceTab(event.detail.value);
      history.push(`${routes.manageInvoice.main}?invoiceTab=${event.detail.value}`);
    }
  };

  useEffect(() => {
    if (showContent) {
      resetCurrentInvoiceTab();
      handleReadUserClient();
      handleReadInvoice();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceTab, showContent]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    resetCurrentInvoiceTab();
    await handleReadInvoice();
    await handleReadUserClient();
    setRefereshingDebounced(event);
  };

  const selectedUserClient = useMemo(() => {
    return (userClients || []).find((userClient: any) => userClient && userClient._id === userClientId);
  }, [userClients, userClientId]);

  const handleConfirmCreate = async () => {
    if (selectedInvoiceType && userClientId) {
      setSubmitting(true);
      try {
        await createInvoice({
          store: getStore(),
          type: selectedInvoiceType,
          status: backendConstants.INVOICE_STATUSES.REQUEST,
          ownerUser: user._id,
          clientUserId: userClientId,
          note: requestTankCount || null,
        });
        setToast(`Tạo đơn mới thành công`, 'primary');
      } catch (err) {
        setToast(`Tạo đơn mới không thành công`, 'primary');
      }
      setSubmitting(false);
      resetCurrentInvoiceTab();
      handleReadInvoice();
      handleReadUserClient();
    }
  };

  const handleConfirmAlert = _.throttle(
    () =>
      setConfirm(
        `<div>
            Xác nhận tạo đơn: ${requestTankCount ? requestTankCount + ' bình' : ''}
            <h5>${selectedUserClient.name}</h5>
        </div>`,
        handleConfirmCreate
      ),
    3000, { leading: true, trailing: false }
  );

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const filteredInvoicesByCurrentUser = useMemo(() => {
    if (!invoices || !invoices.length || !user) return [];
    return invoices.filter(
      (invoice: any) =>
        invoice &&
        invoice.clientUserId &&
        invoice.clientUserId.clientId &&
        invoice.clientUserId.clientId.parentId &&
        invoice.clientUserId.clientId.parentId._id === user._id
    );
  }, [user, invoices]);

  const invoiceGroupedByStatus = useMemo(() => {
    if (!filteredInvoicesByCurrentUser || !filteredInvoicesByCurrentUser.length) return [];
    const invoiceGroupByStatusObject = _.omit(
      _.groupBy(filteredInvoicesByCurrentUser, invoice => invoice.status),
      [backendConstants.INVOICE_STATUSES.TRASH]
    );
    return [
      backendConstants.INVOICE_STATUSES.REQUEST,
      backendConstants.INVOICE_STATUSES.APPROVED,
      backendConstants.INVOICE_STATUSES.DELIVERING,
      backendConstants.INVOICE_STATUSES.DELIVERED,
      backendConstants.INVOICE_STATUSES.CANCELLED,
    ].map((invoiceStatus: string) => ({
      status: invoiceStatus,
      invoices: (invoiceGroupByStatusObject as any)[invoiceStatus] || [],
    }));
  }, [filteredInvoicesByCurrentUser]);

  const userClientsByCurrentUser = useMemo(() => {
    if (!userClients || !userClients.length) return [];
    return userClients.filter((userClient: any) => {
      return (
        userClient &&
        userClient.clientId &&
        userClient.clientId.parentId &&
        userClient.clientId.parentId._id === user._id &&
        userClient.name.toLowerCase().includes(clientSearch.toLowerCase())
      );
    });
  }, [user, userClients, clientSearch]);

  const clientGroupsByUserClient = useMemo(() => {
    if (!userClientsByCurrentUser || !userClientsByCurrentUser.length) return [];
    return Object.values(
      _.groupBy(userClientsByCurrentUser, (userClient: any) => userClient.clientId && userClient.clientId._id)
    ).map((userClients: any) => {
      return {
        _id: userClients && userClients[0] && userClients[0].clientId && userClients[0].clientId._id,
        name: userClients && userClients[0] && userClients[0].clientId && userClients[0].clientId.name,
        status: userClients && userClients[0] && userClients[0].clientId && userClients[0].clientId.status,
        userClients,
      };
    });
  }, [userClientsByCurrentUser]);

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.manageInvoice.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleInvoiceTabChange} scrollable={true}>
              <IonSegmentButton value={INVOICE_TAB_KEYS.ADD} checked={invoiceTab === INVOICE_TAB_KEYS.ADD}>
                {language.manageInvoice.ui.tabs.ADD}
              </IonSegmentButton>
              <IonSegmentButton value={INVOICE_TAB_KEYS.MONTH} checked={invoiceTab === INVOICE_TAB_KEYS.MONTH}>
                {language.manageInvoice.ui.tabs.MONTH}
              </IonSegmentButton>
              <IonSegmentButton value={INVOICE_TAB_KEYS.LAST_MONTH} checked={invoiceTab === INVOICE_TAB_KEYS.LAST_MONTH}>
                {language.manageInvoice.ui.tabs.LAST_MONTH}
              </IonSegmentButton>
            </IonSegment>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent} timeout={1000}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {invoiceReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    {[INVOICE_TAB_KEYS.LAST_MONTH, INVOICE_TAB_KEYS.MONTH].includes(invoiceTab) && (
                      <IonList className={`ion-margin-vertical`}>
                        {invoices && !!invoices.length && (
                          <IonListHeader>Kết quả: {filteredInvoicesByCurrentUser.length} đơn hàng</IonListHeader>
                        )}
                        <GroupedInvoiceList
                          user={user}
                          groupedInvoices={invoiceGroupedByStatus}
                          invoiceTab={invoiceTab}
                          noInteraction={true}
                        />
                      </IonList>
                    )}
                    {invoiceTab === INVOICE_TAB_KEYS.ADD && (
                      <IonList>
                        <IonListHeader>
                          <IonLabel>Chọn hình thức</IonLabel>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleSelectedInvoiceType}>
                          {[backendConstants.INVOICE_TYPES.NORMAL, backendConstants.INVOICE_TYPES.DEMO].map(type => (
                            <IonItem key={type}>
                              <IonLabel>{(languages.manageInvoice.ui.types as any)[type]}</IonLabel>
                              <IonRadio mode={`md`} slot={`start`} value={type} checked={type === selectedInvoiceType} />
                            </IonItem>
                          ))}
                        </IonRadioGroup>
                        <IonListHeader className={`ion-padding-top`}>
                          <IonLabel>Ghi chú / Số lượng bình:</IonLabel>
                        </IonListHeader>
                        <IonItem>
                          <IonInput type={`text`} value={requestTankCount} onIonChange={handleSetRequestTankCount} />
                        </IonItem>
                        <div>
                          <IonSearchbar
                            placeholder={`Tìm tên TK khách hàng ...`}
                            value={clientSearch}
                            onIonChange={handleSearchChange}
                            debounce={250}
                          />
                        </div>
                        <IonListHeader className={`ion-padding-top`}>
                          <IonLabel>Chọn khách hàng:</IonLabel>
                        </IonListHeader>
                        <IonRadioGroup onIonChange={handleRadioChange}>
                          {clientGroupsByUserClient.map(
                            (group: any) =>
                              group.status === backendConstants.CLIENT_STATUSES.ACTIVE && (
                                <IonItemGroup key={group._id}>
                                  <IonItemDivider onClick={handleExpandGroup(group._id)}>
                                    <IonLabel>{group.name}</IonLabel>
                                    <IonBadge color={`medium`} slot={`end`}>
                                      {group.userClients.length}
                                    </IonBadge>
                                    <IonIcon icon={(groupExpanded as any)[group._id] ? arrowUp : arrowDown} slot={`end`} />
                                  </IonItemDivider>
                                  {(groupExpanded as any)[group._id] &&
                                    group.userClients.map((userClient: any) => {
                                      const foundInvoice = !!inDayInvoices.find(
                                        (invoice: any) => invoice.clientUserId._id === userClient._id
                                      );
                                      return (
                                        <IonItem key={userClient._id}>
                                          {foundInvoice && <IonIcon color={`primary`} icon={checkmark} slot={`end`} />}
                                          <IonLabel>
                                            <IonText color={foundInvoice ? `danger` : undefined}>{userClient.name}</IonText>
                                          </IonLabel>
                                          <IonRadio
                                            mode={`md`}
                                            slot={`start`}
                                            value={userClient._id}
                                            checked={userClientId === userClient._id}
                                            disabled={
                                              !userClient.equipments ||
                                              !userClient.equipments.length ||
                                              userClient.status === backendConstants.USER_STATUSES.DISABLED
                                            }
                                          />
                                        </IonItem>
                                      );
                                    })}
                                </IonItemGroup>
                              )
                          )}
                        </IonRadioGroup>
                      </IonList>
                    )}
                  </div>
                )}
              </div>
            </IonGrid>
          </IonContent>
        </ShowContent>
        <IonFooter>
          {invoiceTab === INVOICE_TAB_KEYS.ADD && (
            <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
              <IonButton color={`primary`} onClick={handleConfirmAlert} disabled={!selectedInvoiceType || !userClientId}>
                <IonLabel>Gọi khí</IonLabel>
              </IonButton>
            </IonFab>
          )}
        </IonFooter>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  selfReducer: state.selfReducer,
  invoiceReducer: state.invoiceReducer,
  tankReducer: state.tankReducer,
  userDeliveryReducer: state.userDeliveryReducer,
  equipmentReducer: state.equipmentReducer,
  userClientReducer: state.userClientReducer,
});

const mapDispatchToProps = {
  setSubmitting: submittingActions.setSubmitting,
  setConfirm: confirmActions.setConfirm,
  setToast: toastActions.setToast,

  checkUserClients: userClientActions.checkUserClients,
  resetUserClients: userClientActions.resetUserClients,

  readInvoices: invoiceActions.readInvoices,
  resetInvoices: invoiceActions.resetInvoices,
  updateInvoice: invoiceActions.updateInvoice,
  createInvoice: invoiceActions.createInvoice,

  checkUserDeliveries: userDeliveryActions.checkUserDeliveries,
  resetUserDeliveries: userDeliveryActions.resetUserDeliveries,

  checkTanks: tankActions.checkTanks,
  updateTanks: tankActions.updateTanks,
  resetTanks: tankActions.resetTanks,

  checkEquipments: equipmentActions.checkEquipments,
  resetEquipments: equipmentActions.resetEquipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvoiceSale);
