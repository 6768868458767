import React from 'react';
import { PlaceHolderWrapper } from 'commons/commons.styles';
import { IonBadge, IonButton, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import language from 'languages';
import { TEST_SERIAL } from '../ManageEquipment';
import { getStore } from 'utils/sessionUtils';
import backendConstants from 'backendConstants';

export const NoEquipmentPlaceholder: React.FC<any> = props => {
  const { equipmentStatus, equipmentSearch, onCreateEquipment } = props;

  const handleCreateEquipment = () => {
    onCreateEquipment && onCreateEquipment(equipmentSearch);
  };

  return (
    <PlaceHolderWrapper>
      <IonGrid>
        <IonRow className={`ion-padding ion-justify-content-center`}>
          <IonItem disabled>{language.manageEquipment.ui.noEquipment}</IonItem>
        </IonRow>
        {equipmentStatus === TEST_SERIAL && (
          <React.Fragment>
            {equipmentSearch &&
              equipmentSearch.length >= 10 &&
              equipmentSearch.substring(0, 4).toUpperCase() === 'PLT-' &&
              !equipmentSearch.includes(' ') &&
              getStore() === backendConstants.CENTER_STORE && (
                <IonRow className={`ion-padding ion-justify-content-center`}>
                  <IonButton onClick={handleCreateEquipment}>
                    <IonLabel>Tạo máy mới: </IonLabel>
                    <IonBadge color={`light`}>{equipmentSearch.toUpperCase()}</IonBadge>
                  </IonButton>
                </IonRow>
              )}
            <IonRow className={`ion-padding ion-justify-content-center`}>
              <IonItem disabled>
                <p className={`ion-text-center ion-padding-bottom`}>
                  Lưu ý: Nhập ít nhất 5 kí tự và chỉ hiển thị 20 kết quả đầu tiên
                </p>
              </IonItem>
            </IonRow>
          </React.Fragment>
        )}
      </IonGrid>
    </PlaceHolderWrapper>
  );
};
