import React, { useMemo } from 'react';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
  IonList,
  IonListHeader,
} from '@ionic/react';
import { FormWrapper } from 'commons/commons.styles';
import { NormalField } from 'commons/Form/NormalField';
import { OptionField } from 'commons/Form/OptionField';
import { addUserSchema, editUserSchema } from './AddEditUserForm.schema';
import backendConstants from 'backendConstants';
import languages from 'languages';
import { USER_STATUS_OPTIONS } from './AddEditUserClientForm';
import vnConstants from 'vnConstants';

export const AddEditUserForm: React.FC<any> = props => {
  const { selectedUser, submittingReducer, onConfirm, onFormUpdate } = props;

  const addEditUserInitialValues = useMemo(() => {
    return selectedUser
      ? {
          username: selectedUser.username,
          email: selectedUser.email,
          contactNumber: selectedUser.contactNumber,
          name: selectedUser.name,
          note: selectedUser.note,
          userType: selectedUser.userType,
          status: selectedUser.status,
        }
      : {};
  }, [selectedUser]);

  const handleSubmit = (values: any) => {
    onConfirm && onConfirm(values);
  };

  const canActiveDisable = useMemo(() => {
    if (selectedUser) {
      if (selectedUser.userType !== backendConstants.USER_TYPES.SALE) {
        return true;
      } else {
        return !selectedUser.clients || selectedUser.clients.length === 0;
      }
    }
    return true;
  }, [selectedUser]);

  return (
    <FormWrapper>
      <Formik
        initialValues={addEditUserInitialValues}
        validationSchema={selectedUser ? editUserSchema : addUserSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {(formikProps: FormikProps<FormikValues>) => {
          onFormUpdate && onFormUpdate(formikProps);
          return !submittingReducer ? (
            <Form className={`ion-text-center`} autoComplete={`off`}>
              <Field type={`submit`} className={`ion-hide`} />
              <NormalField
                fieldName={`username`}
                title={`Tên đăng nhập/Username`}
                type={`text`}
                showErrorWhenAvailable={true}
                debounce={300}
                disabled={!!selectedUser}
              />
              <NormalField fieldName={`password`} title={`Mật khẩu`} type={`password`} />
              <OptionField
                fieldName={`userType`}
                title={`Loại tài khoản`}
                type={`text`}
                options={
                  selectedUser
                    ? [
                        {
                          key: selectedUser.userType,
                          label: languages.manageUser.ui.userTypes[selectedUser.userType],
                        },
                      ]
                    : [
                        {
                          key: backendConstants.USER_TYPES.SALE,
                          label: languages.manageUser.ui.userTypes[backendConstants.USER_TYPES.SALE],
                        },
                        {
                          key: backendConstants.USER_TYPES.DELIVERY,
                          label: languages.manageUser.ui.userTypes[backendConstants.USER_TYPES.DELIVERY],
                        },
                        {
                          key: backendConstants.USER_TYPES.TECHNICAL,
                          label: languages.manageUser.ui.userTypes[backendConstants.USER_TYPES.TECHNICAL],
                        },
                      ]
                }
              />
              <OptionField
                fieldName={`status`}
                title={`Trạng thái`}
                type={`text`}
                options={USER_STATUS_OPTIONS}
                disabled={!canActiveDisable}
              />
              {!canActiveDisable && (
                <p>
                  <IonText color={`danger`}>Tài khoản kinh doanh còn khách hàng</IonText>
                </p>
              )}
              <NormalField fieldName={`name`} title={`Họ tên`} type={`text`} />
              <NormalField
                fieldName={`email`}
                title={`Địa chỉ email`}
                type={`text`}
                showErrorWhenAvailable={true}
                debounce={300}
              />
              <NormalField fieldName={`contactNumber`} title={`Số điện thoại`} type={`text`} />
              {selectedUser && selectedUser.userType === backendConstants.USER_TYPES.SALE && (
                <IonList className={`ion-padding-vertical`}>
                  <IonListHeader>Danh sách khách hàng: {selectedUser.clients.length}</IonListHeader>
                  {selectedUser.clients.map((client: any) => (
                    <IonItem key={client._id}>
                      <IonLabel>
                        <p>
                          <IonText color={`primary`}>{client.name}</IonText>
                        </p>
                        {client.status === backendConstants.CLIENT_STATUSES.DISABLED && (
                          <p>
                            <IonText color={`danger`}>Không hoạt động</IonText>
                          </p>
                        )}
                        <p>{(vnConstants as any)[client.province] && (vnConstants as any)[client.province].name}</p>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              )}
            </Form>
          ) : (
            <IonGrid>
              <IonRow className={`ion-align-items-center`}>
                <IonCol className={`ion-padding-vertical ion-text-center`}>
                  <IonSpinner className={`big-spinner`} />
                </IonCol>
              </IonRow>
            </IonGrid>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

export default AddEditUserForm;
