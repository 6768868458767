import { SyntheticEvent } from 'react';

export const getTabRoute = (path: string) => {
  return `/:tab(${path.charAt(0) === '/' ? path.substr(1) : path})`;
};

export const getRouteKey = (path: string) => {
  return `${path.charAt(0) === '/' ? path.substr(1) : path}`;
};

export const navigateByHistory = (history: any, path: string) => (e: SyntheticEvent) => {
  e && e.preventDefault();
  history.push && history.push(path);
};
