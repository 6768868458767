import React from 'react';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import { RowItemTitle, RowItemMainP } from '../Report.styles';
import languages from 'languages';
import { SORT_TYPES } from '../Report';

export const ReportEquipmentItem: React.FC<any> = props => {
  const { equipmentGroups, onSelectedEquipmentGroup } = props;

  const handleSelectEquipment = (group: any) => () => onSelectedEquipmentGroup && onSelectedEquipmentGroup(group);

  return equipmentGroups
    ? equipmentGroups.map((group: any) => (
        <IonItem key={group.key} button={!!group.total} onClick={group.total ? handleSelectEquipment(group) : undefined}>
          <IonLabel>
            <RowItemTitle noLine className={`ion-padding-bottom`}>
              <IonText color={`primary`}>{group.name}: </IonText>
              <IonText color={`danger`}>{group.total}</IonText> máy
            </RowItemTitle>
            <RowItemMainP>
              {Object.keys(group.byType)
                .sort((a: any, b: any) => (SORT_TYPES as any)[a] - (SORT_TYPES as any)[b])
                .map((type: any) => (
                  <span key={type}>
                    {languages.report.equipmentTypes[type]}: <IonText color={`primary`}>{group.byType[type].length}</IonText>{' '}
                  </span>
                ))}
            </RowItemMainP>
          </IonLabel>
        </IonItem>
      ))
    : null;
};

export default ReportEquipmentItem;
