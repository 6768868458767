import React from 'react';
import PropTypes from 'prop-types';
import { IonCheckbox, IonItem, IonLabel, IonText, IonIcon } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import { Field } from 'formik';
import { CheckWrapper } from './styles';

export const MONO_OPTIONS = {
  YES: 'YES',
  NO: 'NO',
};

export const CheckField: React.FC<any> = props => {
  const { fieldName, title, showErrorWhenAvailable, color, disabled } = props;

  const handleChange = (fieldProps: any) => (event: any) => {
    event.target.value = fieldProps.field.value === MONO_OPTIONS.YES ? MONO_OPTIONS.NO : MONO_OPTIONS.YES;
    fieldProps.field.onChange(event);
  };

  return (
    <Field name={fieldName}>
      {(fieldProps: any) => {
        return (
          <CheckWrapper>
            <IonItem button={true} onClick={handleChange(fieldProps)}>
              <IonLabel>
                {title} <IonText color={`danger`}>*</IonText>
              </IonLabel>
              <IonCheckbox
                name={fieldName}
                mode={`ios`}
                slot={`end`}
                color={color}
                checked={fieldProps.field.value === MONO_OPTIONS.YES}
                disabled={disabled}
              />
              {fieldProps.meta.touched && !fieldProps.meta.error && (
                <IonIcon color={`primary`} icon={checkmark} slot={`start`} />
              )}
            </IonItem>
            {fieldProps.form.dirty && (fieldProps.meta.touched || showErrorWhenAvailable) && fieldProps.meta.error && (
              <IonItem lines={`none`}>
                <IonText color={`danger`}>{fieldProps.meta.error}</IonText>
              </IonItem>
            )}
          </CheckWrapper>
        );
      }}
    </Field>
  );
};

CheckField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CheckField;
