import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { setupConfig } from '@ionic/core';
import moment from 'moment-timezone';
import axios from 'axios';

/* Socket IO */
// import io from 'socket.io-client';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme */
import './theme.scss';
/* Index */
import './fonts.css';
import './index.css';
/* Redux */
import { Provider } from 'react-redux';
import store from 'store';
/* Utils */
import { setupCallbackOnAppState } from './utils/generalUtils';
import { setupSession } from './utils/sessionUtils';
/* Constants */
import { API_PATH } from 'frontendConstants';

/* Capacitor */

/* Nav Animation */
import { NavAnimationIOS } from './animations';

/* StatusBar */
import {
  setupStatusBar,
  getUIMode,
  setupNavigationBar,
  getBackendVersion,
  getFrontendVersion,
  appendVersionElement
} from './utils/uiUtils';
import { checkAuth } from './store/self';

setupStatusBar();
setupNavigationBar();

const localization = require('moment/locale/vi');
moment.updateLocale('vi', localization);

setupConfig({
  mode: getUIMode(),
  ...(getUIMode() === `ios`
    ? {
        navAnimation: NavAnimationIOS,
      }
    : {}),
});

// Send timezone of client to server
axios.defaults.headers = {
  tz: moment.tz.guess(),
};

axios.defaults.withCredentials = true;

// For serving local with port 20901, all request will do to port 20899[DEV]/20900[PROD]
// const port = window.location.origin.split(':')[2];
// axios.defaults.baseURL = window.location.origin.replace(
//   port,
//   '20899'
// );
console.log('BASE_PATH: ', API_PATH, process.env);
axios.defaults.baseURL = window.location.origin + API_PATH;

// axios.interceptors.response.use(
//   function(response) {
//     if (response.status === 401) {
//       window.history.pushState({ urlPath: '/login' }, ``);
//     }
//     return response;
//   },
//   function(error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

setupSession().then(async () => {
  const darkMode = localStorage.getItem('pltTheme');
  document.body.setAttribute('class', darkMode === `dark` ? `dark` : ``);

  const backendVersion = await getBackendVersion();
  const frontendVersion = await getFrontendVersion();

  // Render
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
    async () => {
      appendVersionElement('backend-version', backendVersion);
      appendVersionElement('frontend-version', frontendVersion);

      setupCallbackOnAppState(
        async () => {
          await checkAuth()(store.dispatch);
        },
        () => {}
      );
    }
  );
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
