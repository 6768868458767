import backendConstants from 'backendConstants';

export default {
  back: 'Trở lại',
  home: 'Tổng quan',
  stats: 'Thống kê',
  login: 'Đăng nhập',
  resetPassword: 'Phục hôi mật khẩu',
  notification: 'Thông báo',
  menu: 'Chức năng chính',
  tab: {
    manageTank: 'Vỏ/khí',
    manageEquipment: 'Máy',
    manageUser: 'Tài khoản',
    manageClient: 'Khách hàng',
    manageInvoice: 'Đơn hàng',
    report: 'Báo cáo',
    settings: 'Cài đặt/Thiết lập',
  },
  settings: {
    main: 'Cài đặt/thiết lập',
    theme: 'Thay đổi giao diện',
    restore: 'Khôi phục dữ liệu',
    changePassword: 'Thay đổi mật khẩu',
  },
  manageTank: {
    main: 'Quản lí vỏ/khí',
    create: 'Tạo vỏ',
    edit: {
      select: 'Chọn chức năng',
      type: 'Thay đổi loại vỏ',
      getReady: 'Xác nhận vỏ mới',
      getLoading: 'Nhập khí',
      getLoaded: 'Xuất khí',
      getBack: 'Thu hồi vỏ',
    },
    disable: 'Xác nhận vỏ thất thoát',
    ui: {
      all: 'Tất cả',
      lost: 'Bình có khả năng thất thoát',
      TANK_8L: '8 lít',
      TANK_4L: '4 lít',
      TANK_3LC: '3 lít carbon',
      noTank: 'Danh sách rỗng',
      types: {
        TANK_8L: '8 lít',
        TANK_4L: '4 lít',
        TANK_3LC: '3 lít carbon',
      },
      collectionStatuses: {
        [backendConstants.TANK_STATUSES.EMPTY]: 'Thu hồi vỏ',
        [backendConstants.TANK_STATUSES.READY_TO_USE]: 'Thu hồi bình',
      },
      menus: {
        [backendConstants.TANK_STATUSES.EMPTY]: 'Vỏ tồn',
        [backendConstants.TANK_STATUSES.LOADING]: 'Kho nạp',
        [backendConstants.TANK_STATUSES.READY_TO_USE]: 'Bình tồn kho',
        [backendConstants.TANK_STATUSES.AT_CLIENT]: 'Tồn khách hàng',
        TEST_SERIAL: 'Kiểm tra mã',
      },
      tabs: {
        [backendConstants.TANK_STATUSES.EMPTY]: 'Vỏ',
        [backendConstants.TANK_STATUSES.LOADING]: 'Kho nạp',
        [backendConstants.TANK_STATUSES.READY_TO_USE]: 'Tồn kho',
        [backendConstants.TANK_STATUSES.AT_CLIENT]: 'Tồn KH',
        TEST_SERIAL: 'KT mã',
      },
      statuses: {
        [backendConstants.TANK_STATUSES.EMPTY]: 'Vỏ',
        [backendConstants.TANK_STATUSES.LOADING]: 'Kho nạp',
        [backendConstants.TANK_STATUSES.READY_TO_USE]: 'Tồn kho',
        [backendConstants.TANK_STATUSES.LOCKED]: 'Đơn hàng',
        [backendConstants.TANK_STATUSES.AT_CLIENT]: 'Tồn khách hàng',
        [backendConstants.TANK_STATUSES.DISABLED]: 'Thất thoát',
        [backendConstants.TANK_STATUSES.TRASH]: 'Xoá hoàn toàn',
      },
      actions: {
        CREATE: 'Tạo vỏ mới',
        SET_DISABLED: 'Thất thoát',
        [backendConstants.TANK_STATUSES.EMPTY]: 'Chuyển nạp',
        [backendConstants.TANK_STATUSES.LOADING]: 'Nạp xong',
        [backendConstants.TANK_STATUSES.READY_TO_USE]: 'Xuất khí',
        [backendConstants.TANK_STATUSES.AT_CLIENT]: 'Thu hồi',
        [backendConstants.TANK_STATUSES.DISABLED]: 'Khôi phục vỏ',
      },
      modalActions: {
        CREATE: 'Xác nhận vỏ mới',
        SET_DISABLED: 'Xác nhận thất thoát',
        [backendConstants.TANK_STATUSES.EMPTY]: 'Xác nhận chuyển nạp',
        [backendConstants.TANK_STATUSES.LOADING]: 'Xác nhận nạp xong',
        [backendConstants.TANK_STATUSES.READY_TO_USE]: 'Xác nhận xuất khí',
        [backendConstants.TANK_STATUSES.AT_CLIENT]: 'Xác nhận thu hồi',
        [backendConstants.TANK_STATUSES.DISABLED]: 'Xác nhận khôi phục vỏ',
      },
    },
  },
  manageEquipment: {
    main: 'Quản lí máy',
    create: 'Tạo máy',
    edit: {
      select: 'Chọn chức năng',
      info: 'Thay đổi thông tin máy',
      getReady: 'Xác nhận máy trong kho',
      getSold: 'Xác nhận máy đã bán',
      getLent: 'Xác nhận máy cho mượn',
      getRented: 'Xác nhận máy cho thuê',
      getMaintained: 'Phân công bảo dưỡng máy',
    },
    disable: 'Xác nhận máy hỏng',
    ui: {
      noEquipment: 'Không có máy',
      maintainOption: {
        YES: 'Bảo dưỡng',
        NO: 'Không bảo dưỡng',
      },
      warrantyOption: {
        YES: 'Có bảo hành',
        NO: 'Không bảo hành',
      },
      warrantyStatuses: {
        WARRANTY_VALID: 'Còn bảo hành',
        WARRANTY_EXPIRE: 'Hết bảo hành',
        NO_WARRANTY: 'Không bảo hành',
      },
      actions: {
        CREATE: 'Tạo máy mới',
        RECOVERED: 'Phục hồi máy',

        CHECKING_FIXING: 'Kiểm tra',
        CHECKED_READY_TO_USE: 'Kiểm tra xong',
        FIXED_READY_TO_USE: 'Sửa xong',

        SOLD_RENTED_LENT: 'Xuất máy',
        EDIT_SOLD_RENTED_LENT: 'Chỉnh sửa',

        DISABLED: 'Huỷ máy',
        CHECKING: 'Thu hồi',
        FIXING: 'Máy lỗi',
        MAINTAIN: 'Bảo dưỡng',

        [backendConstants.EQUIPMENT_STATUSES.SOLD]: 'Bán máy',
        [backendConstants.EQUIPMENT_STATUSES.RENTED]: 'Cho thuê máy',
        [backendConstants.EQUIPMENT_STATUSES.LENT]: 'Cho mượn máy',
      },
      doneActions: {
        CREATE: 'Tạo máy mới thành công',
        RECOVERED: 'Đã phục hồi máy thành công',
        FIXED_READY_TO_USE: 'Xác nhận sửa máy thành công',
        CHECKED_READY_TO_USE: 'Xác nhận kiểm tra máy thành công',
        SOLD_RENTED_LENT: 'Xuất máy thành công',
        EDIT_SOLD_RENTED_LENT: 'Đã sửa TT xuất máy thành công',
        CHECKING: 'Máy đã được đưa về kiểm tra',
        FIXING: 'Máy đã được đưa về sửa',
        MAINTAIN: 'Máy đã bảo dưỡng thành công',
        DISABLED: 'Máy đã huỷ thành công',
      },
      failActions: {
        CREATE: 'Tạo máy mới không thành công',
        RECOVERED: 'Phục hồi máy không thành công',
        FIXED_READY_TO_USE: 'Xác nhận sửa máy không thành công',
        CHECKED_READY_TO_USE: 'Xác nhận kiểm tra máy không thành công',
        SOLD_RENTED_LENT: 'Xuất máy không thành công',
        EDIT_SOLD_RENTED_LENT: 'Sửa TT xuất máy không thành công',
        CHECKING: 'Máy đưa về kiểm tra không thành công',
        FIXING: 'Máy đưa về sửa không thành công',
        MAINTAIN: 'Bảo dưỡng máy không thành công',
        DISABLED: 'Huỷ máy không thành công',
      },
      menus: {
        TEST_SERIAL: 'Kiểm tra mã',
        MAINTAIN: 'Bảo dưỡng máy',
        [backendConstants.EQUIPMENT_STATUSES.READY_TO_USE]: 'Tồn kho',
        SOLD_RENTED_LENT: 'Tồn khách hàng',
        WARRANTY: 'Máy bảo hành',
        DISABLED: 'Máy hủy',
      },
      tabs: {
        TEST_SERIAL: 'KT Mã',
        CHECKING_FIXING: 'Kiểm tra',
        [backendConstants.EQUIPMENT_STATUSES.READY_TO_USE]: 'Tồn kho',
        SOLD_RENTED_LENT: 'Tồn KH',
        THIS_MONTH: 'Tháng này',
        NEXT_MONTH: 'Tháng sau',
        WARRANTY: 'Máy BH',
        DISABLED: 'Máy hủy',
      },
      types: {
        [backendConstants.EQUIPMENT_TYPES.HOSPITAL]: 'Bệnh viện',
        [backendConstants.EQUIPMENT_TYPES.CLINIC_BIG]: 'Thẩm mĩ lớn',
        [backendConstants.EQUIPMENT_TYPES.CLINIC_MEDIUM]: 'Thẩm mĩ nhỏ',
        [backendConstants.EQUIPMENT_TYPES.PLASMA_CARE]: 'PlasmaCARE',
        [backendConstants.EQUIPMENT_TYPES.OTHER]: 'Khác',
      },
      statuses: {
        [backendConstants.EQUIPMENT_STATUSES.CHECKING]: 'Đang kiểm tra',
        [backendConstants.EQUIPMENT_STATUSES.FIXING]: 'Đang sửa',
        [backendConstants.EQUIPMENT_STATUSES.READY_TO_USE]: 'Tồn kho',
        [backendConstants.EQUIPMENT_STATUSES.SOLD]: 'Máy bán',
        [backendConstants.EQUIPMENT_STATUSES.RENTED]: 'Máy cho thuê',
        [backendConstants.EQUIPMENT_STATUSES.LENT]: 'Máy cho mượn',
        [backendConstants.EQUIPMENT_STATUSES.DISABLED]: 'Máy hỏng',
      },
    },
  },
  manageUser: {
    main: 'Quản lí tài khoản',
    create: 'Tạo tài khoản',
    createClientAccount: 'Tạo tài khoản khách hàng',
    edit: 'Thay đổi thông tin tài khoản',
    disable: 'Vô hiệu hoá tài khoản',
    ui: {
      menus: {
        ADD: 'Thêm tài khoản',
        LIST: 'Danh sách tài khoản',
        USER_CLIENT: 'Khoa/TK Khách hàng',
      },
      tabs: {
        ADD: 'Thêm TK',
        LIST: 'Danh sách TK',
        USER_CLIENT: 'Khoa/TK Khách hàng',
      },
      userTypes: {
        [backendConstants.USER_TYPES.CLIENT]: 'Khách hàng',
        [backendConstants.USER_TYPES.DELIVERY]: 'Giao vận',
        [backendConstants.USER_TYPES.SALE]: 'Kinh doanh',
        [backendConstants.USER_TYPES.TECHNICAL]: 'Kĩ thuật',
        [backendConstants.USER_TYPES.STORE]: 'Kho',
        [backendConstants.USER_TYPES.ADMIN]: 'Quản trị',
      },
    },
  },
  manageClient: {
    main: 'Quản lí khách hàng',
    create: 'Thêm khách hàng',
    edit: 'Thay đổi thông tin khách hàng',
    disable: 'Vô hiệu hoá khách hàng',
    ui: {
      noClient: 'Danh sách rỗng',
      sectors: {
        [backendConstants.SECTORS.GOVERNMENT]: 'Bệnh viện công',
        [backendConstants.SECTORS.PRIVATE]: 'Bệnh viện tư',
      },
      menus: {
        ADD: 'Thêm khách hàng',
        LIST: 'Danh sách khách hàng',
        DEMO: 'Xuất Demo',
        REPORT: 'Báo cáo khách hàng',
      },
      tabs: {
        ADD: 'Thêm KH',
        LIST: 'Danh sách KH',
        DEMO: 'Xuất Demo',
        REPORT: 'Báo cáo KH',
      },
      reportOptions: {
        PROVINCE: 'Theo tỉnh',
        SECTOR: 'Theo công tư',
      },
      types: {
        [backendConstants.CLIENT_TYPES.HOSPITAL]: 'Bệnh viện',
        [backendConstants.CLIENT_TYPES.SPA]: 'Thẩm mĩ viện',
        [backendConstants.CLIENT_TYPES.CLINIC]: 'Phòng khám',
        [backendConstants.CLIENT_TYPES.OTHER]: 'Khác',
      },
      chargeType: {
        [backendConstants.CLIENT_CHARGE_TYPES.VAT]: 'Xuất bán',
        [backendConstants.CLIENT_CHARGE_TYPES.NO_VAT]: 'Hàng bán trả lại',
      },
    },
  },
  manageInvoice: {
    main: 'Quản lí đơn hàng',
    create: 'Tạo đơn hàng',
    edit: {
      select: 'Chọn chức năng',
      info: 'Thay đổi thông tin đơn hàng',
      getApproved: 'Xác nhận đơn hàng',
      getDelivered: 'Phân công giao vận',
      getCancelled: 'Huỷ đơn hàng',
    },
    ui: {
      noInvoice: 'Danh sách rỗng',
      menus: {
        DAY: 'Hôm nay',
        WEEK: 'Trong tuần',
        MONTH: 'Trong tháng',
        LAST_MONTH: 'Tháng trước',
        ADD: 'Gọi khí',
      },
      tabs: {
        DAY: 'Hôm nay',
        WEEK: 'Trong tuần',
        MONTH: 'Trong tháng',
        LAST_MONTH: 'Tháng trước',
        ADD: 'Gọi khí',
        NOW: 'Đơn hàng hiện tại',
        HISTORY: 'Lịch sử đơn hàng',
      },
      types: {
        [backendConstants.INVOICE_TYPES.NORMAL]: 'Xuất bán',
        [backendConstants.INVOICE_TYPES.DEMO]: 'Xuất demo',
      },
      statuses: {
        [backendConstants.INVOICE_STATUSES.REQUEST]: 'Đơn hàng mới',
        [backendConstants.INVOICE_STATUSES.APPROVED]: 'Chờ giao',
        [backendConstants.INVOICE_STATUSES.DELIVERING]: 'Đang giao',
        [backendConstants.INVOICE_STATUSES.DELIVERED]: 'Đã hoàn thành',
        [backendConstants.INVOICE_STATUSES.CANCELLED]: 'Đơn hàng đã huỷ',
        [backendConstants.INVOICE_STATUSES.TRASH]: 'Xoá hoàn toàn',
      },
      deliveryOptions: {
        [backendConstants.DELIVERY_OPTIONS.DIRECT]: 'Giao tận nơi',
        [backendConstants.DELIVERY_OPTIONS.EXPRESS]: 'Chuyển phát nhanh',
        [backendConstants.DELIVERY_OPTIONS.PICKUP]: 'Chuyển nhà xe',
      },
      actions: {
        [backendConstants.INVOICE_STATUSES.REQUEST]: 'Tiếp nhận đơn',
        [backendConstants.INVOICE_STATUSES.APPROVED]: 'Chuyển giao vận',
        [backendConstants.INVOICE_STATUSES.DELIVERING]: 'Hoàn thành',
        [backendConstants.INVOICE_STATUSES.DELIVERED]: 'Đã giao',
        DISABLE: 'Huỷ đơn',
      },
      modalActions: {
        [backendConstants.INVOICE_STATUSES.REQUEST]: 'Xuất khí',
        [backendConstants.INVOICE_STATUSES.APPROVED]: 'Chuyển giao vận',
        [backendConstants.INVOICE_STATUSES.DELIVERING]: 'Hoàn thành',
        DISABLE: 'Huỷ đơn',
      },
      modalActionsDelivery: {
        [backendConstants.INVOICE_STATUSES.REQUEST]: 'Xuất khí',
        [backendConstants.INVOICE_STATUSES.APPROVED]: 'Nhận đơn',
        [backendConstants.INVOICE_STATUSES.DELIVERING]: 'Hoàn thành',
        DISABLE: 'Huỷ đơn',
      },
      notePlaceholder: {
        [backendConstants.DELIVERY_OPTIONS.DIRECT]: 'Nhập thông tin người nhận bình',
        [backendConstants.DELIVERY_OPTIONS.PICKUP]: 'Nhập thông tin nhà xe, gồm số điện thoại và biển số xe',
        [backendConstants.DELIVERY_OPTIONS.EXPRESS]: 'Nhập thông tin vận đơn và đơn vị gửi',
      },
    },
  },
  report: {
    main: 'Báo cáo số liệu',
    byDelivery: 'Theo giao vận',
    bySale: 'Theo kinh doanh',
    byClient: 'Theo khách hàng',
    byEquipment: 'Theo máy',
    tabs: {
      CLIENT: 'Khách hàng',
      SALE: 'Kinh doanh',
      DELIVERY: 'Giao vận',
      EQUIPMENT: 'BC Máy',
      // TO_STORE: 'Vỏ trả',
      // TO_CLIENT: 'Bình sử dụng',
    },
    equipmentTypes: {
      [backendConstants.EQUIPMENT_TYPES.HOSPITAL]: 'BV',
      [backendConstants.EQUIPMENT_TYPES.CLINIC_BIG]: 'TM lớn',
      [backendConstants.EQUIPMENT_TYPES.CLINIC_MEDIUM]: 'TM nhỏ',
      [backendConstants.EQUIPMENT_TYPES.PLASMA_CARE]: 'PC',
      [backendConstants.EQUIPMENT_TYPES.OTHER]: 'Khác',
    },
  },
  storeTransfer: {
    main: 'Luân chuyển kho',
    transferTank: 'Luân chuyển vỏ/bình',
    transferEquipment: 'Luân chuyển máy',
    ui: {
      tabs: {
        TANK: 'Chuyển vỏ/bình',
        EQUIPMENT: 'Chuyển máy',
      },
    },
  },
};
