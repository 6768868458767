import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/log/client/overview`;
export const OVERVIEW_CLIENT_CRUD_ACTIONS = generateCrudActionTypes(`OVERVIEW_CLIENT_CRUD`);

export const { check: checkOverviewClient, resetState: resetOverviewClient } = generateThunkActions(
  OVERVIEW_CLIENT_CRUD_ACTIONS,
  ENDPOINT
);

export const overviewClientReducer = generateCrudReducer(OVERVIEW_CLIENT_CRUD_ACTIONS);
