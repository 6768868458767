import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/tank`;
export const TANK_CRUD_ACTIONS = generateCrudActionTypes(`TANK_CRUD`);

export const {
  check: checkTanks,
  read: readTanks,
  create: createTank,
  update: updateTank,
  updateBatch: updateTanks,
  resetState: resetTanks,
} = generateThunkActions(TANK_CRUD_ACTIONS, ENDPOINT);

export const tankReducer = generateCrudReducer(TANK_CRUD_ACTIONS);
