import styled, { css } from 'styled-components';

export const TankTestItem: any = styled.div`
  ion-label {
    ion-text,
    p {
      ${(props: any) =>
        props.danger
          ? css`
              color: var(--ion-color-danger);
            `
          : ``}
    }
  }
  p.tank-update-date {
    ${(props: any) =>
      props.warning
        ? css`
            color: var(--ion-color-danger);
          `
        : ``}
  }
`;
