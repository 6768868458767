import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import qs from 'querystring';
import * as reportActions from 'store/report';
import * as userSaleActions from 'store/userSale';
import * as userDeliveryActions from 'store/userDelivery';
import * as userClientActions from 'store/userClient';
import * as clientActions from 'store/client';

import {
  IonContent,
  IonPage,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonText,
  IonFab,
  IonRefresherContent,
  IonRefresher,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import Header from 'commons/Header/Header';
import languages from 'languages';
import moment from 'moment-timezone';
import _ from 'lodash';
import ShowContent from 'commons/ShowContent/ShowContent';
import routes from 'routes';
import backendConstants from 'backendConstants';
import * as generalUtils from 'utils/generalUtils';
import { ListWrapper, TankTable } from './Report.styles';
import { Wrapper, SubHeader } from 'commons/commons.styles';
import DateRangePicker from 'commons/DateRangePicker/DateRangePicker';
import ReportDeliveryItem from './Components/ReportDeliveryItem';
import { getStore } from 'utils/sessionUtils';

export const BY_TABS = {
  DELIVERY: 'DELIVERY',
};

export const ReportDelivery: React.FC<any> = props => {
  const {
    history,
    location,
    reportReducer,

    checkReport,
    resetReport,

    selfReducer,

    clientReducer,
    userClientReducer,
    userSaleReducer,
    userDeliveryReducer,

    checkClients,
    checkUserClients,
    checkUserSales,
    checkUserDeliveries,

    resetClients,
    resetUserClients,
    resetUserSales,
    resetUserDeliveries,
  } = props;

  const user = selfReducer && selfReducer.response && selfReducer.response.user;
  const result = reportReducer && reportReducer.responseCheck;

  const userClients = userClientReducer && userClientReducer.responseCheck && userClientReducer.responseCheck.data;

  const userDeliveries = userDeliveryReducer && userDeliveryReducer.responseCheck && userDeliveryReducer.responseCheck.data;

  const isFetching =
    (reportReducer && reportReducer.isFetching) ||
    (clientReducer && clientReducer.isFetching) ||
    (userClientReducer && userClientReducer.isFetching) ||
    (userSaleReducer && userSaleReducer.isFetching) ||
    (userDeliveryReducer && userDeliveryReducer.isFetching);

  const queryParams = qs.parse(props.location.search.replace('?', ''));
  const [refreshing, setRefreshing] = useState(false);
  const [byTab, setByTab] = useState<any>(BY_TABS.DELIVERY);
  const [dateRange, setDateRange] = useState<any>(null);

  const resetDateRange = () => setDateRange(null);

  const resetReportByTab = (
    // keepSearch?: boolean
  ) => {
    resetReport();
    resetDateRange();
    resetClients();
    resetUserClients();
    resetUserSales();
    resetUserDeliveries();
    resetContentScrollTop();
  };

  const handleByTab = (e: any) => {
    if (e && e.detail) {
      setByTab(e.detail.value);
      history.push(`${routes.report.main}?byTab=${e.detail.value}`);
    }
  };

  useEffect(() => {
    if (queryParams.byTab && (BY_TABS as any)[queryParams.byTab as any]) {
      setByTab(queryParams.byTab as any);
    } else {
      setByTab(BY_TABS.DELIVERY);
    }
  }, [queryParams.byTab]);

  const showContent = useMemo(() => {
    return location.pathname === routes.report.main;
  }, [location.pathname]);

  useEffect(() => {
    if (showContent) {
      handleReadClient();
      handleReadUserClient();
      handleReadUserSale();
      handleReadUserDeliveries();
    } else {
      resetReportByTab();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent]);

  const handleDateRangeSubmit = (startDate: any, endDate: any) => {
    if (startDate) {
      setDateRange({ startDate, endDate });
    }
  };

  const handleReadReport = () => {
    return (
      dateRange &&
      dateRange.startDate &&
      dateRange.endDate &&
      checkReport({
        store: getStore(),
        ...dateRange,
      })
    );
  };

  const handleReadUserSale = () =>
    checkUserSales({
      filter: {
        store: getStore(),
        userType: backendConstants.USER_TYPES.SALE,
      },
    });

  const handleReadUserClient = () =>
    checkUserClients({
      filter: {
        store: getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
    });

  const handleReadUserDeliveries = () =>
    checkUserDeliveries({
      filter: {
        store: getStore(),
        userType: backendConstants.USER_TYPES.DELIVERY,
      },
    });

  const handleReadClient = () =>
    checkClients({
      filter: {
        store: getStore(),
        status: backendConstants.CLIENT_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
    });

  useEffect(() => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      handleReadReport();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    handleReadReport();
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const userDeliveryResult = useMemo(() => {
    if (!result || !userDeliveries) return;

    const allInvoices = _.flattenDepth(
      result.data.map((client: any) =>
        client.deliveredInvoices.map((userClient: any) =>
          userClient.invoices.map((invoice: any) => ({
            ...invoice,
            clientUserInfo: userClients.find((userClient: any) => userClient._id === invoice.clientUserId),
          }))
        )
      ),
      2
    );
    return userDeliveries.map((userDelivery: any) => {
      const currentInvoices = allInvoices.filter((invoice: any) => invoice.deliveryUserId === userDelivery._id);
      return {
        ...userDelivery,
        deliveredInvoices: currentInvoices,
        deliveredTanks: _.flatten(currentInvoices.map((invoice: any) => invoice.tankSerials)),
        userClients: _.groupBy(currentInvoices, (i: any) => i.clientUserId),
      };
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, userDeliveries]);

  const selectedEntry = useMemo(() => {
    return user && userDeliveryResult && userDeliveryResult.find((userDelivery: any) => userDelivery._id === user._id);
  }, [user, userDeliveryResult]);

  return (
    <Wrapper>
      <IonPage>
        <Header title={languages.report.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleByTab} scrollable={true}>
              {Object.keys(BY_TABS).map(tabKey => (
                <IonSegmentButton key={tabKey} value={tabKey} checked={byTab === tabKey}>
                  {(languages.report.tabs as any)[tabKey]}
                </IonSegmentButton>
              ))}
            </IonSegment>
            <div className={`ion-padding-vertical`}>
              <DateRangePicker
                min={`2019-01-01`}
                max={`2222-01-01`}
                key={showContent}
                defaultValue={dateRange}
                startDate={dateRange && dateRange.startDate}
                endDate={dateRange && dateRange.endDate}
                disabled={refreshing || reportReducer.isFetching}
                onSubmit={handleDateRangeSubmit}
              />
            </div>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
              {/*<FabWrapper></FabWrapper>*/}
            </IonFab>
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <ListWrapper>
                    <IonList className={`ion-margin-vertical`}>
                      {result && dateRange ? (
                        <React.Fragment>
                          <IonListHeader>
                            Kết quả: {moment(dateRange.startDate).format('DD/MM/YYYY')}
                            {' - '}
                            {moment(dateRange.endDate).format('DD/MM/YYYY')}
                          </IonListHeader>
                          {byTab === BY_TABS.DELIVERY && (
                            <div>
                              {selectedEntry ? (
                                <React.Fragment>
                                  <ReportDeliveryItem key={selectedEntry._id} userDelivery={selectedEntry} />
                                  <TankTable>
                                    <thead>
                                      <tr>
                                        <td>Tên khoa/KH</td>
                                        <td className={`ion-text-right`}>Đơn</td>
                                        <td className={`ion-text-right`}>Bình</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.values(selectedEntry.userClients)
                                        .sort((a: any, b: any) => ((b && b.length) || 0) - ((a && a.length) || 0))
                                        .map(
                                          (userClientInvoices: any) =>
                                            userClientInvoices &&
                                            !!userClientInvoices.length &&
                                            userClientInvoices[0].clientUserInfo && (
                                              <tr key={userClientInvoices[0].clientUserId}>
                                                <td>
                                                  <IonText>{userClientInvoices[0].clientUserInfo.name}</IonText>
                                                </td>
                                                <td className={`ion-text-right`}>
                                                  <IonText color={`primary`}>{userClientInvoices.length}</IonText>
                                                </td>
                                                <td className={`ion-text-right`}>
                                                  <IonText color={`danger`}>
                                                    {userClientInvoices.reduce(
                                                      (totalTank: any, invoice: any) =>
                                                        totalTank + ((invoice.tankSerials && invoice.tankSerials.length) || 0),
                                                      0
                                                    )}
                                                  </IonText>
                                                </td>
                                              </tr>
                                            )
                                        )}
                                    </tbody>
                                  </TankTable>
                                </React.Fragment>
                              ) : (
                                <IonGrid>
                                  <IonRow className={`ion-padding ion-justify-content-center`}>
                                    <IonItem disabled>Không có kết quả</IonItem>
                                  </IonRow>
                                </IonGrid>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      ) : (
                        <IonGrid>
                          <IonRow className={`ion-padding ion-justify-content-center`}>
                            <IonItem disabled>Nhập thời gian báo cáo</IonItem>
                          </IonRow>
                        </IonGrid>
                      )}
                    </IonList>
                  </ListWrapper>
                )}
              </div>
            </IonGrid>
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  selfReducer: state.selfReducer,
  reportReducer: state.reportReducer,
  clientReducer: state.clientReducer,
  userClientReducer: state.userClientReducer,
  userSaleReducer: state.userSaleReducer,
  userDeliveryReducer: state.userDeliveryReducer,
});

const mapDispatchToProps = {
  checkReport: reportActions.checkReport,
  resetReport: reportActions.resetReport,
  checkClients: clientActions.checkClients,
  checkUserClients: userClientActions.checkUserClients,
  checkUserSales: userSaleActions.checkUserSales,
  checkUserDeliveries: userDeliveryActions.checkUserDeliveries,
  resetClients: clientActions.resetClients,
  resetUserClients: userClientActions.resetUserClients,
  resetUserSales: userSaleActions.resetUserSales,
  resetUserDeliveries: userDeliveryActions.resetUserDeliveries,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDelivery);
