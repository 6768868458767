import React, { useState, useEffect } from 'react';
import { PickerWrapper } from './DateRangePicker.styles';
import { IonDatetime } from '@ionic/react';

export const DateRangePicker: React.FC<any> = props => {
  const { submitText, onSubmit, min, max, disabled, defaultDateRange, startDate: startDateProp, endDate: endDateProp } = props;
  const { startDate: defaultStartDate, endDate: defaultEndDate } = defaultDateRange || {};

  const [startDate, setStartDate] = useState<any>(defaultStartDate);
  const [endDate, setEndDate] = useState<any>(defaultEndDate);
  const [endDateMin, setEndDateMin] = useState<any>('');

  useEffect(() => {
    if (startDate) {
      setEndDateMin(startDate);
    } else {
      setEndDateMin(min);
    }
    setEndDate(null);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      onSubmit && onSubmit(startDate, endDate);
    } else if (startDate) {
      onSubmit && onSubmit(startDate);
    } else {
      onSubmit && onSubmit();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const handleStartDate = (value: any) => {
    setStartDate(`${value.year.text}-${value.month.text}-${value.day.text}`);
    setEndDate('');
    return true;
  };

  const handleSubmit = (value: any) => {
    const currentEndDate = `${value.year.text}-${value.month.text}-${value.day.text}`;
    setEndDate(currentEndDate);
    return true;
  };

  return (
    <PickerWrapper>
      <IonDatetime
        disabled={disabled}
        pickerOptions={{
          buttons: [
            {
              text: 'Trở lại',
              handler: () => true,
            },
            {
              text: 'Lưu ngày',
              handler: handleStartDate,
            },
          ],
        }}
        placeholder={`Ngày bắt đầu`}
        displayFormat={`DD-MM-YYYY`}
        value={startDateProp || startDate}
        min={min}
        max={max}
      />
      {' - '}
      <IonDatetime
        disabled={disabled || !startDate}
        pickerOptions={{
          buttons: [
            {
              text: 'Trở lại',
              handler: () => true,
            },
            {
              text: submitText || 'Xem báo cáo',
              handler: handleSubmit,
            },
          ],
        }}
        placeholder={`Ngày kết thúc`}
        displayFormat={`DD-MM-YYYY`}
        value={endDateProp || endDate}
        min={endDateMin || min}
        max={max}
      />
    </PickerWrapper>
  );
};

export default DateRangePicker;
