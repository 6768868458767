import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  IonContent,
  IonPage,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonRefresherContent,
  IonRefresher,
  IonText,
} from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';

import * as confirmActions from 'store/confirm';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as restoreActions from 'store/restore';

import _ from 'lodash';
import { RefresherEventDetail } from '@ionic/core';
import routes from 'routes';

const Restore: React.FC<any> = props => {
  const {
    selfReducer,
    restoreReducer,

    checkRestore,
    hotRestore,
    hotBackup,

    setConfirm,
    setSubmitting,
    setToast,

    location,
  } = props;

  const [password, setPassword] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  const store = selfReducer && selfReducer.response && selfReducer.response.user && selfReducer.response.user.store;

  const lastRestore =
    store && restoreReducer && restoreReducer.responseCheck && restoreReducer.responseCheck[`LAST_DB_${store}_RESTORE`];

  const lastBackup =
    store && restoreReducer && restoreReducer.responseCheck && restoreReducer.responseCheck[`LAST_DB_${store}_BACKUP`];

  const hotBackupFiles = store && restoreReducer && restoreReducer.responseCheck && restoreReducer.responseCheck[`HOT_BACKUP`];

  const hasHotBackupFile =
    hotBackupFiles && hotBackupFiles.find((backup: any) => RegExp(`.${store.toLowerCase()}.tgz`, 'i').test(backup));

  useEffect(() => {
    handleReset();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === routes.settings.restore]);

  const handleReset = () => {
    setPassword('');
    setRefreshing(false);
    checkRestore();
  };

  const handleSetPassword = (event: any) => {
    if (event && event.target) {
      setPassword(event.target.value);
    }
  };

  const handleConfirmBackup = _.throttle(() => setConfirm('Xác nhận lưu trữ cơ sở dữ liệu', handleBackup), 1000, { leading: true, trailing: false });

  const handleConfirmRestore = _.throttle(() => setConfirm('Xác nhận khôi phục cơ sở dữ liệu', handleRestore), 1000, { leading: true, trailing: false });

  const handleRequestResult = (message: string, color: string) => {
    setSubmitting(false);
    setToast(message, color);
    handleReset();
  };

  const handleRestore = () => {
    setSubmitting(true);
    hotRestore()
      .then(() => {
        handleRequestResult(`Khôi phục dữ liệu thành công`, `primary`);
      })
      .catch(() => {
        handleRequestResult(`Khôi phục dữ liệu không thành công`, `danger`);
      });
  };

  const handleBackup = () => {
    setSubmitting(true);
    hotBackup()
      .then(() => {
        handleRequestResult(`Lưu trữ dữ liệu thành công`, `primary`);
      })
      .catch(() => {
        handleRequestResult(`Lưu trữ dữ liệu không thành công`, `danger`);
      });
  };

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    handleReset();
    setRefreshing(true);
    setRefereshingDebounced(event);
  };

  return (
    <IonPage>
      <Header title={language.settings.restore} hasMenu={true} />
      <IonContent className={`ion-padding`}>
        <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {!refreshing && (
          <IonCard className={`ion-text-center ion-padding-vertical`}>
            <IonCardHeader>
              <IonCardSubtitle>Khôi phục dữ liệu trong ngày</IonCardSubtitle>
              <IonCardTitle>
                {moment()
                  .tz('Asia/Bangkok')
                  .startOf('day')
                  .format('DD/MM/YYYY')}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem className={`ion-padding-bottom`}>
                <IonLabel position={`floating`}>Nhập mật khẩu đặc biệt</IonLabel>
                <IonInput type={`password`} value={password} onIonChange={handleSetPassword} />
              </IonItem>
              <IonButton
                slot={`start`}
                color={`primary`}
                onClick={handleConfirmBackup}
                disabled={!password || password !== `pltpltplt`}
              >
                Lưu trữ dữ liệu
              </IonButton>
              <IonButton
                slot={`start`}
                color={`danger`}
                onClick={handleConfirmRestore}
                disabled={!hasHotBackupFile || !password || password !== `pltpltplt`}
              >
                Khôi phục dữ liệu
              </IonButton>
              <p className={`ion-padding-top`}>
                Lưu ý: Khôi phục dữ liệu sẽ mất một khoảng thời gian tuỳ thuộc vào dung lượng của cơ sở dữ liệu. Bạn cần cân
                nhắc trước khi thực hiện chức năng này.
              </p>
              {lastRestore && (
                <p className={`ion-padding-vertical`}>
                  <IonText color={`primary`}>Lần khôi phục gần nhất: {moment(lastRestore).format('DD/MM/YY HH:mm')}</IonText>
                </p>
              )}
              {lastBackup && hasHotBackupFile ? (
                <p className={`ion-padding-top`}>
                  <IonText color={`primary`}>Lần lưu trữ gần nhất: {moment(lastBackup).format('DD/MM/YY HH:mm')}</IonText>
                </p>
              ) : (
                <p className={`ion-padding-vertical`}>
                  <IonText color={`danger`}>Chưa lưu trữ lần nào trong ngày</IonText>
                </p>
              )}
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  restoreReducer: state.restoreReducer,
  selfReducer: state.selfReducer,
});
const mapDispatchToProps = {
  checkRestore: restoreActions.checkRestore,
  hotRestore: restoreActions.hotRestore,
  hotBackup: restoreActions.hotBackup,
  resetRestore: restoreActions.resetRestore,

  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,
  setConfirm: confirmActions.setConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(Restore);
