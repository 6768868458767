import styled from 'styled-components';

export const InvoiceNote = styled.div`
  ion-textarea {
    padding: 10px 15px;
    margin: 15px 0;
    border: 1px solid var(--ion-color-light-shade);
    border-radius: 10px;
    textarea.native-textarea {
      &::placeholder {
        font-size: 14px;
      }
    }
  }
`;
