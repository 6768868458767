import React from 'react';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import { RowItemTitle, RowItemMainP } from '../Report.styles';
import { getStore } from '../../../utils/sessionUtils';
import vnConstants from '../../../vnConstants';

export const ReportDeliveryItem: React.FC<any> = props => {
  const { userDelivery, onSelectedUserDelivery } = props;

  const handleSelectUserDelievery = (userDelivery: any) => () => onSelectedUserDelivery && onSelectedUserDelivery(userDelivery);

  return userDelivery ? (
    <div>
      <IonItem
        button={onSelectedUserDelivery}
        onClick={onSelectedUserDelivery ? handleSelectUserDelievery(userDelivery) : undefined}
      >
        <IonLabel>
          <RowItemTitle noLine className={`ion-padding-bottom`}>
            <IonText color={`primary`}>{userDelivery.name}</IonText>
          </RowItemTitle>
          <RowItemMainP>
            Kho:{' '}
            <IonText color={userDelivery.store === getStore() ? `primary` : `danger`}>
              {(vnConstants as any)[userDelivery.store].name}
            </IonText>
          </RowItemMainP>
          <RowItemMainP>
            Hoàn thành: <IonText color={`primary`}>{userDelivery.deliveredInvoices.length || 0} đơn</IonText>
            {' - '}
            Đã giao: <IonText color={`danger`}>{userDelivery.deliveredTanks.length || 0} bình</IonText>
          </RowItemMainP>
        </IonLabel>
      </IonItem>
    </div>
  ) : null;
};

export default ReportDeliveryItem;
