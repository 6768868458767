import React, { useEffect, useMemo, useState } from 'react';
import routes from 'routes';
import _ from 'lodash';
import backendConstants from 'backendConstants';
import { RefresherEventDetail } from '@ionic/core';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react';
import Header from 'commons/Header/Header';
import language from 'languages';
import ShowContent from 'commons/ShowContent/ShowContent';
import * as overviewClientActions from 'store/overviewClient';
import { connect } from 'react-redux';
import { SubHeader } from 'commons/commons.styles';
import moment from 'moment-timezone';
import languages from 'languages';
import { getStore } from 'utils/sessionUtils';

const OVERVIEW_TAB_KEYS = {
  TANK: 'TANK',
  EQUIPMENT: 'EQUIPMENT',
};

const HomeClient: React.FC<any> = props => {
  const { location, checkOverviewClient, resetOverviewClient, overviewClientReducer } = props;

  const [refreshing, setRefreshing] = useState(false);
  const [overviewTab, setOverviewTab] = useState(OVERVIEW_TAB_KEYS.TANK);

  const resetOverviewTab = () => {
    setOverviewTab(OVERVIEW_TAB_KEYS.TANK);
  };

  const overview = overviewClientReducer && overviewClientReducer.responseCheck && overviewClientReducer.responseCheck.data;

  const tanks = overview && overview.currentTanks;
  const equipments = overview && overview.currentEquipments;

  const isFetching = overviewClientReducer.isFetching;

  const showContent = useMemo(() => {
    return location.pathname === routes.home;
  }, [location.pathname]);

  const resetHome = () => {
    resetOverviewClient();
    resetOverviewTab();
  };

  useEffect(() => {
    if (showContent) {
      resetHome();
      checkOverviewClient({ store: getStore() });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent]);

  const handleOverviewTabChange = (event: any) => {
    if (event && event.target && event.target.value) {
      setOverviewTab(event.target.value);
    }
  };

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    await checkOverviewClient({ store: getStore() });
    setRefereshingDebounced(event);
  };

  return (
    <IonPage>
      <Header title={language.home} hasMenu={true}>
        <SubHeader>
          <IonSegment onIonChange={handleOverviewTabChange} scrollable={true}>
            <IonSegmentButton value={OVERVIEW_TAB_KEYS.TANK} checked={overviewTab === OVERVIEW_TAB_KEYS.TANK}>
              Bình đang sử dụng
            </IonSegmentButton>
            <IonSegmentButton value={OVERVIEW_TAB_KEYS.EQUIPMENT} checked={overviewTab === OVERVIEW_TAB_KEYS.EQUIPMENT}>
              Máy đang sử dụng
            </IonSegmentButton>
          </IonSegment>
        </SubHeader>
      </Header>
      <ShowContent isShow={showContent}>
        <IonContent>
          <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {isFetching ? (
            <IonGrid className={`ion-padding`}>
              <IonRow className={`ion-padding ion-justify-content-center`}>
                <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            !refreshing &&
            overview && (
              <IonGrid className={`ion-padding`}>
                {overviewTab === OVERVIEW_TAB_KEYS.TANK && tanks && !!tanks.length && (
                  <IonList>
                    {tanks.map((tank: any) => (
                      <IonItem key={tank._id}>
                        <IonLabel
                          className={`ion-text-wrap`}
                          color={moment().diff(moment(tank.updatedAt), 'days') >= 90 ? 'danger' : undefined}
                        >
                          {tank.serial}
                          <p>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</p>
                          <p>Ngày xuất: {moment(tank.updatedAt).format('DD/MM/YY HH:mm')}</p>
                          {tank.clientUserId && tank.clientUserId.name && <p>{tank.clientUserId.name}</p>}
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                )}
                {overviewTab === OVERVIEW_TAB_KEYS.EQUIPMENT && equipments && !!equipments.length && (
                  <IonList>
                    {equipments.map((equipment: any) => (
                      <IonItem key={equipment._id}>
                        <IonLabel className={`ion-text-wrap`}>
                          {equipment.serial}
                          <div>
                            <IonText color={`primary`}>Loại máy/bình: </IonText>
                            <p>
                              {(languages.manageEquipment.ui as any).types[equipment.type]}
                              {' - '}
                              {(languages.manageTank.ui as any)[equipment.tankType]}
                            </p>
                          </div>
                          {equipment.clientUserId && (
                            <p>
                              <IonText color={`primary`}>{equipment.clientUserId.name}</IonText>
                            </p>
                          )}
                          <div>
                            <IonText color={`primary`}>Ngày cập nhật: </IonText>
                            <p>{moment(equipment.updatedAt).format('DD/MM/YYYY')}</p>
                          </div>
                          <div>
                            <IonText color={`primary`}>Trạng thái: </IonText>
                            <p>
                              <IonText
                                color={
                                  ([
                                    [backendConstants.EQUIPMENT_STATUSES.DISABLED, `danger`],
                                    [backendConstants.EQUIPMENT_STATUSES.CHECKING, `warning`],
                                    [backendConstants.EQUIPMENT_STATUSES.FIXING, `warning`],
                                    [equipment.status, undefined],
                                  ].find(item => item[0] === equipment.status) as any)[1]
                                }
                              >
                                {languages.manageEquipment.ui.statuses[equipment.status]}
                              </IonText>
                            </p>
                          </div>
                          {[
                            backendConstants.EQUIPMENT_STATUSES.SOLD,
                            backendConstants.EQUIPMENT_STATUSES.RENTED,
                            backendConstants.EQUIPMENT_STATUSES.LENT,
                          ].includes(equipment.status) &&
                            (equipment.maintainOption === backendConstants.EQUIPMENT_MAINTAIN_OPTIONS.YES ? (
                              equipment.maintainedAt && (
                                <div>
                                  <IonText color={`primary`}>Lịch bảo dưỡng: </IonText>
                                  <p>
                                    <IonText
                                      color={moment().diff(moment(equipment.maintainedAt), 'month') >= backendConstants.EQUIPMENT_MAINTAIN_MONTH ? `danger` : undefined}
                                    >
                                      {moment(equipment.maintainedAt)
                                        .add(backendConstants.EQUIPMENT_MAINTAIN_MONTH, 'month')
                                        .format('DD/MM/YYYY')}
                                    </IonText>
                                  </p>
                                </div>
                              )
                            ) : (
                              <p className={`ion-padding-top`}>Không bảo dưỡng</p>
                            ))}
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                )}
              </IonGrid>
            )
          )}
        </IonContent>
      </ShowContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  overviewClientReducer: state.overviewClientReducer,
});

const mapDispatchToProps = {
  checkOverviewClient: overviewClientActions.checkOverviewClient,
  resetOverviewClient: overviewClientActions.resetOverviewClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeClient);
