export const ALERT_ACTION = 'ALERT_ACTION';

export const setConfirm = (hasAlert: any, onConfirm: any) => ({
  type: ALERT_ACTION,
  payload: {
    hasAlert,
    onConfirm,
  },
});

export const confirmReducer = (state: any = false, action: any) => {
  if (action.type === ALERT_ACTION) {
    return action.payload;
  }
  return state;
};
