export const MODEL_NAMES = {
  User: 'User',
  Tank: 'Tank',
  Equipment: 'Equipment',
  Client: 'Client',
  Invoice: 'Invoice',
  Counter: 'Counter',
  Log: 'Log',
  Notification: 'Notification',
};

export const BACKUP_MODELS = [
  MODEL_NAMES.User,
  MODEL_NAMES.Tank,
  MODEL_NAMES.Equipment,
  MODEL_NAMES.Client,
  MODEL_NAMES.Invoice,
  MODEL_NAMES.Log,
  MODEL_NAMES.Notification,
];

export const TIMEZONE = 'Asia/Bangkok';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const API_PATH = `/api/v1`;

export const SERVER_STATUSES = {
  BUSY: 'BUSY',
  AVAILABLE: 'AVAILABLE',
};

export const STORES = {
  HN: 'HN',
  SG: 'SG',
};

export const CENTER_STORE = STORES.HN;

export const SECTORS = {
  GOVERNMENT: 'GOVERNMENT',
  PRIVATE: 'PRIVATE',
};

export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
};

export const CLIENT_STATUSES = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
};

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  STORE: 'STORE',
  SALE: 'SALE',
  DELIVERY: 'DELIVERY',
  TECHNICAL: 'TECHNICAL',
  CLIENT: 'CLIENT',
  REVIEWER: 'REVIEWER',
};

export const CLIENT_DEMO_QUOTA = 4;

export const CLIENT_PARENT_USER_TYPES = {
  [USER_TYPES.ADMIN]: USER_TYPES.ADMIN,
  [USER_TYPES.STORE]: USER_TYPES.STORE,
  [USER_TYPES.SALE]: USER_TYPES.SALE,
};

export const CLIENT_TYPES = {
  HOSPITAL: 'HOSPITAL',
  SPA: 'SPA',
  CLINIC: 'CLINIC',
  OTHER: 'OTHER',
};

export const EQUIPMENT_TYPES = {
  HOSPITAL: 'HOSPITAL',
  CLINIC_BIG: 'CLINIC_BIG',
  CLINIC_MEDIUM: 'CLINIC_MEDIUM',
  PLASMA_CARE: 'PLASMA_CARE',
  OTHER: 'OTHER',
};

export const CLIENT_CHARGE_TYPES = {
  VAT: 'VAT',
  NO_VAT: 'NO_VAT',
};

export const PERMISSIONS = {
  DANGEROUS_DELETE: 'DANGEROUS_DELETE', // ** Can dangerously delete a record with all of its log records  ** //

  READ_USERS: 'READ_USERS',
  MANAGE_USERS: 'MANAGE_USERS',

  READ_CLIENTS: 'READ_CLIENTS',
  MANAGE_CLIENTS: 'MANAGE_CLIENTS',

  READ_EQUIPMENTS: 'READ_EQUIPMENT',
  MANAGE_EQUIPMENTS: 'MANAGE_EQUIPMENTS',

  READ_TANKS: 'READ_TANKS',
  MANAGE_TANKS: 'MANAGE_TANKS',

  READ_INVOICES: 'READ_INVOICES',
  MANAGE_INVOICES: 'MANAGE_INVOICES',

  READ_LOGS: 'READ_LOGS',
  MANAGE_LOGS: 'MANAGE_LOGS',

  READ_INVOICES_CLIENT: 'READ_INVOICES_CLIENT',
  REQUEST_INVOICES_CLIENT: 'REQUEST_INVOICES_CLIENT',
  READ_LOGS_CLIENT: 'READ_LOGS_CLIENT',
};

export const PERMISSION_GROUPS = {
  [USER_TYPES.ADMIN]: [
    PERMISSIONS.DANGEROUS_DELETE,

    PERMISSIONS.READ_USERS,
    PERMISSIONS.MANAGE_USERS,

    PERMISSIONS.READ_CLIENTS,
    PERMISSIONS.MANAGE_CLIENTS,

    PERMISSIONS.READ_TANKS,
    PERMISSIONS.MANAGE_TANKS,

    PERMISSIONS.READ_EQUIPMENTS,
    PERMISSIONS.MANAGE_EQUIPMENTS,

    PERMISSIONS.READ_INVOICES,
    PERMISSIONS.MANAGE_INVOICES,

    PERMISSIONS.READ_LOGS,
    PERMISSIONS.MANAGE_LOGS,
  ],
  [USER_TYPES.STORE]: [
    PERMISSIONS.READ_USERS,
    PERMISSIONS.MANAGE_USERS,

    PERMISSIONS.READ_CLIENTS,
    PERMISSIONS.MANAGE_CLIENTS,

    PERMISSIONS.READ_TANKS,
    PERMISSIONS.MANAGE_TANKS,

    PERMISSIONS.READ_EQUIPMENTS,
    PERMISSIONS.MANAGE_EQUIPMENTS,

    PERMISSIONS.READ_INVOICES,
    PERMISSIONS.MANAGE_INVOICES,

    PERMISSIONS.READ_LOGS,
    PERMISSIONS.MANAGE_LOGS,
  ],
  [USER_TYPES.SALE]: [
    PERMISSIONS.READ_INVOICES,
    PERMISSIONS.MANAGE_INVOICES,

    PERMISSIONS.READ_CLIENTS,

    PERMISSIONS.READ_TANKS,

    PERMISSIONS.READ_EQUIPMENTS,

    PERMISSIONS.READ_USERS,

    PERMISSIONS.READ_LOGS,
  ],
  [USER_TYPES.CLIENT]: [
    PERMISSIONS.READ_LOGS_CLIENT,
    PERMISSIONS.READ_INVOICES_CLIENT,
    PERMISSIONS.REQUEST_INVOICES_CLIENT,
  ],
  [USER_TYPES.DELIVERY]: [
    PERMISSIONS.READ_INVOICES,
    PERMISSIONS.MANAGE_INVOICES,

    PERMISSIONS.READ_TANKS,
    PERMISSIONS.MANAGE_TANKS,

    PERMISSIONS.READ_EQUIPMENTS,

    PERMISSIONS.READ_CLIENTS,

    PERMISSIONS.READ_USERS,

    PERMISSIONS.READ_LOGS,
  ],
  [USER_TYPES.TECHNICAL]: [
    PERMISSIONS.READ_EQUIPMENTS,
    PERMISSIONS.MANAGE_EQUIPMENTS,

    PERMISSIONS.READ_CLIENTS,

    PERMISSIONS.READ_TANKS,

    PERMISSIONS.READ_USERS,
  ],
  [USER_TYPES.REVIEWER]: [
    PERMISSIONS.READ_USERS,

    PERMISSIONS.READ_CLIENTS,

    PERMISSIONS.READ_TANKS,

    PERMISSIONS.READ_EQUIPMENTS,

    PERMISSIONS.READ_INVOICES,

    PERMISSIONS.READ_LOGS,
  ],
};

export const NOTIFICATION_DATA_TYPES = {
  PUSH: 'PUSH',
  REPLACE: 'REPLACE',
  ALL: 'ALL',
};

export const NOTIFICATION_STATUSES = {
  UNREAD: 'UNREAD',
  READ: 'READ',
};

export const SOCKET_EVENTS = {
  NOTIFICATION: 'NOTIFICATION',
  NOTIFICATION_REFRESH: 'NOTIFICATION_REFRESH',
  NOTIFICATION_MARK_READ: 'NOTIFICATION_MARK_READ',
  NOTIFICATION_MARK_ALL_READ: 'NOTIFICATION_MARK_ALL_READ',
};

export const TANK_STATUSES = {
  TRASH: 'TRASH', // TRASH is for requesting dangerously changing details/delete
  DISABLED: 'DISABLED', // DISABLED is (broken/lost)
  EMPTY: 'EMPTY',
  LOADING: 'LOADING',
  READY_TO_USE: 'READY_TO_USE',
  LOCKED: 'LOCKED', // In an invoice waiting for delivery
  AT_CLIENT: 'AT_CLIENT',
};

export const TANK_TYPES = {
  TANK_8L: 'TANK_8L',
  TANK_4L: 'TANK_4L',
  TANK_3LC: 'TANK_3LC',
};

export const EQUIPMENT_STATUSES = {
  TRASH: 'TRASH', // TRASH is for requesting dangerously changing details/delete
  DISABLED: 'DISABLED', // DISABLED is (broken/
  CHECKING: 'CHECKING',
  FIXING: 'FIXING',
  READY_TO_USE: 'READY_TO_USE',
  SOLD: 'SOLD',
  RENTED: 'RENTED',
  LENT: 'LENT',
};

export const DELIVERY_OPTIONS = {
  DIRECT: 'DIRECT',
  EXPRESS: 'EXPRESS',
  PICKUP: 'PICKUP',
};

export const INVOICE_TYPES = {
  DEMO: 'DEMO',
  NORMAL: 'NORMAL',
};

export const INVOICE_STATUSES = {
  TRASH: 'TRASH', // Marked as trash for Admin to consider dangerous delete or not
  REQUEST: 'REQUEST',
  APPROVED: 'APPROVED',
  DELIVERING: 'DELIVERING',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
};

export const EVENT_TYPES = {
  USER: 'USER',
  TANK: 'TANK',
  INVOICE: 'INVOICE',
  EQUIPMENT: 'EQUIPMENT',
  CLIENT: 'CLIENT',
};

export const CRUD_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  READ: 'READ',
  DANGEROUS_DELETE: 'DANGEROUS_DELETE',
};

export const EQUIPMENT_MAINTAIN_OPTIONS = {
  YES: 'YES',
  NO: 'NO',
};

export const EQUIPMENT_MAINTAIN_MONTH = 3;

export const EQUIPMENT_WARRANTY_OPTIONS = {
  YES: 'YES',
  NO: 'NO',
};

export const EQUIPMENT_WARRANTY_MONTH = 18;

export const USER_CLIENT_REPORT_EXCLUDED = {
  YES: 'YES',
  NO: 'NO',
};

export const PROVINCES = {
  SG: 'SG',
  HN: 'HN',
  DDN: 'DDN',
  BD: 'BD',
  DNA: 'DNA',
  KH: 'KH',
  HP: 'HP',
  LA: 'LA',
  QNA: 'QNA',
  VT: 'VT',
  DDL: 'DDL',
  CT: 'CT',
  BTH: 'BTH',
  LDD: 'LDD',
  TTH: 'TTH',
  KG: 'KG',
  BN: 'BN',
  QNI: 'QNI',
  TH: 'TH',
  NA: 'NA',
  HD: 'HD',
  GL: 'GL',
  BP: 'BP',
  HY: 'HY',
  BDD: 'BDD',
  TG: 'TG',
  TB: 'TB',
  BG: 'BG',
  HB: 'HB',
  AG: 'AG',
  VP: 'VP',
  TNI: 'TNI',
  TN: 'TN',
  LCA: 'LCA',
  NDD: 'NDD',
  QNG: 'QNG',
  BTR: 'BTR',
  DNO: 'DNO',
  CM: 'CM',
  VL: 'VL',
  NB: 'NB',
  PT: 'PT',
  NT: 'NT',
  PY: 'PY',
  HNA: 'HNA',
  HT: 'HT',
  DDT: 'DDT',
  ST: 'ST',
  KT: 'KT',
  QB: 'QB',
  QT: 'QT',
  TV: 'TV',
  HGI: 'HGI',
  SL: 'SL',
  BL: 'BL',
  YB: 'YB',
  TQ: 'TQ',
  DDB: 'DDB',
  LCH: 'LCH',
  LS: 'LS',
  HG: 'HG',
  BK: 'BK',
  CB: 'CB',
};

export default {
  TIMEZONE,
  SERVER_STATUSES,
  STORES,
  CENTER_STORE,
  SECTORS,
  PROVINCES,
  DATE_FORMAT,
  API_PATH,
  MODEL_NAMES,
  BACKUP_MODELS,
  USER_STATUSES,
  CLIENT_STATUSES,
  USER_TYPES,
  CLIENT_PARENT_USER_TYPES,
  CLIENT_TYPES,
  CLIENT_CHARGE_TYPES,
  CLIENT_DEMO_QUOTA,
  PERMISSIONS,
  PERMISSION_GROUPS,
  NOTIFICATION_DATA_TYPES,
  NOTIFICATION_STATUSES,
  TANK_STATUSES,
  TANK_TYPES,
  EQUIPMENT_TYPES,
  EQUIPMENT_STATUSES,
  EQUIPMENT_MAINTAIN_OPTIONS,
  EQUIPMENT_MAINTAIN_MONTH,
  EQUIPMENT_WARRANTY_OPTIONS,
  EQUIPMENT_WARRANTY_MONTH,
  DELIVERY_OPTIONS,
  INVOICE_TYPES,
  INVOICE_STATUSES,
  EVENT_TYPES,
  CRUD_TYPES,
  USER_CLIENT_REPORT_EXCLUDED,
  SOCKET_EVENTS,
};
