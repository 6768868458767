import styled from 'styled-components';

export const FabWrapper = styled.div`
  background-color: var(--ion-color-light);
  border-radius: 5px;
`;

export const ListWrapper = styled.div`
  padding-bottom: 100px;
`;

export const SaleTitle = styled.h5`
  padding: 15px 15px 15px 15px;
  ion-text {
    display: block;
    padding-bottom: 10px;
    font-weight: normal;
    font-size: 12px;
    color: var(--ion-color-step-650);
  }
`;

export const ModalDateRange = styled.div`
  font-weight: normal;
  font-size: 12px;
  padding-bottom: 5px;
`;

export const TankTable = styled.table`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-medium);
  margin-bottom: 30px;
  td {
    padding: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--ion-color-medium);
  }
`;

export const TableTitle = styled.div`
  h6 {
    margin: 0;
    padding-bottom: 5px;
  }
  p {
    margin: 0;
    padding-bottom: 10px;
    font-size: 12px;
  }
`;

export const RowItemTitle: any = styled.div`
  padding-top: 25px;
  border-top: ${(props: any) => (props.noLine ? 0 : 1)}px dashed var(--ion-color-medium);
  ion-text {
    font-weight: bold;
  }
`;

export const RowItemMainP = styled.p`
  padding: 8px 0;
  color: var(--ion-step-750) !important;
  ion-text {
    font-size: 16px;
    font-weight: bold;
  }

  span {
    &:not(:first-child) {
      &::before {
        content: ' - ';
        display: inline;
      }
    }
  }
`;

export const RowItemSubP = styled.p`
  padding: 8px 0;
  border-bottom: 1px solid var(--ion-color-light);
  color: var(--ion-step-750) !important;
  margin-left: 30px !important;
`;
