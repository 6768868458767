import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NotificationWrapper } from './Notification.styles';
import {
  IonContent,
  IonPage,
  IonButton,
  IonLabel,
  IonList,
  IonItem,
  IonText,
  IonFab,
  IonRow,
  IonCol,
  IonSpinner,
  IonGrid,
  IonIcon, IonInfiniteScrollContent, IonInfiniteScroll,
} from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';
import moment from 'moment-timezone';
import * as notificationActions from 'store/notification';
import * as userClientActions from 'store/userClient';
import * as userDeliveryActions from 'store/userDelivery';
import backendConstants from 'backendConstants';
import { getStore } from 'utils/sessionUtils';
import { cog, paper, gitCompare } from 'ionicons/icons';
import * as selfActions from '../../store/self';
import { setupSocket } from '../../utils/notificationSocketUtils';

export const MESSAGE_CATEGORIES = {
  'EQUIPMENT--UPDATE--MAINTAINED': 'Bảo dưỡng xong',
  'EQUIPMENT--UPDATE--CHECKED': 'Kiểm tra xong',
  'EQUIPMENT--UPDATE--FIXED': 'Sửa xong',
  'EQUIPMENT--UPDATE--CHECKING': 'Đang kiểm tra',
  'EQUIPMENT--UPDATE--FIXING': 'Đang sửa',

  'EQUIPMENT-PASSIVE--MAINTAIN-ON': 'Cần bảo dưỡng',
  'EQUIPMENT-PASSIVE--WARRANTY-EXPIRED': 'Hết bảo hành',

  'INVOICE--CREATE': 'Đơn hàng mới',
  'INVOICE--UPDATE--APPROVED': 'Đơn hàng chờ giao',
  'INVOICE--UPDATE--DELIVERING': 'Đơn hàng đang giao',
  'INVOICE--UPDATE--DELIVERED': 'Đơn hàng hoàn thành',
  'INVOICE--UPDATE--CANCELLED': 'Đơn hàng đã huỷ',

  'EQUIPMENT-SPECIAL--STORE-TRANSFER': 'Chuyển kho - máy',
  'TANK-SPECIAL--STORE-TRANSFER': 'Chuyển kho - bình',
};

export const EQUIPMENT_MESSAGES = {
  'EQUIPMENT--UPDATE--MAINTAINED': 'đã được bảo dưỡng',
  'EQUIPMENT--UPDATE--CHECKED': 'đã được kiểm tra',
  'EQUIPMENT--UPDATE--FIXED': 'đã được sửa',
  'EQUIPMENT--UPDATE--CHECKING': 'đang được kiểm tra',
  'EQUIPMENT--UPDATE--FIXING': 'đang được sửa',
  'EQUIPMENT-PASSIVE--MAINTAIN-ON': 'cần bảo dưỡng',
  'EQUIPMENT-PASSIVE--WARRANTY-EXPIRED': 'hết bảo hành',
};

const Notification: React.FC<any> = props => {
  const {
    selfReducer,
    notificationReducer,
    userClientReducer,
    userDeliveryReducer,
    markRead,
    markAllRead,
    checkUserClients,
    checkUserDeliveries,
    setSocket,
    processNotification
  } = props;

  const [notificationScrollCount, setNotificationScrollCount] = useState(20);
  const handleNotificationCountIncrement = (e: any) => {
    setNotificationScrollCount(notificationScrollCount + 20);
    e && e.target && e.target.complete();
  };

  const socket = selfReducer && selfReducer.socket;
  const unreadNotification = notificationReducer.filter(
    (notification: any) => notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD
  );
  const userClients = userClientReducer && userClientReducer.responseCheck && userClientReducer.responseCheck.data;
  const userDeliveries = userDeliveryReducer && userDeliveryReducer.responseCheck && userDeliveryReducer.responseCheck.data;
  const isFetching =
    (userClientReducer && userClientReducer.isFetching) || (userDeliveryReducer && userDeliveryReducer.isFetching);
  useEffect(() => {
    checkUserClients({
      filter: {
        store: getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
    });
    checkUserDeliveries({
      filter: {
        store: getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.DELIVERY,
      },
    });
  }, [checkUserClients, checkUserDeliveries]);

  const handleMarkRead = (notification: any) => () =>
    notification &&
    markRead &&
    notification.status !== backendConstants.NOTIFICATION_STATUSES.READ &&
    markRead(notification._id);

  useEffect(() => {
    if (!socket) {
      setupSocket(setSocket, processNotification);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('NOW TRYING TO REFRESH NOTIFICATION!!!');
    if (socket && notificationReducer && notificationReducer.length === 0) {
      socket.emit(backendConstants.SOCKET_EVENTS.NOTIFICATION_REFRESH);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  return (
    <IonPage>
      <Header title={language.notification} hasMenu={false} hasBack={true} noNotification={true} noStore={true} />
      {!isFetching ? (
        <IonContent className={`ion-padding`}>
          <NotificationWrapper className={`ion-padding-vertical ion-text-center`}>
            <IonList>
              {notificationReducer.slice(0, notificationScrollCount).map((notification: any) => {
                const evenType = notification.message.split('--')[0];
                const hideDate =
                  moment()
                    .startOf('day')
                    .diff(moment(notification.createdAt).startOf('day'), 'day') === 0;
                const dateSection = <p className={`ion-top-right`}>
                  <IonText color={`primary`}>{moment(notification.createdAt).format(hideDate ? 'HH:mm' : 'DD-MM-YYYY HH:mm')}</IonText>
                </p>

                if (evenType === backendConstants.EVENT_TYPES.EQUIPMENT) {
                  const keyValue =
                    notification.logId && notification.logId.currentRecord && notification.logId.currentRecord.serial;
                  return (
                    (EQUIPMENT_MESSAGES as any)[notification.message] && (
                      <IonItem
                        key={notification._id}
                        color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'light' : undefined}
                        onClick={handleMarkRead(notification)}
                      >
                        <IonIcon
                          icon={cog}
                          slot={`start`}
                          color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'dark' : 'medium'}
                        />
                        <IonLabel>
                          {(MESSAGE_CATEGORIES as any)[notification.message]}:
                          {keyValue && (
                            <p>
                              <IonText color={`primary`}>{keyValue}</IonText>{' '}
                              {(EQUIPMENT_MESSAGES as any)[notification.message]}{' '}
                            </p>
                          )}
                          <p>
                            {notification.fromUser && (
                              <React.Fragment>
                                Cập nhật bởi <IonText color={`primary`}>{notification.fromUser.name}</IonText>{' '}
                              </React.Fragment>
                            )}
                          </p>
                          {dateSection}
                        </IonLabel>
                      </IonItem>
                    )
                  );
                }

                if (evenType === `${backendConstants.EVENT_TYPES.EQUIPMENT}-SPECIAL`) {
                  return (
                    (MESSAGE_CATEGORIES as any)[notification.message] && (
                      <IonItem
                        key={notification._id}
                        color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'light' : undefined}
                        onClick={handleMarkRead(notification)}
                      >
                        <IonIcon
                          icon={gitCompare}
                          slot={`start`}
                          color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'dark' : 'medium'}
                        />
                        <IonLabel>
                          {(MESSAGE_CATEGORIES as any)[notification.message]}:
                          <p>
                            Đã chuyển <IonText color={`primary`}>{notification.count}</IonText> máy{' '}
                            {notification.extraData && (
                              <React.Fragment>
                                từ <IonText color={`primary`}>{notification.extraData.fromStore}</IonText> tới{' '}
                                <IonText color={`primary`}>{notification.extraData.toStore}</IonText>
                              </React.Fragment>
                            )}
                          </p>
                          {dateSection}
                        </IonLabel>
                      </IonItem>
                    )
                  );
                }

                if (evenType === `${backendConstants.EVENT_TYPES.TANK}-SPECIAL`) {
                  return (
                    (MESSAGE_CATEGORIES as any)[notification.message] && (
                      <IonItem
                        key={notification._id}
                        color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'light' : undefined}
                        onClick={handleMarkRead(notification)}
                      >
                        <IonIcon
                          icon={gitCompare}
                          slot={`start`}
                          color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'dark' : 'medium'}
                        />
                        <IonLabel>
                          {(MESSAGE_CATEGORIES as any)[notification.message]}:
                          <p>
                            Đã chuyển <IonText color={`primary`}>{notification.count}</IonText> vỏ/bình{' '}
                            {notification.extraData && (
                              <React.Fragment>
                                từ <IonText color={`primary`}>{notification.extraData.fromStore}</IonText> tới{' '}
                                <IonText color={`primary`}>{notification.extraData.toStore}</IonText>
                              </React.Fragment>
                            )}
                          </p>
                          {dateSection}
                        </IonLabel>
                      </IonItem>
                    )
                  );
                }

                if (
                  evenType === backendConstants.EVENT_TYPES.INVOICE &&
                  userClients &&
                  userDeliveries &&
                  notification.logId &&
                  notification.logId.currentRecord &&
                  notification.logId.currentRecord.clientUserId
                ) {
                  const userClient = userClients.find(
                    (userClient: any) => userClient._id === notification.logId.currentRecord.clientUserId
                  );
                  const userDelivery =
                    notification.logId.currentRecord.deliveryUserId &&
                    userDeliveries.find(
                      (userDelivery: any) => userDelivery._id === notification.logId.currentRecord.deliveryUserId
                    );

                  return (
                    userClient && (
                      <IonItem
                        key={notification._id}
                        color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'light' : undefined}
                        onClick={handleMarkRead(notification)}
                      >
                        <IonIcon
                          icon={paper}
                          slot={`start`}
                          color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'dark' : 'medium'}
                        />
                        <IonLabel>
                          <IonText color={notification.message.includes('CANCELLED') ? `danger` : undefined}>
                            {(MESSAGE_CATEGORIES as any)[notification.message]}:
                          </IonText>
                          <p>
                            <IonText color={`primary`}>{userClient.name}</IonText>{' '}
                            {(EQUIPMENT_MESSAGES as any)[notification.message]}{' '}
                          </p>
                          {userDelivery && (
                            <p>
                              Giao vận: <IonText color={`primary`}>{userDelivery.name}</IonText>
                            </p>
                          )}
                          <p>
                            {notification.fromUser && (
                              <React.Fragment>
                                Cập nhật bởi <IonText color={`primary`}>{notification.fromUser.name}</IonText>{' '}
                              </React.Fragment>
                            )}
                          </p>
                          {dateSection}
                        </IonLabel>
                      </IonItem>
                    )
                  );
                }

                if (evenType === `${backendConstants.EVENT_TYPES.EQUIPMENT}-PASSIVE` && notification.extraData && userClients) {
                  const keyValue = notification.extraData.serial;
                  const userClient = userClients.find(
                    (userClient: any) => userClient._id === notification.extraData.clientUserId
                  );
                  return (
                    (EQUIPMENT_MESSAGES as any)[notification.message] && (
                      <IonItem
                        key={notification._id}
                        color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'light' : undefined}
                        onClick={handleMarkRead(notification)}
                      >
                        <IonIcon
                          icon={cog}
                          slot={`start`}
                          color={notification.status === backendConstants.NOTIFICATION_STATUSES.UNREAD ? 'dark' : 'medium'}
                        />
                        <IonLabel>
                          {(MESSAGE_CATEGORIES as any)[notification.message]}:
                          {keyValue && (
                            <p>
                              <IonText color={`primary`}>{keyValue}</IonText>{' '}
                              {(EQUIPMENT_MESSAGES as any)[notification.message]}{' '}
                            </p>
                          )}
                          {userClient && (
                            <p>
                              <IonText color={`primary`}>{userClient.name}</IonText>{' '}
                            </p>
                          )}
                          {dateSection}
                        </IonLabel>
                      </IonItem>
                    )
                  );
                }

                return null;
              })}
              <IonInfiniteScroll
                threshold={`200px`}
                onIonInfinite={handleNotificationCountIncrement}
              >
                <IonInfiniteScrollContent />
              </IonInfiniteScroll>
            </IonList>
          </NotificationWrapper>
          {unreadNotification.length > 0 && (
            <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
              <IonButton color={`primary`} onClick={markAllRead}>
                <IonLabel>Đã đọc tất cả</IonLabel>
              </IonButton>
            </IonFab>
          )}
        </IonContent>
      ) : (
        <IonContent className={`ion-padding`}>
          <IonGrid className={`ion-padding`}>
            <IonRow className={`ion-padding ion-justify-content-center`}>
              <IonCol className={`ion-text-center`}>
                <IonSpinner name={`lines`} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      )}
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  selfReducer: state.selfReducer,
  userClientReducer: state.userClientReducer,
  userDeliveryReducer: state.userDeliveryReducer,
  notificationReducer: state.notificationReducer,
});

const mapDispatchToProps = {
  markRead: notificationActions.markRead,
  markAllRead: notificationActions.markAllRead,
  checkUserClients: userClientActions.checkUserClients,
  checkUserDeliveries: userDeliveryActions.checkUserDeliveries,
  setSocket: selfActions.setSocket,
  processNotification: notificationActions.processNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
