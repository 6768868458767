import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/user`;
export const USER_ADMIN_CRUD_ACTIONS = generateCrudActionTypes(`USER_ADMIN_CRUD`);

export const {
  check: checkUserAdmins,
  read: readUserAdmins,
  create: createUserAdmin,
  update: updateUserAdmin,
  updateBatch: updateUserAdmins,
  resetState: resetUserAdmins,
} = generateThunkActions(USER_ADMIN_CRUD_ACTIONS, ENDPOINT);

export const userAdminReducer = generateCrudReducer(USER_ADMIN_CRUD_ACTIONS);
