import React, { useState } from 'react';
import _ from 'lodash';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { TitleWrapper } from 'commons/commons.styles';
import { ModalFooterWrapper, ModalWrapper } from 'commons/commons.styles';
import ShowContent from 'commons/ShowContent/ShowContent';
import { AddEditUserForm } from '../Forms/AddEditUserForm';
import { AddEditUserClientForm } from '../Forms/AddEditUserClientForm';
import * as generalUtils from 'utils/generalUtils';

export const ManageUserModal: React.FC<any> = props => {
  const {
    title,
    isOpen,
    onClose,
    onConfirm,
    setConfirm,
    confirmLabel,
    selectedUser,
    selectedClient,
    selectedUserClient,
  } = props;

  const [formUserClientProps, setFormUserClientProps] = useState<any>(null);
  const [formUserProps, setFormUserProps] = useState<any>(null);

  const resetFormUserClientProps = () => setFormUserClientProps(null);
  const resetFormUserProps = () => setFormUserProps(null);

  const resetModal = () => {
    resetFormUserClientProps();
    resetFormUserProps();
  };

  const handleFormUserClientUpdate = generalUtils.handleFormPropsUpdate(formUserClientProps, setFormUserClientProps);

  const handleFormUserUpdate = generalUtils.handleFormPropsUpdate(formUserProps, setFormUserProps);

  const handleCloseModal = (noReload: boolean) => (
    // event?: any
  ) => {
    onClose && onClose(noReload);
    resetModal();
  };

  const handleConfirmUserClientAlert = () =>
    setConfirm('Xác nhận thay đổi TT Khoa/TK KH', async () => {
      return await handleConfirmUpdate();
    });

  const handleConfirmUserAlert = () =>
    setConfirm('Xác nhận thay đổi TT TK', async () => {
      return await handleConfirmUpdate();
    });

  const handleConfirmUpdate = _.throttle(async () => {
    onConfirm && (await onConfirm(formUserProps, formUserClientProps));
    resetModal();
  }, 3000, { leading: true, trailing: false });

  return (
    isOpen && (
      <IonModal isOpen={true} backdropDismiss={false} showBackdrop={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={`start`}>
              <IonButton onClick={handleCloseModal(true)}>Trở lại</IonButton>
            </IonButtons>
            {title && <IonTitle>{title}</IonTitle>}
            {selectedClient && selectedUserClient && (
              <TitleWrapper>
                <IonTitle>
                  {selectedClient.name}
                  <p>
                    <IonText>{selectedUserClient.name}</IonText>
                  </p>
                </IonTitle>
              </TitleWrapper>
            )}
          </IonToolbar>
        </IonHeader>
        <ShowContent isShow={true}>
          <IonContent className={`ion-padding`}>
            <ModalWrapper>
              {selectedUser && (
                <IonGrid>
                  <AddEditUserForm selectedUser={selectedUser} onFormUpdate={handleFormUserUpdate} />
                </IonGrid>
              )}
              {selectedClient && selectedUserClient && (
                <IonGrid>
                  <AddEditUserClientForm
                    selectedClient={selectedClient}
                    selectedUserClient={selectedUserClient}
                    onFormUpdate={handleFormUserClientUpdate}
                  />
                </IonGrid>
              )}
            </ModalWrapper>
          </IonContent>
        </ShowContent>
        <IonFooter>
          <ModalFooterWrapper>
            <IonFab vertical={`top`} horizontal={`end`} slot={`fixed`}>
              {selectedClient && selectedUserClient && (
                <IonButton
                  color={`primary`}
                  onClick={handleConfirmUserClientAlert}
                  disabled={!formUserClientProps || !formUserClientProps.dirty || !formUserClientProps.isValid}
                >
                  <IonLabel>{confirmLabel}</IonLabel>
                </IonButton>
              )}
              {selectedUser && (
                <IonButton
                  color={`primary`}
                  onClick={handleConfirmUserAlert}
                  disabled={!formUserProps || !formUserProps.dirty || !formUserProps.isValid}
                >
                  <IonLabel>{confirmLabel}</IonLabel>
                </IonButton>
              )}
            </IonFab>
          </ModalFooterWrapper>
        </IonFooter>
      </IonModal>
    )
  );
};

export default ManageUserModal;
