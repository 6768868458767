import React from 'react';
import { IonList, IonItem, IonLabel, IonText } from '@ionic/react';
import vnConstants from 'vnConstants';
import { getStore } from 'utils/sessionUtils';

export const ReportClientItem: React.FC<any> = props => {
  const { client, onSelectedUserClient, onSelectedClient } = props;

  const handleSelectClient = (client: any) => () => onSelectedClient && onSelectedClient(client);
  const handleSelectUserClient = (userClient: any) => () => onSelectedUserClient && onSelectedUserClient(userClient);

  const clientToStoreTankCount = client.userClients.reduce(
    (totalCount: any, userClient: any) => totalCount + userClient.toStoreTanks.length,
    0
  );

  return client ? (
    <div>
      <IonItem button={true} onClick={handleSelectClient(client)}>
        <IonLabel>
          <IonText color={`primary`}>{client.name}</IonText>
          <p>
            Kho:{' '}
            <IonText color={client.store === getStore() ? `primary` : `danger`}>
              {(vnConstants as any)[client.store].name}
            </IonText>
          </p>
          <p>
            <IonText>Đang sử dụng: {client.usingEquipments.length} máy</IonText>
          </p>
          <p>
            Đã sử dụng: <IonText color={`primary`}>{client.deliveredTanks.length} bình</IonText>
          </p>
          <p>
            Thu hồi: <IonText color={`danger`}>{clientToStoreTankCount} vỏ</IonText>
          </p>
        </IonLabel>
      </IonItem>
      <div className={`ion-padding-start`}>
        <IonList>
          {client.userClients &&
            client.userClients.map((userClient: any) => {
              const saleTanks = userClient.tanks && userClient.tanks.filter((t: any) => !t.isDemo);
              const demoTanks = userClient.tanks && userClient.tanks.filter((t: any) => t.isDemo);
              return (
                <IonItem key={userClient._id} button={true} onClick={handleSelectUserClient(userClient)}>
                  <IonLabel>
                    <p>
                      <IonText color={`secondary`}>{userClient.name}</IonText>
                    </p>
                    <p>
                      Kho:{' '}
                      <IonText color={userClient.store === getStore() ? `primary` : `danger`}>
                        {(vnConstants as any)[userClient.store].name}
                      </IonText>
                    </p>
                    <p>
                      <IonText>Đang sử dụng: {userClient.equipments.length} máy</IonText>
                    </p>
                    <p>
                      Bán: <IonText color={`secondary`}>{saleTanks.length} bình</IonText>
                      {demoTanks && !!demoTanks.length && (
                        <React.Fragment>
                          , Demo: <IonText color={`danger`}>{demoTanks.length} bình</IonText>
                        </React.Fragment>
                      )}
                    </p>
                    <p>
                      Thu hồi: <IonText color={`danger`}>{userClient.toStoreTanks.length} vỏ</IonText>
                    </p>
                  </IonLabel>
                </IonItem>
              );
            })}
        </IonList>
      </div>
    </div>
  ) : null;
};

export default ReportClientItem;
