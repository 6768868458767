import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonFab,
  IonRefresherContent,
  IonRefresher,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import Header from 'commons/Header/Header';
import languages from 'languages';
import moment from 'moment-timezone';
import * as reportClientActions from 'store/reportClient';
import _ from 'lodash';
import ShowContent from 'commons/ShowContent/ShowContent';
import routes from 'routes';
import * as generalUtils from 'utils/generalUtils';
import { ListWrapper } from './Report.styles';
import { Wrapper, SubHeader } from 'commons/commons.styles';
import DateRangePicker from 'commons/DateRangePicker/DateRangePicker';
import { getStore } from 'utils/sessionUtils';

export const BY_TABS = {
  TO_CLIENT: 'TO_CLIENT',
  TO_STORE: 'TO_STORE',
};

export const ReportClient: React.FC<any> = props => {
  const {
    location,
    reportClientReducer,

    checkClientReport,
    resetClientReport,
  } = props;

  const result = reportClientReducer && reportClientReducer.responseCheck && reportClientReducer.responseCheck.data;

  const toStoreTanks = result && result.toStoreTanks;
  const toClientTanks = result && result.toClientTanks;

  const isFetching = reportClientReducer && reportClientReducer.isFetching;

  const [refreshing, setRefreshing] = useState(false);
  const [byTab, setByTab] = useState<any>(BY_TABS.TO_CLIENT);
  const [dateRange, setDateRange] = useState<any>(null);

  const resetDateRange = () => setDateRange(null);

  const resetReportByTab = () => {
    resetClientReport();
    resetDateRange();
    resetContentScrollTop();
  };

  const handleByTab = (e: any) => {
    if (e && e.target) {
      setByTab(e.target.value);
    }
  };

  const showContent = useMemo(() => {
    return location.pathname === routes.report.main;
  }, [location.pathname]);

  useEffect(() => {
    if (!showContent) {
      resetReportByTab();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent]);

  const handleDateRangeSubmit = (startDate: any, endDate: any) => {
    if (startDate) {
      setDateRange({ startDate, endDate });
    }
  };

  const handleReadReport = () => {
    return (
      dateRange &&
      dateRange.startDate &&
      dateRange.endDate &&
      checkClientReport({
        store: getStore(),
        ...dateRange,
      })
    );
  };

  useEffect(() => {
    resetContentScrollTop();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byTab]);

  useEffect(() => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      handleReadReport();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    handleReadReport();
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  return (
    <Wrapper>
      <IonPage>
        <Header title={languages.report.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleByTab} scrollable={true}>
              {Object.keys(BY_TABS).map(tabKey => (
                <IonSegmentButton key={tabKey} value={tabKey} checked={byTab === tabKey}>
                  {(languages.report.tabs as any)[tabKey]}
                </IonSegmentButton>
              ))}
            </IonSegment>
            <div className={`ion-padding-vertical`}>
              <DateRangePicker
                min={`2019-01-01`}
                max={`2222-01-01`}
                key={showContent}
                defaultValue={dateRange}
                startDate={dateRange && dateRange.startDate}
                endDate={dateRange && dateRange.endDate}
                disabled={refreshing || reportClientReducer.isFetching}
                onSubmit={handleDateRangeSubmit}
              />
            </div>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
              {/*<FabWrapper></FabWrapper>*/}
            </IonFab>
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <ListWrapper>
                    <IonList className={`ion-margin-vertical`}>
                      {result && dateRange ? (
                        <React.Fragment>
                          {byTab === BY_TABS.TO_CLIENT &&
                            toClientTanks &&
                            (toClientTanks.length ? (
                              <React.Fragment>
                                <IonListHeader>Kết quả: {toClientTanks.length} bình</IonListHeader>
                                {toClientTanks.map((tank: any) => (
                                  <IonItem key={tank._id + tank.updatedAt}>
                                    <IonLabel className={`ion-text-wrap`}>
                                      {tank.serial}
                                      <p>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</p>
                                      <p>Ngày xuất: {moment(tank.updatedAt).format('DD/MM/YY HH:mm')}</p>
                                    </IonLabel>
                                  </IonItem>
                                ))}
                              </React.Fragment>
                            ) : (
                              <IonGrid>
                                <IonRow className={`ion-padding ion-justify-content-center`}>
                                  <IonItem disabled>Không sử dụng bình nào</IonItem>
                                </IonRow>
                              </IonGrid>
                            ))}
                          {byTab === BY_TABS.TO_STORE &&
                            toStoreTanks &&
                            (toStoreTanks.length ? (
                              <React.Fragment>
                                <IonListHeader>Kết quả: {toStoreTanks.length} vỏ</IonListHeader>
                                {toStoreTanks.map((tank: any) => (
                                  <IonItem key={tank._id + tank.updatedAt}>
                                    <IonLabel className={`ion-text-wrap`}>
                                      {tank.serial}
                                      <p>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</p>
                                      <p>Ngày trả: {moment(tank.updatedAt).format('DD/MM/YY HH:mm')}</p>
                                    </IonLabel>
                                  </IonItem>
                                ))}
                              </React.Fragment>
                            ) : (
                              <IonGrid>
                                <IonRow className={`ion-padding ion-justify-content-center`}>
                                  <IonItem disabled>Không trả vỏ nào</IonItem>
                                </IonRow>
                              </IonGrid>
                            ))}
                        </React.Fragment>
                      ) : (
                        <IonGrid>
                          <IonRow className={`ion-padding ion-justify-content-center`}>
                            <IonItem disabled>Nhập thời gian báo cáo</IonItem>
                          </IonRow>
                        </IonGrid>
                      )}
                    </IonList>
                  </ListWrapper>
                )}
              </div>
            </IonGrid>
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  selfReducer: state.selfReducer,
  reportClientReducer: state.reportClientReducer,
});

const mapDispatchToProps = {
  checkClientReport: reportClientActions.checkClientReport,
  resetClientReport: reportClientActions.resetClientReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportClient);
