import React, { useState } from 'react';
import {
  IonCheckbox,
  IonGrid, IonInfiniteScroll, IonInfiniteScrollContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import languages from 'languages';
import moment from 'moment-timezone';
import { PlaceHolderWrapper } from 'commons/commons.styles';

export const TransferEquipmentItem: React.FC<any> = props => {
  const { equipments, onSelectEquipment, selectedEquipments, isInModal } = props;
  const [equipmentScrollCount, setEquipmentScrollCount] = useState(50);
  const handleEquipmentCountIncrement = (e: any) => {
    setEquipmentScrollCount(equipmentScrollCount + 50);
    e && e.target && e.target.complete();
  };
  return equipments && !!equipments.length ? (
    <React.Fragment>
      {
        equipments.slice(0, equipmentScrollCount).map((equipment: any) => {
          return (
            <IonItemSliding key={equipment.serial}>
              <IonItem>
                <IonLabel>
                  <IonText>{equipment.serial}</IonText>
                  <p>
                    <IonText>Loại máy: {(languages.manageEquipment.ui.types as any)[equipment.type]}</IonText>
                  </p>
                  <p>
                    <IonText>Loại bình: {(languages.manageTank.ui.types as any)[equipment.tankType]}</IonText>
                  </p>
                  {equipment.updatedAt && (
                    <p className={`ion-fixed-end`}>
                      <IonText>{moment(equipment.updatedAt).format('DD/MM/YYYY HH:mm')}</IonText>
                    </p>
                  )}
                </IonLabel>
                {!isInModal && (
                  <IonCheckbox
                    slot={`start`}
                    onIonChange={onSelectEquipment(equipment.serial)}
                    checked={(selectedEquipments as any)[equipment.serial]}
                  />
                )}
              </IonItem>
              {isInModal && (
                <IonItemOptions side={`end`}>
                  <IonItemOption color={`danger`} onClick={onSelectEquipment(equipment.serial)}>
                    Loại bỏ
                  </IonItemOption>
                </IonItemOptions>
              )}
            </IonItemSliding>
          );
        })
      }
      <IonInfiniteScroll
        threshold={`300px`}
        onIonInfinite={handleEquipmentCountIncrement}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </React.Fragment>
  ) : (
    <PlaceHolderWrapper>
      <IonGrid>
        <IonRow className={`ion-padding ion-justify-content-center`}>
          <IonItem disabled>Không có máy nào</IonItem>
        </IonRow>
      </IonGrid>
    </PlaceHolderWrapper>
  );
};

export default TransferEquipmentItem;
