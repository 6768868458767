import { API_PATH } from 'backendConstants';
import { generateCrudActionTypes, generateCrudReducer, generateThunkActions } from 'utils/storeUtils';

export const ENDPOINT = `${API_PATH}/all`;
export const ALL_CRUD_ACTIONS = generateCrudActionTypes(`ALL_CRUD`);

export const { update: updateAll, reset: resetAll } = generateThunkActions(
    ALL_CRUD_ACTIONS,
    ENDPOINT
);

export const allReducer = generateCrudReducer(ALL_CRUD_ACTIONS);
