import React, { useEffect, useMemo, useRef, useState } from 'react';
import qs from 'querystring';
import routes from 'routes';
import backendConstants from 'backendConstants';
import _ from 'lodash';
import { RefresherEventDetail } from '@ionic/core';
import * as generalUtils from 'utils/generalUtils';
import { SubHeader, TitleWrapper, Wrapper } from 'commons/commons.styles';
import languages from 'languages';
import {
  IonBadge,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonModal,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonHeader,
  IonInput,
} from '@ionic/react';
import Header from 'commons/Header/Header';
import language from 'languages';
import ShowContent from 'commons/ShowContent/ShowContent';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as confirmActions from 'store/confirm';
import * as invoiceClientActions from 'store/invoiceClient';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { TankInInvoiceItem } from './ManageInvoice.styles';
import { getStore } from '../../utils/sessionUtils';

export const INVOICE_CLIENT_TAB_KEYS = {
  NOW: 'NOW',
  HISTORY: 'HISTORY',
};

export const INVOICE_STATUS_COLORS = {
  [backendConstants.INVOICE_STATUSES.REQUEST]: 'danger',
  [backendConstants.INVOICE_STATUSES.APPROVED]: 'primary',
  [backendConstants.INVOICE_STATUSES.DELIVERING]: 'primary',
  [backendConstants.INVOICE_STATUSES.DELIVERED]: 'success',
  [backendConstants.INVOICE_STATUSES.CANCELLED]: 'medium',
};

export const InvoiceClientItem: React.FC<any> = props => {
  const { invoice, tanks, button, onClick } = props;
  const handleClick = (invoice: any) => {
    return button && onClick ? () => onClick(invoice) : undefined;
  };
  return invoice ? (
    <IonItem key={invoice._id} button={button} onClick={handleClick(invoice)}>
      <IonLabel className={`ion-text-wrap`}>
        <p>
          <IonText color={INVOICE_STATUS_COLORS[invoice.status]}>{languages.manageInvoice.ui.statuses[invoice.status]}</IonText>
        </p>
        <p>{generalUtils.getFormedIdx(invoice.invoiceIdx, 'PLT-')}</p>
        <IonText>
          {invoice.clientUserId && (
            <p>
              <IonText color={`primary`}>{invoice.clientUserId.name}</IonText>
            </p>
          )}
        </IonText>
        <p>{moment(invoice.updatedAt).format(`DD/MM/YYYY HH:mm`)}</p>
        {invoice.deliveryOption && <p>Giao vận: {languages.manageInvoice.ui.deliveryOptions[invoice.deliveryOption]}</p>}
        {[backendConstants.INVOICE_STATUSES.DELIVERING, backendConstants.INVOICE_STATUSES.DELIVERED].includes(
          invoice.status
        ) && (
          <React.Fragment>
            <IonText color={`primary`}>Người giao:</IonText>
            <p>{invoice.deliveryUserId.name}</p>
          </React.Fragment>
        )}
        {invoice.status === backendConstants.INVOICE_STATUSES.DELIVERED && (
          <React.Fragment>
            <IonText color={`primary`}>Thông tin giao hàng:</IonText>
            <p>{invoice.note}</p>
          </React.Fragment>
        )}
        {invoice.type === backendConstants.INVOICE_TYPES.DEMO && (
          <React.Fragment>
            <IonText color={`danger`}>Đơn xuất Demo</IonText>
            <p />
          </React.Fragment>
        )}
        {invoice.status === backendConstants.INVOICE_STATUSES.REQUEST && invoice.note && (
          <React.Fragment>
            <IonText color={`primary`}>Ghi chú / Số lượng bình:</IonText>
            <p>{invoice.note}</p>
          </React.Fragment>
        )}
        {invoice.status === backendConstants.INVOICE_STATUSES.REQUEST && invoice.ownerUser && invoice.ownerUser.name && (
          <React.Fragment>
            <IonText color={`primary`}>Tạo bởi:</IonText>
            <p>{invoice.ownerUser.name}</p>
          </React.Fragment>
        )}
        {tanks && !!tanks.length && (
          <div className={`ion-padding-vertical`}>
            <IonText color={`primary`}>Danh sách bình:</IonText>
            {tanks.map((tank: any) => (
              <TankInInvoiceItem key={tank.serial}>
                <p>{tank.serial}</p>
                <p>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</p>
              </TankInInvoiceItem>
            ))}
          </div>
        )}
      </IonLabel>
      {invoice.tanks && !!invoice.tanks.length && (
        <IonBadge slot={`end`} color={`primary`}>
          {invoice.tanks.length} Bình
        </IonBadge>
      )}
    </IonItem>
  ) : null;
};

export const ManageInvoiceClient: React.FC<any> = props => {
  const {
    // selfReducer,

    invoiceClientReducer,
    readClientInvoices,
    createClientInvoice,
    resetClientInvoices,

    setSubmitting,
    setConfirm,
    setToast,

    history,
    location,
  } = props;

  const queryParams = qs.parse(location.search.replace('?', ''));

  const invoices = invoiceClientReducer && invoiceClientReducer.responseRead && invoiceClientReducer.responseRead.data;

  const recentInvoices =
    invoices &&
    invoices.filter((invoice: any) => {
      const updatedAt = moment(invoice.updatedAt);
      return (
        [
          backendConstants.INVOICE_STATUSES.REQUEST,
          backendConstants.INVOICE_STATUSES.APPROVED,
          backendConstants.INVOICE_STATUSES.DELIVERING,
          backendConstants.INVOICE_STATUSES.DELIVERED,
        ].includes(invoice.status) &&
        updatedAt.isBefore(moment().endOf('day')) &&
        updatedAt.isAfter(moment().startOf('day'))
      );
    });

  const [invoiceTab, setInvoiceTab] = useState<string>((queryParams.invoiceTab as string) || INVOICE_CLIENT_TAB_KEYS.NOW);
  const [invoiceNote, setInvoiceNote] = useState<any>('');

  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);

  const handleInvoiceNote = (e: any) => {
    if (e && e.target) {
      setInvoiceNote(e.target.value);
    }
  };

  useEffect(() => {
    if (queryParams.invoiceTab) {
      setInvoiceTab(queryParams.invoiceTab as any);
    }
  }, [queryParams.invoiceTab]);

  const showContent = useMemo(() => {
    return location.pathname === routes.manageInvoice.main;
  }, [location.pathname]);

  const resetSelectedInvoice = (noReload?: any) => {
    setSelectedInvoice(null);
    if (!noReload) {
      resetCurrentInvoiceTab();
    }
  };

  const resetInvoiceNote = () => setInvoiceNote('');

  const handleSelectedInvoice = (invoice: any) => {
    setSelectedInvoice(invoice);
  };

  const [refreshing, setRefreshing] = useState(false);

  const resetCurrentInvoiceTab = () => {
    resetClientInvoices();
    resetInvoiceNote();
    // Reset Content Scroll
    resetContentScrollTop();
  };

  const handleReadInvoice = () => {
    return readClientInvoices({
      filter: {
        store: getStore(),
      },
    });
  };

  const handleInvoiceTabChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setInvoiceTab(event.detail.value);
      history.push(`${routes.manageInvoice.main}?invoiceTab=${event.detail.value}`);
    }
  };

  useEffect(() => {
    if (showContent) {
      resetCurrentInvoiceTab();
      handleReadInvoice();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event?: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    resetCurrentInvoiceTab();
    await handleReadInvoice();
    event && setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const handleRequestInvoice = () => {
    setConfirm(
      `
        <h6>
            Xác nhận gọi khí
        </h6>
      `,
      async () => {
        setSubmitting(true);
        try {
          await createClientInvoice({
            store: getStore(),
            note: invoiceNote || null,
          });
          await doRefresh();
          setToast(`Gọi khí thành công`, `primary`);
        } catch (error) {
          setToast(`Gọi khí không thành công`, `danger`);
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.manageInvoice.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleInvoiceTabChange} scrollable={true}>
              <IonSegmentButton value={INVOICE_CLIENT_TAB_KEYS.NOW} checked={invoiceTab === INVOICE_CLIENT_TAB_KEYS.NOW}>
                {language.manageInvoice.ui.tabs.NOW}
              </IonSegmentButton>
              <IonSegmentButton
                value={INVOICE_CLIENT_TAB_KEYS.HISTORY}
                checked={invoiceTab === INVOICE_CLIENT_TAB_KEYS.HISTORY}
              >
                {language.manageInvoice.ui.tabs.HISTORY}
              </IonSegmentButton>
            </IonSegment>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent} timeout={1000}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {invoiceClientReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    {invoiceTab === INVOICE_CLIENT_TAB_KEYS.NOW && (
                      <React.Fragment>
                        <div className={`ion-padding-vertical`}>
                          <IonButton
                            expand={`block`}
                            size={recentInvoices && recentInvoices.length ? `small` : `default`}
                            color={recentInvoices && recentInvoices.length ? `medium` : `primary`}
                            disabled={recentInvoices && recentInvoices.length && recentInvoices.length <= 5}
                            onClick={handleRequestInvoice}
                          >
                            Gọi khí
                          </IonButton>
                          {(!recentInvoices || !recentInvoices.length) && (
                            <IonItem>
                              <IonInput
                                placeholder={`Nhập ghi chú...`}
                                type={`text`}
                                value={invoiceNote}
                                onIonChange={handleInvoiceNote}
                              />
                            </IonItem>
                          )}
                        </div>
                        <IonList className={`ion-margin-vertical`}>
                          {recentInvoices && !!recentInvoices.length && (
                            <React.Fragment>
                              {recentInvoices.map((invoice: any) => (
                                <InvoiceClientItem key={invoice._id} invoice={invoice} tanks={invoice.tanks} />
                              ))}
                            </React.Fragment>
                          )}
                        </IonList>
                      </React.Fragment>
                    )}
                    {invoiceTab === INVOICE_CLIENT_TAB_KEYS.HISTORY && (
                      <IonList className={`ion-margin-vertical`}>
                        {invoices && !!invoices.length && (
                          <React.Fragment>
                            <IonListHeader>Lịch sử: {invoices.length} đơn hàng gần nhất</IonListHeader>
                            {invoices.map((invoice: any) => (
                              <InvoiceClientItem
                                key={invoice._id}
                                invoice={invoice}
                                button={[
                                  backendConstants.INVOICE_STATUSES.APPROVED,
                                  backendConstants.INVOICE_STATUSES.DELIVERING,
                                  backendConstants.INVOICE_STATUSES.DELIVERED,
                                ].includes(invoice.status)}
                                onClick={handleSelectedInvoice}
                              />
                            ))}
                          </React.Fragment>
                        )}
                      </IonList>
                    )}
                  </div>
                )}
              </div>
            </IonGrid>
            {selectedInvoice && (
              <IonModal isOpen={true} backdropDismiss={false} showBackdrop={true}>
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot={`start`}>
                      <IonButton onClick={resetSelectedInvoice}>Trở lại</IonButton>
                    </IonButtons>
                    <TitleWrapper>
                      <IonTitle>
                        <IonText color={INVOICE_STATUS_COLORS[selectedInvoice.status]}>
                          {generalUtils.getFormedIdx(selectedInvoice.invoiceIdx, 'PLT-')}
                        </IonText>
                      </IonTitle>
                    </TitleWrapper>
                  </IonToolbar>
                </IonHeader>
                <IonContent className={`ion-padding`}>
                  {selectedInvoice.tanks &&
                    selectedInvoice.tanks.map((tank: any) => (
                      <IonItem key={tank._id}>
                        <IonLabel className={`ion-text-wrap`}>
                          {tank.serial}
                          <p>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</p>
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonContent>
              </IonModal>
            )}
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  invoiceClientReducer: state.invoiceClientReducer,
  selfReducer: state.selfReducer,
});

const mapDispatchToProps = {
  setSubmitting: submittingActions.setSubmitting,
  setConfirm: confirmActions.setConfirm,
  setToast: toastActions.setToast,

  readClientInvoices: invoiceClientActions.readClientInvoices,
  resetClientInvoices: invoiceClientActions.resetClientInvoices,
  createClientInvoice: invoiceClientActions.createClientInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvoiceClient);
