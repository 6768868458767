import * as yup from 'yup';
import backendConstants from 'backendConstants';

export default yup.object().shape({
  name: yup.string().required(`Yêu cầu nhập tên khách hàng`),
  address: yup.string().required(`Yêu cầu nhập địa chỉ khách hàng`),
  province: yup
    .string()
    .oneOf(Object.keys(backendConstants.PROVINCES))
    .required(`Yêu cầu nhập tỉnh thành`),
  sector: yup
    .string()
    .oneOf(Object.keys(backendConstants.SECTORS))
    .required(`Yêu cầu chọn loại doanh nghiệp`),
  type: yup
    .string()
    .oneOf(Object.keys(backendConstants.CLIENT_TYPES))
    .required(`Yêu câu chọn loại khách hàng`),
  chargeType: yup
    .string()
    .oneOf(Object.keys(backendConstants.CLIENT_CHARGE_TYPES))
    .required(`Yêu cầu chọn phương thức thanh toán`),
  primaryContactName: yup.string().required(`Yêu cầu nhập tên người liên hệ`),
  primaryContactPosition: yup.string().required(`Yêu cầu nhập chức vụ`),
  primaryContactNumber: yup.string().required(`Yêu cầu nhập sổ điện thoại`),
  primaryContactEmail: yup
    .string()
    .email(`Yêu cầu nhập email hợp lệ`)
    .required(`Yêu cầu nhập email`),
  parentId: yup.string().required(`Yêu cầu chọn kinh doanh/chăm sóc`),
  status: yup
    .string()
    .oneOf(Object.keys(backendConstants.CLIENT_STATUSES))
    .required(`Yêu cầu chọn trạng thái khách hàng`),
  note: yup.string(),
});
