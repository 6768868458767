import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import qs from 'querystring';
import * as reportActions from 'store/report';
import * as userSaleActions from 'store/userSale';
import * as userDeliveryActions from 'store/userDelivery';
import * as userClientActions from 'store/userClient';
import * as clientActions from 'store/client';
import { getUIMode } from 'utils/uiUtils';

import {
  IonContent,
  IonPage,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonText,
  IonSearchbar,
  IonFab,
  IonRefresherContent,
  IonRefresher,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import Header from 'commons/Header/Header';
import languages from 'languages';
import moment from 'moment-timezone';
import * as tankActions from 'store/tank';
import * as equipmentActions from 'store/equipment';
import _ from 'lodash';
import ShowContent from 'commons/ShowContent/ShowContent';
import routes from 'routes';
import backendConstants from 'backendConstants';
import * as generalUtils from 'utils/generalUtils';
import { ListWrapper, SaleTitle } from './Report.styles';
import { Wrapper, SubHeader } from 'commons/commons.styles';
import DateRangePicker from 'commons/DateRangePicker/DateRangePicker';
import ReportClientItem from './Components/ReportClientItem';
import ReportSaleItem from './Components/ReportSaleItem';
import ReportModal from './Modals/ReportModal';
import ReportDeliveryItem from './Components/ReportDeliveryItem';
import { getStore, isCenterStore } from 'utils/sessionUtils';
import ReportEquipmentItem from './Components/ReportEquipmentItem';

export const BY_TABS = {
  CLIENT: 'CLIENT',
  SALE: 'SALE',
  DELIVERY: 'DELIVERY',
  EQUIPMENT: 'EQUIPMENT',
};

export const SORT_TYPES = {
  ..._.mapValues(backendConstants.EQUIPMENT_TYPES, (type: any) => Object.keys(backendConstants.EQUIPMENT_TYPES).indexOf(type)),
};

export const Report: React.FC<any> = props => {
  const {
    history,
    location,
    reportReducer,

    checkReport,
    resetReport,

    clientReducer,
    userClientReducer,
    userSaleReducer,
    userDeliveryReducer,

    checkClients,
    checkUserClients,
    checkUserSales,
    checkUserDeliveries,

    resetClients,
    resetUserClients,
    resetUserSales,
    resetUserDeliveries,
  } = props;

  const result = reportReducer && reportReducer.responseCheck;

  // const clients = clientReducer && clientReducer.responseCheck && clientReducer.responseCheck.data;

  const userClients = userClientReducer && userClientReducer.responseCheck && userClientReducer.responseCheck.data;

  const userSales = userSaleReducer && userSaleReducer.responseCheck && userSaleReducer.responseCheck.data;

  const userDeliveries = userDeliveryReducer && userDeliveryReducer.responseCheck && userDeliveryReducer.responseCheck.data;

  const isFetching =
    (reportReducer && reportReducer.isFetching) ||
    (clientReducer && clientReducer.isFetching) ||
    (userClientReducer && userClientReducer.isFetching) ||
    (userSaleReducer && userSaleReducer.isFetching) ||
    (userDeliveryReducer && userDeliveryReducer.isFetching);

  const queryParams = qs.parse(props.location.search.replace('?', ''));
  const [refreshing, setRefreshing] = useState(false);
  const [reportSearch, setReportSearch] = useState<string>('');
  const [byTab, setByTab] = useState<string>((queryParams.byTab as string) || BY_TABS.CLIENT);
  const [dateRange, setDateRange] = useState<any>(null);
  const [selectedEntry, setSelectedEntry] = useState<any>(null);

  const resetDateRange = () => setDateRange(null);
  const resetReportSearch = () => setReportSearch('');
  const resetSelectedEntry = () => setSelectedEntry(null);

  const resetReportByTab = (keepSearch?: boolean) => {
    resetReport();
    resetDateRange();
    !keepSearch && resetReportSearch();
    resetClients();
    resetUserClients();
    resetUserSales();
    resetUserDeliveries();
    resetContentScrollTop();
  };

  const handleReportSearch = (e: any) => e && e.target && setReportSearch(e.target.value);

  const handleByTab = (e: any) => {
    if (e && e.detail) {
      setByTab(e.detail.value);
      history.push(`${routes.report.main}?byTab=${e.detail.value}`);
    }
  };

  const handleSelectedEntry = (entry: any) => setSelectedEntry(entry);

  useEffect(() => {
    if (queryParams.byTab) {
      setByTab(queryParams.byTab as any);
    }
  }, [queryParams.byTab]);

  const showContent = useMemo(() => {
    return location.pathname === routes.report.main;
  }, [location.pathname]);

  useEffect(() => {
    if (showContent) {
      handleReadClient();
      handleReadUserClient();
      handleReadUserSale();
      handleReadUserDeliveries();
    } else {
      resetReportByTab(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent]);

  const handleDateRangeSubmit = (startDate: any, endDate: any) => {
    if (startDate) {
      setDateRange({ startDate, endDate });
    }
  };

  const handleReadReport = () => {
    return (
      dateRange &&
      dateRange.startDate &&
      dateRange.endDate &&
      checkReport({
        store: isCenterStore() ? undefined : getStore(),
        ...dateRange,
      })
    );
  };

  const handleReadUserSale = () =>
    checkUserSales({
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        userType: backendConstants.USER_TYPES.SALE,
      },
    });

  const handleReadUserClient = () =>
    checkUserClients({
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
    });

  const handleReadUserDeliveries = () =>
    checkUserDeliveries({
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        userType: backendConstants.USER_TYPES.DELIVERY,
      },
    });

  const handleReadClient = () =>
    checkClients({
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        status: backendConstants.CLIENT_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
    });

  useEffect(() => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      handleReadReport();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    handleReadClient();
    handleReadUserClient();
    handleReadUserSale();
    handleReadUserDeliveries();

    handleReadReport();

    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const toDates = (momentDate: any) => ({
    date: momentDate.format('DD/MM/YYYY'),
    dateHour: momentDate.format('DD/MM/YYYY HH'),
  });

  const clientResult = useMemo(() => {
    if (!userClients) return;
    return (
      result &&
      result.data &&
      result.data
        .map((client: any) => {
          return {
            _id: client._id,
            store: client.store,
            name: client.name,
            parentId: client.parentId,
            usingEquipments: client.usingEquipments,
            deliveredTanks:
              client &&
              client.deliveredInvoices &&
              _.flattenDeep(
                client.deliveredInvoices.map((userClient: any) =>
                  userClient.invoices.map((invoice: any) =>
                    invoice.tankSerials.map((serial: any) => ({
                      serial,
                      store: client.store,
                      isDemo: invoice.type === backendConstants.INVOICE_TYPES.DEMO,
                      ...toDates(moment(invoice.updatedAt).tz('Asia/Bangkok')),
                    }))
                  )
                )
              ),
            userClients:
              client &&
              client.deliveredInvoices &&
              client.deliveredInvoices
                .map((userClient: any) => {
                  const currentUserClient = userClients.find((item: any) => item._id === userClient._id);
                  return (
                    currentUserClient && {
                      _id: currentUserClient._id,
                      name: currentUserClient.name,
                      store: currentUserClient.store,
                      reportExcluded: currentUserClient.reportExcluded,
                      equipments: currentUserClient.equipments,
                      toStoreTanks: result.toStoreTanks
                        .filter((tank: any) => tank.clientUserId === currentUserClient._id)
                        .map((t: any) => ({
                          ...t,
                          ...toDates(moment(t.updatedAt).tz('Asia/Bangkok')),
                        })),
                      tanks: _.flatten(
                        userClient.invoices
                          ? userClient.invoices
                              .sort((a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt))
                              .map((invoice: any) =>
                                invoice.tankSerials.map((serial: any) => ({
                                  serial,
                                  store: currentUserClient.store,
                                  isDemo: invoice.type === backendConstants.INVOICE_TYPES.DEMO,
                                  ...toDates(moment(invoice.updatedAt).tz('Asia/Bangkok')),
                                }))
                              )
                          : []
                      ),
                    }
                  );
                })
                // FILTER OUT TEMPORARY STORE HERE
                .filter((userClient: any) => {
                  return userClient && userClient.reportExcluded !== backendConstants.USER_CLIENT_REPORT_EXCLUDED.YES;
                })
                .sort((a: any, b: any) => b.tanks.length - a.tanks.length),
          };
        })
        .filter(
          (client: any) =>
            client.userClients &&
            client.userClients.length &&
            ((client.usingEquipments && client.usingEquipments.length > 0) ||
              (client.deliveredTanks && client.deliveredTanks.length > 0))
        )
    );
  }, [result, userClients]);

  const clientByUserSale = useMemo(() => {
    return (
      userSales &&
      userSales
        .map((userSale: any) => ({
          _id: userSale._id,
          name: userSale.name,
          store: userSale.store,
          clients:
            clientResult &&
            clientResult
              .filter(
                (client: any) =>
                  client.parentId &&
                  client.parentId._id === userSale._id &&
                  client.name.toLowerCase().includes(reportSearch.toLowerCase())
              )
              .sort((a: any, b: any) => b.deliveredTanks.length - a.deliveredTanks.length),
        }))
        .sort((a: any, b: any) => ((b.clients && b.clients.length) || 0) - (a.clients && a.clients.length) || 0)
    );
  }, [clientResult, userSales, reportSearch]);

  const userSaleResult = useMemo(() => {
    if (!result || !result.toClientEquipments || !result.toStoreEquipments || !userSales) return;

    const toClientEquipmentsByUserSale = _.groupBy(result.toClientEquipments, i => i.userSaleId);
    const toStoreEquipmentsByUserSale = _.groupBy(result.toStoreEquipments, i => i.userSaleId);

    const availableUserSales = userSales.map((u: any) => u._id);

    return availableUserSales
      .map((userSaleId: any) => {
        const currentUserSale = userSales.find((userSale: any) => userSale._id === userSaleId);
        if (!currentUserSale) return null;

        const clientsFromCurrentUserSale = (
          clientByUserSale.find((u: any) => u._id === userSaleId) || {
            clients: [],
          }
        ).clients;

        return {
          ...currentUserSale,
          clients: clientsFromCurrentUserSale,
          tankCount: clientsFromCurrentUserSale.reduce(
            (totalCount: any, client: any) =>
              totalCount + ((client.deliveredTanks && client.deliveredTanks.filter((tank: any) => !tank.isDemo).length) || 0),
            0
          ),
          tankDemoCount: clientsFromCurrentUserSale.reduce(
            (totalCount: any, client: any) =>
              totalCount + ((client.deliveredTanks && client.deliveredTanks.filter((tank: any) => tank.isDemo).length) || 0),
            0
          ),
          toClientEquipments: _.groupBy(toClientEquipmentsByUserSale[userSaleId], i => i.status),
          toStoreEquipments: _.groupBy(toStoreEquipmentsByUserSale[userSaleId], i => i.status),
        };
      })
      .filter((i: any) => !!i)
      .sort((a: any, b: any) => b.tankCount - a.tankCount);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, userSales]);

  const userDeliveryResult = useMemo(() => {
    if (!result || !userDeliveries) return;

    const allInvoices = _.flattenDepth(
      result.data.map((client: any) =>
        client.deliveredInvoices.map((userClient: any) =>
          userClient.invoices.map((invoice: any) => ({
            ...invoice,
            clientUserInfo: userClients.find((userClient: any) => userClient._id === invoice.clientUserId),
          }))
        )
      ),
      2
    );
    return userDeliveries.map((userDelivery: any) => {
      const currentInvoices = allInvoices.filter((invoice: any) => invoice.deliveryUserId === userDelivery._id);
      return {
        ...userDelivery,
        deliveredInvoices: currentInvoices,
        deliveredTanks: _.flatten(currentInvoices.map((invoice: any) => invoice.tankSerials)),
        userClients: _.groupBy(currentInvoices, (i: any) => i.clientUserId),
      };
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, userDeliveries]);

  const newEquipments = useMemo(() => {
    return (
      result &&
      result.newEquipments && {
        name: `Sản xuất mới`,
        key: `newEquipments`,
        total: result.newEquipments.length,
        byType: _.groupBy(result.newEquipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const checkedEquipments = useMemo(() => {
    return (
      result &&
      result.checkedEquipments && {
        name: `Kiểm tra`,
        key: `checkedEquipments`,
        total: result.checkedEquipments.length,
        byType: _.groupBy(result.checkedEquipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const fixedEquipments = useMemo(() => {
    return (
      result &&
      result.fixedEquipments && {
        name: `Sửa lỗi`,
        key: `fixedEquipments`,
        total: result.fixedEquipments.length,
        byType: _.groupBy(result.fixedEquipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const maintainedEquipments = useMemo(() => {
    return (
      result &&
      result.maintainedEquipments && {
        name: `Bảo dưỡng`,
        key: `maintainedEquipments`,
        total: result.maintainedEquipments.length,
        byType: _.groupBy(result.maintainedEquipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const soldEquipments = useMemo(() => {
    const equipments =
      result &&
      result.toClientEquipments &&
      result.toClientEquipments.filter((equipment: any) => equipment.status === backendConstants.EQUIPMENT_STATUSES.SOLD);
    return (
      equipments && {
        name: `Bán`,
        key: `soldEquipments`,
        total: equipments.length,
        byType: _.groupBy(equipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const rentedEquipments = useMemo(() => {
    const equipments =
      result &&
      result.toClientEquipments &&
      result.toClientEquipments.filter((equipment: any) => equipment.status === backendConstants.EQUIPMENT_STATUSES.RENTED);
    return (
      equipments && {
        name: `Cho thuê`,
        key: `rentedEquipments`,
        total: equipments.length,
        byType: _.groupBy(equipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const lentEquipments = useMemo(() => {
    const equipments =
      result &&
      result.toClientEquipments &&
      result.toClientEquipments.filter((equipment: any) => equipment.status === backendConstants.EQUIPMENT_STATUSES.LENT);
    return (
      equipments && {
        name: `Cho mượn/Demo`,
        key: `lentEquipments`,
        total: equipments.length,
        byType: _.groupBy(equipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const checkingEquipments = useMemo(() => {
    const equipments =
      result &&
      result.toStoreEquipments &&
      result.toStoreEquipments.filter((equipment: any) => equipment.status === backendConstants.EQUIPMENT_STATUSES.CHECKING);
    return (
      equipments && {
        name: `Thu hồi`,
        key: `checkingEquipments`,
        total: equipments.length,
        byType: _.groupBy(equipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const fixingEquipments = useMemo(() => {
    const equipments =
      result &&
      result.toStoreEquipments &&
      result.toStoreEquipments.filter((equipment: any) => equipment.status === backendConstants.EQUIPMENT_STATUSES.FIXING);
    return (
      equipments && {
        name: `Lỗi`,
        key: `fixingEquipments`,
        total: equipments.length,
        byType: _.groupBy(equipments, (equipment: any) => equipment.type),
      }
    );
  }, [result]);

  const equipmentGroups = useMemo(
    () => [
      newEquipments,
      fixedEquipments,
      checkedEquipments,
      maintainedEquipments,
      soldEquipments,
      rentedEquipments,
      lentEquipments,
      fixingEquipments,
      checkingEquipments,
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      newEquipments,
      fixedEquipments,
      checkedEquipments,
      soldEquipments,
      rentedEquipments,
      lentEquipments,
      fixingEquipments,
      checkingEquipments,
    ]
  );

  return (
    <Wrapper>
      <IonPage>
        <Header title={languages.report.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleByTab} scrollable={true}>
              {Object.keys(BY_TABS).map(tabKey => (
                <IonSegmentButton key={tabKey} value={tabKey} checked={byTab === tabKey}>
                  {(languages.report.tabs as any)[tabKey]}
                </IonSegmentButton>
              ))}
            </IonSegment>
            <div className={getUIMode() === `ios` && byTab === BY_TABS.CLIENT ? `ion-padding-top` : `ion-padding-vertical`}>
              <DateRangePicker
                min={`2019-01-01`}
                max={`2222-01-01`}
                key={showContent}
                defaultValue={dateRange}
                startDate={dateRange && dateRange.startDate}
                endDate={dateRange && dateRange.endDate}
                disabled={refreshing || reportReducer.isFetching}
                onSubmit={handleDateRangeSubmit}
              />
            </div>
            {byTab === BY_TABS.CLIENT && (
              <div>
                <IonSearchbar
                  disabled={false}
                  placeholder={`Tìm kiếm theo tên ${(languages.report.tabs as any)[byTab].toLowerCase()}...`}
                  value={reportSearch}
                  onIonChange={handleReportSearch}
                  debounce={250}
                />
              </div>
            )}
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
              {/*<FabWrapper></FabWrapper>*/}
            </IonFab>
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <ListWrapper>
                    <IonList className={`ion-margin-vertical`}>
                      {result && dateRange ? (
                        <React.Fragment>
                          <IonListHeader>
                            Kết quả: {moment(dateRange.startDate).format('DD/MM/YYYY')}
                            {' - '}
                            {moment(dateRange.endDate).format('DD/MM/YYYY')}
                          </IonListHeader>
                          {byTab === BY_TABS.CLIENT &&
                            clientByUserSale &&
                            (clientByUserSale.every((userSale: any) => userSale.clients.length === 0) ? (
                              <IonGrid>
                                <IonRow className={`ion-padding ion-justify-content-center`}>
                                  <IonItem disabled>Không tìm thấy khách hàng</IonItem>
                                </IonRow>
                              </IonGrid>
                            ) : (
                              clientByUserSale.map(
                                (userSale: any) =>
                                  !!userSale.clients.length && (
                                    <div key={userSale._id}>
                                      <SaleTitle>
                                        <IonText>Kinh doanh/Chăm sóc</IonText>
                                        {userSale.name}: {userSale.clients && `${userSale.clients.length} khách hàng`}
                                      </SaleTitle>
                                      {userSale.clients &&
                                        userSale.clients.map((client: any) => (
                                          <ReportClientItem
                                            key={client._id}
                                            client={client}
                                            onSelectedClient={handleSelectedEntry}
                                            onSelectedUserClient={handleSelectedEntry}
                                          />
                                        ))}
                                    </div>
                                  )
                              )
                            ))}
                          {byTab === BY_TABS.SALE && (
                            <div>
                              {userSaleResult && !!userSaleResult.length ? (
                                userSaleResult.map((userSale: any) => <ReportSaleItem key={userSale._id} userSale={userSale} />)
                              ) : (
                                <IonGrid>
                                  <IonRow className={`ion-padding ion-justify-content-center`}>
                                    <IonItem disabled>Không có kết quả</IonItem>
                                  </IonRow>
                                </IonGrid>
                              )}
                            </div>
                          )}
                          {byTab === BY_TABS.DELIVERY && (
                            <div>
                              <IonItem lines={`none`}>
                                <h4>Đã nạp: {result && result.loadingTanks} lần</h4>
                              </IonItem>
                              {userDeliveryResult && !!userDeliveryResult.length ? (
                                userDeliveryResult.map((userDelivery: any) => (
                                  <ReportDeliveryItem
                                    key={userDelivery._id}
                                    userDelivery={userDelivery}
                                    onSelectedUserDelivery={handleSelectedEntry}
                                  />
                                ))
                              ) : (
                                <IonGrid>
                                  <IonRow className={`ion-padding ion-justify-content-center`}>
                                    <IonItem disabled>Không có kết quả</IonItem>
                                  </IonRow>
                                </IonGrid>
                              )}
                            </div>
                          )}
                          {byTab === BY_TABS.EQUIPMENT && (
                            <ReportEquipmentItem
                              equipmentGroups={equipmentGroups}
                              onSelectedEquipmentGroup={handleSelectedEntry}
                            />
                          )}
                        </React.Fragment>
                      ) : (
                        <IonGrid>
                          <IonRow className={`ion-padding ion-justify-content-center`}>
                            <IonItem disabled>Nhập thời gian báo cáo</IonItem>
                          </IonRow>
                        </IonGrid>
                      )}
                    </IonList>
                  </ListWrapper>
                )}
              </div>
              <ReportModal
                isOpen={selectedEntry}
                selectedEntry={selectedEntry}
                onClose={resetSelectedEntry}
                dateRange={dateRange}
                byTab={byTab}
              />
            </IonGrid>
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  reportReducer: state.reportReducer,
  clientReducer: state.clientReducer,
  userClientReducer: state.userClientReducer,
  userSaleReducer: state.userSaleReducer,
  userDeliveryReducer: state.userDeliveryReducer,
});

const mapDispatchToProps = {
  checkReport: reportActions.checkReport,
  resetReport: reportActions.resetReport,
  checkClients: clientActions.checkClients,
  checkUserClients: userClientActions.checkUserClients,
  checkUserSales: userSaleActions.checkUserSales,
  checkUserDeliveries: userDeliveryActions.checkUserDeliveries,
  checkTanks: tankActions.checkTanks,
  checkEquipments: equipmentActions.checkEquipments,
  resetClients: clientActions.resetClients,
  resetUserClients: userClientActions.resetUserClients,
  resetUserSales: userSaleActions.resetUserSales,
  resetUserDeliveries: userDeliveryActions.resetUserDeliveries,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
