import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/client`;
export const CLIENT_CRUD_ACTIONS = generateCrudActionTypes(`CLIENT_CRUD`);

export const {
  check: checkClients,
  read: readClients,
  create: createClient,
  update: updateClient,
  updateBatch: updateClients,
  resetState: resetClients,
} = generateThunkActions(CLIENT_CRUD_ACTIONS, ENDPOINT);

export const clientReducer = generateCrudReducer(CLIENT_CRUD_ACTIONS);
