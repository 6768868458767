import axios from 'axios';
import backendConstants from '../backendConstants';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
const { StatusBar } = Plugins;

export const getDefaultMode = () => {
  let defaultMode;
  if (isPlatform(`ios`)) {
    defaultMode = `ios`;
  }
  if (isPlatform(`android`)) {
    defaultMode = `md`;
  }
  return defaultMode;
};

export const setupStatusBar = () => {
  const mode = localStorage.getItem('pltMode') || getDefaultMode();
  const darkMode = localStorage.getItem('pltTheme');
  if (isPlatform(`ios`)) {
    if (mode === `ios`) {
      StatusBar.setStyle({
        style: darkMode === 'dark' ? StatusBarStyle.Dark : StatusBarStyle.Light,
      });
    }
    if (mode === `md`) {
      StatusBar.setStyle({
        style: StatusBarStyle.Dark,
      });
    }
  } else {
    // isPlatform(`android`)
    StatusBar.setStyle({
      style: darkMode === `dark` ? StatusBarStyle.Dark : StatusBarStyle.Light,
    });
    if (darkMode === `dark`) {
      if (mode === `md`) {
        StatusBar.setBackgroundColor({
          color: `#111111`,
        });
      }
    } else {
      StatusBar.setBackgroundColor({
        color: `#F0F0F0`,
      });
    }
  }
};

export const getUIMode = (): any => {
  const mode = localStorage.getItem('pltMode') || getDefaultMode();
  if (isPlatform(`ios`)) {
    return mode && mode !== `ios` ? `md` : `ios`;
  }
  if (isPlatform(`android`)) {
    return mode && mode !== `md` ? `ios` : `md`;
  }
  return mode;
};

export const setupNavigationBar = () => {};

export const getBackendVersion = async () => {
  const response = await axios({
    url: `${backendConstants.API_PATH}/version`,
    method: 'get',
  });
  if (response) {
    return response.data && response.data.version;
  }
};

export const getFrontendVersion = async () => {
  const response = await axios({
    baseURL: ``,
    url: `/frontend-version`,
    method: 'get',
  });
  if (response) {
    return response.data && response.data.version;
  }
}

export const appendVersionElement = (id: string, text: string) => {
  const element = document.createElement('div');
  element.style.display = 'none';
  element.setAttribute('id', id)
  element.textContent = text;
  document.body.appendChild(element);
}
