import backendConstants from '../backendConstants';

export const markRead = (notificationId: any) => (dispatch: any, getState: any) => {
  const { selfReducer } = getState();
  const { socket } = selfReducer;
  if (socket) {
    socket.emit(backendConstants.SOCKET_EVENTS.NOTIFICATION_MARK_READ, notificationId);
  }
};

export const markAllRead = () => (dispatch: any, getState: any) => {
  const { selfReducer } = getState();
  const { socket } = selfReducer;
  if (socket) {
    socket.emit(backendConstants.SOCKET_EVENTS.NOTIFICATION_MARK_ALL_READ);
  }
};

export const processNotification = (notification: any) => (dispatch: any) =>
  dispatch({
    type: notification.type,
    payload: notification.data,
  });

export const notificationReducer = (state: any = [], action: any) => {
  const { type } = action;
  switch (type) {
    case backendConstants.NOTIFICATION_DATA_TYPES.PUSH: {
      const notificationId = action && action.payload && action.payload._id;
      if (state.find((notification: any) => notification._id === notificationId)) {
        return state;
      } else {
        return [action.payload, ...state];
      }
    }
    case backendConstants.NOTIFICATION_DATA_TYPES.REPLACE: {
      const notificationId = action && action.payload && action.payload._id;
      const index = notificationId && state.findIndex((notification: any) => notification._id === notificationId);
      if (index >= 0) {
        return [...state.slice(0, index), action.payload, ...state.slice(index + 1)];
      } else {
        return state;
      }
    }
    case backendConstants.NOTIFICATION_DATA_TYPES.ALL: {
      return [...action.payload];
    }
    default: {
      return state;
    }
  }
};
