import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import qs from 'querystring';
import moment from 'moment-timezone';
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonGrid,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonRefresherContent,
  IonRefresher,
  IonRow,
  IonCol,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSearchbar,
  IonItemGroup,
  IonItemDivider,
  IonBadge, IonInfiniteScrollContent, IonInfiniteScroll,
} from '@ionic/react';
import * as generalUtils from 'utils/generalUtils';
import backendConstants from 'backendConstants';
import language from 'languages';
import Header from 'commons/Header/Header';
import TestSerialEquipmentList from './Components/TestSerialEquipmentList';

import routes from 'routes';

import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as confirmActions from 'store/confirm';

import * as userClientActions from 'store/userClient';
import * as userTechnicalActions from 'store/userTechnical';
import * as equipmentActions from 'store/equipment';

import { SubHeader, Wrapper } from 'commons/commons.styles';
import ShowContent from 'commons/ShowContent/ShowContent';
import { RefresherEventDetail } from '@ionic/core';
import { RootGroupWrapper, FirstGroupWrapper, SecondGroupWrapper } from './ManageEquipment.styles';
import { NoEquipmentPlaceholder } from './Components/NoEquipmentPlaceholder';
import { EquipmentItem } from './Components/EquipmentItem';
import EquipmentStatusChangeModal from './Modals/EquipmentStatusChangeModal';
import languages from 'languages';
import { arrowUp, arrowDown } from 'ionicons/icons';
import { EQUIPMENT_ACTIONS, EQUIPMENT_NAMES } from './ManageEquipment.constants';
import { getStore, isCenterStore } from 'utils/sessionUtils';

export const AT_CLIENT_SORT_OPTIONS = {
  TYPE: 'TYPE',
  STATUS: 'STATUS',
};

export const WARRANTY_STATUSES = {
  WARRANTY_VALID: 'WARRANTY_VALID',
  WARRANTY_EXPIRE: 'WARRANTY_EXPIRE',
  NO_WARRANTY: 'NO_WARRANTY',
};

export const TEST_SERIAL = 'TEST_SERIAL';
export const WARRANTY = 'WARRANTY';
export const DISABLED = 'DISABLED';
export const MIN_CHAR = 5;

export const SORT_REF = {
  [backendConstants.EQUIPMENT_TYPES.HOSPITAL]: 1,
  [backendConstants.EQUIPMENT_TYPES.CLINIC_BIG]: 2,
  [backendConstants.EQUIPMENT_TYPES.CLINIC_MEDIUM]: 3,
  [backendConstants.EQUIPMENT_TYPES.PLASMA_CARE]: 4,
  [backendConstants.EQUIPMENT_TYPES.OTHER]: 5,
  [backendConstants.EQUIPMENT_STATUSES.SOLD]: 1,
  [backendConstants.EQUIPMENT_STATUSES.RENTED]: 2,
  [backendConstants.EQUIPMENT_STATUSES.LENT]: 3,
  [WARRANTY_STATUSES.WARRANTY_VALID]: 1,
  [WARRANTY_STATUSES.WARRANTY_EXPIRE]: 2,
  [WARRANTY_STATUSES.NO_WARRANTY]: 3,
};

export const LABELS = {
  ...languages.manageEquipment.ui.statuses,
  ...languages.manageEquipment.ui.types,
  ...languages.manageEquipment.ui.warrantyStatuses,
};

export const GroupedEquipmentsList: React.FC<any> = props => {
  const { groupedEquipments, equipmentStatus, onSelectedEquipment } = props;
  const groups = useMemo(() => {
    return groupedEquipments
      ? Object.keys(groupedEquipments)
          .sort((keyA: string, keyB: string) => SORT_REF[keyA] - SORT_REF[keyB] || 0)
          .map((groupKey: any) => {
            return {
              name: groupKey,
              label: (LABELS as any)[groupKey],
              count: Object.keys(groupedEquipments[groupKey]).reduce(
                (total, group2ndKey) => total + groupedEquipments[groupKey][group2ndKey].length,
                0
              ),
              group2nds: Object.keys(groupedEquipments[groupKey])
                .sort((keyA: string, keyB: string) => SORT_REF[keyA] - SORT_REF[keyB] || 0)
                .map((group2ndKey: any) => ({
                  name: group2ndKey,
                  label: (LABELS as any)[group2ndKey],
                  equipments: groupedEquipments[groupKey][group2ndKey] || [],
                })),
            };
          })
      : [];
  }, [groupedEquipments]);
  const [groupExpanded, setGroupExpanded] = useState({});
  const [group2ndExpanded, setGroup2ndExpanded] = useState({});
  const handleExpandGroup = (groupId: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupId]: !(groupExpanded as any)[groupId],
    });
  const handleExpandGroup2nd = (groupId: string) => () =>
    setGroup2ndExpanded({
      ...group2ndExpanded,
      [groupId]: !(group2ndExpanded as any)[groupId],
    });
  return groups && groups.length > 0 ? (
    <IonList>
      <RootGroupWrapper>
        {groups.map((group: any) => (
          <IonItemGroup key={group.name}>
            <IonItemDivider onClick={handleExpandGroup(group.name)}>
              <IonLabel color={`primary`}>{group.label}</IonLabel>
              <IonBadge color={`primary`} slot={`end`}>
                {group.count}
              </IonBadge>
              <IonIcon icon={(groupExpanded as any)[group.name] ? arrowUp : arrowDown} slot={`end`} />
            </IonItemDivider>
            <FirstGroupWrapper>
              {(groupExpanded as any)[group.name] &&
                group.group2nds.map((group2nd: any) => (
                  <SecondGroupWrapper key={group2nd.name}>
                    <IonItemGroup>
                      <IonItemDivider onClick={handleExpandGroup2nd(group2nd.name)}>
                        <IonLabel>{group2nd.label}</IonLabel>
                        <IonBadge color={`secondary`} slot={`end`}>
                          {group2nd.equipments.length}
                        </IonBadge>
                        <IonIcon icon={(group2ndExpanded as any)[group2nd.name] ? arrowUp : arrowDown} slot={`end`} />
                      </IonItemDivider>
                      {(group2ndExpanded as any)[group2nd.name] &&
                        group2nd.equipments.map((equipment: any) => {
                          return (
                            <EquipmentItem
                              key={equipment.serial}
                              equipment={equipment}
                              equipmentStatus={equipmentStatus}
                              onSelectedEquipment={onSelectedEquipment}
                            />
                          );
                        })}
                    </IonItemGroup>
                  </SecondGroupWrapper>
                ))}
            </FirstGroupWrapper>
          </IonItemGroup>
        ))}
      </RootGroupWrapper>
    </IonList>
  ) : null;
};

export const NormalEquipmentList: React.FC<any> = props => {
  const { equipments, equipmentStatus, handleSetSelectedEquipment } = props;
  const [equipmentScrollCount, setEquipmentScrollCount] = useState(50);
  const handleEquipmentCountIncrement = (e: any) => {
    setEquipmentScrollCount(equipmentScrollCount + 50);
    e && e.target && e.target.complete();
  };
  return (equipments && !!equipments.length ? (
    <React.Fragment>
      {
        equipments.slice(0, equipmentScrollCount).map((equipment: any) => (
          <EquipmentItem
            key={equipment.serial}
            equipment={equipment}
            equipmentStatus={equipmentStatus}
            onSelectedEquipment={handleSetSelectedEquipment}
          />
        ))
      }
      <IonInfiniteScroll
        threshold={`300px`}
        onIonInfinite={handleEquipmentCountIncrement}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </React.Fragment>
  ) : (
    <NoEquipmentPlaceholder />
  ))
}

export const ManageEquipment: React.FC<any> = props => {
  const {
    userClientReducer,
    userTechnicalReducer,
    equipmentReducer,
    selfReducer,

    // setConfirm,
    setSubmitting,
    setToast,

    checkUserClients,
    checkUserTechnicals,

    // checkEquipments,
    readEquipments,
    updateEquipment,
    // updateEquipments,
    resetEquipments,

    createEquipment,

    history,
    location,
  } = props;

  const queryParams = qs.parse(props.location.search.replace('?', ''));
  const showContent = useMemo(() => {
    return location.pathname === routes.manageEquipment.main;
  }, [location.pathname]);

  const [refreshing, setRefreshing] = useState(false);
  const [equipmentStatus, setEquipmentStatus] = useState<string>(
    (queryParams.equipmentStatus as string) || backendConstants.EQUIPMENT_STATUSES.READY_TO_USE
  );

  const [equipmentSearch, setEquipmentSearch] = useState<string>('');
  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);
  const [selectedUserClient, setSelectedUserClient] = useState<any>(null);
  const [atClientSort, setAtClientSort] = useState(AT_CLIENT_SORT_OPTIONS.TYPE);

  const userClients = userClientReducer.responseCheck && userClientReducer.responseCheck.data;

  const userTechnicals = userTechnicalReducer.responseCheck && userTechnicalReducer.responseCheck.data;

  const equipments = equipmentReducer.responseRead && equipmentReducer.responseRead.data;

  const updatedEquipments = equipmentReducer.responseUpdate && equipmentReducer.responseUpdate.data;

  const self = selfReducer.response;

  const equipmentsBySearch = useMemo(() => {
    if (self === null) {
      console.log(updatedEquipments);
    }

    return equipments && equipments.length
      ? equipments.filter((equipment: any) => {
          const matchEquipmentSerial = (equipment.serial as string).toLowerCase().includes(equipmentSearch.toLowerCase());

          if (equipmentStatus === EQUIPMENT_ACTIONS.CHECKING_FIXING) {
            return (
              matchEquipmentSerial &&
              [backendConstants.EQUIPMENT_STATUSES.CHECKING, backendConstants.EQUIPMENT_STATUSES.FIXING].includes(
                equipment.status
              )
            );
          }

          if (equipmentStatus === EQUIPMENT_ACTIONS.SOLD_RENTED_LENT) {
            return (
              matchEquipmentSerial &&
              [
                backendConstants.EQUIPMENT_STATUSES.SOLD,
                backendConstants.EQUIPMENT_STATUSES.RENTED,
                backendConstants.EQUIPMENT_STATUSES.LENT,
              ].includes(equipment.status)
            );
          }

          if (equipmentStatus === WARRANTY) {
            return matchEquipmentSerial && [backendConstants.EQUIPMENT_STATUSES.SOLD].includes(equipment.status);
          }

          if (equipmentStatus === backendConstants.EQUIPMENT_STATUSES.READY_TO_USE) {
            return matchEquipmentSerial && [backendConstants.EQUIPMENT_STATUSES.READY_TO_USE].includes(equipment.status);
          }

          if (equipmentStatus === DISABLED) {
            return matchEquipmentSerial && [backendConstants.EQUIPMENT_STATUSES.DISABLED].includes(equipment.status);
          }

          if (equipmentStatus === TEST_SERIAL) {
            return (
              equipmentSearch.length >= MIN_CHAR &&
              (matchEquipmentSerial ||
                (equipment.clientUserId && equipment.clientUserId.name.toLowerCase().includes(equipmentSearch.toLowerCase())))
            );
          }
          return false;
        })
      : [];
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipments, equipmentStatus, equipmentSearch]);

  const atClientEquipments = useMemo(() => {
    return equipmentsBySearch
      ? equipmentsBySearch.filter((equipment: any) =>
          [
            backendConstants.EQUIPMENT_STATUSES.SOLD,
            backendConstants.EQUIPMENT_STATUSES.RENTED,
            backendConstants.EQUIPMENT_STATUSES.LENT,
          ].includes(equipment.status)
        )
      : [];
  }, [equipmentsBySearch]);

  const atClientEquipmentsByType = useMemo(() => {
    const sortByClientName = atClientEquipments.sort(
      (a: any, b: any) => a.clientUserId && b.clientUserId && a.clientUserId.name.localeCompare(b.clientUserId.name)
    );
    const groupedByType = _.groupBy(sortByClientName, (equipment: any) => equipment.type);
    const groupedByTypeByStatus = _.mapValues(groupedByType, (equipmentsByType: any) =>
      _.groupBy(equipmentsByType, (equipment: any) => equipment.status)
    );
    return groupedByTypeByStatus;
  }, [atClientEquipments]);

  const atClientEquipmentsByStatus = useMemo(() => {
    const sortByClientName = atClientEquipments.sort(
      (a: any, b: any) => a.clientUserId && b.clientUserId && a.clientUserId.name.localeCompare(b.clientUserId.name)
    );
    const groupedByStatus = _.groupBy(sortByClientName, (equipment: any) => equipment.status);
    const groupedByStatusByType = _.mapValues(groupedByStatus, (equipmentsByStatus: any) =>
      _.groupBy(equipmentsByStatus, (equipment: any) => equipment.type)
    );
    return groupedByStatusByType;
  }, [atClientEquipments]);

  const atClientEquipmentsByWarranty = useMemo(() => {
    const sortByClientName = atClientEquipments
      .filter((equipment: any) => equipment.status === backendConstants.EQUIPMENT_STATUSES.SOLD)
      .sort((a: any, b: any) => a.clientUserId && b.clientUserId && a.clientUserId.name.localeCompare(b.clientUserId.name));
    const groupedByStatus = _.groupBy(sortByClientName, (equipment: any) => {
      if (equipment.warrantyOption === backendConstants.EQUIPMENT_WARRANTY_OPTIONS.YES) {
        if (equipment.warrantyStartDate) {
          const isExpired = moment(equipment.warrantyStartDate)
            .add(backendConstants.EQUIPMENT_WARRANTY_MONTH, 'month')
            .isBefore(moment());
          return isExpired ? WARRANTY_STATUSES.WARRANTY_EXPIRE : WARRANTY_STATUSES.WARRANTY_VALID;
        }
      }
      return WARRANTY_STATUSES.NO_WARRANTY;
    });
    const groupedByStatusByType = _.mapValues(groupedByStatus, (equipmentsByStatus: any) =>
      _.groupBy(equipmentsByStatus, (equipment: any) => equipment.type)
    );
    return groupedByStatusByType;
  }, [atClientEquipments]);

  const handleEquipmentStatusChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setEquipmentStatus(event.detail.value);
      history.push(`${routes.manageEquipment.main}?equipmentStatus=${event.detail.value}`);
    }
  };

  const handleAtClientEquipmentSortChange = (event: any) => {
    if (event && event.target && event.target.value) {
      setAtClientSort(event.target.value);
    }
  };

  useEffect(() => {
    if (queryParams.equipmentStatus) {
      setEquipmentStatus(queryParams.equipmentStatus as any);
    }
  }, [queryParams.equipmentStatus]);

  const handleSearchChange = (event: any) => {
    event && event.target && setEquipmentSearch(event.target.value);
  };

  const resetSelectedEquipment = () => setSelectedEquipment(null);
  const resetSelectedUserClient = () => setSelectedUserClient(null);
  const resetEquipmentSearch = () => setEquipmentSearch('');
  const resetAtClientSort = () => setAtClientSort(AT_CLIENT_SORT_OPTIONS.TYPE);

  const handleSetSelectedEquipment = (equipment: any, action: any) => {
    setSelectedEquipment({
      ...equipment,
      submitAction: action,
    });
  };

  const resetCurrentEquipmentStatusTab = (keepSearch?: boolean) => {
    resetEquipments();
    resetSelectedEquipment();
    resetSelectedUserClient();
    resetAtClientSort();
    !keepSearch && resetEquipmentSearch();
    // Reset Content Scroll
    resetContentScrollTop();
  };

  useEffect(() => {
    const currentUserClient =
      userClients &&
      userClients.find((userClient: any) => {
        return selectedEquipment && selectedEquipment.clientUserId && selectedEquipment.clientUserId._id === userClient._id;
      });
    if (currentUserClient) {
      setSelectedUserClient(currentUserClient);
    } else {
      resetSelectedUserClient();
    }
  }, [selectedEquipment, userClients]);

  const handleReadUserClient = () => {
    return checkUserClients({
      page: 1,
      pageSize: 99999,
      filter: {
        store: equipmentStatus === TEST_SERIAL && isCenterStore() ? undefined : getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
      populate: {
        path: 'clientId',
        select: '_id name type status',
      },
      aggregationOptions: {
        keepObject: true,
      },
    });
  };

  const handleReadUserTechnical = () => {
    return checkUserTechnicals({
      page: 1,
      pageSize: 99999,
      filter: {
        store: equipmentStatus === TEST_SERIAL && isCenterStore() ? undefined : getStore(),
        userType: backendConstants.USER_TYPES.TECHNICAL,
      },
    });
  };

  const handleReadEquipment = () => {
    let filter: any = {
      store: equipmentStatus === TEST_SERIAL && isCenterStore() ? undefined : getStore(),
    };
    if ([EQUIPMENT_ACTIONS.SOLD_RENTED_LENT, TEST_SERIAL].includes(equipmentStatus)) {
      filter = {
        ...filter,
        status: {
          $ne: backendConstants.EQUIPMENT_STATUSES.TRASH,
        },
      };
    }
    if ([EQUIPMENT_ACTIONS.CHECKING_FIXING, backendConstants.EQUIPMENT_STATUSES.READY_TO_USE].includes(equipmentStatus)) {
      filter = {
        ...filter,
        status:
          equipmentStatus === EQUIPMENT_ACTIONS.CHECKING_FIXING
            ? {
                $in: [backendConstants.EQUIPMENT_STATUSES.CHECKING, backendConstants.EQUIPMENT_STATUSES.FIXING],
              }
            : equipmentStatus,
      };
    }
    return readEquipments({
      page: 1,
      pageSize: 99999,
      filter,
      populate: [
        {
          path: 'clientUserId',
          select: '_id name clientId',
          populate: 'clientId',
        },
      ],
      sort: {
        updatedAt: 1,
      },
    });
  };

  // FOR NORMAL TAB - SEARCH IMMEDIATELY
  useEffect(() => {
    if (showContent) {
      resetCurrentEquipmentStatusTab(equipmentStatus === TEST_SERIAL);
      handleReadEquipment();
      handleReadUserClient();
      handleReadUserTechnical();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentStatus, showContent]);

  const handleModalClose = (noReload: boolean) => {
    if (!noReload) {
      resetCurrentEquipmentStatusTab(equipmentStatus === TEST_SERIAL);
      handleReadEquipment();
      handleReadUserClient();
      handleReadUserTechnical();
    }
  };

  const closeUpdateModalDebounced = _.debounce((isSuccessful: boolean, doneMessage: string, failedMessage: string) => {
    if (isSuccessful) {
      setToast(doneMessage, `primary`);
    } else {
      setToast(failedMessage, `danger`);
    }
    handleModalClose(false);
    setSubmitting(false);
  }, 500);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    resetCurrentEquipmentStatusTab(true);
    await handleReadEquipment();
    await handleReadUserClient();
    await handleReadUserTechnical();
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const handleSubmit = async (equipmentToSubmit: any) => {
    const submitData: any = {
      serial: equipmentToSubmit.serial,
    };
    let doneMessage = ``;
    let failedMessage = ``;

    if (equipmentToSubmit.submitAction === EQUIPMENT_ACTIONS.CREATE) {
      submitData.status = backendConstants.EQUIPMENT_STATUSES.READY_TO_USE;
      submitData.type = equipmentToSubmit.submitOptions.optionEquipmentType;
      submitData.name = EQUIPMENT_NAMES[submitData.type] || `Máy PlasmaMED`;
      submitData.tankType = equipmentToSubmit.submitOptions.optionEquipmentTankType;
    }

    if (equipmentToSubmit.submitAction === EQUIPMENT_ACTIONS.DISABLED) {
      submitData.status = backendConstants.EQUIPMENT_STATUSES.DISABLED;
    }

    if (
      [EQUIPMENT_ACTIONS.RECOVERED, EQUIPMENT_ACTIONS.CHECKED_READY_TO_USE, EQUIPMENT_ACTIONS.FIXED_READY_TO_USE].includes(
        equipmentToSubmit.submitAction
      )
    ) {
      submitData.status = backendConstants.EQUIPMENT_STATUSES.READY_TO_USE;
    }

    if (
      [EQUIPMENT_ACTIONS.SOLD_RENTED_LENT, EQUIPMENT_ACTIONS.EDIT_SOLD_RENTED_LENT].includes(equipmentToSubmit.submitAction)
    ) {
      submitData.clientUserId = equipmentToSubmit.submitOptions.userClientId || equipmentToSubmit.clientUserId._id;
      submitData.status = equipmentToSubmit.submitOptions.optionToUserClient;

      submitData.warrantyOption = null;
      submitData.warrantyStartDate = null;

      if (equipmentToSubmit.submitOptions.optionToUserClient === backendConstants.EQUIPMENT_STATUSES.SOLD) {
        submitData.warrantyOption = equipmentToSubmit.submitOptions.optionWarranty;
        if (equipmentToSubmit.submitOptions.optionWarranty === backendConstants.EQUIPMENT_WARRANTY_OPTIONS.YES) {
          submitData.warrantyStartDate = moment()
            .utc()
            .format();
        }
      }

      submitData.maintainOption = equipmentToSubmit.submitOptions.optionCanMaintain;

      if (submitData.maintainOption === backendConstants.EQUIPMENT_MAINTAIN_OPTIONS.YES && !equipmentToSubmit.maintainedAt) {
        submitData.maintainedAt = moment()
          .utc()
          .toDate();
      }
      if (submitData.maintainOption === backendConstants.EQUIPMENT_MAINTAIN_OPTIONS.NO) {
        submitData.maintainedAt = null;
      }
    }

    doneMessage = (languages.manageEquipment.ui.doneActions as any)[equipmentToSubmit.submitAction];

    failedMessage = (languages.manageEquipment.ui.failActions as any)[equipmentToSubmit.submitAction];

    if (equipmentToSubmit.submitAction === EQUIPMENT_ACTIONS.CHECKING_FIXING) {
      submitData.status = equipmentToSubmit.submitOptions.optionReason || backendConstants.EQUIPMENT_STATUSES.CHECKING;

      doneMessage = (languages.manageEquipment.ui.doneActions as any)[submitData.status];

      failedMessage = (languages.manageEquipment.ui.failActions as any)[submitData.status];
    }

    setSubmitting(true);
    if (Object.keys(submitData).length >= 2) {
      try {
        await (equipmentToSubmit.submitAction === EQUIPMENT_ACTIONS.CREATE ? createEquipment : updateEquipment)({
          store: getStore(),
          ...submitData,
        });
        closeUpdateModalDebounced(true, doneMessage, failedMessage);
      } catch (err) {
        closeUpdateModalDebounced(false, doneMessage, failedMessage);
      }
    } else {
      closeUpdateModalDebounced(true, doneMessage, failedMessage);
    }
  };

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.manageEquipment.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleEquipmentStatusChange} scrollable={true}>
              <IonSegmentButton
                value={EQUIPMENT_ACTIONS.CHECKING_FIXING}
                checked={equipmentStatus === EQUIPMENT_ACTIONS.CHECKING_FIXING}
              >
                {language.manageEquipment.ui.tabs[EQUIPMENT_ACTIONS.CHECKING_FIXING]}
              </IonSegmentButton>
              <IonSegmentButton
                value={backendConstants.EQUIPMENT_STATUSES.READY_TO_USE}
                checked={equipmentStatus === backendConstants.EQUIPMENT_STATUSES.READY_TO_USE}
              >
                {language.manageTank.ui.tabs[backendConstants.EQUIPMENT_STATUSES.READY_TO_USE]}
              </IonSegmentButton>
              <IonSegmentButton
                value={EQUIPMENT_ACTIONS.SOLD_RENTED_LENT}
                checked={equipmentStatus === EQUIPMENT_ACTIONS.SOLD_RENTED_LENT}
              >
                {language.manageEquipment.ui.tabs[EQUIPMENT_ACTIONS.SOLD_RENTED_LENT]}
              </IonSegmentButton>
              <IonSegmentButton value={WARRANTY} checked={equipmentStatus === WARRANTY}>
                {language.manageEquipment.ui.tabs[WARRANTY]}
              </IonSegmentButton>
              <IonSegmentButton value={TEST_SERIAL} checked={equipmentStatus === TEST_SERIAL}>
                {language.manageTank.ui.tabs[TEST_SERIAL]}
              </IonSegmentButton>
              <IonSegmentButton value={DISABLED} checked={equipmentStatus === DISABLED}>
                {language.manageEquipment.ui.tabs[DISABLED]}
              </IonSegmentButton>
            </IonSegment>
            <div>
              <IonSearchbar
                disabled={equipmentReducer.isFetching}
                placeholder={equipmentStatus === TEST_SERIAL ? `Tìm theo serial hoặc Khoa/TK...` : `Tìm theo serial máy...`}
                value={equipmentSearch}
                onIonChange={handleSearchChange}
                debounce={250}
              />
              {equipmentStatus === EQUIPMENT_ACTIONS.SOLD_RENTED_LENT && (
                <IonSegment onIonChange={handleAtClientEquipmentSortChange}>
                  <IonSegmentButton value={AT_CLIENT_SORT_OPTIONS.TYPE} checked={atClientSort === AT_CLIENT_SORT_OPTIONS.TYPE}>
                    Loại máy
                  </IonSegmentButton>
                  <IonSegmentButton
                    value={AT_CLIENT_SORT_OPTIONS.STATUS}
                    checked={atClientSort === AT_CLIENT_SORT_OPTIONS.STATUS}
                  >
                    Dạng xuất
                  </IonSegmentButton>
                </IonSegment>
              )}
            </div>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            {equipmentStatus !== TEST_SERIAL && (
              <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
                <IonRefresherContent />
              </IonRefresher>
            )}
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {equipmentReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    <IonList className={`ion-margin-vertical`}>
                      {equipments && !!equipments.length && (
                        <IonListHeader>
                          Kết quả: {equipmentsBySearch && equipmentsBySearch.length}
                          {' máy'}
                        </IonListHeader>
                      )}
                    </IonList>
                    {equipmentStatus === EQUIPMENT_ACTIONS.CHECKING_FIXING && (
                      <NormalEquipmentList
                        equipments={equipmentsBySearch}
                        equipmentStatus={equipmentStatus}
                        handleSetSelectedEquipment={handleSetSelectedEquipment}
                      />
                    )}
                    {equipmentStatus === backendConstants.EQUIPMENT_STATUSES.READY_TO_USE && (
                      <NormalEquipmentList
                        equipments={equipmentsBySearch}
                        handleSetSelectedEquipment={handleSetSelectedEquipment}
                      />
                    )}
                    {equipmentStatus === TEST_SERIAL && (
                      <TestSerialEquipmentList
                        equipmentStatus={equipmentStatus}
                        equipments={equipmentsBySearch}
                        equipmentSearch={equipmentSearch}
                        setSubmitting={setSubmitting}
                        onActionModalConfirm={closeUpdateModalDebounced}
                        onSelectedEquipment={handleSetSelectedEquipment}
                      />
                    )}
                    {equipmentStatus === EQUIPMENT_ACTIONS.SOLD_RENTED_LENT && (
                      <React.Fragment>
                        {atClientSort === AT_CLIENT_SORT_OPTIONS.TYPE && (
                          <GroupedEquipmentsList
                            groupedEquipments={atClientEquipmentsByType}
                            equipmentStatus={equipmentStatus}
                            onSelectedEquipment={handleSetSelectedEquipment}
                          />
                        )}
                        {atClientSort === AT_CLIENT_SORT_OPTIONS.STATUS && (
                          <GroupedEquipmentsList
                            groupedEquipments={atClientEquipmentsByStatus}
                            equipmentStatus={equipmentStatus}
                            onSelectedEquipment={handleSetSelectedEquipment}
                          />
                        )}
                      </React.Fragment>
                    )}
                    {equipmentStatus === WARRANTY && (
                      <GroupedEquipmentsList
                        groupedEquipments={atClientEquipmentsByWarranty}
                        equipmentStatus={equipmentStatus}
                        onSelectedEquipment={handleSetSelectedEquipment}
                      />
                    )}
                    {equipmentStatus === DISABLED && (
                      <NormalEquipmentList
                        equipments={equipmentsBySearch}
                        handleSetSelectedEquipment={handleSetSelectedEquipment}
                      />
                    )}
                  </div>
                )}
              </div>
            </IonGrid>
            <EquipmentStatusChangeModal
              isOpen={selectedEquipment}
              onClose={resetSelectedEquipment}
              selectedEquipment={selectedEquipment}
              selectedUserClient={selectedUserClient}
              userClients={userClients}
              userTechnicals={userTechnicals}
              onConfirm={handleSubmit}
            />
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  equipmentReducer: state.equipmentReducer,
  userClientReducer: state.userClientReducer,
  userTechnicalReducer: state.userTechnicalReducer,
  selfReducer: state.selfReducer,
});

const mapDispathToProps = {
  setConfirm: confirmActions.setConfirm,
  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,

  checkUserClients: userClientActions.checkUserClients,
  checkUserTechnicals: userTechnicalActions.checkUserTechnicals,

  checkEquipments: equipmentActions.checkEquipments,
  readEquipments: equipmentActions.readEquipments,
  updateEquipment: equipmentActions.updateEquipment,
  updateEquipments: equipmentActions.updateEquipments,
  resetEquipments: equipmentActions.resetEquipments,

  createEquipment: equipmentActions.createEquipment,
};

export default connect(mapStateToProps, mapDispathToProps)(ManageEquipment);
