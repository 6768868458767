import {
  Plugins,
  // PushNotification,
  PushNotificationToken,
  // PushNotificationActionPerformed
} from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import routes from '../routes';

const { PushNotifications } = Plugins;

export const getDeviceNativeToken = () => {
  if (isPlatform(`ios`) || isPlatform(`android`)) {
    PushNotifications.requestPermission().then(async (result: any) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register();
      } else {
        // Show some error
      }
    });
  }
};

export const setupNativeNotification = (setNativeToken: any, history: any) => {
  if (isPlatform(`ios`) || isPlatform(`android`)) {
    getDeviceNativeToken();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token: PushNotificationToken) => {
      // alert('Push registration success, token: ' + token.value);
      console.log('Push registration success, token: ', token);
      setNativeToken && setNativeToken(token.value);
    });

    // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError',
    //   (error: any) => {
    //     alert('Error on registration: ' + JSON.stringify(error));
    //   }
    // );

    // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener('pushNotificationReceived',
    //   (notification: PushNotification) => {
    //     alert('Push received: ' + JSON.stringify(notification));
    //   }
    // );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (
        // notification: PushNotificationActionPerformed
      ) => {
        setTimeout(() => {
          history.push(routes.notification);
        });
        // alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
};
