import React from 'react';
import { PlaceHolderWrapper } from 'commons/commons.styles';
import { IonBadge, IonButton, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import languages from 'languages';
import { TEST_SERIAL } from '../ManageTank.constants';
import { getStore } from 'utils/sessionUtils';
import backendConstants from 'backendConstants';

export const NoTankPlaceholder: React.FC<any> = props => {
  const { tankStatus, tankSearch, onCreateTank } = props;

  const handleCreateTank = () => {
    onCreateTank && onCreateTank(tankSearch);
  };

  return (
    <PlaceHolderWrapper>
      <IonGrid>
        <IonRow className={`ion-padding ion-justify-content-center`}>
          <IonItem disabled>{languages.manageTank.ui.noTank}</IonItem>
        </IonRow>
        {tankStatus === TEST_SERIAL && (
          <React.Fragment>
            {tankSearch && tankSearch.length === 7 && getStore() === backendConstants.CENTER_STORE && (
              <IonRow className={`ion-padding ion-justify-content-center`}>
                <IonButton onClick={handleCreateTank}>
                  <IonLabel>Tạo vỏ mới: </IonLabel>
                  <IonBadge color={`light`}>{tankSearch.toUpperCase()}</IonBadge>
                </IonButton>
              </IonRow>
            )}
            <IonRow className={`ion-padding ion-justify-content-center`}>
              <IonItem disabled>Lưu ý: Nhập 7 kí tự</IonItem>
            </IonRow>
          </React.Fragment>
        )}
      </IonGrid>
    </PlaceHolderWrapper>
  );
};
