import React from 'react';
import { defaultMemoize } from 'reselect';
import PropTypes from 'prop-types';
import { IonList, IonItem, IonListHeader, IonIcon, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';

import { SelectWrapper } from './styles';
import { Field } from 'formik';
import { checkmark } from 'ionicons/icons';

export const SelectField: React.FC<any> = props => {
  const { fieldName, title, placeholder, options, disabled } = props;
  const handleSelectedOption = (formikProps: any, fieldName: any) => (event: any) => {
    if (formikProps && formikProps.setFieldValue && fieldName && event && event.target) {
      formikProps.setFieldValue(fieldName, event.target.value);
    }
  };

  const getSelectedLabel = defaultMemoize((value: string) => {
    const foundItem = options.find((option: any) => {
      return value === option.key;
    });
    return foundItem && foundItem.label;
  });

  return (
    <Field name={fieldName}>
      {(fieldProps: any) => {
        return (
          <SelectWrapper>
            <IonList>
              <IonListHeader>
                <IonLabel>
                  {title} <IonText color={`danger`}>*</IonText>
                  {((fieldProps.form.dirty && fieldProps.field.value) || fieldProps.meta.touched) && !fieldProps.meta.error && (
                    <IonIcon color={`primary`} icon={checkmark} slot={`end`} />
                  )}
                </IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel>{getSelectedLabel(fieldProps.field.value) || placeholder}</IonLabel>
                <IonSelect
                  disabled={disabled}
                  onIonChange={handleSelectedOption(fieldProps.form, fieldName)}
                  value={fieldProps.field.value}
                  selectedText={fieldProps.field.value}
                >
                  {options.map((option: any) => (
                    <IonSelectOption key={option.key} value={option.key}>
                      {option.label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonList>
            {((fieldProps.form.dirty && fieldProps.field.value) || fieldProps.meta.touched) && fieldProps.meta.error && (
              <IonItem lines={`none`}>
                <IonText color={`danger`}>{fieldProps.meta.error}</IonText>
              </IonItem>
            )}
          </SelectWrapper>
        );
      }}
    </Field>
  );
};

SelectField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectField;
