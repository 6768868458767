import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/invoice/client`;
export const INVOICE_CLIENT_CRUD_ACTIONS = generateCrudActionTypes(`INVOICE_CLIENT_CRUD`);

export const { read: readClientInvoices, create: createClientInvoice, resetState: resetClientInvoices } = generateThunkActions(
  INVOICE_CLIENT_CRUD_ACTIONS,
  ENDPOINT
);

export const invoiceClientReducer = generateCrudReducer(INVOICE_CLIENT_CRUD_ACTIONS);
