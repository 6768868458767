export default {
  SG: { id: '1', code: 'SG', name: 'HCM' },
  HN: { id: '2', code: 'HN', name: 'Hà Nội' },
  DDN: { id: '3', code: 'DDN', name: 'Đà Nẵng' },
  BD: { id: '4', code: 'BD', name: 'Bình Dương' },
  DNA: { id: '5', code: 'DNA', name: 'Đồng Nai' },
  KH: { id: '6', code: 'KH', name: 'Khánh Hòa' },
  HP: { id: '7', code: 'HP', name: 'Hải Phòng' },
  LA: { id: '8', code: 'LA', name: 'Long An' },
  QNA: { id: '9', code: 'QNA', name: 'Quảng Nam' },
  VT: { id: '10', code: 'VT', name: 'Bà Rịa Vũng Tàu' },
  DDL: { id: '11', code: 'DDL', name: 'Đắk Lắk' },
  CT: { id: '12', code: 'CT', name: 'Cần Thơ' },
  BTH: { id: '13', code: 'BTH', name: 'Bình Thuận  ' },
  LDD: { id: '14', code: 'LDD', name: 'Lâm Đồng' },
  TTH: { id: '15', code: 'TTH', name: 'Thừa Thiên Huế' },
  KG: { id: '16', code: 'KG', name: 'Kiên Giang' },
  BN: { id: '17', code: 'BN', name: 'Bắc Ninh' },
  QNI: { id: '18', code: 'QNI', name: 'Quảng Ninh' },
  TH: { id: '19', code: 'TH', name: 'Thanh Hóa' },
  NA: { id: '20', code: 'NA', name: 'Nghệ An' },
  HD: { id: '21', code: 'HD', name: 'Hải Dương' },
  GL: { id: '22', code: 'GL', name: 'Gia Lai' },
  BP: { id: '23', code: 'BP', name: 'Bình Phước' },
  HY: { id: '24', code: 'HY', name: 'Hưng Yên' },
  BDD: { id: '25', code: 'BDD', name: 'Bình Định' },
  TG: { id: '26', code: 'TG', name: 'Tiền Giang' },
  TB: { id: '27', code: 'TB', name: 'Thái Bình' },
  BG: { id: '28', code: 'BG', name: 'Bắc Giang' },
  HB: { id: '29', code: 'HB', name: 'Hòa Bình' },
  AG: { id: '30', code: 'AG', name: 'An Giang' },
  VP: { id: '31', code: 'VP', name: 'Vĩnh Phúc' },
  TNI: { id: '32', code: 'TNI', name: 'Tây Ninh' },
  TN: { id: '33', code: 'TN', name: 'Thái Nguyên' },
  LCA: { id: '34', code: 'LCA', name: 'Lào Cai' },
  NDD: { id: '35', code: 'NDD', name: 'Nam Định' },
  QNG: { id: '36', code: 'QNG', name: 'Quảng Ngãi' },
  BTR: { id: '37', code: 'BTR', name: 'Bến Tre' },
  DNO: { id: '38', code: 'DNO', name: 'Đắk Nông' },
  CM: { id: '39', code: 'CM', name: 'Cà Mau' },
  VL: { id: '40', code: 'VL', name: 'Vĩnh Long' },
  NB: { id: '41', code: 'NB', name: 'Ninh Bình' },
  PT: { id: '42', code: 'PT', name: 'Phú Thọ' },
  NT: { id: '43', code: 'NT', name: 'Ninh Thuận' },
  PY: { id: '44', code: 'PY', name: 'Phú Yên' },
  HNA: { id: '45', code: 'HNA', name: 'Hà Nam' },
  HT: { id: '46', code: 'HT', name: 'Hà Tĩnh' },
  DDT: { id: '47', code: 'DDT', name: 'Đồng Tháp' },
  ST: { id: '48', code: 'ST', name: 'Sóc Trăng' },
  KT: { id: '49', code: 'KT', name: 'Kon Tum' },
  QB: { id: '50', code: 'QB', name: 'Quảng Bình' },
  QT: { id: '51', code: 'QT', name: 'Quảng Trị' },
  TV: { id: '52', code: 'TV', name: 'Trà Vinh' },
  HGI: { id: '53', code: 'HGI', name: 'Hậu Giang' },
  SL: { id: '54', code: 'SL', name: 'Sơn La' },
  BL: { id: '55', code: 'BL', name: 'Bạc Liêu' },
  YB: { id: '56', code: 'YB', name: 'Yên Bái' },
  TQ: { id: '57', code: 'TQ', name: 'Tuyên Quang' },
  DDB: { id: '58', code: 'DDB', name: 'Điện Biên' },
  LCH: { id: '59', code: 'LCH', name: 'Lai Châu' },
  LS: { id: '60', code: 'LS', name: 'Lạng Sơn' },
  HG: { id: '61', code: 'HG', name: 'Hà Giang' },
  BK: { id: '62', code: 'BK', name: 'Bắc Kạn' },
  CB: { id: '63', code: 'CB', name: 'Cao Bằng' },
};
