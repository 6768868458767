import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonFab,
  IonButton,
  IonBadge,
} from '@ionic/react';
import { arrowRoundForward } from 'ionicons/icons';
import * as tankActions from 'store/tank';
import * as equipmentActions from 'store/equipment';
import * as userAdminActions from 'store/userAdmin';
import language from 'languages';
import Header from 'commons/Header/Header';
import qs from 'querystring';
import routes from 'routes';
import { getStore } from 'utils/sessionUtils';
import backendConstants from 'backendConstants';

import _ from 'lodash';
import { RefresherEventDetail } from '@ionic/core';
import * as generalUtils from 'utils/generalUtils';
import { SubHeader, Wrapper } from 'commons/commons.styles';
import ShowContent from 'commons/ShowContent/ShowContent';
import languages from 'languages';
import vnConstants from 'vnConstants';
import { SubHeaderSecondaryWrapper } from './StoreTransfer.styles';
import TransferTankItem from './Components/TransferTankItem';
import TransferEquipmentItem from './Components/TransferEquipmentItem';
import StoreTransferChangeModal from './Modals/StoreTransferChangeModal';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';

export const TRANSFER_TABS = {
  TANK: 'TANK',
  EQUIPMENT: 'EQUIPMENT',
};

const StoreTransfer: React.FC<any> = props => {
  const {
    history,
    location,

    setSubmitting,
    setToast,

    tankReducer,
    checkTanks,
    updateTanks,
    resetTanks,

    userAdminReducer,
    checkUserAdmins,

    equipmentReducer,
    checkEquipments,
    updateEquipments,
    resetEquipments,
  } = props;

  const queryParams = qs.parse(props.location.search.replace('?', ''));
  const showContent = useMemo(() => {
    return location.pathname === routes.storeTransfer.main;
  }, [location.pathname]);

  const tanks = tankReducer && tankReducer.responseCheck && tankReducer.responseCheck.data;
  const equipments = equipmentReducer && equipmentReducer.responseCheck && equipmentReducer.responseCheck.data;
  const userAdmins = userAdminReducer && userAdminReducer.responseCheck && userAdminReducer.responseCheck.data;

  const [refreshing, setRefreshing] = useState(false);
  const [transferTab, setTransferTab] = useState<string>((queryParams.transferTab as string) || TRANSFER_TABS.TANK);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const resetIsModalOpen = () => setIsModalOpen(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleModalClose = (noReload?: boolean) => {
    resetIsModalOpen();
    if (!noReload) {
      resetTransferTab();
      if (transferTab === TRANSFER_TABS.TANK) {
        handleReadTank();
      }
      if (transferTab === TRANSFER_TABS.EQUIPMENT) {
        handleReadEquipment();
      }
      handleReadUserAdmin();
    }
  };
  const handleModalConfirm = async () => {
    setSubmitting(true);
    try {
      if (transferTab === TRANSFER_TABS.TANK) {
        await updateTanks({
          store: toStore,
          serial: tanksBySelectedTanks.map((tank: any) => tank.serial),
        });
        await axios({
          method: 'post',
          url: `${backendConstants.API_PATH}/push-notification`,
          data: {
            toUsers: userAdmins.filter((user: any) => [selectedStore, toStore].includes(user.store)),
            extraData: {
              fromStore: selectedStore,
              toStore,
              list: tanksBySelectedTanks.map((tank: any) => tank.serial),
            },
            message: `TANK-SPECIAL--STORE-TRANSFER`,
            nativeTitle: `Chuyển kho - Vỏ/bình`,
            nativeMessage: `Đã chuyển ${tanksBySelectedTanks.length} vỏ/bình từ kho ${selectedStore} tới kho ${toStore}`,
            count: tanksBySelectedTanks.length,
          },
        });
      }
      if (transferTab === TRANSFER_TABS.EQUIPMENT) {
        await updateEquipments({
          store: toStore,
          serial: equipmentsBySelectedEquipments.map((equipment: any) => equipment.serial),
        });
        await axios({
          method: 'post',
          url: `${backendConstants.API_PATH}/push-notification`,
          data: {
            toUsers: userAdmins.filter((user: any) => [selectedStore, toStore].includes(user.store)),
            extraData: {
              fromStore: selectedStore,
              toStore,
              list: equipmentsBySelectedEquipments.map((equipment: any) => equipment.serial),
            },
            message: `EQUIPMENT-SPECIAL--STORE-TRANSFER`,
            nativeTitle: `Chuyển kho - Máy`,
            nativeMessage: `Đã chuyển ${equipmentsBySelectedEquipments.length} máy từ kho ${selectedStore} tới kho ${toStore}`,
            count: equipmentsBySelectedEquipments.length,
          },
        });
      }
      setToast(
        `${(languages.storeTransfer.ui.tabs as any)[transferTab]} từ ${(vnConstants as any)[selectedStore].name} đến ${
          (vnConstants as any)[toStore].name
        } thành công`,
        `primary`
      );
    } catch (error) {
      setToast(
        `${(languages.storeTransfer.ui.tabs as any)[transferTab]} từ ${(vnConstants as any)[selectedStore].name} đến ${
          (vnConstants as any)[toStore].name
        } không thành công`,
        `danger`
      );
    }
    setSubmitting(false);
    resetTransferTab();
    handleModalClose(false);
  };

  const [searchText, setSearchText] = useState<string>('');
  const resetSearchText = () => setSearchText('');

  const [selectedStore, setSelectedStore] = useState<string>(getStore());
  const handleSelectedStore = (event: any) => {
    event && event.target && setSelectedStore(event.target.value);
  };

  const [toStore, setToStore] = useState<string>('');
  const resetToStore = () => setToStore('');
  const handleToStore = (event: any) => {
    event && event.target && setToStore(event.target.value);
  };
  useEffect(() => {
    resetToStore();
  }, [selectedStore]);

  const [selectedTanks, setSelectedTanks] = useState({});
  const resetSelectedTanks = () => setSelectedTanks({});
  const handleSelectTank = (key: string) => (event: any) => {
    return (
      event &&
      event.target &&
      setSelectedTanks({
        ...(selectedTanks as any),
        [key]: event.target.checked,
      })
    );
  };
  const tanksBySelectedTanks = useMemo(() => {
    return tanks && tanks.filter((tank: any) => (selectedTanks as any)[tank.serial]);
  }, [tanks, selectedTanks]);

  const [selectedEquipments, setSelectedEquipments] = useState({});
  const resetSelectedEquipment = () => setSelectedEquipments({});
  const handleSelectEquipment = (key: string) => (event: any) => {
    return (
      event &&
      event.target &&
      setSelectedEquipments({
        ...(selectedEquipments as any),
        [key]: event.target.checked,
      })
    );
  };
  const equipmentsBySelectedEquipments = useMemo(() => {
    return equipments && equipments.filter((equipment: any) => (selectedEquipments as any)[equipment.serial]);
  }, [equipments, selectedEquipments]);

  const resetTransferTab = () => {
    // Reset Data
    resetTanks();
    resetEquipments();
    // Reset states
    resetIsModalOpen();
    resetSearchText();
    resetSelectedTanks();
    resetSelectedEquipment();
    // Reset scrolling
    resetContentScrollTop();
  };

  const tanksBySearch = useMemo(() => {
    return (
      transferTab === TRANSFER_TABS.TANK &&
      tanks &&
      tanks.filter((tank: any) => tank.serial.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [tanks, searchText, transferTab]);

  const equipmentsBySearch = useMemo(() => {
    return (
      transferTab === TRANSFER_TABS.EQUIPMENT &&
      equipments &&
      equipments.filter((equipment: any) => equipment.serial.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [equipments, searchText, transferTab]);

  const isFetching =
    (tankReducer && tankReducer.isFetching) ||
    (equipmentReducer && equipmentReducer.isFetching) ||
    (userAdminReducer && userAdminReducer.isFetching);

  const handleReadTank = () => {
    return checkTanks({
      page: 1,
      pageSize: 9999,
      filter: {
        store: selectedStore || `NONE`,
        status: {
          $in: [backendConstants.TANK_STATUSES.EMPTY, backendConstants.TANK_STATUSES.READY_TO_USE],
        },
      },
      sort: {
        updatedAt: 1,
      },
    });
  };

  const handleReadEquipment = () => {
    return checkEquipments({
      page: 1,
      pageSize: 9999,
      filter: {
        store: selectedStore || `NONE`,
        status: backendConstants.EQUIPMENT_STATUSES.READY_TO_USE,
      },
      sort: {
        updatedAt: 1,
      },
    });
  };

  const handleReadUserAdmin = () => {
    return checkUserAdmins({
      filter: {
        userType: {
          $in: [backendConstants.USER_TYPES.ADMIN, backendConstants.USER_TYPES.REVIEWER],
        },
      },
    });
  };

  const handleTransferTabChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setTransferTab(event.detail.value);
      history.push(`${routes.storeTransfer.main}?transferTab=${event.detail.value}`);
    }
  };

  const handleSearchText = (e: any) => e && e.target && setSearchText(e.target.value);

  // FETCH TANKS OR EQUIPMENTS
  useEffect(() => {
    if (showContent) {
      resetTransferTab();
      if (transferTab === TRANSFER_TABS.TANK) {
        handleReadTank();
      }
      if (transferTab === TRANSFER_TABS.EQUIPMENT) {
        handleReadEquipment();
      }
      handleReadUserAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferTab, showContent, selectedStore]);

  useEffect(() => {
    if (queryParams.transferTab) {
      setTransferTab(queryParams.transferTab as any);
    }
  }, [queryParams.transferTab]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    resetTransferTab();
    if (transferTab === TRANSFER_TABS.TANK) {
      await handleReadTank();
    }
    if (transferTab === TRANSFER_TABS.EQUIPMENT) {
      await handleReadEquipment();
    }
    await handleReadUserAdmin();
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);
  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.storeTransfer.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleTransferTabChange} scrollable={true}>
              <IonSegmentButton value={TRANSFER_TABS.TANK} checked={transferTab === TRANSFER_TABS.TANK}>
                {(language.storeTransfer.ui.tabs as any)[TRANSFER_TABS.TANK]}
              </IonSegmentButton>
              <IonSegmentButton value={TRANSFER_TABS.EQUIPMENT} checked={transferTab === TRANSFER_TABS.EQUIPMENT}>
                {(language.storeTransfer.ui.tabs as any)[TRANSFER_TABS.EQUIPMENT]}
              </IonSegmentButton>
            </IonSegment>
            <div>
              <IonSearchbar
                disabled={false}
                placeholder={`Tìm kiếm theo serial ...`}
                value={searchText}
                onIonChange={handleSearchText}
                debounce={250}
              />
              <SubHeaderSecondaryWrapper>
                <IonItem lines={`none`}>
                  <IonLabel>
                    {selectedStore ? (
                      <span>
                        Từ: <IonText color={`primary`}>{(vnConstants as any)[selectedStore].name}</IonText>
                      </span>
                    ) : (
                      `Chọn kho`
                    )}
                  </IonLabel>
                  <IonSelect onIonChange={handleSelectedStore} value={selectedStore} selectedText={selectedStore}>
                    {Object.keys(backendConstants.STORES).map((store: any) => (
                      <IonSelectOption key={store} value={store}>
                        {(vnConstants as any)[store].name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonIcon icon={arrowRoundForward} />
                <IonItem lines={`none`}>
                  <IonLabel>
                    {toStore ? (
                      <span>
                        Đến: <IonText color={`primary`}>{(vnConstants as any)[toStore].name}</IonText>
                      </span>
                    ) : (
                      `Chọn kho`
                    )}
                  </IonLabel>
                  <IonSelect onIonChange={handleToStore} value={toStore} selectedText={toStore}>
                    {Object.keys(backendConstants.STORES)
                      .filter((store: any) => store !== selectedStore)
                      .map((store: any) => (
                        <IonSelectOption key={store} value={store}>
                          {(vnConstants as any)[store].name}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </SubHeaderSecondaryWrapper>
            </div>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <IonGrid className={`ion-padding-bottom`}>
              <div className={`ion-align-items-center`}>
                {isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    {transferTab === TRANSFER_TABS.TANK && (
                      <IonList className={`ion-margin-vertical`}>
                        <IonListHeader>
                          Kết quả: {tanksBySearch && tanksBySearch.length}
                          {' vỏ/bình'}
                        </IonListHeader>
                        <TransferTankItem
                          tanks={tanksBySearch}
                          onSelectTank={handleSelectTank}
                          selectedTanks={selectedTanks}
                          isInModal={false}
                        />
                      </IonList>
                    )}
                    {transferTab === TRANSFER_TABS.EQUIPMENT && (
                      <IonList className={`ion-margin-vertical`}>
                        <IonListHeader>
                          Kết quả: {equipmentsBySearch && equipmentsBySearch.length}
                          {' máy'}
                        </IonListHeader>
                        <TransferEquipmentItem
                          equipments={equipmentsBySearch}
                          onSelectEquipment={handleSelectEquipment}
                          selectedEquipments={selectedEquipments}
                          isInModal={false}
                        />
                      </IonList>
                    )}
                  </div>
                )}
              </div>
              {isModalOpen && (
                <StoreTransferChangeModal
                  isOpen={isModalOpen}
                  onClose={handleModalClose}
                  onConfirm={handleModalConfirm}
                  tanksBySelectedTanks={tanksBySelectedTanks}
                  equipmentsBySelectedEquipments={equipmentsBySelectedEquipments}
                  fromStore={selectedStore}
                  toStore={toStore}
                  transferTab={transferTab}
                  onSelectTank={handleSelectTank}
                  onSelectEquipment={handleSelectEquipment}
                />
              )}
            </IonGrid>
          </IonContent>
          {((tanksBySelectedTanks && !!tanksBySelectedTanks.length) ||
            (equipmentsBySelectedEquipments && !!equipmentsBySelectedEquipments.length)) && (
            <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
              <IonButton color={`primary`} disabled={!selectedStore || !toStore} onClick={handleOpenModal}>
                <IonLabel>Xác nhận chuyển kho</IonLabel>
                <IonBadge color={`light`}>
                  {transferTab === TRANSFER_TABS.TANK && tanksBySelectedTanks && tanksBySelectedTanks.length}
                  {transferTab === TRANSFER_TABS.EQUIPMENT &&
                  equipmentsBySelectedEquipments &&
                  equipmentsBySelectedEquipments.length}
                </IonBadge>
              </IonButton>
            </IonFab>
          )}
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  tankReducer: state.tankReducer,
  equipmentReducer: state.equipmentReducer,
  userAdminReducer: state.userAdminReducer,
});

const mapDispatchToProps = {
  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,

  checkTanks: tankActions.checkTanks,
  updateTanks: tankActions.updateTanks,
  resetTanks: tankActions.resetTanks,

  checkUserAdmins: userAdminActions.checkUserAdmins,

  checkEquipments: equipmentActions.checkEquipments,
  updateEquipments: equipmentActions.updateEquipments,
  resetEquipments: equipmentActions.resetEquipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreTransfer);
