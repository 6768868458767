export const SUBMITTING_ACTION = 'SUBMITTING_ACTION';

export const setSubmitting = (isSubmitting: boolean) => ({
  type: SUBMITTING_ACTION,
  payload: isSubmitting,
});

export const submittingReducer = (state: any = false, action: any) => {
  if (action.type === SUBMITTING_ACTION) {
    return action.payload;
  }
  return state;
};
