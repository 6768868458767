import React from 'react';
import {
  IonBadge,
  IonButton,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
  IonText,
} from '@ionic/react';
import backendConstants from 'backendConstants';
import languages from 'languages';
import { getStore } from 'utils/sessionUtils';
import vnConstants from 'vnConstants';

export const ClientItem: React.FC<any> = props => {
  const { client, onSelectClient, onAddUserToClient } = props;
  const handleSelectedClient = (client: any) => () => {
    onSelectClient && onSelectClient(client);
  };
  const handleAddUserToClient = (client: any) => () => {
    onAddUserToClient && onAddUserToClient(client);
  };
  return (
    <IonItemSliding key={client._id}>
      <IonItem onClick={handleSelectedClient(client)} button={true}>
        <IonLabel className={`ion-text-wrap`}>
          <IonText color={client.status === backendConstants.CLIENT_STATUSES.DISABLED ? `danger` : `primary`}>
            <h2>{client.name}</h2>
          </IonText>
          <p>
            Kho:{' '}
            <IonText color={client.store === getStore() ? `primary` : `danger`}>
              {(vnConstants as any)[client.store].name}
            </IonText>
          </p>
          {client.status === backendConstants.CLIENT_STATUSES.DISABLED && (
            <p>
              <IonText color={`danger`}>Không hoạt động</IonText>
            </p>
          )}
          <p>
            <IonText>Loại KH: {languages.manageClient.ui.types[client.type]}</IonText>
          </p>
          <p className={`ion-padding-bottom`}>
            <IonText>{client.address}</IonText>
          </p>
          <p>
            <IonText color={`primary`}>Người liên hệ: </IonText>
            <br />
            <IonText>{client.primaryContactName}</IonText>
          </p>
          <p className={`ion-padding-bottom`}>
            <IonText color={`primary`}>SDT: </IonText>
            <br />
            <IonText>{client.primaryContactNumber}</IonText>
          </p>
          <p>
            <IonText color={`primary`}>Kinh doanh/Chăm sóc: </IonText>
            <br />
            <IonText>{client.parentId.name}</IonText>
          </p>
        </IonLabel>
        <IonNote slot={`end`}>
          <div className={`ion-text-end`}>
            <IonBadge color={`danger`} slot={`end`}>
              {client.totalTank} vỏ nợ
            </IonBadge>
          </div>
          <div className={`ion-text-end`}>
            <IonBadge color={`primary`} slot={`end`}>
              {client.equipments.length} máy
            </IonBadge>
          </div>
        </IonNote>
      </IonItem>
      {client.store === getStore() && client.status === backendConstants.CLIENT_STATUSES.ACTIVE && (
        <IonItemOptions side={`end`}>
          <IonItemOption color={`transparent`}>
            <IonButton onClick={handleAddUserToClient(client)} size={`small`} color={`primary`}>
              Thêm khoa/tài khoản
            </IonButton>
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
};

export default ClientItem;
