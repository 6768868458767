import { combineReducers } from 'redux';

import { submittingReducer } from './submitting';
import { toastReducer } from './toast';
import { confirmReducer } from './confirm';
import { selfReducer } from './self';
import { tankReducer } from './tank';
import { userClientReducer } from './userClient';
import { userDeliveryReducer } from './userDelivery';
import { userTechnicalReducer } from './userTechnical';
import { userSaleReducer } from './userSale';
import { userAdminReducer } from './userAdmin';
import { equipmentReducer } from './equipment';
import { invoiceReducer } from './invoice';
import { invoiceClientReducer } from './invoiceClient';
import { clientReducer } from './client';
import { userReducer } from './user';
import { overviewReducer } from './overview';
import { overviewClientReducer } from './overviewClient';
import { reportReducer } from './report';
import { reportClientReducer } from './reportClient';
import { restoreReducer } from './restore';
import { changePasswordReducer } from './changePassword';
import { notificationReducer } from './notification';
import { allReducer } from './all';

const rootReducer = combineReducers({
  submittingReducer,
  toastReducer,
  confirmReducer,
  selfReducer,
  tankReducer,
  equipmentReducer,
  userClientReducer,
  userDeliveryReducer,
  userTechnicalReducer,
  userSaleReducer,
  userAdminReducer,
  invoiceReducer,
  invoiceClientReducer,
  clientReducer,
  userReducer,
  overviewReducer,
  overviewClientReducer,
  reportReducer,
  reportClientReducer,
  restoreReducer,
  changePasswordReducer,
  notificationReducer,
  allReducer,
  self: (state: any = {}) => state, // Just for saving user session
});

export default rootReducer;
