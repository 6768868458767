import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/user`;
export const USER_SALE_CRUD_ACTIONS = generateCrudActionTypes(`USER_SALE_CRUD`);

export const {
  check: checkUserSales,
  read: readUserSales,
  create: createUserSale,
  update: updateUserSale,
  updateBatch: updateUserSales,
  resetState: resetUserSales,
} = generateThunkActions(USER_SALE_CRUD_ACTIONS, ENDPOINT);

export const userSaleReducer = generateCrudReducer(USER_SALE_CRUD_ACTIONS);
