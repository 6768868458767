import React, { useMemo } from 'react';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { FormWrapper } from 'commons/commons.styles';
import { NormalField } from 'commons/Form/NormalField';
import { OptionField } from 'commons/Form/OptionField';
import { SelectField } from 'commons/Form/SelectField';
import addEditClientSchema from './AddEditClientForm.schema';
import backendConstants from 'backendConstants';
import vnConstants from 'vnConstants';

export const CLIENT_PROVINCE_OPTIONS = Object.keys(backendConstants.PROVINCES)
  .sort((a: any, b: any) => a.localeCompare(b))
  .map(province => ({
    key: province,
    label: (vnConstants as any)[province].name,
  }));

export const CLIENT_SECTOR_OPTIONS = [
  {
    key: backendConstants.SECTORS.GOVERNMENT,
    label: 'Bệnh viện công',
  },
  {
    key: backendConstants.SECTORS.PRIVATE,
    label: 'Bệnh viện tư',
  },
];

export const CLIENT_STATUS_OPTIONS = [
  {
    key: backendConstants.CLIENT_STATUSES.ACTIVE,
    label: 'Hoạt động',
  },
  {
    key: backendConstants.CLIENT_STATUSES.DISABLED,
    label: 'Không hoạt động',
  },
];

export const CLIENT_TYPE_OPTIONS = [
  {
    key: backendConstants.CLIENT_TYPES.HOSPITAL,
    label: 'Bệnh viện',
  },
  {
    key: backendConstants.CLIENT_TYPES.CLINIC,
    label: 'Phòng khám',
  },
  {
    key: backendConstants.CLIENT_TYPES.SPA,
    label: 'Thẩm mĩ',
  },
  {
    key: backendConstants.CLIENT_TYPES.OTHER,
    label: 'Khác',
  },
];

export const CLIENT_CHARGE_TYPE_OPTIONS = [
  {
    key: backendConstants.CLIENT_CHARGE_TYPES.VAT,
    label: 'Xuất bán',
  },
  {
    key: backendConstants.CLIENT_CHARGE_TYPES.NO_VAT,
    label: 'Hàng bán trả lại',
  },
];

export const AddEditClientForm: React.FC<any> = props => {
  const { selectedClient, submittingReducer, setSubmitting, userSales, onFormUpdate } = props;

  const addEditClientInitialValues = useMemo(() => {
    return selectedClient
      ? {
          name: selectedClient.name,
          address: selectedClient.address,
          province: selectedClient.province,
          sector: selectedClient.sector,
          type: selectedClient.type,
          chargeType: selectedClient.chargeType,
          primaryContactName: selectedClient.primaryContactName,
          primaryContactPosition: selectedClient.primaryContactPosition,
          primaryContactNumber: selectedClient.primaryContactNumber,
          primaryContactEmail: selectedClient.primaryContactEmail,
          parentId: selectedClient.parentId._id || selectedClient.parentId,
          note: selectedClient.note,
          status: selectedClient.status,
          store: selectedClient.store,
        }
      : {};
  }, [selectedClient]);

  const userSaleOptions = useMemo(() => {
    return userSales
      ? userSales
          .filter((userSale: any) => userSale.status === backendConstants.USER_STATUSES.ACTIVE)
          .sort((userSaleA: any, userSaleB: any) => userSaleA.name.localeCompare(userSaleB.name))
          .map((userSale: any) => ({
            key: userSale._id,
            label: userSale.name,
            subLabel: userSale.username,
          }))
      : [];
  }, [userSales]);

  const handleSubmit = () => {
    setSubmitting(true);
  };

  const canActiveDisable =
    !selectedClient ||
    (selectedClient &&
      selectedClient._id &&
      selectedClient.tanks &&
      selectedClient.tanks.length === 0 &&
      selectedClient.equipments &&
      selectedClient.equipments.length === 0 &&
      (!selectedClient.userClients ||
        selectedClient.userClients.length === 0 ||
        selectedClient.userClients.every((userClient: any) => userClient.status === backendConstants.USER_STATUSES.DISABLED)));

  return (
    <FormWrapper>
      <Formik
        initialValues={addEditClientInitialValues}
        validationSchema={addEditClientSchema}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {(formikProps: FormikProps<FormikValues>) => {
          onFormUpdate && onFormUpdate(formikProps);
          return !submittingReducer ? (
            <Form className={`ion-text-center`} autoComplete={`off`}>
              <Field type={`submit`} className={`ion-hide`} />
              <NormalField fieldName={`name`} title={`Tên khách hàng`} type={`text`} />
              <OptionField
                fieldName={`status`}
                title={`Trạng thái`}
                type={`text`}
                options={CLIENT_STATUS_OPTIONS}
                disabled={!canActiveDisable}
              />
              {!canActiveDisable && (
                <p>
                  <IonText color={`danger`}>Yêu cầu vô hiệu hoá TK/KH, thu hồi máy/vỏ</IonText>
                </p>
              )}
              <OptionField fieldName={`sector`} title={`Loại bệnh viện`} type={`text`} options={CLIENT_SECTOR_OPTIONS} />
              <OptionField fieldName={`type`} title={`Loại khách hàng`} type={`text`} options={CLIENT_TYPE_OPTIONS} />
              <OptionField
                fieldName={`chargeType`}
                title={`Hình thức thanh toán`}
                type={`text`}
                options={CLIENT_CHARGE_TYPE_OPTIONS}
              />
              <SelectField
                fieldName={`province`}
                title={`Tỉnh thành`}
                placeholder={`Chọn tỉnh hoặc thành phố`}
                type={`text`}
                options={CLIENT_PROVINCE_OPTIONS}
              />
              <NormalField fieldName={`address`} title={`Địa chỉ`} type={`text`} />
              <NormalField fieldName={`primaryContactName`} title={`Người liên hệ`} type={`text`} />
              <NormalField fieldName={`primaryContactPosition`} title={`Chức vụ`} type={`text`} />
              <NormalField fieldName={`primaryContactNumber`} title={`Số điện thoại`} type={`text`} />
              <NormalField fieldName={`primaryContactEmail`} title={`Email`} type={`text`} />
              <OptionField fieldName={`parentId`} title={`Kinh doanh/Chăm sóc`} type={`text`} options={userSaleOptions} />
            </Form>
          ) : (
            <IonGrid>
              <IonRow className={`ion-align-items-center`}>
                <IonCol className={`ion-padding-vertical ion-text-center`}>
                  <IonSpinner className={`big-spinner`} />
                </IonCol>
              </IonRow>
            </IonGrid>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

export default AddEditClientForm;
