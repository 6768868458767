import React from 'react';
import { IonButton, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonText } from '@ionic/react';
import languages from 'languages';
import moment from 'moment-timezone';
import backendConstants from 'backendConstants';
import { TEST_SERIAL } from '../ManageEquipment';
import { EQUIPMENT_ACTIONS } from '../ManageEquipment.constants';
import { getStore } from '../../../utils/sessionUtils';
import vnConstants from '../../../vnConstants';

export const EquipmentItem: React.FC<any> = props => {
  const { equipment, equipmentStatus, onSelectedEquipment, onMaintainEquipment, noAction } = props;

  const handleSelectedEquipment = (action: any) => () => {
    if (action === EQUIPMENT_ACTIONS.MAINTAIN) {
      return onMaintainEquipment && onMaintainEquipment(equipment, action);
    }
    return onSelectedEquipment && onSelectedEquipment(equipment, action);
  };

  return (
    <IonItemSliding>
      <IonItem>
        <IonLabel>
          <IonText>{equipment.serial}</IonText>
          {equipment.submitAction !== EQUIPMENT_ACTIONS.CREATE && (
            <React.Fragment>
              {equipmentStatus === TEST_SERIAL && (
                <p>
                  Kho:{' '}
                  <IonText color={equipment.store === getStore() ? `primary` : `danger`}>
                    {(vnConstants as any)[equipment.store].name}
                  </IonText>
                </p>
              )}
              <p>
                Loại máy/bình: {(languages.manageEquipment.ui as any).types[equipment.type]}
                {' - '}
                {(languages.manageTank.ui as any)[equipment.tankType]}
              </p>
              {equipment.clientUserId && (
                <p>
                  <IonText color={`primary`}>{equipment.clientUserId.name}</IonText>
                </p>
              )}
              <p>Ngày cập nhật: {moment(equipment.updatedAt).format('DD/MM/YYYY')}</p>
              <p>
                Trạng thái:{' '}
                <IonText
                  color={
                    ([
                      [backendConstants.EQUIPMENT_STATUSES.DISABLED, `danger`],
                      [backendConstants.EQUIPMENT_STATUSES.CHECKING, `warning`],
                      [backendConstants.EQUIPMENT_STATUSES.FIXING, `warning`],
                      [equipment.status, undefined],
                    ].find(item => item[0] === equipment.status) as any)[1]
                  }
                >
                  {languages.manageEquipment.ui.statuses[equipment.status]}
                </IonText>
              </p>
              {[
                backendConstants.EQUIPMENT_STATUSES.SOLD,
                backendConstants.EQUIPMENT_STATUSES.RENTED,
                backendConstants.EQUIPMENT_STATUSES.LENT,
              ].includes(equipment.status) && (
                <React.Fragment>
                  {equipment.maintainOption === backendConstants.EQUIPMENT_MAINTAIN_OPTIONS.YES ? (
                    equipment.maintainedAt && (
                      <p>
                        Lịch bảo dưỡng:{' '}
                        <IonText color={moment().diff(moment(equipment.maintainedAt), 'month') >= backendConstants.EQUIPMENT_MAINTAIN_MONTH ? `danger` : undefined}>
                          {moment(equipment.maintainedAt)
                            .add(backendConstants.EQUIPMENT_MAINTAIN_MONTH, 'month')
                            .format('DD/MM/YYYY')}
                        </IonText>
                      </p>
                    )
                  ) : (
                    <p>Không bảo dưỡng</p>
                  )}
                  {equipment.status === backendConstants.EQUIPMENT_STATUSES.SOLD &&
                    (equipment.warrantyOption === backendConstants.EQUIPMENT_WARRANTY_OPTIONS.YES ? (
                      equipment.warrantyStartDate && (
                        <React.Fragment>
                          <p>
                            Bảo hành từ:{' '}
                            <IonText>
                              {moment(equipment.warrantyStartDate).format(`DD/MM/YYYY`)}
                              {` đến `}
                              {moment(equipment.warrantyStartDate)
                                .add(backendConstants.EQUIPMENT_WARRANTY_MONTH, 'month')
                                .format(`DD/MM/YYYY`)}
                            </IonText>
                          </p>
                          {moment(equipment.warrantyStartDate)
                            .add(backendConstants.EQUIPMENT_WARRANTY_MONTH, 'month')
                            .isBefore(moment()) && (
                            <p>
                              <IonText color={`danger`}>Hết bảo hành</IonText>
                            </p>
                          )}
                        </React.Fragment>
                      )
                    ) : (
                      <p>Không bảo hành</p>
                    ))}
                </React.Fragment>
              )}
              {[backendConstants.EQUIPMENT_STATUSES.CHECKING, backendConstants.EQUIPMENT_STATUSES.FIXING].includes(
                equipment.status
              ) && <p>Lí do kiểm tra: {languages.manageEquipment.ui.actions[equipment.status]}</p>}
              {[backendConstants.EQUIPMENT_STATUSES.DISABLED].includes(equipment.status) && equipment.note && (
                <p>Lí do huỷ: {equipment.note}</p>
              )}
              {onMaintainEquipment && equipment.clientUserId && (
                <React.Fragment>
                  {equipment.clientUserId.clientId && (
                    <React.Fragment>
                      <p className={`ion-padding-top`}>
                        <IonText color={`primary`}>Địa chỉ:</IonText>
                      </p>{' '}
                      <p>{equipment.clientUserId.clientId.address}</p>
                    </React.Fragment>
                  )}
                  <p>
                    <IonText color={`primary`}>Thông tin người liên hệ:</IonText>
                  </p>{' '}
                  <p>{equipment.clientUserId.contactName}</p>
                  <p>
                    <IonText color={`primary`}>Số điện thoại:</IonText>
                  </p>{' '}
                  <p>{equipment.clientUserId.contactNumber}</p>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </IonLabel>
        {onMaintainEquipment && (
          <IonButton
            slot={`end`}
            color={`primary`}
            size={`small`}
            onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.MAINTAIN)}
          >
            {languages.manageEquipment.ui.actions.MAINTAIN}
          </IonButton>
        )}
      </IonItem>
      {equipment.store === getStore() && !noAction && onSelectedEquipment && (
        <IonItemOptions side={`end`}>
          {[
            backendConstants.EQUIPMENT_STATUSES.SOLD,
            backendConstants.EQUIPMENT_STATUSES.RENTED,
            backendConstants.EQUIPMENT_STATUSES.LENT,
          ].includes(equipment.status) && (
            <IonItemOption color={`transparent`}>
              <IonButton
                slot={`end`}
                color={`primary`}
                size={`small`}
                onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.CHECKING_FIXING)}
              >
                {equipmentStatus !== backendConstants.EQUIPMENT_STATUSES.READY_TO_USE
                  ? languages.manageEquipment.ui.actions.CHECKING
                  : languages.manageEquipment.ui.actions.CHECKING_FIXING}
              </IonButton>
              <IonButton
                slot={`end`}
                color={`primary`}
                size={`small`}
                onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.EDIT_SOLD_RENTED_LENT)}
              >
                {languages.manageEquipment.ui.actions.EDIT_SOLD_RENTED_LENT}
              </IonButton>
            </IonItemOption>
          )}
          {[backendConstants.EQUIPMENT_STATUSES.CHECKING].includes(equipment.status) && (
            <React.Fragment>
              <IonItemOption color={`transparent`}>
                <IonButton
                  slot={`end`}
                  color={`primary`}
                  size={`small`}
                  onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.CHECKED_READY_TO_USE)}
                >
                  {languages.manageEquipment.ui.actions.CHECKED_READY_TO_USE}
                </IonButton>
                <IonButton
                  slot={`end`}
                  color={`danger`}
                  size={`small`}
                  onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.DISABLED)}
                >
                  {languages.manageEquipment.ui.actions.DISABLED}
                </IonButton>
              </IonItemOption>
            </React.Fragment>
          )}
          {[backendConstants.EQUIPMENT_STATUSES.DISABLED].includes(equipment.status) && (
            <React.Fragment>
              <IonItemOption color={`transparent`}>
                <IonButton
                  slot={`end`}
                  color={`primary`}
                  size={`small`}
                  onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.RECOVERED)}
                >
                  {languages.manageEquipment.ui.actions.RECOVERED}
                </IonButton>
              </IonItemOption>
            </React.Fragment>
          )}
          {[backendConstants.EQUIPMENT_STATUSES.FIXING].includes(equipment.status) && (
            <React.Fragment>
              <IonItemOption color={`transparent`}>
                <IonButton
                  slot={`end`}
                  color={`primary`}
                  size={`small`}
                  onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.FIXED_READY_TO_USE)}
                >
                  {languages.manageEquipment.ui.actions.FIXED_READY_TO_USE}
                </IonButton>
                <IonButton
                  slot={`end`}
                  color={`danger`}
                  size={`small`}
                  onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.DISABLED)}
                >
                  {languages.manageEquipment.ui.actions.DISABLED}
                </IonButton>
              </IonItemOption>
            </React.Fragment>
          )}
          {[backendConstants.EQUIPMENT_STATUSES.READY_TO_USE].includes(equipment.status) && equipmentStatus !== TEST_SERIAL && (
            <React.Fragment>
              <IonItemOption color={`transparent`}>
                <IonButton
                  slot={`end`}
                  color={`primary`}
                  size={`small`}
                  onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.CHECKING_FIXING)}
                >
                  {languages.manageEquipment.ui.actions.CHECKING_FIXING}
                </IonButton>
                <IonButton
                  slot={`end`}
                  color={`primary`}
                  size={`small`}
                  onClick={handleSelectedEquipment(EQUIPMENT_ACTIONS.SOLD_RENTED_LENT)}
                >
                  {languages.manageEquipment.ui.actions.SOLD_RENTED_LENT}
                </IonButton>
              </IonItemOption>
            </React.Fragment>
          )}
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
};

export default EquipmentItem;
