import React from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import language from 'languages';

const logo = require('assets/logo.png');

const ResetPassword: React.FC = (props: any) => {
  const { history } = props;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={language.back} />
          </IonButtons>
          <IonTitle>Reset Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={`ion-padding`}>
        <IonButton
          onClick={e => {
            e.preventDefault();
            history.goBack();
          }}
        >
          To home
        </IonButton>
        <img src={logo} style={{ width: '50%' }} alt={'logo'} />
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
