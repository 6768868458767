import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/user`;
export const USER_CLIENT_CRUD_ACTIONS = generateCrudActionTypes(`USER_CLIENT_CRUD`);

export const {
  check: checkUserClients,
  read: readUserClients,
  create: createUserClient,
  update: updateUserClient,
  updateBatch: updateUserClients,
  resetState: resetUserClients,
} = generateThunkActions(USER_CLIENT_CRUD_ACTIONS, ENDPOINT);

export const userClientReducer = generateCrudReducer(USER_CLIENT_CRUD_ACTIONS);
