import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonLabel,
  IonMenuToggle,
  IonIcon,
  IonListHeader,
  IonText,
} from '@ionic/react';
import { logOut, arrowForward as suffixHeaderIcon } from 'ionicons/icons';
import language from 'languages';
import { navigateByHistory } from 'utils/routeUtils';
import menus from 'menus';
import { MenuListHeaderWrapper, MenuListHeader, MenuItemWrapper, MenuVersionText } from './Menu.styles';
import { isPermittedByUserType } from 'utils/generalUtils';
import { isPermittedByUserStore } from 'utils/sessionUtils';

const Menu: React.FC<any> = (props: any) => {
  const { history, location, user, logout, setFirstLocation } = props;
  const path = useMemo(() => {
    return `${location.pathname}${location.search}`;
  }, [location]);

  const userType = user && user.userType;

  const versionFrontend = useMemo(() => document.getElementById('frontend-version'), []);
  const versionBackend = useMemo(() => document.getElementById('backend-version'), []);

  const version = useMemo(() => {
    const frontend = versionFrontend && (versionFrontend.textContent || `DEV`);
    const backend = versionBackend && (versionBackend.textContent || `DEV`);
    return `FE ${frontend} - BE ${backend}`;
  }, [versionFrontend, versionBackend]);

  const handleLogout = () => {
    setFirstLocation && setFirstLocation(null);
    logout && logout();
  };

  return (
    <IonMenu side={`start`} type={`push`} contentId={`main`}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {process.env.REACT_APP_BACKEND === 'TEST' ? (
              <IonText className={`app-test-title`} color={`danger`}>
                Bản thử nghiệm
              </IonText>
            ) : (
              language.menu
            )}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {Object.keys(menus).map((menuKey: any) => {
          const menuItem = (menus as any)[menuKey];
          const headerColor =
            (menus as any)[menuKey].path && (menus as any)[menuKey].path.indexOf(location.pathname) === 0
              ? `primary`
              : undefined;
          const hidden =
            (menuItem.permissions && !isPermittedByUserType(userType, menuItem.permissions)) ||
            (menuItem.permittedStore && !isPermittedByUserStore(menuItem.permittedStore));

          return (
            !hidden && (
              <IonList key={menuKey}>
                <MenuListHeaderWrapper>
                  <IonMenuToggle autoHide={false}>
                    <IonListHeader onClick={navigateByHistory(history, (menus as any)[menuKey].path)}>
                      <MenuListHeader>
                        <IonText color={headerColor}>{menuItem.text}</IonText>
                        <IonIcon slot={`end`} color={headerColor} icon={suffixHeaderIcon} />
                      </MenuListHeader>
                    </IonListHeader>
                  </IonMenuToggle>
                </MenuListHeaderWrapper>
                {menuItem.actions &&
                  Object.keys(menuItem.actions).map((actionItemKey, actionIndex) => {
                    const actionItem = menuItem.actions[actionItemKey];
                    const actionHidden = actionItem.permissions && !isPermittedByUserType(userType, actionItem.permissions);
                    return (
                      !actionHidden && (
                        <MenuItemWrapper key={actionItemKey} active={path === actionItem.path}>
                          <IonMenuToggle autoHide={false}>
                            <IonItem onClick={navigateByHistory(history, actionItem.path)} button={true}>
                              <IonIcon
                                slot={`start`}
                                color={actionItem.path.indexOf(location.pathname) === 0 ? `primary` : undefined}
                                icon={actionIndex === 0 ? menuItem.icon : undefined}
                              />
                              <IonLabel color={path === actionItem.path ? `primary` : undefined}>{actionItem.text}</IonLabel>
                            </IonItem>
                          </IonMenuToggle>
                        </MenuItemWrapper>
                      )
                    );
                  })}
              </IonList>
            )
          );
        })}
        <IonList>
          <MenuListHeaderWrapper>
            <IonListHeader>
              <MenuListHeader>
                <IonText color={`primary`}>Khác</IonText>
                <IonIcon slot={`end`} color={`primary`} icon={suffixHeaderIcon} />
              </MenuListHeader>
            </IonListHeader>
          </MenuListHeaderWrapper>
          <IonMenuToggle key={'logout'} autoHide={false}>
            <MenuItemWrapper>
              <IonItem onClick={handleLogout}>
                <IonIcon slot={`start`} icon={logOut} />
                <IonLabel>
                  Đăng xuất{' '}
                  <p>
                    <IonText className={`app-menu-sub-label`} color={`primary`}>
                      {user && user.name}
                    </IonText>
                  </p>
                </IonLabel>
              </IonItem>
            </MenuItemWrapper>
          </IonMenuToggle>
        </IonList>
        <IonList className={`ion-padding`}>
          <MenuVersionText>Phiên bản: <span>{version}</span></MenuVersionText>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
