import styled from 'styled-components';

export const StatsItemWrapper = styled.div`
  ion-list-header {
    font-size: 14px;
    text-transform: uppercase;
    ion-text {
      font-weight: 600;  
    }
  }
`;
