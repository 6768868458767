import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/log/details`;
export const REPORT_CRUD_ACTIONS = generateCrudActionTypes(`REPORT_CRUD`);

export const { check: checkReport, resetState: resetReport } = generateThunkActions(REPORT_CRUD_ACTIONS, ENDPOINT);

export const reportReducer = generateCrudReducer(REPORT_CRUD_ACTIONS);
