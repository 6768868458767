import axios from 'axios';
import { API_PATH } from 'backendConstants';
import jwt from 'jsonwebtoken';
import { isPlatform } from '@ionic/react';
import backendConstants from '../backendConstants';
import {
  localStorageSet,
  // saveSessionItem
} from '../utils/sessionUtils';

export const ENDPOINT_LOGIN = `${API_PATH}/auth`;
export const ENDPOINT_CHECK = `${API_PATH}/check`;

export const SELF_ACTIONS = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCEED: 'AUTH_SUCCEED',
  AUTH_FAILED: 'AUTH_FAILED',
  CHECK_REQUEST: 'CHECK_REQUEST',
  CHECK_SUCCEED: 'CHECK_SUCCEED',
  CHECK_FAILED: 'CHECK_FAILED',
  RESET_STATE: 'RESET_STATE',
  SET_SOCKET: 'SET_SOCKET_IO',
  SET_NATIVE: 'SET_NATIVE_TOKEN',
};

export const login = (usernameOrEmail: string, password: string) => (dispatch: any) => {
  dispatch({
    type: SELF_ACTIONS.AUTH_REQUEST,
  });

  const request = axios.post(ENDPOINT_LOGIN, {
    usernameOrEmail,
    password,
  });

  request
    .then(response => {
      localStorageSet('pltToken', response.data.token);
      localStorageSet('pltSession', JSON.stringify(jwt.decode(response.data.token)));
      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      dispatch({
        type: SELF_ACTIONS.AUTH_SUCCEED,
        payload: jwt.decode(response.data.token),
      });
    })
    .catch(err => {
      localStorage.clear();
      axios.defaults.headers.Authorization = ``;
      dispatch({
        type: SELF_ACTIONS.AUTH_FAILED,
        payload: err.response && err.response.data,
      });
    });

  return request;
};

export const logout = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const { selfReducer } = state;
  if (selfReducer && selfReducer.socket) {
    console.log('Close socket as user logged out!');
    selfReducer.socket.close();
  }

  if (selfReducer && selfReducer.nativeToken) {
    if (isPlatform(`ios`) || isPlatform(`android`)) {
      try {
        const result = await axios({
          method: 'POST',
          url: `${backendConstants.API_PATH}/clear-native-notification`,
          data: {
            os: isPlatform(`ios`) ? `ios` : `android`,
            token: selfReducer.nativeToken,
          },
        });
        console.log('NATIVE NOTIFICATION IS CLEARED SUCCESSFULLY!', result);
      } catch (err) {
        console.log(err);
      }
    }
  }

  localStorage.clear();
  axios.defaults.headers.Authorization = ``;
  dispatch({
    type: SELF_ACTIONS.RESET_STATE,
  });
};

export const checkAuth = () => (dispatch: any) => {
  dispatch({
    type: SELF_ACTIONS.CHECK_REQUEST,
  });

  const request = axios.get(ENDPOINT_CHECK, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('pltToken')}`,
    },
  });

  request
    .then(response => {
      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      localStorageSet('pltToken', response.data.token);
      localStorageSet('pltSession', JSON.stringify(jwt.decode(response.data.token)));
      dispatch({
        type: SELF_ACTIONS.CHECK_SUCCEED,
        payload: jwt.decode(response.data.token),
      });
    })
    .catch(err => {
      localStorage.clear();
      axios.defaults.headers.Authorization = ``;
      dispatch({
        type: SELF_ACTIONS.CHECK_FAILED,
        payload: err.response && err.response.data,
      });
    });

  return request;
};

export const setSocket = (socket: any) => (dispatch: any) => {
  dispatch({
    type: SELF_ACTIONS.SET_SOCKET,
    payload: socket,
  });
};

export const setNativeToken = (token: any) => async (dispatch: any, getState: any) => {
  const state = getState();
  const { selfReducer } = state;
  const isLoggedIn = selfReducer && selfReducer.response && !!selfReducer.response.user;
  if (isLoggedIn && (isPlatform(`ios`) || isPlatform(`android`))) {
    try {
      const result = await axios({
        method: 'POST',
        url: `${backendConstants.API_PATH}/update-native-notification`,
        data: {
          os: isPlatform(`ios`) ? `ios` : `android`,
          token,
        },
      });
      console.log('NATIVE NOTIFICATION IS REGISTERED SUCCESSFULLY!', result && result.data);
    } catch (err) {
      console.log(err);
    }
  }
  dispatch({
    type: SELF_ACTIONS.SET_NATIVE,
    payload: token,
  });
};

export const selfInitialState = {
  socket: null,
  nativeToken: null,
  isRequesting: false,
  error: null,
  response: null,
};

export const selfReducer = (state: any = selfInitialState, action: any) => {
  switch (action.type) {
    case SELF_ACTIONS.SET_SOCKET: {
      return {
        ...state,
        socket: action.payload,
      };
    }
    case SELF_ACTIONS.SET_NATIVE: {
      return {
        ...state,
        nativeToken: action.payload,
      };
    }
    case SELF_ACTIONS.AUTH_REQUEST:
    case SELF_ACTIONS.CHECK_REQUEST: {
      return {
        ...state,
        isRequesting: true,
      };
    }
    case SELF_ACTIONS.AUTH_SUCCEED:
    case SELF_ACTIONS.CHECK_SUCCEED: {
      return {
        ...state,
        isRequesting: false,
        error: null,
        response: action.payload,
      };
    }
    case SELF_ACTIONS.AUTH_FAILED:
    case SELF_ACTIONS.CHECK_FAILED: {
      return {
        ...state,
        isRequesting: false,
        error: (action.payload && action.payload.error) || action.payload,
        response: null,
      };
    }
    case SELF_ACTIONS.RESET_STATE: {
      return selfInitialState;
    }
    default: {
      return state;
    }
  }
};
