import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFab,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { SubHeader } from 'commons/commons.styles';
import { ModalFooterWrapper, ModalWrapper, TitleWrapper, SearchBarModalWrapper } from 'commons/commons.styles';
import ShowContent from 'commons/ShowContent/ShowContent';

import backendConstants from 'backendConstants';
import languages from 'languages';

import * as generalUtils from 'utils/generalUtils';

import { NoTankPlaceholder } from 'pages/ManageTank/Components/NoTankPlaceholder';
import { AddEditClientForm } from '../Forms/AddEditClientForm';
import { AddEditUserClientForm } from 'pages/ManageUser/Forms/AddEditUserClientForm';
import { getStore } from '../../../utils/sessionUtils';
import vnConstants from '../../../vnConstants';

export const SEGMENTS = {
  DETAILS: 'DETAILS',
  TANK: 'TANK',
  USER_CLIENT: 'USER_CLIENT',
  EDIT_CLIENT: 'EDIT_CLIENT',

  ADD_USER_CLIENT: 'ADD_USER_CLIENT',

  SELECT_TANK: 'SELECT_TANK',
  CONFIRM_TANK: 'CONFIRM_TANK',
};

export const ClientChangeModal: React.FC<any> = props => {
  const {
    isOpen,
    onClose,
    onConfirm,
    setConfirm,
    selectedClient,
    selectedUserClient,
    availableEquipments,
    availableTanks,
    userSales,
    user,
  } = props;

  const noEdit = user && [backendConstants.USER_TYPES.SALE, backendConstants.USER_TYPES.TECHNICAL].includes(user.userType);

  const [segment, setSegment] = useState<any>(null);
  const [tankSearch, setTankSearch] = useState<any>('');
  const [selectedTanks, setSelectedTanks] = useState<any>({});
  const [formProps, setFormProps] = useState<any>(null);
  const [formUserProps, setFormUserProps] = useState<any>(null);

  const resetSelectedTanks = () => setSelectedTanks({});
  const resetTankSearch = () => setTankSearch('');
  const resetFormProps = () => setFormProps(null);

  const resetModal = () => {
    resetFormProps();
    resetSelectedTanks();
    resetTankSearch();
  };

  useEffect(() => {
    if (selectedUserClient) {
      return setSegment(SEGMENTS.SELECT_TANK);
    } else {
      if (selectedClient) {
        return setSegment(selectedClient.willCreateUser ? SEGMENTS.ADD_USER_CLIENT : SEGMENTS.DETAILS);
      }
      return setSegment({});
    }
  }, [selectedClient, selectedUserClient]);

  const handleCloseModal = (noReload: boolean) => (
    // event?: any
  ) => {
    onClose && onClose(noReload);
    resetModal();
  };

  const handleConfirmUpdate = _.throttle(async segment => {
    onConfirm &&
      (await onConfirm(selectedClient, {
        selectedUserClient,
        formProps: segment === SEGMENTS.EDIT_CLIENT ? formProps : null,
        formUserProps: segment === SEGMENTS.ADD_USER_CLIENT ? formUserProps : null,
        selectedTanks: segment === SEGMENTS.CONFIRM_TANK ? tanksBySelectedTanks : null,
      }));
    return resetModal();
  }, 3000, { leading: true, trailing: false });

  const handleSegmentChange = generalUtils.handleTargetValue(setSegment);

  const handleSearchChange = generalUtils.handleTargetValue(setTankSearch);

  const handleSelectedTanks = generalUtils.handleSelectedList(setSelectedTanks, selectedTanks);

  const handleRemoveTank = generalUtils.handleRemoveListItem(setSelectedTanks, selectedTanks);

  const tanksBySelectedTanks = useMemo(() => {
    return generalUtils.itemsBySelectedList(selectedTanks, availableTanks, '_id');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTanks]);

  const availableEquipmentTankTypes = useMemo(() => {
    return generalUtils.availableItemTypes(availableEquipments, 'tankType');
  }, [availableEquipments]);

  const availableTanksByTankType = useMemo(() => {
    return generalUtils.availableItemsByItemType(availableTanks, availableEquipmentTankTypes, 'type');
  }, [availableTanks, availableEquipmentTankTypes]);

  const availableTanksByTankTypeAfterSearch = useMemo(() => {
    return availableTanksByTankType.filter((tank: any) => tank.serial.toLowerCase().includes(tankSearch.toLowerCase()));
  }, [availableTanksByTankType, tankSearch]);

  const getHasButton = () => {
    let hasButton = false;
    if (segment === SEGMENTS.EDIT_CLIENT) {
      hasButton = true;
    }
    if (segment === SEGMENTS.CONFIRM_TANK) {
      hasButton = true;
    }
    if (segment === SEGMENTS.ADD_USER_CLIENT) {
      hasButton = true;
    }
    return hasButton;
  };

  const handleFormUpdate = generalUtils.handleFormPropsUpdate(formProps, setFormProps);

  const handleFormUserUpdate = generalUtils.handleFormPropsUpdate(formUserProps, setFormUserProps);

  const handleConfirmAlert = () =>
    setConfirm('Xác nhận thay đổi thông tin khách hàng', async () => {
      return await handleConfirmUpdate(segment);
    });

  const handleConfirmAddUserAlert = () => {
    setConfirm('Xác nhận thêm tài khoản', async () => {
      return await handleConfirmUpdate(segment);
    });
  };

  const handleConfirmDemo = async () => {
    return await handleConfirmUpdate(segment);
  };

  return (
    isOpen && (
      <IonModal isOpen={true} backdropDismiss={false} showBackdrop={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={`start`}>
              <IonButton onClick={handleCloseModal(true)}>Trở lại</IonButton>
            </IonButtons>
            <TitleWrapper>
              <IonTitle>
                {selectedClient && selectedClient.name}
                {selectedUserClient && (
                  <p>
                    <IonText>{selectedUserClient.name}</IonText>
                  </p>
                )}
              </IonTitle>
            </TitleWrapper>
          </IonToolbar>
          <SubHeader>
            {!selectedClient.willCreateUser && !selectedUserClient && (
              <IonSegment className={`ion-padding-bottom`} scrollable={true} onIonChange={handleSegmentChange}>
                <IonSegmentButton value={SEGMENTS.DETAILS} checked={segment === SEGMENTS.DETAILS}>
                  Chi tiết
                </IonSegmentButton>
                <IonSegmentButton value={SEGMENTS.USER_CLIENT} checked={segment === SEGMENTS.USER_CLIENT}>
                  Khoa/TK
                </IonSegmentButton>
                {!noEdit && (
                  <IonSegmentButton value={SEGMENTS.EDIT_CLIENT} checked={segment === SEGMENTS.EDIT_CLIENT}>
                    Thay đổi TT
                  </IonSegmentButton>
                )}
                <IonSegmentButton value={SEGMENTS.TANK} checked={segment === SEGMENTS.TANK}>
                  Danh sách vỏ nợ
                </IonSegmentButton>
              </IonSegment>
            )}
            {selectedUserClient && (
              <IonSegment className={`ion-padding-bottom`} scrollable={true} onIonChange={handleSegmentChange}>
                <IonSegmentButton value={SEGMENTS.SELECT_TANK} checked={segment === SEGMENTS.SELECT_TANK}>
                  Danh sách bình
                </IonSegmentButton>
                <IonSegmentButton value={SEGMENTS.CONFIRM_TANK} checked={segment === SEGMENTS.CONFIRM_TANK}>
                  Bình đã chọn: {tanksBySelectedTanks.length}
                </IonSegmentButton>
              </IonSegment>
            )}
            {segment === SEGMENTS.SELECT_TANK && (
              <SearchBarModalWrapper>
                <IonSearchbar
                  placeholder={`Tìm vỏ theo serial...`}
                  value={tankSearch}
                  onIonChange={handleSearchChange}
                  debounce={250}
                />
              </SearchBarModalWrapper>
            )}
          </SubHeader>
        </IonHeader>
        <ShowContent isShow={true}>
          <IonContent className={`ion-padding`}>
            <ModalWrapper>
              <IonGrid>
                {segment === SEGMENTS.ADD_USER_CLIENT && (
                  <AddEditUserClientForm
                    onFormUpdate={handleFormUserUpdate}
                    selectedClient={selectedClient}
                    selectedUser={selectedUserClient}
                  />
                )}
                {segment === SEGMENTS.EDIT_CLIENT && (
                  <AddEditClientForm selectedClient={selectedClient} userSales={userSales} onFormUpdate={handleFormUpdate} />
                )}
                {segment === SEGMENTS.DETAILS && (
                  <IonList>
                    <IonListHeader>
                      <IonText color={`primary`}>Thông tin chi tiết</IonText>
                    </IonListHeader>
                    <IonItem lines={`none`}>
                      <IonLabel className={`ion-text-wrap`}>
                        <IonText color={`primary`}>
                          <h2>{selectedClient.name}</h2>
                        </IonText>
                        <p>
                          Kho:{' '}
                          <IonText color={selectedClient.store === getStore() ? `primary` : `danger`}>
                            {(vnConstants as any)[selectedClient.store].name}
                          </IonText>
                        </p>
                        <p>
                          <IonText>Loại KH: {languages.manageClient.ui.types[selectedClient.type]}</IonText>
                        </p>
                        <p className={`ion-padding-bottom`}>
                          <IonText>{selectedClient.address}</IonText>
                        </p>
                        <p className={`ion-padding-bottom`}>
                          <IonText color={`primary`}>Hình thức thanh toán: </IonText>
                          <br />
                          <IonText>{languages.manageClient.ui.chargeType[selectedClient.chargeType]}</IonText>
                        </p>
                        <p>
                          <IonText color={`primary`}>Người liên hệ: </IonText>
                          <br />
                          <IonText>{selectedClient.primaryContactName}</IonText>
                        </p>
                        <p>
                          <IonText color={`primary`}>Chức vụ: </IonText>
                          <br />
                          <IonText>{selectedClient.primaryContactPosition}</IonText>
                        </p>
                        <p>
                          <IonText color={`primary`}>SDT: </IonText>
                          <br />
                          <IonText>{selectedClient.primaryContactNumber}</IonText>
                        </p>
                        <p className={`ion-padding-bottom`}>
                          <IonText color={`primary`}>Email: </IonText>
                          <br />
                          <IonText>{selectedClient.primaryContactEmail}</IonText>
                        </p>
                        <p className={`ion-padding-bottom`}>
                          <IonText color={`primary`}>Số vỏ nợ: </IonText>
                          <br />
                          <IonText>{selectedClient.totalTank} vỏ nợ</IonText>
                        </p>
                        <p className={`ion-padding-bottom`}>
                          <IonText color={`primary`}>Số máy đang sử dụng: </IonText>
                          <br />
                          <IonText>{selectedClient.equipments.length} máy</IonText>
                        </p>
                        <p>
                          <IonText color={`primary`}>Kinh doanh/Chăm sóc: </IonText>
                          <br />
                          <IonText>{selectedClient.parentId.name}</IonText>
                        </p>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                )}

                {segment === SEGMENTS.TANK &&
                  (selectedClient.willCreateUser ? (
                    <NoTankPlaceholder />
                  ) : availableTanks && !!availableTanks.length ? (
                    <IonList>
                      <IonListHeader>
                        <IonText color={`primary`}>Số vỏ nợ: {availableTanks.length}</IonText>
                      </IonListHeader>
                      {availableTanks.map((tank: any) => (
                        <IonItem key={tank._id}>
                          <IonLabel
                            className={`ion-text-wrap`}
                            color={moment().diff(moment(tank.updatedAt), 'days') >= 90 ? 'danger' : undefined}
                          >
                            {tank.serial}
                            <p>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</p>
                            <p>Ngày xuất: {moment(tank.updatedAt).format('DD/MM/YY HH:mm')}</p>
                            {tank.clientUserId && tank.clientUserId.name && <p>{tank.clientUserId.name}</p>}
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  ) : (
                    <NoTankPlaceholder />
                  ))}

                {segment === SEGMENTS.USER_CLIENT && (
                  <IonList>
                    <IonListHeader>
                      <IonText color={`primary`}>Số khoa/tài khoản: {selectedClient.userClients.length}</IonText>
                    </IonListHeader>
                    {selectedClient.userClients.map((userClient: any) => {
                      const userClientEquipments = availableEquipments
                        ? availableEquipments.filter((equipment: any) => userClient.equipments.includes(equipment._id))
                        : [];
                      return (
                        <IonItem key={userClient._id}>
                          <IonGrid className={`ion-padding-top`}>
                            <IonLabel className={`ion-text-wrap ion-padding-bottom`}>
                              <IonText color={`primary`}>
                                <h2 className={`ion-padding-bottom`}>{userClient.name}</h2>
                              </IonText>
                              {userClient.reportExcluded === backendConstants.USER_CLIENT_REPORT_EXCLUDED.YES && (
                                <p>
                                  <IonText color={`danger`}>Kho tạm</IonText>
                                </p>
                              )}
                              <p>
                                Kho:{' '}
                                <IonText color={userClient.store === getStore() ? `primary` : `danger`}>
                                  {(vnConstants as any)[userClient.store].name}
                                </IonText>
                              </p>
                              {userClient.status === backendConstants.USER_STATUSES.DISABLED && (
                                <p>
                                  <IonText color={`danger`}>Không hoạt động</IonText>
                                </p>
                              )}
                              <p>
                                <IonText color={`primary`}>Tài khoản: </IonText>
                                <br />
                                <IonText>{userClient.username}</IonText>
                              </p>
                              <p>
                                <IonText color={`primary`}>Người tiếp nhận: </IonText>
                                <br />
                                <IonText>{userClient.contactName}</IonText>
                              </p>
                              <p>
                                <IonText color={`primary`}>SDT: </IonText>
                                <br />
                                <IonText>{userClient.contactNumber}</IonText>
                              </p>
                              <p>
                                <IonText color={`primary`}>Email: </IonText>
                                <br />
                                <IonText>{userClient.email}</IonText>
                              </p>
                            </IonLabel>
                            <IonList>
                              <IonListHeader>
                                <IonLabel color={`primary`}>Sử dụng {userClientEquipments.length} máy</IonLabel>
                              </IonListHeader>
                              {userClientEquipments.map((equipment: any) => (
                                <IonItem key={equipment._id} lines={`none`}>
                                  <IonLabel>
                                    <p>
                                      <IonText>{equipment.serial}</IonText>
                                    </p>
                                    <p>
                                      <IonText color={`primary`}>{equipment.name}</IonText>
                                    </p>
                                    <p>
                                      <IonText>Loại máy: {(languages.manageEquipment.ui.types as any)[equipment.type]}</IonText>
                                    </p>
                                    <p>
                                      <IonText>
                                        Trạng thái: {(languages.manageEquipment.ui.statuses as any)[equipment.status]}
                                      </IonText>
                                    </p>
                                    <p>
                                      <IonText>Dạng bình: {(languages.manageTank.ui as any)[equipment.tankType]}</IonText>
                                    </p>
                                    {equipment.maintainOption === backendConstants.EQUIPMENT_MAINTAIN_OPTIONS.YES ? (
                                      equipment.maintainedAt && (
                                        <p>
                                          Lịch bảo dưỡng:{' '}
                                          <IonText
                                            color={
                                              moment().diff(moment(equipment.maintainedAt), 'month') >= backendConstants.EQUIPMENT_MAINTAIN_MONTH ? `danger` : undefined
                                            }
                                          >
                                            {moment(equipment.maintainedAt)
                                              .add(backendConstants.EQUIPMENT_MAINTAIN_MONTH, 'month')
                                              .format('DD/MM/YYYY')}
                                          </IonText>
                                        </p>
                                      )
                                    ) : (
                                      <p>Không bảo dưỡng</p>
                                    )}
                                  </IonLabel>
                                </IonItem>
                              ))}
                            </IonList>
                          </IonGrid>
                        </IonItem>
                      );
                    })}
                  </IonList>
                )}

                {segment === SEGMENTS.SELECT_TANK &&
                  (availableTanks && !!availableTanks.length ? (
                    <IonList>
                      <IonListHeader>
                        <IonText color={`primary`}>Số bình khả dụng: {availableTanks.length}</IonText>
                      </IonListHeader>
                      {availableTanksByTankTypeAfterSearch.map((tank: any) => (
                        <IonItem key={tank.serial}>
                          <IonLabel>
                            <IonText>{tank.serial}</IonText>
                            {tank.clientUserId && (
                              <p>
                                <IonText color={`primary`}>{tank.clientUserId.name}</IonText>
                              </p>
                            )}
                            <p>
                              <IonText>{(languages.manageTank.ui as any)[tank.type]}</IonText>
                            </p>
                          </IonLabel>
                          <IonCheckbox
                            slot={`start`}
                            onIonChange={handleSelectedTanks(tank._id)}
                            checked={(selectedTanks as any)[tank._id]}
                          />
                        </IonItem>
                      ))}
                    </IonList>
                  ) : (
                    <NoTankPlaceholder />
                  ))}

                {segment === SEGMENTS.CONFIRM_TANK &&
                  (!!tanksBySelectedTanks.length ? (
                    <IonList>
                      <IonListHeader>
                        <IonText color={`primary`}>
                          {selectedClient.name} {selectedUserClient.name}
                        </IonText>
                      </IonListHeader>
                      {tanksBySelectedTanks.map((tank: any) => (
                        <IonItemSliding key={tank._id}>
                          <IonItem>
                            <IonLabel>
                              <IonText>{tank.serial}</IonText>
                            </IonLabel>
                          </IonItem>
                          {tanksBySelectedTanks.length > 1 && (
                            <IonItemOptions side={`end`}>
                              <IonItemOption color={`danger`} onClick={handleRemoveTank(tank._id)}>
                                Loại bỏ
                              </IonItemOption>
                            </IonItemOptions>
                          )}
                        </IonItemSliding>
                      ))}
                    </IonList>
                  ) : (
                    <NoTankPlaceholder />
                  ))}
              </IonGrid>
            </ModalWrapper>
          </IonContent>
        </ShowContent>
        <IonFooter>
          {getHasButton() && (
            <ModalFooterWrapper>
              <IonFab vertical={`top`} horizontal={`end`} slot={`fixed`}>
                {segment === SEGMENTS.CONFIRM_TANK && (
                  <IonButton color={`danger`} onClick={handleConfirmDemo} disabled={!tanksBySelectedTanks.length}>
                    <IonLabel>Xuất Demo</IonLabel>
                    <IonBadge color={`light`}>{tanksBySelectedTanks.length}</IonBadge>
                  </IonButton>
                )}
                {segment === SEGMENTS.EDIT_CLIENT && (
                  <IonButton
                    color={`primary`}
                    onClick={handleConfirmAlert}
                    disabled={!formProps || !formProps.dirty || !formProps.isValid}
                  >
                    <IonLabel>Xác nhận thay đổi TT</IonLabel>
                  </IonButton>
                )}
                {segment === SEGMENTS.ADD_USER_CLIENT && (
                  <IonButton
                    color={`primary`}
                    onClick={handleConfirmAddUserAlert}
                    disabled={!formUserProps || !formUserProps.dirty || !formUserProps.isValid}
                  >
                    <IonLabel>Thêm tài khoản</IonLabel>
                  </IonButton>
                )}
              </IonFab>
            </ModalFooterWrapper>
          )}
        </IonFooter>
      </IonModal>
    )
  );
};

export default ClientChangeModal;
