import { generateCrudActionTypes, generateThunkActions, generateCrudReducer } from 'utils/storeUtils';

import { API_PATH } from 'backendConstants';

export const ENDPOINT = `${API_PATH}/user`;
export const USER_CRUD_ACTIONS = generateCrudActionTypes(`USER_CRUD`);

export const {
  check: checkUsers,
  read: readUsers,
  create: createUser,
  update: updateUser,
  updateBatch: updateUsers,
  resetState: resetUsers,
} = generateThunkActions(USER_CRUD_ACTIONS, ENDPOINT);

export const userReducer = generateCrudReducer(USER_CRUD_ACTIONS);
