import React from 'react';
import {
  IonItem,
  IonLabel,
  IonText,
  IonModal,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonHeader,
  IonContent,
} from '@ionic/react';
import languages from 'languages';
import moment from 'moment-timezone';
import { ModalDateRange, TankTable, TableTitle } from '../Report.styles';
import { SubHeader } from 'commons/commons.styles';
import { BY_TABS, SORT_TYPES } from '../Report';

export const ReportModal: React.FC<any> = props => {
  const { isOpen, onClose, byTab, selectedEntry, dateRange } = props;

  const handleCloseModal = (noReload: boolean) => (
    // event?: any
  ) => {
    onClose && onClose(noReload);
  };

  const clientTanks = selectedEntry && selectedEntry.userClients;
  const userClientTanks = selectedEntry && selectedEntry.tanks && [selectedEntry];

  const selectedTanks = clientTanks || userClientTanks;

  return selectedEntry && isOpen ? (
    <IonModal isOpen={true} showBackdrop={true} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={`start`}>
            <IonButton onClick={handleCloseModal(true)}>Trở lại</IonButton>
          </IonButtons>
          <IonTitle>{selectedEntry.name}</IonTitle>
        </IonToolbar>
        {dateRange && (
          <SubHeader>
            <ModalDateRange className={`ion-text-center`}>
              {moment(dateRange.startDate).format('DD/MM/YYYY')}
              {' - '}
              {moment(dateRange.endDate).format('DD/MM/YYYY')}
            </ModalDateRange>
          </SubHeader>
        )}
      </IonHeader>
      <IonContent>
        {byTab === BY_TABS.CLIENT && (
          <div className={`ion-padding`}>
            {selectedTanks &&
              selectedTanks.map((userClient: any) => {
                const saleTanks = userClient.tanks && userClient.tanks.filter((t: any) => !t.isDemo);
                const demoTanks = userClient.tanks && userClient.tanks.filter((t: any) => t.isDemo);
                return (
                  <React.Fragment key={userClient._id}>
                    <IonItem lines={`none`} className={`ion-padding-bottom`}>
                      <IonLabel>
                        <h2>
                          <IonText color={`primary`}>{userClient.name}</IonText>
                        </h2>
                        <p>Đang sử dụng: {userClient.equipments.length} máy</p>
                        <p>
                          Bán: <IonText color={`secondary`}>{saleTanks.length} bình</IonText>
                          {demoTanks && !!demoTanks.length && (
                            <React.Fragment>
                              , Demo: <IonText color={`danger`}>{demoTanks.length} bình</IonText>
                            </React.Fragment>
                          )}
                        </p>
                        <p>
                          Thu hồi: <IonText color={`danger`}>{userClient.toStoreTanks.length} vỏ</IonText>
                        </p>
                      </IonLabel>
                    </IonItem>
                    {userClient.tanks && !!userClient.tanks.length && (
                      <React.Fragment>
                        <TableTitle>
                          <h6>Bình đã bán: </h6>
                          <p>{userClient.name}</p>
                        </TableTitle>
                        <TankTable>
                          <tbody>
                            {userClient.tanks.map((tank: any, tankIndex: any) => (
                              <tr key={tankIndex.toString() + tank.serial + tank.date}>
                                <td>
                                  <IonText color={tank.isDemo ? `danger` : undefined}>{tank.serial}</IonText>
                                </td>
                                <td>
                                  <IonText color={tank.isDemo ? `danger` : undefined}>{tank.date}</IonText>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TankTable>
                      </React.Fragment>
                    )}
                    {userClient.toStoreTanks && !!userClient.toStoreTanks.length && (
                      <React.Fragment>
                        <TableTitle>
                          <h6>Vỏ đã thu hồi: </h6>
                          <p>{userClient.name}</p>
                        </TableTitle>
                        <TankTable>
                          <tbody>
                            {userClient.toStoreTanks.map((tank: any, tankIndex: any) => (
                              <tr key={tankIndex.toString() + tank.serial + tank.date}>
                                <td>
                                  <IonText>{tank.serial}</IonText>
                                </td>
                                <td>
                                  <IonText>{tank.date}</IonText>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TankTable>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        )}
        {byTab === BY_TABS.DELIVERY && (
          <div className={`ion-padding`}>
            <TankTable>
              <thead>
                <tr>
                  <td>Tên khoa/KH</td>
                  <td className={`ion-text-right`}>Đơn</td>
                  <td className={`ion-text-right`}>Bình</td>
                </tr>
              </thead>
              <tbody>
                {Object.values(selectedEntry.userClients)
                  .sort((a: any, b: any) => ((b && b.length) || 0) - ((a && a.length) || 0))
                  .map(
                    (userClientInvoices: any) =>
                      userClientInvoices &&
                      !!userClientInvoices.length &&
                      userClientInvoices[0].clientUserInfo && (
                        <tr key={userClientInvoices[0].clientUserId}>
                          <td>
                            <IonText>{userClientInvoices[0].clientUserInfo.name}</IonText>
                          </td>
                          <td className={`ion-text-right`}>
                            <IonText color={`primary`}>{userClientInvoices.length}</IonText>
                          </td>
                          <td className={`ion-text-right`}>
                            <IonText color={`danger`}>
                              {userClientInvoices.reduce(
                                (totalTank: any, invoice: any) =>
                                  totalTank + ((invoice.tankSerials && invoice.tankSerials.length) || 0),
                                0
                              )}
                            </IonText>
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </TankTable>
          </div>
        )}
        {byTab === BY_TABS.EQUIPMENT &&
          Object.keys(selectedEntry.byType)
            .sort((a: any, b: any) => (SORT_TYPES as any)[a] - (SORT_TYPES as any)[b])
            .map((type: any) => (
              <div key={type} className={`ion-padding`}>
                <h4>{languages.manageEquipment.ui.types[type]}</h4>
                <TankTable>
                  <thead>
                    <tr>
                      <td>Serial</td>
                      <td>Ngày</td>
                      <td>Khách hàng</td>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEntry.byType[type]
                      .sort((eqA: any, eqB: any) => moment(eqB.updatedAt).diff(moment(eqA.updatedAt), 'day'))
                      .map((equipment: any) => (
                        <tr key={equipment.serial + '-' + equipment.updatedAt}>
                          <td>{equipment.serial}</td>
                          <td>{moment(equipment.updatedAt).format('DD/MM/YYYY')}</td>
                          <td>{equipment.clientName}</td>
                        </tr>
                      ))}
                  </tbody>
                </TankTable>
              </div>
            ))}
      </IonContent>
    </IonModal>
  ) : null;
};

export default ReportModal;
