import styled from 'styled-components';

export const ClientWrapper = styled.div`
  //ion-item-divider {
  //  padding: 0 15px;
  //  background-color: var(--ion-color-light);
  //  transition: background-color 0.15s ease-out;
  //  &:active {
  //    background-color: var(--ion-color-light-shade);
  //  }
  //  border-radius: 4px;
  //}
`;
