import React, { useState, useMemo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonIcon,
  IonGrid,
  IonSegment,
  IonSegmentButton,
  IonSearchbar,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonCol,
  IonSpinner,
  IonList,
  IonListHeader,
  IonText,
  IonBadge,
  IonButton,
  IonItemGroup,
  IonItemDivider,
  IonFab,
} from '@ionic/react';
import language from 'languages';
import Header from 'commons/Header/Header';
import { arrowDown, arrowUp } from 'ionicons/icons';
import { AddEditUserForm } from './Forms/AddEditUserForm';
import { SubHeader, Wrapper } from 'commons/commons.styles';
import ShowContent from 'commons/ShowContent/ShowContent';
import languages from 'languages';
import qs from 'querystring';
import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as confirmActions from 'store/confirm';
import * as userClientActions from 'store/userClient';
import * as userActions from 'store/user';
import _ from 'lodash';
import { RefresherEventDetail } from '@ionic/core';
import backendConstants from 'backendConstants';
import * as generalUtils from 'utils/generalUtils';
import routes from 'routes';
import { ManageUserModal } from './Modals/ManageUserModal';
import { getStore, isCenterStore } from 'utils/sessionUtils';
import vnConstants from '../../vnConstants';

const USER_TAB_KEYS = {
  ADD: 'ADD',
  LIST: 'LIST',
  USER_CLIENT: 'USER_CLIENT',
};

const USER_EXPAND_GROUPS = {
  [backendConstants.USER_TYPES.SALE]: backendConstants.USER_TYPES.SALE,
  [backendConstants.USER_TYPES.DELIVERY]: backendConstants.USER_TYPES.DELIVERY,
  [backendConstants.USER_TYPES.TECHNICAL]: backendConstants.USER_TYPES.TECHNICAL,
};

const ManageUser: React.FC<any> = props => {
  const {
    userReducer,
    userClientReducer,

    setSubmitting,
    setToast,
    setConfirm,

    // updateUserClient,
    checkUserClients,
    resetUserClients,

    readUsers,
    createUser,
    updateUser,
    resetUsers,

    history,
    location,
  } = props;
  const queryParams = qs.parse(location.search.replace('?', ''));

  const [refreshing, setRefreshing] = useState(false);
  const [userTab, setUserTab] = useState<any>((queryParams.userTab as string) || USER_TAB_KEYS.LIST);
  const [clientSearch, setClientSearch] = useState<string>('');
  const [userTypeExpanded, setUserTypeExpanded] = useState({});
  const [groupExpanded, setGroupExpanded] = useState({});
  const [formProps, setFormProps] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedUserClient, setSelectedUserClient] = useState<any>(null);

  useEffect(() => {
    if (queryParams.userTab) {
      setUserTab(queryParams.userTab as any);
    }
  }, [queryParams.userTab]);

  const showContent = useMemo(() => {
    return location.pathname === routes.manageUser.main;
  }, [location.pathname]);

  useEffect(() => {
    resetCurrentUserTab();
    if (showContent) {
      handleReadUser();
      handleReadUserClient();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent, userTab]);

  const resetGroupExpanded = () => setGroupExpanded({});
  const resetClientSearch = () => setClientSearch('');
  const resetFormProps = () => setFormProps(null);
  const resetSelectedUser = () => setSelectedUser(null);
  const resetSelectedClient = () => setSelectedClient(null);
  const resetSelectedUserClient = () => {
    resetSelectedClient();
    setSelectedUserClient(null);
  };

  const resetCurrentUserTab = () => {
    // Reset Content Scroll
    resetFormProps();
    resetGroupExpanded();
    resetClientSearch();
    resetSelectedUser();
    resetSelectedClient();
    resetSelectedUserClient();
    resetContentScrollTop();
    resetUserClients();
    resetUsers();
  };

  const users = userReducer && userReducer.responseRead && userReducer.responseRead.data;

  const userClients = userClientReducer && userClientReducer.responseCheck && userClientReducer.responseCheck.data;

  // useEffect(() => {
  //   let initialExpandState = usersByUserTypes.reduce(
  //     (expanded, userTypeGroup) => {
  //       if (userTypeGroup.userType) {
  //         return { ...expanded, [userTypeGroup.userType]: true };
  //       }
  //       return expanded;
  //     },
  //     {}
  //   );
  //   if (!_.isEmpty(initialExpandState)) {
  //     setUserTypeExpanded(initialExpandState);
  //   }
  // }, [usersByUserTypes]);

  const clientsByUserClients = useMemo(() => {
    if (!userClients || !showContent) return [];
    const groups = _.groupBy(userClients, userClient => userClient.clientId._id);

    if (Object.keys(groups).length <= 1 && Object.keys(groups)[0] === 'undefined') return [];

    return Object.values(groups).map(group => ({
      ...group[0].clientId,
      userClients: group,
    }));
  }, [userClients, showContent]);

  const usersByUserTypes = useMemo(() => {
    if (!users) return [];
    return Object.keys(USER_EXPAND_GROUPS).map((userType: any) => ({
      userType,
      users: users
        .filter((user: any) => user.userType === userType)
        .sort((userA: any, userB: any) => userA.name.localeCompare(userB.name))
        .map((user: any) => ({
          ...user,
          clients:
            user.userType === backendConstants.USER_TYPES.SALE
              ? clientsByUserClients.filter(
                  (client: any) => client.parentId && (client.parentId === user._id || client.parentId._id === user._id)
                )
              : undefined,
        })),
    }));
  }, [users, clientsByUserClients]);

  const clientsByClientSearch = useMemo(() => {
    if (!clientsByUserClients || !clientsByUserClients.length) return [];
    return clientsByUserClients
      .sort((clientA, clientB) => clientA.name.localeCompare(clientB.name))
      .filter(client => client.name.toLowerCase().includes(clientSearch.toLowerCase()));
  }, [clientsByUserClients, clientSearch]);

  const handleReadUser = () => {
    return readUsers({
      page: 1,
      pageSize: 99999,
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        userType: {
          $in: [backendConstants.USER_TYPES.SALE, backendConstants.USER_TYPES.DELIVERY, backendConstants.USER_TYPES.TECHNICAL],
        },
      },
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleReadUserClient = () => {
    return checkUserClients({
      page: 1,
      pageSize: 99999,
      filter: {
        store: isCenterStore() ? undefined : getStore(),
        // status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
      populate: [
        {
          path: 'clientId',
          populate: {
            path: 'parentId',
          },
        },
      ],
      sort: {
        updatedAt: -1,
      },
    });
  };

  const handleSearchChange = (event: any) => {
    if (event && event.target) {
      setClientSearch(event.target.value || '');
    }
  };

  const handleUserTabChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      setUserTab(event.detail.value);
      history.push(`${routes.manageUser.main}?userTab=${event.detail.value}`);
    }
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    handleReadUser();
    handleReadUserClient();
    setRefereshingDebounced(event);
  };

  const handleSelectedUserClient = (userClient: any, client: any) => () => {
    if (userClient) {
      setSelectedUserClient(userClient);
      setSelectedClient(client);
    }
  };

  const handleSelectedUser = (user: any) => () => {
    if (user) {
      setSelectedUser(user);
    }
  };

  const handleFormUpdate = generalUtils.handleFormPropsUpdate(formProps, setFormProps);

  const handleExpandGroup = (groupStatus: string) => () =>
    setGroupExpanded({
      ...groupExpanded,
      [groupStatus]: !(groupExpanded as any)[groupStatus],
    });

  const handleUserTypeExpanded = (userType: string) => () =>
    setUserTypeExpanded({
      ...userTypeExpanded,
      [userType]: !(userTypeExpanded as any)[userType],
    });

  const handleConfirmAlert = _.throttle(() => setConfirm('Xác nhận thêm tài khoản mới', handleConfirmAdd), 3000, { leading: true, trailing: false });

  const resetSubmittingDebounced = _.debounce((isSuccessful, successMessage, failedMessage) => {
    if (isSuccessful) {
      setToast(successMessage, `primary`);
    } else {
      setToast(failedMessage, `danger`);
    }
    resetCurrentUserTab();
    handleReadUser();
    handleReadUserClient();
    setSubmitting(false);
  }, 500);

  const handleConfirmAdd = async () => {
    const messages = [`Thêm tài khoản thành công`, `Thêm tài khoản không thành công`];
    setSubmitting(true);
    try {
      await createUser({
        store: getStore(),
        ...formProps.values,
      });
      return resetSubmittingDebounced(true, messages[0], messages[1]);
    } catch (err) {
      return resetSubmittingDebounced(false, messages[0], messages[1]);
    }
  };

  const handleUserUpdate = async (selectedUserForm: any, selectedUserClientForm: any) => {
    const messages = [
      `Thay đổi ${selectedUserClientForm ? `Khoa/` : ``}TK thành công`,
      `Thay đổi ${selectedUserClientForm ? `Khoa/` : ``}TK không thành công`,
    ];
    try {
      if (selectedUserForm) {
        await updateUser({
          ...selectedUserForm.values,
        });
      }
      if (selectedUserClientForm) {
        await updateUser({
          ...selectedUserClientForm.values,
        });
      }
      return resetSubmittingDebounced(true, messages[0], messages[1]);
    } catch (err) {
      return resetSubmittingDebounced(false, messages[0], messages[1]);
    }
  };

  return (
    <Wrapper>
      <IonPage>
        <Header title={language.manageUser.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleUserTabChange} scrollable={true}>
              <IonSegmentButton value={USER_TAB_KEYS.ADD} checked={userTab === USER_TAB_KEYS.ADD}>
                {language.manageUser.ui.tabs.ADD}
              </IonSegmentButton>
              <IonSegmentButton value={USER_TAB_KEYS.LIST} checked={userTab === USER_TAB_KEYS.LIST}>
                {language.manageUser.ui.tabs.LIST}
              </IonSegmentButton>
              <IonSegmentButton value={USER_TAB_KEYS.USER_CLIENT} checked={userTab === USER_TAB_KEYS.USER_CLIENT}>
                {language.manageUser.ui.tabs.USER_CLIENT}
              </IonSegmentButton>
            </IonSegment>
            {userTab === USER_TAB_KEYS.USER_CLIENT && (
              <IonSearchbar
                placeholder={`Tìm tên khách hàng ...`}
                value={clientSearch}
                onIonChange={handleSearchChange}
                debounce={250}
              />
            )}
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
              <IonRefresherContent />
            </IonRefresher>

            <ManageUserModal
              isOpen={!!selectedClient && !!selectedUserClient}
              selectedClient={selectedClient}
              selectedUserClient={selectedUserClient}
              onClose={resetSelectedUserClient}
              onConfirm={handleUserUpdate}
              setConfirm={setConfirm}
              confirmLabel={`Thay đổi thông tin Khoa/TK KH`}
            />

            <ManageUserModal
              isOpen={!!selectedUser}
              title={selectedUser && selectedUser.name}
              selectedUser={selectedUser}
              onClose={resetSelectedUser}
              onConfirm={handleUserUpdate}
              setConfirm={setConfirm}
              confirmLabel={`Thay đổi thông tin TK`}
            />

            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {userClientReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    {userTab === USER_TAB_KEYS.ADD && <AddEditUserForm onFormUpdate={handleFormUpdate} />}
                    {userTab === USER_TAB_KEYS.LIST && (
                      <IonList className={`ion-margin-vertical`}>
                        <IonListHeader>Kết quả: {users ? users.length : 0} tài khoản</IonListHeader>
                        {usersByUserTypes.map((usersByUserType: any) => (
                          <IonItemGroup key={usersByUserType.userType}>
                            <IonItemDivider onClick={handleUserTypeExpanded(usersByUserType.userType)}>
                              <IonLabel>{languages.manageUser.ui.userTypes[usersByUserType.userType]}</IonLabel>
                              {usersByUserType.users && (
                                <IonBadge color={`medium`} slot={`end`}>
                                  {usersByUserType.users.length}
                                </IonBadge>
                              )}
                              <IonIcon
                                icon={(userTypeExpanded as any)[usersByUserType.userType] ? arrowUp : arrowDown}
                                slot={`end`}
                              />
                            </IonItemDivider>
                            {(userTypeExpanded as any)[usersByUserType.userType] &&
                              usersByUserType.users.map((user: any) => (
                                <IonItem key={user._id} button={true} onClick={handleSelectedUser(user)}>
                                  <IonLabel>
                                    <p>
                                      <IonText color={`primary`}>{user.name}</IonText>
                                    </p>
                                    <p>
                                      Kho:{' '}
                                      <IonText color={user.store === getStore() ? `primary` : `danger`}>
                                        {(vnConstants as any)[user.store].name}
                                      </IonText>
                                    </p>
                                    {user.status === backendConstants.USER_STATUSES.DISABLED && (
                                      <p>
                                        <IonText color={`danger`}>Không hoạt động</IonText>
                                      </p>
                                    )}
                                    <p>
                                      <IonText>{user.email}</IonText>
                                    </p>
                                    {user.userType === backendConstants.USER_TYPES.SALE && (
                                      <p>{(user.clients && user.clients.length) || 0} khách hàng</p>
                                    )}
                                  </IonLabel>
                                </IonItem>
                              ))}
                          </IonItemGroup>
                        ))}
                      </IonList>
                    )}
                    {userTab === USER_TAB_KEYS.USER_CLIENT && (
                      <IonList className={`ion-margin-vertical`}>
                        <IonListHeader>Kết quả: {userClients ? userClients.length : 0} Khoa/TK khách hàng</IonListHeader>
                        {clientsByClientSearch &&
                          clientsByClientSearch.map((client: any) => (
                            <IonItemGroup key={client._id}>
                              <IonItemDivider
                                onClick={
                                  client.status === backendConstants.CLIENT_STATUSES.ACTIVE
                                    ? handleExpandGroup(client._id)
                                    : undefined
                                }
                              >
                                <IonLabel>
                                  <IonText
                                    color={client.status === backendConstants.CLIENT_STATUSES.ACTIVE ? undefined : `danger`}
                                  >
                                    {client.name}
                                  </IonText>
                                </IonLabel>
                                {client.userClients && (
                                  <IonBadge color={`medium`} slot={`end`}>
                                    {client.userClients.length}
                                  </IonBadge>
                                )}
                                <IonIcon icon={(groupExpanded as any)[client._id] ? arrowUp : arrowDown} slot={`end`} />
                              </IonItemDivider>
                              {(groupExpanded as any)[client._id] &&
                                client.userClients.map((userClient: any) => (
                                  <IonItem
                                    key={userClient._id}
                                    button={true}
                                    onClick={handleSelectedUserClient(userClient, client)}
                                  >
                                    <IonLabel>
                                      <p>
                                        <IonText color={`primary`}>{userClient.name}</IonText>
                                      </p>
                                      <p>
                                        Kho:{' '}
                                        <IonText color={userClient.store === getStore() ? `primary` : `danger`}>
                                          {(vnConstants as any)[userClient.store].name}
                                        </IonText>
                                      </p>
                                      {userClient.reportExcluded === backendConstants.USER_CLIENT_REPORT_EXCLUDED.YES && (
                                        <p>
                                          <IonText color={`danger`}>Kho tạm</IonText>
                                        </p>
                                      )}
                                      {userClient.status === backendConstants.USER_STATUSES.DISABLED && (
                                        <p>
                                          <IonText color={`danger`}>Không hoạt động</IonText>
                                        </p>
                                      )}
                                      <p>
                                        <IonText>Số máy sử dụng: {userClient.equipments.length} máy</IonText>
                                      </p>
                                      <p>
                                        <IonText>Sổ vỏ nợ: {userClient.tanks.length} vỏ</IonText>
                                      </p>
                                    </IonLabel>
                                  </IonItem>
                                ))}
                            </IonItemGroup>
                          ))}
                      </IonList>
                    )}
                  </div>
                )}
              </div>
            </IonGrid>
            {userTab === USER_TAB_KEYS.ADD && (
              <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
                <IonButton
                  color={`primary`}
                  onClick={handleConfirmAlert}
                  disabled={!formProps || !formProps.dirty || !formProps.isValid}
                >
                  <IonLabel>{`Tạo tài khoản`}</IonLabel>
                </IonButton>
              </IonFab>
            )}
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  userReducer: state.userReducer,
  userClientReducer: state.userClientReducer,
});

const mapDispatchToProps = {
  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,
  setConfirm: confirmActions.setConfirm,

  updateUserClient: userClientActions.updateUserClient,
  checkUserClients: userClientActions.checkUserClients,
  resetUserClients: userClientActions.resetUserClients,

  createUser: userActions.createUser,
  updateUser: userActions.updateUser,
  readUsers: userActions.readUsers,
  resetUsers: userActions.resetUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
