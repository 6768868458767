import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import qs from 'querystring';
import {
  IonContent,
  IonPage,
  IonItem,
  IonItemGroup,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonText,
  IonSearchbar,
  IonCheckbox,
  IonItemDivider,
  IonBadge,
  IonFab,
  IonButton,
  IonRefresherContent,
  IonRefresher,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import { arrowUp, arrowDown } from 'ionicons/icons';
import { TankTestItem } from './ManageTank.styles';
import { Wrapper, SubHeader } from 'commons/commons.styles';
import * as generalUtils from 'utils/generalUtils';
import backendConstants from 'backendConstants';
import languages from 'languages';

import Header from 'commons/Header/Header';
import ShowContent from 'commons/ShowContent/ShowContent';

import TankStatusChangeModal from './Modals/TankStatusChangeModal';

import * as submittingActions from 'store/submitting';
import * as toastActions from 'store/toast';
import * as tankActions from 'store/tank';
import * as userClientActions from 'store/userClient';
import * as equipmentActions from 'store/equipment';
import * as invoiceActions from 'store/invoice';
import * as allActions from 'store/all';
import moment from 'moment-timezone';
import { RefresherEventDetail } from '@ionic/core';
import routes from 'routes';
import { TEST_SERIAL, MIN_CHAR } from './ManageTank.constants';
import { NoTankPlaceholder } from './Components/NoTankPlaceholder';
import { getStore, isCenterStore } from 'utils/sessionUtils';
import vnConstants from '../../vnConstants';

export const NormalTankList: React.FC<any> = props => {
  const { tanks, onCheckboxChange, selectedTanks, noCheckbox, tankStatus } = props;
  const [tankScrollCount, setTankScrollCount] = useState(50);
  const handleTankCountIncrement = (e: any) => {
    setTankScrollCount(tankScrollCount + 50);
    e && e.target && e.target.complete();
  };
  return tanks && !!tanks.length ? (
    <React.Fragment>
      {tanks.slice(0, tankScrollCount).map((tank: any) => {
        const isDanger = moment().diff(moment(tank.updatedAt), 'days') >= 90;
        return (
          <IonItem key={tank.serial}>
            <IonLabel>
              <IonText color={isDanger ? `danger` : undefined}>{tank.serial}</IonText>
              <p>
                <IonText color={isDanger ? `danger` : undefined}>
                  Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}
                </IonText>
              </p>
              {tank.clientUserId && (
                <p>
                  <IonText color={isDanger ? `danger` : `primary`}>{tank.clientUserId.name}</IonText>
                </p>
              )}
              {tank.updatedAt && (
                <p className={`ion-fixed-end`}>
                  <IonText color={isDanger ? `danger` : undefined}>{moment(tank.updatedAt).format('DD/MM/YYYY HH:mm')}</IonText>
                </p>
              )}
            </IonLabel>
            {!noCheckbox && (
              <IonCheckbox
                slot={`start`}
                onIonChange={onCheckboxChange(tank.serial)}
                checked={(selectedTanks as any)[tank.serial]}
              />
            )}
          </IonItem>
        );
      })}
      <IonInfiniteScroll threshold={`300px`} onIonInfinite={handleTankCountIncrement}>
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </React.Fragment>
  ) : (
    <NoTankPlaceholder tankStatus={tankStatus} />
  );
};

export const GroupedTankList: React.FC<any> = props => {
  const [groupExpanded, setGroupExpanded] = useState({});
  const { groupedTanks, onCheckboxChange, selectedTanks, tankSearch, tankStatus } = props;

  const [tankScrollCount, setTankScrollCount] = useState(50);
  const handleTankCountIncrement = (e: any) => {
    setTankScrollCount(tankScrollCount + 50);
    e && e.target && e.target.complete();
  };

  const handleExpandGroup = (groupId: string) => () =>
    !tankSearch &&
    setGroupExpanded({
      ...groupExpanded,
      [groupId]: !(groupExpanded as any)[groupId],
    });

  if (!groupedTanks.length) return <NoTankPlaceholder tankStatus={tankStatus} />;

  return (
    <React.Fragment>
      {groupedTanks.slice(0, tankScrollCount).map((group: any) => {
        const tankSelectedCountInGroup = group.tanks.filter(
          (tank: any) => Object.keys(selectedTanks).includes(tank.serial) && selectedTanks[tank.serial]
        ).length;
        return group && group.tanks && group.tanks.length ? (
          <IonItemGroup key={group._id}>
            <IonItemDivider onClick={handleExpandGroup(group._id)}>
              <IonLabel>{group.name}</IonLabel>
              <IonBadge color={tankSelectedCountInGroup ? `secondary` : `medium`} slot={`end`}>
                {tankSelectedCountInGroup ? `${tankSelectedCountInGroup}/` : ``}
                {group.tanks.length}
              </IonBadge>
              <IonIcon icon={(groupExpanded as any)[group._id] ? arrowUp : arrowDown} slot={`end`} />
            </IonItemDivider>
            {((groupExpanded as any)[group._id] || tankSearch) &&
              group.tanks.map((tank: any) => {
                const isDanger = moment().diff(moment(tank.updatedAt), 'days') >= 90;
                return (
                  <IonItem key={tank.serial}>
                    <IonLabel className={`ion-padding-right`}>
                      <IonText color={isDanger ? `danger` : undefined}>{tank.serial}</IonText>
                      <p>
                        <IonText color={isDanger ? `danger` : undefined}>
                          Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}
                        </IonText>
                      </p>
                      {tank.clientUserId && (
                        <p>
                          <IonText color={isDanger ? `danger` : `primary`}>{tank.clientUserId.name}</IonText>
                        </p>
                      )}
                      <p className={`ion-fixed-end`}>
                        <IonText color={isDanger ? `danger` : undefined}>
                          {moment(tank.updatedAt).format('DD/MM/YYYY HH:mm')}
                        </IonText>
                      </p>
                    </IonLabel>
                    <IonCheckbox
                      slot={`start`}
                      onIonChange={onCheckboxChange(tank.serial)}
                      checked={(selectedTanks as any)[tank.serial]}
                    />
                  </IonItem>
                );
              })}
          </IonItemGroup>
        ) : null;
      })}
      <IonInfiniteScroll threshold={`200px`} onIonInfinite={handleTankCountIncrement}>
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </React.Fragment>
  );
};

export const TestSerialTankList: React.FC<any> = props => {
  const { tanks, setSubmitting, onActionModalConfirm, tankStatus, tankSearch, createTank, updateTank } = props;
  const [selectedTank, setSelectedTank] = useState<any>(null);

  const handleSelectedTank = (tank: any) => () => {
    tank && setSelectedTank(tank);
  };

  const handleCreateTank = (serial: string) => {
    setSelectedTank({ serial: serial.toUpperCase(), isNew: true });
  };

  const handleDisableTank = (tank: any) => () => {
    setSelectedTank({
      ...tank,
      willDisable: true,
    });
  };

  const handleModalClose = () => {
    setSelectedTank(null);
  };

  const handleActionModalConfirm = _.debounce(isSuccess => {
    onActionModalConfirm && onActionModalConfirm(isSuccess);
  }, 500);

  const handleModalConfirm = _.throttle(async ({ newTankType, newTankStatus, collectTankStatus } = {}) => {
    if (selectedTank) {
      setSubmitting(true);
      const submitData = {
        serial: selectedTank.serial,
        clientUserId: null,
        invoiceId: null,
      };
      if (selectedTank.willDisable) {
        (submitData as any).status = backendConstants.TANK_STATUSES.DISABLED;
      } else {
        switch (selectedTank.status) {
          case backendConstants.TANK_STATUSES.EMPTY: {
            (submitData as any).status = backendConstants.TANK_STATUSES.LOADING;
            break;
          }
          case backendConstants.TANK_STATUSES.LOADING: {
            (submitData as any).status = backendConstants.TANK_STATUSES.READY_TO_USE;
            break;
          }
          case backendConstants.TANK_STATUSES.AT_CLIENT: {
            (submitData as any).status = collectTankStatus;
            break;
          }
          case backendConstants.TANK_STATUSES.DISABLED: {
            (submitData as any).status = backendConstants.TANK_STATUSES.EMPTY;
            break;
          }
          default: {
            break;
          }
        }
      }
      if (newTankType && newTankStatus) {
        try {
          await createTank({
            store: getStore(),
            ...submitData,
            type: newTankType,
            status: newTankStatus,
          });
          handleActionModalConfirm(true);
        } catch (err) {
          handleActionModalConfirm(false);
        }
      } else {
        if ((submitData as any).status) {
          try {
            await updateTank({
              store: getStore(),
              ...submitData,
            });
            handleActionModalConfirm(true);
          } catch (err) {
            handleActionModalConfirm(false);
          }
        }
      }
    }
  }, 3000, { leading: true, trailing: false });

  let modalTitle = selectedTank && languages.manageTank.ui.actions[selectedTank.status];
  if (selectedTank && selectedTank.isNew) {
    modalTitle = languages.manageTank.ui.actions.CREATE;
  }
  if (selectedTank && selectedTank.willDisable) {
    modalTitle = languages.manageTank.ui.actions.SET_DISABLED;
  }

  let confirmLabel = selectedTank && languages.manageTank.ui.modalActions[selectedTank.status];
  if (selectedTank && selectedTank.isNew) {
    confirmLabel = languages.manageTank.ui.modalActions.CREATE;
  }
  if (selectedTank && selectedTank.willDisable) {
    confirmLabel = languages.manageTank.ui.modalActions.SET_DISABLED;
  }

  return (
    <React.Fragment>
      <TankStatusChangeModal
        title={modalTitle}
        isOpen={!!selectedTank}
        isCreate={selectedTank && selectedTank.isNew}
        willDisable={selectedTank && selectedTank.willDisable}
        onClose={handleModalClose}
        tankStatusTab={tankStatus}
        tanksBySelectedTanks={selectedTank ? [selectedTank] : []}
        onConfirm={handleModalConfirm}
        confirmLabel={confirmLabel}
      />
      {tanks && !!tanks.length ? (
        tanks.map((tank: any) => (
          <TankTestItem
            key={tank.serial}
            danger={tank.status === backendConstants.TANK_STATUSES.DISABLED}
            warning={
              [
                backendConstants.TANK_STATUSES.EMPTY,
                backendConstants.TANK_STATUSES.LOADING,
                backendConstants.TANK_STATUSES.READY_TO_USE,
                backendConstants.TANK_STATUSES.AT_CLIENT,
              ].includes(tank.status) && moment().diff(moment(tank.updatedAt), 'days') >= 90
            }
          >
            <IonItemSliding>
              <IonItem>
                <IonLabel>
                  <IonText>{tank.serial}</IonText>
                  <p>
                    Kho:{' '}
                    <IonText color={tank.store === getStore() ? `primary` : `danger`}>
                      {(vnConstants as any)[tank.store].name}
                    </IonText>
                  </p>
                  <p>Loại vỏ: {(languages.manageTank.ui as any)[tank.type]}</p>
                  {tank.clientUserId && (
                    <p>
                      <IonText color={`primary`}>{tank.clientUserId.name}</IonText>
                    </p>
                  )}
                  <p className={`ion-text-capitalize tank-update-date`}>
                    Ngày cập nhật: {moment(tank.updatedAt).format('DD/MM/YYYY')}
                  </p>
                  <p>
                    Trạng thái: {languages.manageTank.ui.statuses[tank.status]}
                    <IonText color={`secondary`}>
                      {tank.invoiceId && tank.invoiceId.status === backendConstants.INVOICE_STATUSES.APPROVED && ` - Chờ giao`}
                      {tank.invoiceId &&
                        tank.invoiceId.status === backendConstants.INVOICE_STATUSES.DELIVERING &&
                        ` - Đang giao`}
                    </IonText>
                  </p>
                  {tank.invoiceId && <p>Mã Đơn: {generalUtils.getFormedIdx(tank.invoiceId.invoiceIdx, `PLT-`)}</p>}
                </IonLabel>
                {tank.store === getStore() &&
                  languages.manageTank.ui.actions[tank.status] &&
                  tank.status !== backendConstants.TANK_STATUSES.READY_TO_USE && (
                    <IonButton
                      slot={`end`}
                      color={tank.status === backendConstants.TANK_STATUSES.DISABLED ? `danger` : `primary`}
                      onClick={handleSelectedTank(tank)}
                    >
                      {languages.manageTank.ui.actions[tank.status]}
                    </IonButton>
                  )}
              </IonItem>
              {tank.store === getStore() && tank.status !== backendConstants.TANK_STATUSES.DISABLED && (
                <IonItemOptions side={`end`}>
                  <IonItemOption color={`transparent`}>
                    <IonButton slot={`end`} color={`danger`} size={`small`} onClick={handleDisableTank(tank)}>
                      {languages.manageTank.ui.actions.SET_DISABLED}
                    </IonButton>
                  </IonItemOption>
                </IonItemOptions>
              )}
            </IonItemSliding>
          </TankTestItem>
        ))
      ) : (
        <NoTankPlaceholder tankStatus={tankStatus} tankSearch={tankSearch} onCreateTank={handleCreateTank} />
      )}
    </React.Fragment>
  );
};

export const ManageTank: React.FC<any> = props => {
  const {
    setSubmitting,
    setToast,
    tankReducer,
    createTank,
    checkTanks,
    readTanks,
    updateTank,
    updateTanks,
    resetTanks,
    userClientReducer,
    checkUserClients,
    resetUserClients,
    equipmentReducer,
    checkEquipments,
    resetEquipments,
    createInvoice,
    selfReducer,
    updateAll,
    history,
    location,
  } = props;

  const queryParams = qs.parse(props.location.search.replace('?', ''));

  const showContent = useMemo(() => {
    return location.pathname === routes.manageTank.main;
  }, [location.pathname]);

  const [refreshing, setRefreshing] = useState(false);
  const [tankStatus, setTankStatus] = useState<string>(
    (queryParams.tankStatus as string) || backendConstants.TANK_STATUSES.EMPTY
  );

  const [modalByTankStatus, setModalByTankStatus] = useState('');
  const [tankSearch, setTankSearch] = useState('');
  const [selectedTanks, setSelectedTanks] = useState({});
  const [canOpenModal, setCanOpenModal] = useState(true);

  const tanks = tankReducer.responseRead && tankReducer.responseRead.data;
  const updatedTanks = tankReducer.responseUpdate && tankReducer.responseUpdate.data;
  const userClients = userClientReducer.responseCheck && userClientReducer.responseCheck.data;
  const equipments = equipmentReducer.responseCheck && equipmentReducer.responseCheck.data;
  const self = selfReducer.response;

  const handleTankStatusChange = (event: any) => {
    if (event && event.detail && event.detail.value) {
      resetTanks();
      setTankStatus(event.detail.value);
      history.push(`${routes.manageTank.main}?tankStatus=${event.detail.value}`);
    }
  };

  useEffect(() => {
    if (queryParams.tankStatus) {
      setTankStatus(queryParams.tankStatus as any);
    }
  }, [queryParams.tankStatus]);

  const handleSearchChange = (event: any) => {
    event && event.target && setTankSearch(event.target.value);
  };

  const handleOpenModalByTankStatus = (key: string) => () => {
    key && setModalByTankStatus(key);
  };

  const handleCheckboxChange = (key: string) => (event: any) => {
    return (
      event &&
      event.target &&
      setSelectedTanks({
        ...(selectedTanks as any),
        [key]: event.target.checked,
      })
    );
  };

  const handleModalClose = (noReload: boolean) => {
    resetModalByTankStatus();
    if (!noReload) {
      resetCurrentTankStatusTab(tankStatus === TEST_SERIAL);
      handleReadTank();
    }
  };

  const handleReadUserClient = () => {
    return checkUserClients({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        status: backendConstants.USER_STATUSES.ACTIVE,
        userType: backendConstants.USER_TYPES.CLIENT,
      },
      populate: {
        path: 'clientId',
        select: '_id name type',
      },
    });
  };

  const handleReadEquipment = () => {
    return checkEquipments({
      page: 1,
      pageSize: 99999,
      filter: {
        store: getStore(),
        status: {
          $in: [
            backendConstants.EQUIPMENT_STATUSES.SOLD,
            backendConstants.EQUIPMENT_STATUSES.RENTED,
            backendConstants.EQUIPMENT_STATUSES.LENT,
          ],
        },
      },
      populate: {
        path: 'clientUserId',
        select: '_id username name type',
      },
    });
  };

  const handleReadTank = () => {
    return readTanks({
      page: 1,
      pageSize: tankStatus === TEST_SERIAL ? 100 : 99999,
      filter: {
        store: tankStatus === TEST_SERIAL && isCenterStore() ? undefined : getStore(),
        ...(tankStatus === TEST_SERIAL
          ? {
              serial: {
                $regex: tankSearch,
                $options: 'i',
              },
              status: {
                $ne: backendConstants.TANK_STATUSES.TRASH,
              },
            }
          : {
              status: tankStatus,
            }),
      },
      populate: [
        {
          path: 'clientUserId',
          select: '_id name clientId',
          populate: 'clientId',
        },
        {
          path: 'invoiceId',
          select: '_id invoiceIdx clientUserId status',
          populate: 'clientUserId',
        },
      ],
      sort: {
        updatedAt: 1,
      },
    });
  };

  const closeUpdateModalDebounced = _.debounce(isSuccessful => {
    if (isSuccessful) {
      setToast(`Cập nhật thành công`, `primary`);
    } else {
      setToast(`Cập nhật không thành công`, `danger`);
    }
    handleModalClose(false);
    setSubmitting(false);
  }, 500);

  const handleModalConfirm = async ({ userClientId: clientUserId, collectTankStatus } = {} as any) => {
    try {
      setSubmitting(true);
      let submitData = {
        serial: Object.keys(selectedTanks).filter(serial => (selectedTanks as any)[serial]),
        clientUserId: clientUserId || null,
        invoiceId: null,
      };

      resetTanks();
      const checkTanksResponse = await checkTanks({
        page: 1,
        pageSize: 99999,
        filter: {
          store: getStore(),
          serial: {
            $in: submitData.serial,
          },
          status: tankStatus,
        },
        populate: {
          path: 'clientUserId',
          select: '_id name clientId',
          populate: 'clientId',
        },
        sort: {
          updatedAt: 1,
        },
      });

      const tanksByCheckTanks = checkTanksResponse && checkTanksResponse.data && checkTanksResponse.data.data;

      if (tanksByCheckTanks && tanksByCheckTanks.length === submitData.serial.length) {
        switch (tankStatus) {
          case backendConstants.TANK_STATUSES.EMPTY: {
            (submitData as any).status = backendConstants.TANK_STATUSES.LOADING;
            break;
          }
          case backendConstants.TANK_STATUSES.LOADING: {
            (submitData as any).status = backendConstants.TANK_STATUSES.READY_TO_USE;
            break;
          }
          case backendConstants.TANK_STATUSES.READY_TO_USE: {
            const newInvoiceResponse = await createInvoice({
              store: getStore(),
              type: backendConstants.INVOICE_TYPES.NORMAL,
              status: backendConstants.INVOICE_STATUSES.REQUEST,
              ownerUser: self.user._id,
              clientUserId: clientUserId,
              tanks: [],
              note: `Tạo bởi ${self.user.name}`,
            });

            const newInvoice = newInvoiceResponse && newInvoiceResponse.data && newInvoiceResponse.data.data;

            (submitData as any).invoiceId = newInvoice._id;

            (submitData as any).status = backendConstants.TANK_STATUSES.LOCKED;
            break;
          }
          case backendConstants.TANK_STATUSES.AT_CLIENT: {
            (submitData as any).status = collectTankStatus;
            break;
          }
          default: {
            break;
          }
        }

        if (submitData.invoiceId) {
          await updateAll({
            tanks: submitData.serial.map(s => ({
              ...submitData,
              serial: s,
            })),
            invoices: [
              {
                _id: submitData.invoiceId,
                store: getStore(),
                status: backendConstants.INVOICE_STATUSES.APPROVED,
                tanks: tanksByCheckTanks.map((tank: any) => tank._id),
              },
            ],
          });
        } else {
          await updateTanks({
            store: getStore(),
            ...submitData,
          });
        }

        closeUpdateModalDebounced(true);
      } else {
        closeUpdateModalDebounced(false);
      }
    } catch (err) {
      closeUpdateModalDebounced(false);
    }
  };

  const resetSelectedTanks = () => setSelectedTanks({});
  const resetTankSearch = () => setTankSearch('');
  const resetModalByTankStatus = () => setModalByTankStatus('');
  const resetCanModalOpen = () => setCanOpenModal(true);

  const resetCurrentTankStatusTab = (keepSearch?: boolean) => {
    resetTanks();
    resetSelectedTanks();
    !keepSearch && resetTankSearch();
    resetCanModalOpen();
    // Reset Content Scroll
    resetContentScrollTop();
  };

  const tankSelectedCount = useMemo(() => {
    return Object.values(selectedTanks).filter(tank => tank).length;
  }, [selectedTanks]);

  const tanksBySelectedTanks = useMemo(() => {
    const tankKeys = Object.keys(selectedTanks).filter(serial => (selectedTanks as any)[serial]);
    return tanks && tanks.length && selectedTanks
      ? tanks
          .filter((tank: any) => tankKeys.includes(tank.serial))
          .sort((tankA: any, tankB: any) => tankA.serial.localeCompare(tankB.serial))
      : [];
  }, [tanks, selectedTanks]);

  const tanksBySearch = useMemo(() => {
    return tanks && tanks.length
      ? tanks.filter((tank: any) => (tank.serial as string).toLowerCase().includes(tankSearch.toLowerCase()))
      : [];
  }, [tanks, tankSearch]);

  const groupTanksByClient = useMemo(() => {
    if (tankStatus !== backendConstants.TANK_STATUSES.AT_CLIENT) return [];
    if (!tanksBySearch || !tanksBySearch[0] || tanksBySearch[0].status !== backendConstants.TANK_STATUSES.AT_CLIENT) return [];

    const tanks = tanksBySearch.filter((tank: any) => tank.clientUserId && tank.clientUserId.clientId);

    const tanksGroupedByClientId = _.groupBy(tanks, (tank: any) => {
      return tank && tank.clientUserId && tank.clientUserId.clientId && tank.clientUserId.clientId._id;
    });

    if (Object.keys(tanksGroupedByClientId).length <= 1 && Object.keys(tanksGroupedByClientId)[0] === 'undefined') return [];

    return Object.values(tanksGroupedByClientId)
      .map(group => {
        return {
          _id: group[0].clientUserId.clientId._id,
          name: group[0].clientUserId.clientId.name,
          tanks: group,
        };
      })
      .sort((aGroup, bGroup) => aGroup.name.localeCompare(bGroup.name));
  }, [tanksBySearch, tankStatus]);

  useEffect(() => {
    if (tankStatus === backendConstants.TANK_STATUSES.READY_TO_USE) {
      handleReadUserClient();
      handleReadEquipment();
    } else {
      resetUserClients();
      resetEquipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankStatus, props.location.pathname]);

  useEffect(() => {
    if (showContent) {
      resetCurrentTankStatusTab(tankStatus === TEST_SERIAL);
      if (tankStatus !== TEST_SERIAL) {
        handleReadTank();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankStatus, updatedTanks, showContent]);

  useEffect(() => {
    if (showContent) {
      if (tankStatus === TEST_SERIAL) {
        if (tankSearch.length >= MIN_CHAR) {
          handleReadTank();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankStatus, updatedTanks, tankSearch, showContent]);

  const setRefereshingDebounced = _.debounce((event: any) => {
    setRefreshing(false);
    event.detail.complete();
  }, 250);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setRefreshing(true);
    resetCurrentTankStatusTab(true);
    await handleReadTank();
    setRefereshingDebounced(event);
  };

  const contentDOMRef = useRef<any>(null);

  const resetContentScrollTop = generalUtils.ionContentScrollToTop(contentDOMRef);

  return (
    <Wrapper>
      <IonPage>
        <Header title={languages.manageTank.main} hasMenu={true}>
          <SubHeader>
            <IonSegment onIonChange={handleTankStatusChange} scrollable={true}>
              <IonSegmentButton
                value={backendConstants.TANK_STATUSES.EMPTY}
                checked={tankStatus === backendConstants.TANK_STATUSES.EMPTY}
              >
                {languages.manageTank.ui.tabs[backendConstants.TANK_STATUSES.EMPTY]}
              </IonSegmentButton>
              <IonSegmentButton
                value={backendConstants.TANK_STATUSES.LOADING}
                checked={tankStatus === backendConstants.TANK_STATUSES.LOADING}
              >
                {languages.manageTank.ui.tabs[backendConstants.TANK_STATUSES.LOADING]}
              </IonSegmentButton>
              <IonSegmentButton
                value={backendConstants.TANK_STATUSES.READY_TO_USE}
                checked={tankStatus === backendConstants.TANK_STATUSES.READY_TO_USE}
              >
                {languages.manageTank.ui.tabs[backendConstants.TANK_STATUSES.READY_TO_USE]}
              </IonSegmentButton>
              <IonSegmentButton
                value={backendConstants.TANK_STATUSES.AT_CLIENT}
                checked={tankStatus === backendConstants.TANK_STATUSES.AT_CLIENT}
              >
                {languages.manageTank.ui.tabs[backendConstants.TANK_STATUSES.AT_CLIENT]}
              </IonSegmentButton>
              <IonSegmentButton value={TEST_SERIAL} checked={tankStatus === TEST_SERIAL}>
                {languages.manageTank.ui.tabs.TEST_SERIAL}
              </IonSegmentButton>
            </IonSegment>

            <div>
              <IonSearchbar
                disabled={tankReducer.isFetching}
                placeholder={`Tìm ${tankStatus === backendConstants.TANK_STATUSES.READY_TO_USE ? `bình` : `vỏ`} theo serial...`}
                value={tankSearch}
                onIonChange={handleSearchChange}
                debounce={250}
              />
            </div>
          </SubHeader>
        </Header>
        <ShowContent isShow={showContent}>
          <IonContent className={`ion-padding-horizontal`} ref={contentDOMRef}>
            {tankStatus !== TEST_SERIAL && (
              <IonRefresher slot={`fixed`} pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={doRefresh}>
                <IonRefresherContent />
              </IonRefresher>
            )}
            {tankSelectedCount > 0 && (
              <React.Fragment>
                <IonFab vertical={`bottom`} horizontal={`end`} slot={`fixed`}>
                  {canOpenModal ? (
                    <IonButton color={`primary`} onClick={handleOpenModalByTankStatus(tankStatus)}>
                      <IonLabel>{languages.manageTank.ui.actions[tankStatus]}</IonLabel>
                      <IonBadge color={`light`}>{tankSelectedCount}</IonBadge>
                    </IonButton>
                  ) : (
                    <IonButton color={`danger`}>
                      <IonLabel>Chọn lại mã</IonLabel>
                      <IonBadge color={`light`}>{tankSelectedCount}</IonBadge>
                    </IonButton>
                  )}
                </IonFab>
                <TankStatusChangeModal
                  title={languages.manageTank.ui.actions[tankStatus]}
                  isOpen={modalByTankStatus === tankStatus}
                  onCanOpenModal={setCanOpenModal}
                  onClose={handleModalClose}
                  tanksBySelectedTanks={tanksBySelectedTanks}
                  tankStatusTab={tankStatus}
                  userClients={tankStatus === backendConstants.TANK_STATUSES.READY_TO_USE && userClients}
                  equipments={tankStatus === backendConstants.TANK_STATUSES.READY_TO_USE && equipments}
                  onRemoveTank={handleCheckboxChange}
                  onConfirm={handleModalConfirm}
                  confirmLabel={languages.manageTank.ui.modalActions[tankStatus]}
                />
              </React.Fragment>
            )}
            <IonGrid className={`ion-padding-bottom`}>
              <div className="ion-align-items-center">
                {tankReducer.isFetching || userClientReducer.isFetching ? (
                  <IonGrid className={`ion-padding`}>
                    <IonRow className={`ion-padding ion-justify-content-center`}>
                      <IonCol className={`ion-text-center`}>{!refreshing && <IonSpinner name={`lines`} />}</IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <div>
                    <IonList className={`ion-margin-vertical`}>
                      {tanks && !!tanks.length && (
                        <IonListHeader>
                          Kết quả: {tanksBySearch && tanksBySearch.length}{' '}
                          {tankStatus === backendConstants.TANK_STATUSES.READY_TO_USE ? `bình` : `vỏ`}
                        </IonListHeader>
                      )}
                      {tankStatus === backendConstants.TANK_STATUSES.AT_CLIENT ? (
                        <GroupedTankList
                          tankStatus={tankStatus}
                          groupedTanks={groupTanksByClient}
                          selectedTanks={selectedTanks}
                          tankSearch={tankSearch}
                          onCheckboxChange={handleCheckboxChange}
                        />
                      ) : tankStatus === TEST_SERIAL ? (
                        <TestSerialTankList
                          tankStatus={tankStatus}
                          tanks={tanksBySearch}
                          createTank={createTank}
                          updateTank={updateTank}
                          tankSearch={tankSearch}
                          setSubmitting={setSubmitting}
                          onActionModalConfirm={closeUpdateModalDebounced}
                        />
                      ) : (
                        <NormalTankList
                          tankStatus={tankStatus}
                          tanks={tanksBySearch}
                          selectedTanks={selectedTanks}
                          onCheckboxChange={handleCheckboxChange}
                        />
                      )}
                    </IonList>
                  </div>
                )}
              </div>
            </IonGrid>
          </IonContent>
        </ShowContent>
      </IonPage>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  tankReducer: state.tankReducer,
  userClientReducer: state.userClientReducer,
  equipmentReducer: state.equipmentReducer,
  selfReducer: state.selfReducer,
});

const mapDispatchToProps = {
  setSubmitting: submittingActions.setSubmitting,
  setToast: toastActions.setToast,

  checkTanks: tankActions.checkTanks,
  readTanks: tankActions.readTanks,
  resetTanks: tankActions.resetTanks,
  updateTank: tankActions.updateTank,
  updateTanks: tankActions.updateTanks,
  createTank: tankActions.createTank,

  checkUserClients: userClientActions.checkUserClients,
  resetUserClients: userClientActions.resetUserClients,

  checkEquipments: equipmentActions.checkEquipments,
  resetEquipments: equipmentActions.resetEquipments,

  createInvoice: invoiceActions.createInvoice,

  updateAll: allActions.updateAll,
};

// const withLazyContent = (pathname: string) => (
//   Component: React.FC
// ): React.FC<any> => (props: any) => {
//   const { location } = props;
//   if (location && location.pathname === pathname) {
//     return <Component {...props} />;
//   } else {
//     return null;
//   }
// };

export default connect(mapStateToProps, mapDispatchToProps)(ManageTank);
