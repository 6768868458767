import axios from 'axios';

export const crudActionName = {
  CHECK: 'CHECK',
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  UPDATE_BATCH: 'UPDATE_BATCH',
};

export const crudActionEndpoint = {
  [crudActionName.CHECK]: '',
  [crudActionName.READ]: '',
  [crudActionName.CREATE]: '/create',
  [crudActionName.UPDATE]: '/update',
  [crudActionName.UPDATE_BATCH]: '/update-batch',
};

export interface CrudActionType {
  pendingAction: string;
  fulfilledAction: string;
  failedAction: string;
  resetStateAction: string;
}

export const generateCrudActionTypes = (prefix: string): CrudActionType => {
  return {
    pendingAction: `${prefix}_PENDING`,
    fulfilledAction: `${prefix}_FULFILLED`,
    failedAction: `${prefix}_FAILED`,
    resetStateAction: `${prefix}_RESET_STATE`,
  };
};

export const generateThunkActions = (crudActions: CrudActionType, endpoint: string, config: object = {}): any => {
  const generateRequest = (crudName: string, overrideEndpoint?: string, extraConfig: object = {}) => (
    data: object = {},
    currentRequestConfig: object = {}
  ) => (dispatch: Function) => {
    let currentEndpoint = endpoint;
    currentEndpoint += overrideEndpoint || crudActionEndpoint[crudName];

    dispatch({
      type: crudActions.pendingAction,
      payload: {
        crud: crudName,
      },
    });

    const request = axios({
      method: 'post',
      url: currentEndpoint,
      data,
      ...config,
      ...extraConfig,
      ...currentRequestConfig,
    });

    request
      .then(result => {
        dispatch({
          type: crudActions.fulfilledAction,
          payload: {
            crud: crudName,
            data: result.data,
          },
        });
      })
      .catch(err => {
        dispatch({
          type: crudActions.failedAction,
          payload: {
            crud: crudName,
            data: err,
          },
        });
      });
    return request;
  };

  const resetState = () => (dispatch: Function) => {
    return dispatch({
      type: crudActions.resetStateAction,
    });
  };

  return {
    check: generateRequest(crudActionName.CHECK),
    read: generateRequest(crudActionName.READ),
    create: generateRequest(crudActionName.CREATE),
    update: generateRequest(crudActionName.UPDATE),
    updateBatch: generateRequest(crudActionName.UPDATE_BATCH),
    generateRequest: generateRequest,
    resetState,
  };
};

export const initialCrudState = {
  error: null,
  isFetching: null,
  response: null,
};

export const getCrudSuffix = (crud: string) => {
  return (
    crud &&
    crud
      .toLowerCase()
      .replace(/^\w/, (char: string) => char.toUpperCase())
      .replace(/_\w/, (char: string) => char.charAt(1).toUpperCase())
  );
};

export const generateCrudReducer = (crudActions: CrudActionType, payloadMapper?: Function) => (
  state = initialCrudState,
  action: any
) => {
  const { type, payload = {} } = action;
  const { crud } = payload;
  const currentPayload = payloadMapper && payload ? payloadMapper(payload, type, state) : payload;
  const response = currentPayload ? currentPayload.data : null;
  if (!crud && type !== crudActions.resetStateAction) return state;

  const crudSuffix = getCrudSuffix(crud);

  switch (type) {
    case crudActions.pendingAction: {
      return {
        ...state,
        isFetching: (crudActionName as any)[crud] || crud,
        [`isFetching${crudSuffix}`]: true,
      };
    }
    case crudActions.fulfilledAction: {
      return {
        ...state,
        error: null,
        isFetching: null,
        [`isFetching${crudSuffix}`]: null,
        [`response${crudSuffix}`]: response,
      };
    }
    case crudActions.failedAction: {
      return {
        ...state,
        error: response,
        isFetching: null,
        [`isFetching${crudSuffix}`]: null,
        [`response${crudSuffix}`]: null,
      };
    }
    case crudActions.resetStateAction: {
      return initialCrudState;
    }
    default: {
      return state;
    }
  }
};
