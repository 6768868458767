import styled from 'styled-components';
// import { getPlatforms } from '@ionic/react';

export const AppWrapper: any = styled.div`
  ion-tab-bar, ion-split-pane {
    // transition: opacity .15s ease-out;
    // opacity: ${(props: any) => (props.inactived ? 0 : 1)};
    pointer-events: ${(props: any) => (props.inactived ? 'none' : 'all')};
  }
`;
