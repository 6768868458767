import backendConstants from './backendConstants';

// This is used only once to set to axios default baseUrl,
// Don't use it with axios calling, just use backendConstants.API_PATH as normal
export const API_PATH = process.env[`REACT_APP_${process.env.REACT_APP_BACKEND}_API_PATH`];

export const VIEW_PERMISSIONS = {
  VIEW_HOME: 'VIEW_HOME',
  VIEW_HOME_CLIENT: 'VIEW_HOME_CLIENT',

  VIEW_STORE_TRANSFER: 'VIEW_STORE_TRANSFER',

  VIEW_REPORT: 'VIEW_REPORT',
  VIEW_TANK: 'VIEW_TANK',
  VIEW_EQUIPMENT: 'VIEW_EQUIPMENT',
  VIEW_CLIENT: 'VIEW_CLIENT',
  VIEW_USER: 'VIEW_USER',

  VIEW_INVOICE: 'VIEW_INVOICE',
  VIEW_INVOICE_CLIENT: 'VIEW_INVOICE_CLIENT',

  VIEW_REPORT_CLIENT: 'VIEW_REPORT_CLIENT',

  VIEW_RESTORE_DB: 'VIEW_RESTORE_DB',

  VIEW_INVOICE_DELIVERY: 'VIEW_INVOICE_DELIVERY',
  VIEW_REPORT_DELIVERY: 'VIEW_REPORT_DELIVERY',

  VIEW_REPORT_SALE: 'VIEW_REPORT_SALE',
  VIEW_INVOICE_SALE: 'VIEW_INVOICE_SALE',
  VIEW_CLIENT_SALE: 'VIEW_CLIENT_SALE',
  VIEW_CLIENT_TECH: 'VIEW_CLIENT_TECH',

  VIEW_EQUIPMENT_TECHNICAL: 'VIEW_EQUIPMENT_TECHNICAL',
};

export const VIEW_PERMISSIONS_GROUPS = {
  [backendConstants.USER_TYPES.ADMIN]: [
    VIEW_PERMISSIONS.VIEW_HOME,
    VIEW_PERMISSIONS.VIEW_REPORT,
    VIEW_PERMISSIONS.VIEW_TANK,
    VIEW_PERMISSIONS.VIEW_EQUIPMENT,
    VIEW_PERMISSIONS.VIEW_CLIENT,
    VIEW_PERMISSIONS.VIEW_USER,
    VIEW_PERMISSIONS.VIEW_INVOICE,
    VIEW_PERMISSIONS.VIEW_RESTORE_DB,
    VIEW_PERMISSIONS.VIEW_STORE_TRANSFER,
  ],
  [backendConstants.USER_TYPES.STORE]: [
    VIEW_PERMISSIONS.VIEW_HOME,
    VIEW_PERMISSIONS.VIEW_REPORT,
    VIEW_PERMISSIONS.VIEW_TANK,
    VIEW_PERMISSIONS.VIEW_EQUIPMENT,
    VIEW_PERMISSIONS.VIEW_CLIENT,
    VIEW_PERMISSIONS.VIEW_USER,
    VIEW_PERMISSIONS.VIEW_INVOICE,
    VIEW_PERMISSIONS.VIEW_RESTORE_DB,
  ],
  [backendConstants.USER_TYPES.REVIEWER]: [
    VIEW_PERMISSIONS.VIEW_HOME,
    VIEW_PERMISSIONS.VIEW_REPORT,
    VIEW_PERMISSIONS.VIEW_TANK,
    VIEW_PERMISSIONS.VIEW_EQUIPMENT,
    VIEW_PERMISSIONS.VIEW_CLIENT,
    VIEW_PERMISSIONS.VIEW_USER,
    VIEW_PERMISSIONS.VIEW_INVOICE,
  ],
  [backendConstants.USER_TYPES.SALE]: [
    VIEW_PERMISSIONS.VIEW_INVOICE_SALE,
    VIEW_PERMISSIONS.VIEW_REPORT_SALE,
    VIEW_PERMISSIONS.VIEW_CLIENT_SALE,
  ],
  [backendConstants.USER_TYPES.DELIVERY]: [VIEW_PERMISSIONS.VIEW_INVOICE_DELIVERY, VIEW_PERMISSIONS.VIEW_REPORT_DELIVERY],
  [backendConstants.USER_TYPES.TECHNICAL]: [VIEW_PERMISSIONS.VIEW_EQUIPMENT_TECHNICAL, VIEW_PERMISSIONS.VIEW_CLIENT_TECH],
  [backendConstants.USER_TYPES.CLIENT]: [
    VIEW_PERMISSIONS.VIEW_HOME_CLIENT,
    VIEW_PERMISSIONS.VIEW_INVOICE_CLIENT,
    VIEW_PERMISSIONS.VIEW_REPORT_CLIENT,
  ],
};

export default {
  API_PATH,
  VIEW_PERMISSIONS,
  VIEW_PERMISSIONS_GROUPS,
};
